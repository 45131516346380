<app-header></app-header>
<mat-card class="adminBody">

    <mat-card-content class="addEditPageWrapper addEditPractice practice_form_style form_title_style">

        <ng-container *ngIf="addFormLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>


        <h2 class="listingpageh2">{{isedit?'Edit':'Add'}} Practice</h2>
        <div class="AddEditBlog location_style">
            <!--[ngClass]="externaldatavalue.length !== 0? externaldatavalue.length > 3 ? 'location_height2':'location_height1  ':''"-->

            <div class="addEditForm">
                <lib-showform [formdata]="practiceformdata" [formfieldrefreshdata]="formfieldrefreshdata" (onFormFieldChange)="listenFormFieldChange($event)">
                </lib-showform>
                <!-- <ng-container *ngIf="userImage">
                    <img [src]="userImage">
                </ng-container> -->
                <mat-card class="location_col_wrapper" *ngIf="externaldatavalue && externaldatavalue.length > 0">
                    <div class="location_col" *ngFor="let item of externaldatavalue;let i = index">
                        <p><strong>Location Name : </strong> {{item.location_name}}</p>
                        <p><strong>Address :</strong> {{item.address}}</p>
                        <p><strong>City :</strong> {{item.city}}</p>
                        <p><strong>State :</strong> {{item.state}}</p>
                        <p><strong>Zip :</strong> {{item.zip}}</p>
                        <p><strong>Phone :</strong> {{item.phone}}</p>
                        <div class="location_iconwrapper">
                            <a (click)="deleteLocation(item,i)">
                                <mat-icon>close</mat-icon>
                            </a>
                            <a (click)="editLocation(item,i)">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </div>
                </mat-card>

                <ng-container *ngIf="addFormLoader">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </ng-container>

            </div>

        </div>

    </mat-card-content>

    <span class="footerBlock"><app-footer></app-footer></span>
</mat-card>