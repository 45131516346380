<button class="close_button" style="right: 0;top: 0;">
    <mat-icon style="cursor: pointer;" (click)="closeModal()"> cancel</mat-icon>
</button>

<div class="symptoms_bodal_container">
    <mat-progress-bar mode="indeterminate" *ngIf="modalrogressBar"></mat-progress-bar>
    <ng-container>
        <mat-card-content class="new_usermanagement_right_mainpart_searchpart cw1">
            <div class="navlistwrapper">
                <div class="navlistwrapper">
                    <div class="navlist" style="display: inline;" *ngFor="let item of DiagnostselectedData;let i=index;">
                        <mat-chip class="example-box">{{item.name}}
                            <mat-icon style="cursor: pointer;" matChipRemove (click)="remove(item,i,'name')">
                                cancel</mat-icon>
                        </mat-chip>
                    </div>
                </div>
            </div>
            <mat-progress-bar mode="indeterminate" *ngIf="nameprogressFlag"></mat-progress-bar>
            <mat-form-field class="example-full-width patient_searchmodal_location_label">                
                <input type="text" placeholder="Add Diagnosis" matInput [(ngModel)]="DiagnostName"
                    (ngModelChange)="userNameModalChange.next($event)" [matAutocomplete]="nameAuto">
            </mat-form-field>
            <mat-autocomplete #nameAuto="matAutocomplete">
                <mat-option *ngFor="let diagnosticObj of diagnosticArr;let i = index" [value]="diagnosticObj.name"
                    (click)="namechooseChipItem(diagnosticObj,i)">
                    {{diagnosticObj.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-card-content>
    </ng-container>


    <div class="search_modal_buttonwrapper">
        <button mat-button (click)="onNoClick()">Submit</button>
   
    </div>
</div>