<ng-container *ngIf="modalValue.successFlag == 'alert'">
    <h2 class="alerth2">
        <mat-icon>report_problem</mat-icon>&nbsp;&nbsp;Alert
    </h2>

    <label class="labelRext">Are you sure you want to send email to
        <strong>{{modalValue.patient.patient_name}}</strong>, who has partially submit assessment form? </label>
    <div class="signmodalgroup">
        <button type="submit" mat-raised-button color="primary" (click)="sendemail('yes')">Confirm</button>
        <button type="button" mat-raised-button color="accent" (click)="sendemail('no')">Cancel</button>
    </div>
</ng-container>


<ng-container *ngIf="modalValue.successFlag == 'yes'">
    <h2 class="alerth2">
        Success
    </h2>

    <label class="labelRext">Email send to
        <strong>{{modalValue.patient.patient_name}}</strong> successfully! </label>
    <div class="signmodalgroup">
        <button type="button" mat-raised-button color="accent" (click)="sendemail('no')">Ok</button>
    </div>
</ng-container>