import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.css']
})
export class PatientHistoryComponent {
  public patientdetailsData:any;

  public folderviewdataData: any;

  @Input() set patientdetails(value: any) {

    if (value) {
      console.log("Input444444", value)

      this.patientdetailsData=value;

      console.log("patientdetailsData", this.patientdetailsData)

      
      let datasetval: any = {
        condition: {},
        searchcondition: {
          _id: this.patientdetailsData.practice_id
        },
        sort: { type: 'desc', field: '_id' },
      }
  
      // this.httpService.httpViaPost('users/folderviewdata', datasetval).subscribe((response: any) => {
      //   if (response.status == "success") {
  
  
      //     this.folderviewdataData = response.results.res[0];
          
      //     console.log(this.folderviewdataData, 'folderviewdataData+++++++++++++++')
  
      //   }
      // })
    }


  }
}
