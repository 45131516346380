import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-emcode',
  templateUrl: './emcode.component.html',
  styleUrls: ['./emcode.component.css']
})
export class EmcodeComponent {
  public eandmCodeData:any;
  public newPatient:any
  public establishedPatient:any
  // public icdCode:any

  @Input() set eandmCode(value: any) {
    if (value) {
      this.eandmCodeData= value; 
      // console.log("eandmCodeData=====>>>>>", this.eandmCodeData)
      this.eandmCodeData.evaluation_details.forEach(element => {        
        if (element.pateint_type=="new pateint") {
          this.newPatient = element
        }else{
          this.establishedPatient = element
        }        
      });

      // console.log("newPatient====>", this.newPatient);
      // console.log("establishedPatient====>", this.establishedPatient);
    }


  }
}
