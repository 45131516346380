<app-header></app-header>
<mat-card class="adminBody">

    <mat-card-content class="addEditPageWrapper practice_form_style form_title_style">

        <ng-container *ngIf="addFormLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>


        <h2 class="listingpageh2">{{isedit?'Edit':'Add'}} Taxonomies</h2>
        <div class="AddEditBlog taxonomies_formstyle">
            <div class="addEditForm">
            <lib-showform  [formdata]="practiceformdata" [formfieldrefresh]="formfieldrefresh"
                [formfieldrefreshdata]="formfieldrefreshdata" (onFormFieldChange)="listenFormFieldChange($event)">
            </lib-showform>

            <ng-container *ngIf="addFormLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>
        </div>
        </div>

    </mat-card-content>

    <span class="footerBlock"><app-footer></app-footer></span>
</mat-card>
