import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-lab-reports',
  templateUrl: './lab-reports.component.html',
  styleUrls: ['./lab-reports.component.css']
})
export class LabReportsComponent {
  public patientID: any;
  // public addFormLoader: boolean = true;
  // public order_sheet_valu: any
  // public isPatientFolderView: boolean = false;
  // public cookieData: any = null;

  // public chakeboxdataID: any = []
  // @Input() set labreports(value: any) {

  //   if (value) {
  //     this.patientID = value.patientID?.data;
  //     this.fetchRecommendedData();
  //     console.log(value, 'value0000>>>>>>>')

  //     this.cookieData = value.patientID?.cookiedata;
  //   }
  // }



  // constructor(public dialog: MatDialog, private httpService: HttpServiceService, public cookieService: CookieService) {




  // }

 
  // public fetchRecommendedData(): void {

  //   let data: any = {
  //     searchcondition: {
  //       patient_id: this.patientID,
  //       is_trash: { $exists: false }
  //     }
  //   }

  //   this.httpService.httpViaPost('intake/ordersheet', data).subscribe((response: any) => {
  //     if (response && response.status == 'success') {
  //       // console.log('response0000', response.results.res)
  //       const newValue = response.results.res.filter((obj: any, index: number) => response.results.res.findIndex((item: any) => item.diagnosis_id === obj.diagnosis_id) === index);
  //       console.log('response0000', newValue)
  //       this.order_sheet_valu = newValue;
  //       if (this.chakeboxdataID.length > 0 && this.order_sheet_valu.length > 0) {
  //         this.order_sheet_valu.forEach((e: any, i: number) => {
  //           this.chakeboxdataID.forEach((f: any) => {
  //             if (e._id == f) {
  //               e.selected = true;
  //             }
  //             if (e.status == 1) {
  //               e.selected = true;
  //             }
  //           })
  //         })
  //       }
  //       this.addFormLoader = false;
  //       // setTimeout(() => {
  //       //   this.dataFetching();
  //       // }, 100)
  //     }
  //   })
  // }






  
  public chakeboxdataID: any = []

  public order_sheet_valu: any = []

  public paramID: any

  public order_sheet_sign_value: boolean = false;

  public ordersheetloderflag: boolean = false;

  public addFormLoader: boolean = true;

  public isPatientFolderView: boolean = false;
  public fororderreject: boolean = false;
  public selecteallFlag: boolean = false;
  public selectHideFlag: boolean = false;

 



  @Input() set labreports(value: any) {

    console.log("inputupdates=======>>>>>", value);
    this.paramID = value.patientID?.data
    console.log("this.paramID", this.paramID);
    if (this.paramID) {
      this.ordersheetpage()
    }

    if (value) {
      const newValue = value.filter((obj: any, index: number) => value.findIndex((item: any) => item.diagnosis_id === obj.diagnosis_id) === index);
      console.log("inputupdates newValue response0000", newValue)
      this.order_sheet_valu = newValue;
      if (this.order_sheet_valu.length > 0) {
        this.order_sheet_valu.forEach((e: any) => {
          e.selected = false;
          if (e.status == 1) {
            e.selected = true;
            this.chakeboxdataID.push(e._id);
          }
        })
      }
      setTimeout(() => {
        this.dataFetching();
      }, 4000)
     
      

    }

  }


  @Input()
  set ordersheetSign(value: any) {
    console.log("inputupdatessign", value);
    if (value) {
      this.order_sheet_sign_value = value;
    }
  }

  @Input()
  set orderstatusreject(value: any) {
    console.log("fororderreject", value);
    if (value) {
      this.fororderreject = value;
    }
  }

  @Input()
  set needSignFlag(value: any) {
    console.log("fororderreject", value);
    if (value) {
      this.selecteallFlag = value;
    }
  }

  public cookieData: any = null;
  public masterSelected: boolean = false;
  public ordersheetSelectedData: any = [] ;


  constructor(public dialog: MatDialog, public activatedRoute: ActivatedRoute, private httpService: HttpServiceService, public cookieService: CookieService) {
    // this.paramID = this.activatedRoute.snapshot.params['_id'];
    console.log('activatedRoutelinktest++++', this.activatedRoute)
  }
  // public name: string = 'Order sheet'
  // loadingPercent = 0;
  // isStart = false;
  // res: Observable<null | string> = of(null);
  // intervalId = {} as any;
  // constructor() { }


  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      console.log("this.cookieData", this.cookieData)
      if (this.cookieData.user_type !== 'doctor' && this.cookieData.user_type !== 'sales_person') {
        this.isPatientFolderView = true;
      }

      if (this.cookieData.user_type == 'sales_person') {
        if (window.location.pathname.includes('sales_person/preview-intake')) {
          this.selecteallFlag = true;
        }
      }
    }
    if (this.activatedRoute.snapshot.routeConfig.path.includes('patient-folderview/:_id')) {
      this.isPatientFolderView = true;
      if (this.fororderreject) {
        this.isPatientFolderView = false;

      }
      // setTimeout(() => {
      //   let chakeboxclass = document.querySelector('.orderAllConSubTop');
      //   chakeboxclass.classList.add('chakeboxremove')
      // }, 4000);

    }
    // this.startLoading()
    // setTimeout(() => {
    //   this.addFormLoader=false
    //  }, 4000);

    setTimeout(() => {
      this.selectHideFlag = true;
    }, 4000);

     
    

  }


  showOptions(chakeboxdata: any, item: any) {
    console.warn('chakeboxdata>>>0', chakeboxdata.checked, 'item', item);

    if (chakeboxdata.checked) {
      item.selected = true;
      const dialogRef = this.dialog.open(SymptomReasonModalLabReports, {
        panelClass: 'signPageDialog',
        data: item
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
        if (result) {
          this.chakeboxdataID.push(item._id);
          this.ordersheetSelectedData.push(item);
          this.ordersheetpage();
        } else {
          item.selected = false;
        }
      });
    } else {
      const dialogalert = this.dialog.open(alertModalLabReports, {
        panelClass: 'signPageDialog',
        data: 'uncheckedSymptom'
      });
      dialogalert.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        // this.animal = result;
        if (result) {
          if (result == 'yes') {
            item.selected = false;
            this.chakeboxdataID = this.chakeboxdataID.filter((e: any) => {
              return e !== item._id
            })
          } else {
            item.selected = true;
            chakeboxdata.checked = true;
          }
        } else {
          item.selected = true;
          chakeboxdata.checked = true;
        }
      })

      // this.chakeboxdataID.push(item._id)
    }

    console.log('chakeboxdataID', this.chakeboxdataID)

    this.relateddiagnosis(item.related_diagnosis, chakeboxdata.checked);

  }

  checkUncheckAll() {
    console.warn("this.masterSelected>>", this.masterSelected);

    for (var i = 0; i < this.order_sheet_valu.length; i++) {
      this.order_sheet_valu[i].selected = this.masterSelected;
      // this.chakeboxdataID.push(this.order_sheet_valu[i]._id)
    }

    this.getCheckedItemList();
  }

  getCheckedItemList() {
    // this.chakeboxdataID
    if (this.masterSelected) {
      for (var i = 0; i < this.order_sheet_valu.length; i++) {
        this.chakeboxdataID.push(this.order_sheet_valu[i]._id);
        this.ordersheetSelectedData.push(this.order_sheet_valu[i]);
      }
      
      const dialogRef = this.dialog.open(SymptomReasonModalLabReports, {
        panelClass: 'signPageDialog',
        data: this.order_sheet_valu
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if (result) {
          this.ordersheetpage();
        }
        else {
          this.masterSelected = false;
          for (var i = 0; i < this.order_sheet_valu.length; i++) {
            this.order_sheet_valu[i].selected = this.masterSelected;
            // this.chakeboxdataID.push(this.order_sheet_valu[i]._id)
          }
          this.chakeboxdataID = []
        }

      });
    }
    if (!this.masterSelected) {
      this.chakeboxdataID = []
    }
    console.warn(this.ordersheetSelectedData,'Seleted item list >>>', this.chakeboxdataID);

  }

  veiwDoctorNotes(item: any) {
    const dialogRef = this.dialog.open(SymptomReasonModalLabReports, {
      panelClass: 'signPageDialog',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.ordersheetpage();
      }

    });
  }

  relateddiagnosis(val: any, checkevalu: any) {
    let relateddiagnosisName = document.querySelectorAll(".orderAllConSubbody");
    console.log('relateddiagnosisName', checkevalu)

    relateddiagnosisName.forEach((spandiagnosis) => {
      let diagnosisclass = spandiagnosis.id.replace("diagnostic_", "");

      console.log(val, 'diagnosisclass', diagnosisclass)

      for (const key in val) {
        if (val[key] == diagnosisclass) {
          if (checkevalu) {
            spandiagnosis.classList.add("disibaleechake");
          }
          if (!checkevalu) {
            spandiagnosis.classList.remove("disibaleechake");
          }
        }
      }


    })
  }



  dataFetching() {
    // setTimeout(() => {
    console.log('order_sheet_sign_value', this.order_sheet_sign_value)

    if (this.order_sheet_valu.length == 0) {
      this.order_sheet_sign_value = false

    }

    let currentpractise = null
    let i = 0;

    // console.log('order_sheet_valu', this.order_sheet_valu)
    let allcategoryName = document.querySelectorAll(".orderAllConh2");

    // console.log('allcategoryName', allcategoryName)

    allcategoryName.forEach((spanElement) => {

      let phead = spanElement.id.replace("diagnostic_cat_", "");
      // console.log('phead', phead)
      if (currentpractise == null || currentpractise != phead) {
        // console.log('>>>>>>');
        spanElement.classList.remove("hidecon");
      }
      // else{
      //   console.log('>>>>>>',currentpractise);

      // }
      // else console.log("no header", phead, currentpractise, i);
      currentpractise = phead;
      i++;


    })

    this.addFormLoader = false

    // }, 1500);
  }

  // startLoading() {
  //   this.isStart = true;
  //   this.intervalId = setInterval(() => {
  //     if (this.loadingPercent < 100) {
  //       this.loadingPercent += 1;
  //     }
  //   }, 100);
  // }
  // progressInLoading() {
  //   if (this.loadingPercent === 100) {
  //     clearInterval(this.intervalId);
  //     // this.res = of("Item Loaded");
  //   }
  //   console.log('Loading: ' + this.loadingPercent + '% completed.');
  // }


  opensignDialog(): void {
    console.log(this.ordersheetSelectedData,'lengthdataID', this.chakeboxdataID)
    if (this.chakeboxdataID != '') {
      const dialogRef = this.dialog.open(SignModalLabReports, {
        data: { idsData: this.chakeboxdataID, paramIDcon: this.paramID, isRejectOrder: this.fororderreject, orderData:this.ordersheetSelectedData },
        panelClass: 'signPageDialog'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
    else {
      const dialogalert = this.dialog.open(alertModalLabReports, {
        panelClass: 'signPageDialog'
      });
    }
  }

  ordersheetpage() {
    // console.log('test?????????????????????????????????????', this.activatedRoute.snapshot);
    
    let data: any = {
      searchcondition: {
        patient_id: this.paramID,
        is_trash: { $exists: false }
      }
    }
    // if (this.activatedRoute.snapshot.routeConfig.path.includes('patient-folderview/:_id')) {
    //   data.searchcondition.status = 1
    // }
    // if (this.fororderreject) {
    //   data.searchcondition.status = { $in: [1, 0] };
    // }
    this.httpService.httpViaPost('intake/ordersheet', data).subscribe((response: any) => {

      console.log("response===>>>>?????", response);
      

      if (response && response.status == 'success') {
        // console.log('response0000', response.results.res)
        const newValue = response.results.res.filter((obj: any, index: number) => response.results.res.findIndex((item: any) => item.diagnosis_id === obj.diagnosis_id) === index);
        console.log('response0000', newValue)
        this.order_sheet_valu = newValue;
        if (this.chakeboxdataID.length > 0 && this.order_sheet_valu.length > 0) {
          this.order_sheet_valu.forEach((e: any, i: number) => {
            this.chakeboxdataID.forEach((f: any) => {
              if (e._id == f) {
                e.selected = true;
              }
              if (e.status == 1) {
                e.selected = true;
              }
            })
          })
        }
        this.addFormLoader = false;
        setTimeout(() => {
          this.dataFetching();
        }, 100)
      }
    })
  }





}





@Component({
  selector: 'SignModalLabReports',
  templateUrl: 'SignPage.html',
})
export class SignModalLabReports {

  public signvalu: any = '';
  public addsignForm: FormGroup;
  public modaldata: any = {};

  public cookieData: any = {};


  public addsignLoader: boolean = false;
  public previewFlg: boolean = true;

  public btnpreviewFlg: boolean = false;

public additional_note: any = '';

  constructor(
    public dialogRef: MatDialogRef<SignModalLabReports>,
    @Inject(MAT_DIALOG_DATA) public data: any, private apiservice: HttpServiceService, public activatedRoute: ActivatedRoute, public snackbar: MatSnackBar, public router: Router, public cookieService: CookieService
  ) {

    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);

    // console.log('cookieData+++', this.cookieData)

  }

  myFunction(event) {

    // var div = document.getElementById('divID');
    console.log('event', event.target.value)

    this.signvalu = event.target.value

    console.log('signvalu', this.signvalu)

  }


  ngOnInit() {
    console.log("SignModalLabReports data", this.data)
    this.addsignForm = new FormGroup({
      signinput: new FormControl('', Validators.required),
    });

    setTimeout(() => {
      this.btnpreviewFlg = true;
    }, 5000);

  }

  addsign() {
    this.addsignForm.markAllAsTouched()
    if (this.addsignForm.valid) {
      if (this.modaldata.item) {
        this.addsignForm.patchValue({
          doctor_signature: this.modaldata.item.signinput,
        });
      }

     
      this.dialogRef.close(this.addsignForm.value);

      if (this.cookieData.user_type == 'sales_person') {
        this.dialogRef.close();
        this.snackbar.open("This is for Salesperson Demo purpose!", "", {
          duration: 2000
        });
        setTimeout(() => {
          this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard/doctor`);
        }, 2000);
      } else {

      this.addsignLoader = true;
      let dataset: any = {
        data: {
          ids: this.data.idsData,
          status: 1
        }
      }

      let datasetchange: any = {
        _id: this.data.paramIDcon,
        status: 3,
        doctor_signature: this.signvalu,
        ordersheet_additional_notes: this.additional_note
      }

      if (this.cookieData.user_type == 'doctor') {
        datasetchange.doctor_id = this.cookieData._id;
      }
      if (this.cookieData.user_type == 'sales_person') {
        datasetchange.doctor_id = this.cookieData._id;
      }

      if (this.data.isRejectOrder) {
        dataset.data.patient_id = this.data.paramIDcon;
        this.apiservice.httpViaPost('intake/reorderordersheet', dataset).subscribe((response: any) => {
          if (response) {
            this.addsignLoader = false;
            this.snackbar.open(response.message, "", {
              duration: 1000
            });
            if (response.status == 'success') {
              this.dialogRef.close();
              if (this.cookieData.user_type == 'sales_person') {
                this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard/doctor`);
              } else {
                this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard`);
              }
            }
          }
        })
      } else {
        this.apiservice.httpViaPost('intake/acceptedordersheet', dataset).subscribe((response: any) => {
          if (response && response.status == 'success') {
            this.apiservice.httpViaPost('intake/leadstatuschange', datasetchange).subscribe((res: any) => {
              if (res) {
                this.addsignLoader = false;
                this.dialogRef.close();
                this.snackbar.open('Order Sheet Sign Successfully!', "", {
                  duration: 1000
                });
                if (res.status == 'success') {
                  if (this.cookieData.user_type == 'sales_person') {
                    this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard/doctor`);
                  } else {
                    this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard`);
                  }
                }
              }
            })
          }
        })
      }
      }
    }
  }

  finalSubmit() {
     this.previewFlg = false;
console.log("additional_note",this.additional_note)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}



@Component({
  selector: 'alertModalLabReports',
  templateUrl: 'alertModal.html',
})
export class alertModalLabReports {
  public isUncheckedSymptom: boolean = false;
  constructor(
    public dialogalert: MatDialogRef<alertModalLabReports>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  ngOnInit() {
    console.log("alertModalLabReports data", this.data);
    if (this.data) {
      if (this.data == 'uncheckedSymptom') {
        this.isUncheckedSymptom = true;
      }
    }
  }
  symptomRemove(type: string) {
    this.dialogalert.close(type);
  }

  onNoClick(): void {
    this.dialogalert.close();
  }
}

@Component({
  selector: 'app-symptom-reason-modal',
  templateUrl: 'symptom-reason-modal.html',
})
export class SymptomReasonModalLabReports {
  public symptomReasonForm: FormGroup;
  public addsignLoader: boolean = false;
  public cookieData: any = null;
  public multiformFlag: boolean = false;
  public resonValArray: any = [];
  constructor(
    public dialogalert: MatDialogRef<SymptomReasonModalLabReports>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpService: HttpServiceService,
    public snackbar: MatSnackBar,
    public cookieService: CookieService
  ) { }
  ngOnInit() {
    console.log("symptom data", this.data)
    this.symptomReasonForm = new FormGroup({
      reason: new FormControl('')
    });
    if (typeof this.data.length != "undefined") {
      for (const vall of this.data) {
        this.resonValArray.push({ 'notes_by_doctor': '', '_id': vall._id, 'diagnostic_name': vall.diagnostic_name })
      }
      this.multiformFlag = true;
    }

    if (this.data) {
      if (this.data.notes_by_doctor) {
        this.symptomReasonForm.patchValue({
          reason: this.data.notes_by_doctor
        })
      }

    }
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      console.log("this.cookieData", this.cookieData)
    }
  }
  addSymptomReasonMessage() {
    console.warn('FORM VALUEEE >>>', this.resonValArray);
    if (this.multiformFlag) {
      for (const value of this.resonValArray) {
        if (value.notes_by_doctor == '') {
          this.snackbar.open("Add Notes For" + '"' + value.diagnostic_name + '"', "", {
            duration: 4000
          });
          return;
        }
      }

      this.addsignLoader = true;
      let dataSet: any = {
        data: this.resonValArray
      }
      // this.dialogalert.close(this.symptomReasonForm.value.reason);
      this.httpService.httpViaPost('intake/addordernotesmany', dataSet).subscribe((response: any) => {
        if (response) {
          if (response.status == 'success') {
            this.dialogalert.close('success');
          }
          this.snackbar.open(response.message, "", {
            duration: 1000
          });
          this.addsignLoader = false;
        }
      })
    }
    else {
      this.symptomReasonForm.markAllAsTouched();
      if (this.symptomReasonForm.valid) {
        // if (this.cookieData.user_type == 'sales_person') {
        //   // this.snackbar.open("This is for Sales Person Demo purpose!", "", {
        //   //   duration: 2000
        //   // });
        //   this.dialogalert.close('success');
        // } else {
          this.addsignLoader = true;
          let data: any = {
            // data: {
            _id: this.data._id,
            notes_by_doctor: this.symptomReasonForm.value.reason
            // }
          }
          // this.dialogalert.close(this.symptomReasonForm.value.reason);
          this.httpService.httpViaPost('intake/addordernotes', data).subscribe((response: any) => {
            if (response) {
              if (response.status == 'success') {
                this.dialogalert.close('success');
              }
              this.snackbar.open(response.message, "", {
                duration: 1000
              });
              this.addsignLoader = false;
            }
          })
        // }
      }
    }


  }

  onNoClick(): void {
    this.dialogalert.close();
  }

}
