import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ForgetpasswordComponent } from './components/auth/forgetpassword/forgetpassword.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsermanagementModule } from './components/usermanagement/usermanagement.module';
import { HttpServiceService } from './service/http-service.service';
import { ListingAngular15Module } from 'listing-angular15';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { AccountSettingsComponent } from './layouts/account-settings/account-settings.component';
import { ChangePasswordComponent } from './layouts/change-password/change-password.component';
import { PagesModule } from './components/pages/pages.module';
import { ChangeLabLogoComponent } from './layouts/change-lab-logo/change-lab-logo.component';
import { ClinicalReportViewComponent } from './components/leads/clinical-report-view/clinical-report-view.component';

 


 
 
@NgModule({
  declarations: [
    AppComponent,
    // LoginComponent,
    // ForgetpasswordComponent,
    AccountSettingsComponent,
    ChangePasswordComponent,
    ChangeLabLogoComponent,
    ClinicalReportViewComponent
 
  
 

  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
    // PagesModule
   
  ],

 
  // providers: [HttpServiceService],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
})
export class AppModule { }
