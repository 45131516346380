<div class="evaluationBody">

    <h2>evaluation & management code</h2>

    <div class="evaluationBodyCon">

        <div class="evaluationBodyConsub">
            <h3>New Patient</h3>
            <div class="evaluationBodyConsubText"><label>LAB TEST</label><strong>:<span>General Chemistry 13<br />
                        Liver Panel Plus<br />
                        CBC - 3 Part Differential</span></strong></div>

            <div class="evaluationBodyConsubText"><label>FINDINGS (ABNORMAL)</label><strong>:<span>Albumin, Alkaline
                        phosphatase, Bilirubin, Gamma-glutamyl transferase, Total protein, Bilirubin (total), Aspartate
                        aminotransferase, Alanine transaminase</span></strong></div>


            <div class="evaluationBodyConsubText"><label>DIAGNOSIS (ICD-10)</label><strong>:<span>

                        <b>K74.60</b> - Unspecified cirrhosis of liver<br />
                        <b>K76.0</b> - Fatty liver, not elsewhere
                        classified<br />
                        <b>D64.9</b> - for Anemia, unspecified

                    </span></strong></div>

            <div class="evaluationBodyConsubText"><label>MDM REASON</label><strong>:<span>

                        <p>Elevated albumin, alkaline phosphatase, bilirubin (total), and gamma-glutamyl transpeptidase
                            are indicators of liver disease and abnormal RBC Count, RDW and PLT Count are indicators of
                            Anaemia. Then the number and complexity of problems addressed at the encounter is Moderate,
                            as the patient has one chronic illness with exacerbation, progression, or side effects of
                            treatment and one classified as a self-limited or minor problem.</p>

                        <p>The provider orders and reviews the CBC - 3 Part Differential, General Chemistry 13 and Liver
                            Panel Plus tests then the amount and/or complexity of data to be reviewed and analysed is
                            Moderate.</p>

                        <p>The provider prescribes or continues prescription drug management such as antiviral agents,
                            corticosteroids, or diuretics and suggests lifestyle changes such as avoiding alcohol,
                            maintaining a healthy weight,
                            and eating a balanced diet, to improve the patient's liver health to prevent or treat the
                            complications of liver disease, the risk of complications and/or morbidity or mortality of
                            patient management is Moderate.</p>

                    </span></strong></div>



            <div class="evaluationBodyConsubText"><label>MDM LEVEL</label><strong>:<span>Moderate</span></strong></div>
            <div class="evaluationBodyConsubText"><label>CODE</label><strong>:<span>99204</span></strong></div>
            <div class="evaluationBodyConsubText"><label>TIME<span>(Minite)</span></label><strong>:<span>45 - 59
                        Min</span></strong></div>
            <div class="evaluationBodyConsubText"><label>NON FACILITY
                    CHARGES($)</label><strong>:<span>$215.51</span></strong></div>
            <div class="evaluationBodyConsubText"><label>NON FACILITY LIMITING
                    CHARGES($)</label><strong>:<span>$235.45</span></strong></div>


        </div>

        <div class="evaluationBodyConsub">
            <h3>ESTABLISHED PATIENT</h3>
            <div class="evaluationBodyConsubText"><label>LAB TEST</label><strong>:<span>General Chemistry 13<br />
                        Liver Panel Plus<br />
                        CBC - 3 Part Differential</span></strong></div>

            <div class="evaluationBodyConsubText"><label>FINDINGS (ABNORMAL)</label><strong>:<span>Albumin, Alkaline
                        phosphatase, Bilirubin, Gamma-glutamyl transferase, Total protein, Bilirubin (total), Aspartate
                        aminotransferase, Alanine transaminase</span></strong></div>

            <div class="evaluationBodyConsubText"><label>DIAGNOSIS (ICD-10)</label><strong>:<span>

                        <b>K74.60</b> - Unspecified cirrhosis of liver<br />
                        <b>K76.0</b> - Fatty liver, not elsewhere
                        classified<br />
                        <b>D64.9</b> - for Anemia, unspecified

                    </span></strong></div>

            <div class="evaluationBodyConsubText"><label>MDM REASON</label><strong>:<span>

                        <p>Elevated albumin, alkaline phosphatase, bilirubin (total), and gamma-glutamyl transpeptidase
                            are indicators of liver disease and abnormal RBC Count, RDW and PLT Count are indicators of
                            Anaemia. Then the number and complexity of problems addressed at the encounter is Moderate,
                            as the patient has one chronic illness with exacerbation, progression, or side effects of
                            treatment and one classified as a self-limited or minor problem.</p>

                        <p>The provider orders and reviews the CBC - 3 Part Differential, General Chemistry 13 and Liver
                            Panel Plus tests then the amount and/or complexity of data to be reviewed and analysed is
                            Moderate.</p>

                        <p>The provider prescribes or continues prescription drug management such as antiviral agents,
                            corticosteroids, or diuretics and suggests lifestyle changes such as avoiding alcohol,
                            maintaining a healthy weight,
                            and eating a balanced diet, to improve the patient's liver health to prevent or treat the
                            complications of liver disease, the risk of complications and/or morbidity or mortality of
                            patient management is Moderate.</p>

                    </span></strong></div>




            <div class="evaluationBodyConsubText"><label>MDM LEVEL</label><strong>:<span>Moderate</span></strong></div>
            <div class="evaluationBodyConsubText"><label>CODE</label><strong>:<span>99214</span></strong></div>
            <div class="evaluationBodyConsubText"><label>TIME<span>(Minite)</span></label><strong>:<span>45 - 59
                        Min</span></strong></div>
            <div class="evaluationBodyConsubText"><label>NON FACILITY
                    CHARGES($)</label><strong>:<span>$232.52</span></strong></div>
            <div class="evaluationBodyConsubText"><label>NON FACILITY LIMITING
                    CHARGES($)</label><strong>:<span>$254.03</span></strong></div>


        </div>




    </div>

</div>