<app-header></app-header>
<!-- <mat-card-content class="headerBody">
    <mat-card class="headerIcon">
       <a (click)="imageUpadete()"><img [src]="imgShowVal" alt="practice2lab_logo"
                class="toplogo"></a>
    </mat-card>
    </mat-card-content> -->


<mat-card class="adminBody">

    <mat-card-content class="addEditPageWrapper accountSettings form_title_style">

        <!-- <ng-container *ngIf="addFormLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container> -->


        <h2 class="listingpageh2">Change Logo</h2>
        <div class="logo_show_block">
            <a (click)="imageUpadete()"><img [src]="imgShowVal" alt="practice2lab_logo" class="toplogo"></a>
            
        </div>

        <div class="logo_show_block">
            <button class="logo_update_btn" (click)="imageUpadete()">Change Logo</button>
            <button class="logo_update_btn" (click)="cancelFun()">Cancel</button>
        </div>

    </mat-card-content>

    <span class="footerBlock"><app-footer></app-footer></span>
</mat-card>