import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CookieService } from 'ngx-cookie-service';
import { AddUserBottomSheet } from '../../usermanagement/user-list/user-list.component';

@Component({
  selector: 'app-taxonomies-list',
  templateUrl: './taxonomies-list.component.html',
  styleUrls: ['./taxonomies-list.component.css']
})
export class TaxonomiesListComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'status', 'usertype'];
  public tabledatatalist: []
  public listprogressBar: any = false;
  tablename = "users";
  editroute = "";
  updateendpoint = "users/statusChangeTaxo";
  deleteendpoint = "users/deleteTaxomany";
  datacollection: any = "users/fetchtaxolist";

  searchendpoint = "";
  date_search_endpoint: any = "users/fetchtaxolist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  modify_header_array: any = {
    taxonomy: "Taxonomy",
    description: "Description",
    priority: "Priority",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On",
    status_text: "Status"
  };
  search_settings: any = {


    search: [{
      label: "Search By Taxonomy", field: '_id',
      serversearchdata: { endpoint: 'users/taxonameAutocomplete' },
    },
    ],

    textsearch: [
      { label: "Search By Priority", field: 'priority' },
    ],
    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["taxonomy", "createdon_datetime", "updatedon_datetime", "status_text", "usertype", "priority"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Add Taxonomies',
      //   type: 'button',
      //   name: 'add_taxonomies',
      //   tooltip: 'Add Taxonomies',
      //   classname: 'add_adminBTN',
      // },
    ],
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: "taxonomy", val: "Taxonomy" },
      { key: "description", val: "Description" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "priority", val: "Priority" },
      { key: "status_text", val: "Status" }


    ],
    updateendpoint: "users/statusChangeTaxo",

    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "users/statusChangeTaxo",

    deleteendpointmany: "users/deleteTaxo",
    tableheaders: ["taxonomy", "description", "createdon_datetime", "priority", "status_text", "updatedon_datetime"],


    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'users/previewtaxo',
        otherparam: [],
        // cond:'status',
        // condval:0,
        param: '_id',
        datafields: ['taxonomy', 'description', 'priority', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
    ],

  }
  public taxonomy_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService) {
    if (!window.location.pathname.includes('admin/taxonomies')) {
      this.router.navigateByUrl('404')
    }
  }
  openBottomSheet(): void {
    this._bottomSheet.open(AddUserBottomSheet);
  }

  ngOnInit() {
    this.progressLoader = true;
    this.userListFetching();
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      switch (this.cookieData.user_type) {
        case 'admin':
          this.isAdminUser = true;

          break;
        case 'doctor':
          this.isDoctorUser = true;
          break;
      }
    }
    console.log("cookieService", this.cookieData)
  }
  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }

    this.activatedRoute.data.subscribe((response: any) => {
      console.log("activatedRoute", response)
      this.listprogressBar = true
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);

      }
    })

    this.httpService.httpViaPost('users/fetchtaxolist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }

  addBtn() {
    this.router.navigateByUrl(`admin/taxonomies/add`);
  }

  onLiblistingButtonChange(val: any) {
    // throw new Error('Function not implemented.');
    console.log("test>>>>>>>", val);
    this.router.navigateByUrl(`admin/taxonomies/add`);

  }
  listenLiblistingChange(data: any = null) {
    console.log("test", data);
    if (data.action == "custombuttonclick") {
      if (data.custombuttonclick.btninfo.id == 'edit_btn') {
        this.editListItem(data.custombuttonclick.data);
      }
    }
    if (data.action == "multipleselectionchange") {
      const actionbuttonwrapperDiv = document.querySelector('.tablewrapper');
      if (data.selecteddata.length > 0) {
        actionbuttonwrapperDiv.classList.add("remove");
      } else {
        actionbuttonwrapperDiv.classList.remove("remove");
      }
    }
    if (data.action == "statusupdate") {
      if (data.totalresults.status == 'success') {
        console.log('statusupdate>>>>', data);
        this.taxonomy_updatetable = !this.taxonomy_updatetable;

      }

    }
    if (data.action == "multiplestatusupdate") {
      console.log('statusupdate>>>>', data);
      this.taxonomy_updatetable = !this.taxonomy_updatetable;
    }
  }
  editListItem(item: any) {
    if (item) {
      console.log("Op=====>1", item);
      this.router.navigateByUrl(`admin/taxonomies/edit/${item._id}`);
    }
  }
}
