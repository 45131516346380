<div class="ModalTabSubConPage">
  <h1>Sample Conversation</h1>

  <mat-progress-bar mode="indeterminate" *ngIf="progressBarLoader"></mat-progress-bar>

  <div class="sampleConversation previewTabLeftContent taxonomyChangesDataClass" [innerHtml]="sampleConversationDataset"
    (click)="handleClick($event)">


    <!-- <div class="newElement">

      <div *ngIf="sampleconversationHTML">
        <div [innerHtml]="sampleconversationHTML"></div>
      </div>

     
      <div *ngIf="!sampleconversationHTML" class="fld_nodata">
        <h3> No Record Found !</h3>
      </div>

      
    </div> -->






    <!-- <div class="sampleConversation previewTabLeftContent taxonomyChangesDataClass ">
    <p class="conternt_wrapper taxonomy_changes"></p>
    <div class="content_wrapper taxonomies">
      <div class="taxonomies_suggested">
        

        <div class="tabscolblock">

          <div class="tabscolblockmain">
            <div class="tabscolblock_sub">

              <input type="radio" id="rd1" name="rd">
              <label class="tab-label" for="rd1">
                <strong>Cardiologist</strong> </label>

              <p>A patient with Congestive Heart Failure (CHF) should be referred to a cardiologist. CHF is a condition
                where the heart is unable to pump blood effectively, leading to fluid buildup in the lungs and other
                parts of the body. Cardiologists specialize in diagnosing and treating heart diseases, including CHF.
                They can perform tests such as echocardiograms, stress tests, and cardiac catheterization to evaluate
                the function of the heart. Cardiologists can prescribe medications to manage CHF symptoms, provide
                lifestyle recommendations, and may perform procedures such as angioplasty or implantation of a pacemaker
                if necessary.</p>

              <div class="tab-content">
                <div class="content_wrapper sample_conversation">
                  <div class="conversation">
                    <p class="doctor"><strong>Doctor:</strong><span>Good morning, how can I help you today?</span></p>
                    <p class="patient"><strong>Patient:</strong><span>Good morning, Doctor. I've been experiencing some
                        concerning symptoms lately. I have swelling in my ankles, a stabbing or electrical shock
                        sensation, pins and needles sensation in my feet, headaches, fatigue, fainting, exercise
                        intolerance, and dizziness or lightheadedness.</span></p>
                    <p class="doctor"><strong>Doctor:</strong><span>I see. Have you experienced any of these symptoms
                        before?</span></p>
                    <p class="patient"><strong>Patient:</strong><span>Yes, I have. I've been previously diagnosed with a
                        seizure disorder (epilepsy) and I also have swelling of my lower extremities and pulmonary
                        (lung) diseases.</span></p>
                    <p class="doctor"><strong>Doctor:</strong><span>Thank you for sharing that information. Based on
                        your symptoms and medical history, it's important to evaluate the possible causes for your
                        current symptoms. The swelling in your ankles could be related to your pulmonary diseases, as
                        they can cause fluid retention. The stabbing or electrical shock sensation, pins and needles
                        sensation in your feet, and headaches may be related to your seizure disorder. Fatigue,
                        fainting, exercise intolerance, and dizziness or lightheadedness can be associated with various
                        underlying conditions.</span></p>
                    <p class="patient"><strong>Patient:</strong><span>What could be causing these symptoms?</span></p>
                    <p class="doctor"><strong>Doctor:</strong><span>There are several possibilities. It could be related
                        to your seizure disorder, such as changes in medication or seizure activity. It's also important
                        to consider other neurological conditions, such as peripheral neuropathy or autonomic
                        dysfunction. Additionally, we should evaluate your pulmonary diseases and assess if they are
                        contributing to your symptoms. To determine the exact cause, we will need to conduct further
                        tests and examinations. Would you be open to scheduling an appointment for a thorough
                        evaluation?</span></p>
                    <p class="patient"><strong>Patient:</strong><span>Yes, I would definitely like to get to the bottom
                        of these symptoms. Please schedule an appointment for me.</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabscolblock_sub">
              <input type="radio" id="rd2" name="rd">
              <label class="tab-label" for="rd2"><strong> Neurologist</strong>

              </label>
              <p>Peripheral Neuropathy, a condition characterized by damage to the peripheral nerves, may require a
                referral to a neurologist. Neurologists specialize in diagnosing and treating disorders of the nervous
                system, including peripheral neuropathy. They can perform nerve conduction studies and electromyography
                to assess nerve function and determine the underlying cause of the neuropathy. Neurologists can
                prescribe medications to manage pain and other symptoms, recommend physical therapy or occupational
                therapy, and provide guidance on lifestyle modifications to improve nerve health.</p>
              <div class="tab-content">
                <div class="content_wrapper sample_conversation">
                  <div class="conversation">
                    <p class="doctor"><strong>Doctor:</strong><span>Good morning, how can I help you today?</span></p>
                    <p class="patient"><strong>Patient:</strong><span>Good morning, Doctor. I've been experiencing some
                        concerning symptoms lately. I have swelling in my ankles, a stabbing or electrical shock
                        sensation, pins and needles sensation in my feet, headaches, fatigue, fainting, exercise
                        intolerance, and dizziness or lightheadedness.</span></p>
                    <p class="doctor"><strong>Doctor:</strong><span>I see. Have you experienced any of these symptoms
                        before?</span></p>
                    <p class="patient"><strong>Patient:</strong><span>Yes, I have. I've been previously diagnosed with a
                        seizure disorder (epilepsy) and I also have swelling of my lower extremities and pulmonary
                        (lung) diseases.</span></p>
                    <p class="doctor"><strong>Doctor:</strong><span>Thank you for sharing that information. Based on
                        your symptoms and medical history, it's important to evaluate the possible causes for your
                        current symptoms. The swelling in your ankles could be related to your pulmonary diseases, as
                        they can cause fluid retention. The stabbing or electrical shock sensation, pins and needles
                        sensation in your feet, and headaches may be related to your seizure disorder. Fatigue,
                        fainting, exercise intolerance, and dizziness or lightheadedness can be associated with various
                        underlying conditions.</span></p>
                    <p class="patient"><strong>Patient:</strong><span>What could be causing these symptoms?</span></p>
                    <p class="doctor"><strong>Doctor:</strong><span>There are several possibilities. It could be related
                        to your seizure disorder, such as changes in medication or seizure activity. It's also important
                        to consider other neurological conditions, such as peripheral neuropathy or autonomic
                        dysfunction. Additionally, we should evaluate your pulmonary diseases and assess if they are
                        contributing to your symptoms. To determine the exact cause, we will need to conduct further
                        tests and examinations. Would you be open to scheduling an appointment for a thorough
                        evaluation?</span></p>
                    <p class="patient"><strong>Patient:</strong><span>Yes, I would definitely like to get to the bottom
                        of these symptoms. Please schedule an appointment for me.</span></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="tabscolblock_sub">
              <input type="radio" id="rd3" name="rd">
              <label class="tab-label" for="rd3"><strong>NEPHROLOGIST</strong>

              </label>
              <p>Chronic Kidney Disease (CKD) necessitates a referral to a nephrologist. Nephrologists specialize in the diagnosis and treatment of kidney diseases. They can evaluate kidney function through blood tests, urine tests, and imaging studies. Nephrologists can provide guidance on dietary changes, prescribe medications to manage CKD complications, and monitor the progression of the disease. In advanced stages of CKD, nephrologists may discuss options for dialysis or kidney transplantation.</p>
              <div class="tab-content">
                <div class="content_wrapper sample_conversation">
                  <div class="conversation">
                      <p class="doctor"><strong>Doctor:</strong><span>Good morning, how can I help you today?</span></p>
                      <p class="patient"><strong>Patient:</strong><span>Good morning, Doctor. I've been experiencing some concerning symptoms lately. I have swelling in my ankles, a stabbing or electrical shock sensation, pins and needles sensation in my feet, headaches, fatigue, fainting, exercise intolerance, and dizziness or lightheadedness.</span></p>
                      <p class="doctor"><strong>Doctor:</strong><span>I see. Have you experienced any of these symptoms before?</span></p>
                      <p class="patient"><strong>Patient:</strong><span>Yes, I have. I've been previously diagnosed with a seizure disorder (epilepsy) and I also have swelling of my lower extremities and pulmonary (lung) diseases.</span></p>
                      <p class="doctor"><strong>Doctor:</strong><span>Thank you for sharing that information. Based on your symptoms and medical history, it's important to evaluate the possible causes for your current symptoms. The swelling in your ankles could be related to your pulmonary diseases, as they can cause fluid retention. The stabbing or electrical shock sensation, pins and needles sensation in your feet, and headaches may be related to your seizure disorder. Fatigue, fainting, exercise intolerance, and dizziness or lightheadedness can be associated with various underlying conditions.</span></p>
                      <p class="patient"><strong>Patient:</strong><span>What could be causing these symptoms?</span></p>
                      <p class="doctor"><strong>Doctor:</strong><span>There are several possibilities. It could be related to your seizure disorder, such as changes in medication or seizure activity. It's also important to consider other neurological conditions, such as peripheral neuropathy or autonomic dysfunction. Additionally, we should evaluate your pulmonary diseases and assess if they are contributing to your symptoms. To determine the exact cause, we will need to conduct further tests and examinations. Would you be open to scheduling an appointment for a thorough evaluation?</span></p>
                      <p class="patient"><strong>Patient:</strong><span>Yes, I would definitely like to get to the bottom of these symptoms. Please schedule an appointment for me.</span></p>
                  </div>
              </div>
              </div>
            </div>

            <div class="tabscolblock_sub">
              <input type="radio" id="rd4" name="rd">
              <label class="tab-label" for="rd4"><strong>Endocrinologist</strong>

              </label>
              <p>Hypothyroidism, a condition where the thyroid gland does not produce enough thyroid hormone, may require a referral to an endocrinologist. Endocrinologists specialize in disorders of the endocrine system, including thyroid diseases. They can perform blood tests to assess thyroid hormone levels and thyroid function. Endocrinologists can prescribe thyroid hormone replacement therapy to manage hypothyroidism and monitor the patient's hormone levels over time. They can also provide guidance on lifestyle modifications and address any other endocrine-related concerns.</p>
              <div class="tab-content">
                <div class="content_wrapper sample_conversation">
                  <div class="conversation">
                      <p class="doctor"><strong>Doctor:</strong><span>Good morning, how can I help you today?</span></p>
                      <p class="patient"><strong>Patient:</strong><span>Good morning, Doctor. I've been experiencing some concerning symptoms lately. I have swelling in my ankles, a stabbing or electrical shock sensation, pins and needles sensation in my feet, headaches, fatigue, fainting, exercise intolerance, and dizziness or lightheadedness.</span></p>
                      <p class="doctor"><strong>Doctor:</strong><span>I see. Have you experienced any of these symptoms before?</span></p>
                      <p class="patient"><strong>Patient:</strong><span>Yes, I have. I've been previously diagnosed with a seizure disorder (epilepsy) and I also have swelling of my lower extremities and pulmonary (lung) diseases.</span></p>
                      <p class="doctor"><strong>Doctor:</strong><span>Thank you for sharing that information. Based on your symptoms and medical history, it's important to evaluate the possible causes for your current symptoms. The swelling in your ankles could be related to your pulmonary diseases, as they can cause fluid retention. The stabbing or electrical shock sensation, pins and needles sensation in your feet, and headaches may be related to your seizure disorder. Fatigue, fainting, exercise intolerance, and dizziness or lightheadedness can be associated with various underlying conditions.</span></p>
                      <p class="patient"><strong>Patient:</strong><span>What could be causing these symptoms?</span></p>
                      <p class="doctor"><strong>Doctor:</strong><span>There are several possibilities. It could be related to your seizure disorder, such as changes in medication or seizure activity. It's also important to consider other neurological conditions, such as peripheral neuropathy or autonomic dysfunction. Additionally, we should evaluate your pulmonary diseases and assess if they are contributing to your symptoms. To determine the exact cause, we will need to conduct further tests and examinations. Would you be open to scheduling an appointment for a thorough evaluation?</span></p>
                      <p class="patient"><strong>Patient:</strong><span>Yes, I would definitely like to get to the bottom of these symptoms. Please schedule an appointment for me.</span></p>
                  </div>
              </div>
              </div>
            </div>

            <div class="tabscolblock_sub">
              <input type="radio" id="rd5" name="rd">
              <label class="tab-label" for="rd5"><strong>VASCULAR SURGEON</strong>

              </label>
              <p>Chronic Venous Insufficiency (CVI) may warrant a referral to a vascular surgeon. Vascular surgeons specialize in the diagnosis and treatment of conditions affecting the blood vessels, including CVI. They can assess the severity of CVI through physical examination, ultrasound imaging, and other tests. Vascular surgeons can recommend lifestyle changes, prescribe compression stockings, and perform procedures such as endovenous ablation or vein stripping to improve venous circulation. They can also provide guidance on wound care for any associated skin ulcers or complications.</p>
              <div class="tab-content">
                <div class="content_wrapper sample_conversation">
                  <div class="conversation">
                      <p class="doctor"><strong>Doctor:</strong><span>Good morning, how can I help you today?</span></p>
                      <p class="patient"><strong>Patient:</strong><span>Good morning, Doctor. I've been experiencing some concerning symptoms lately. I have swelling in my ankles, a stabbing or electrical shock sensation, pins and needles sensation in my feet, headaches, fatigue, fainting, exercise intolerance, and dizziness or lightheadedness.</span></p>
                      <p class="doctor"><strong>Doctor:</strong><span>I see. Have you experienced any of these symptoms before?</span></p>
                      <p class="patient"><strong>Patient:</strong><span>Yes, I have. I've been previously diagnosed with a seizure disorder (epilepsy) and I also have swelling of my lower extremities and pulmonary (lung) diseases.</span></p>
                      <p class="doctor"><strong>Doctor:</strong><span>Thank you for sharing that information. Based on your symptoms and medical history, it's important to evaluate the possible causes for your current symptoms. The swelling in your ankles could be related to your pulmonary diseases, as they can cause fluid retention. The stabbing or electrical shock sensation, pins and needles sensation in your feet, and headaches may be related to your seizure disorder. Fatigue, fainting, exercise intolerance, and dizziness or lightheadedness can be associated with various underlying conditions.</span></p>
                      <p class="patient"><strong>Patient:</strong><span>What could be causing these symptoms?</span></p>
                      <p class="doctor"><strong>Doctor:</strong><span>There are several possibilities. It could be related to your seizure disorder, such as changes in medication or seizure activity. It's also important to consider other neurological conditions, such as peripheral neuropathy or autonomic dysfunction. Additionally, we should evaluate your pulmonary diseases and assess if they are contributing to your symptoms. To determine the exact cause, we will need to conduct further tests and examinations. Would you be open to scheduling an appointment for a thorough evaluation?</span></p>
                      <p class="patient"><strong>Patient:</strong><span>Yes, I would definitely like to get to the bottom of these symptoms. Please schedule an appointment for me.</span></p>
                  </div>
              </div>
              </div>
            </div>

          </div>
        </div>




      </div>
    </div>
    <p></p>
  </div>  -->


  </div>