import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-email-slug-list',
  templateUrl: './email-slug-list.component.html',
  styleUrls: ['./email-slug-list.component.css']
})
export class EmailSlugListComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', "status", 'usertype', 'owned_by'];
  public tabledatatalist: []
  public listprogressBar: any = false;
  tablename = "email_templates";
  editroute = "";
  updateendpoint = "email/emailslugstatus";
  deleteendpoint = "email/deleteslug";
  datacollection: any = "email/emailsluglist";

  searchendpoint = "email/emailsluglist";
  date_search_endpoint: any = "email/emailsluglist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {

    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];

  modify_header_array: any = {
    slug_name: "Slug Name",
    slug_variable: "Slug Variable",
    data_base_variable: "Database Variable",
    status: "Status",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On"
  };
  search_settings: any = {
    search: [{
      label: "Search By Slug Name", field: 'slug_name',
      // values: this.authval,
      serversearchdata: { endpoint: 'email/slugnameautocomplete' },
      // value: [{ val: 'jessica', name: 'jessica' }]
    },

    {
      label: "Search By Slug Variable ", field: 'slug_variable',
      // values: this.authval,
      serversearchdata: { endpoint: 'email/slugvariableautocomplete' },
      // value: [{ val: 'jessica', name: 'jessica' }]
    },
    ],
    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [

      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["slug_variable", "slug_name", "createdon_datetime", "status", "updatedon_datetime", "data_base_variable"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Add Slug',
      //   type: 'button',
      //   name: 'add_slug',
      //   tooltip: 'Add Slug',
      //   classname: 'add_adminBTN',
      // },
    ],
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: "slug_name", val: "Slug Name" },
      { key: "slug_variable", val: "Slug Variable" },
      { key: "data_base_variable", val: "Database Variable" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: 'from_collection', val: 'From Collection' },
      { key: 'description', val: 'Description' },
      { key: 'value', val: 'Value' }
    ],
    updateendpoint: "email/emailslugstatus",
    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "email/emailslugstatus",

    deleteendpointmany: "email/deleteslug",
    tableheaders: ["slug_name", "slug_variable", "data_base_variable", "createdon_datetime", "updatedon_datetime", "status"],

    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'email/previewemailslug',
        otherparam: [],
        // cond:'status',
        // condval:0,
        param: '_id',
        datafields: ['slug_name', 'slug_variable', 'description', 'data_base_variable', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
    ],

  }
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  public adduserBTN: boolean = false;
  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService) {
    if (!window.location.pathname.includes('admin/email-template')) {
      this.router.navigateByUrl('404')
    }
  }
  ngOnInit() {
    this.progressLoader = true;
    this.activatedRoute.data.subscribe((response: any) => {
      this.listprogressBar = true
      console.log("activatedRoute responseff", response)
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);
        if (this.tabledatatalist.length == 0) {

          // console.log("no record")

          setTimeout(() => {
            this.adduserBTN = true

            let myRow = document.querySelector('.adminBody');
            myRow.classList.add('libListNoDataCSS');
          }, 200);


        }
      }
    })
    this.userListFetching();
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      switch (this.cookieData.user_type) {
        case 'admin':
          this.isAdminUser = true;

          break;
        case 'doctor':
          this.isDoctorUser = true;


          break;
      }
    }
    console.log("cookieService", this.cookieData)
  }

  addBtn() {
    this.router.navigateByUrl(`admin/email-template/add-slug`);
  }
  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }


    this.httpService.httpViaPost('email/emailsluglist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }
  listenLiblistingChange(data: any = null) {
    console.log("listenLiblistingChange", data);
    // practice/intake-form-preview/view
    // console.log("Op=====>1", data);
    if (data.action == "custombuttonclick") {
      switch (data.custombuttonclick.btninfo.label) {
        case "Edit":
          this.router.navigateByUrl(`admin/email-template/slug/edit/${data.custombuttonclick.data._id}`)
          break;
        case "Delete":
          // this.deleteListItem(data.custombuttonclick.data)
          break;
        default: null
      }
    }
    if (data.action == "multipleselectionchange") {
      const actionbuttonwrapperDiv = document.querySelector('.tablewrapper');
      if (data.selecteddata.length > 0) {
        actionbuttonwrapperDiv.classList.add("remove");
      } else {
        actionbuttonwrapperDiv.classList.remove("remove");
      }
    }
  }

  // addNewSlug() {
  //   this.router.navigateByUrl('admin/email-template/add-slug')
  // }
  onLiblistingButtonChange(val: any) {
    console.log("onLiblistingButtonChange val", val)
    if (val.action == "customlistenbutton") {
      if (val.buttondata.name == 'add_slug') {
        this.router.navigateByUrl('admin/email-template/add-slug')
      }
    }

  }
  listenFormFieldChange(val: any) {


  }
  editListItem(data: any) {
    throw new Error('Method not implemented.');
  }
}
