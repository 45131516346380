<div class="signPageDialogBody ">
    <button mat-button (click)="onNoClick()" class="closealert"> <mat-icon>close</mat-icon></button>
    <h2>Delete Records</h2>
    <p style="font-size: 20px;">Are you sure you want to delete this record? This process can not be undone.</p>
    <div class="signmodalgroup">
        <button type="button" mat-raised-button color="accent" (click)="onNoClick()">CANCEL</button>
        <button type="submit" mat-raised-button color="primary" (click)="confirm()">CONFIRM</button>
    </div>

    <mat-progress-bar mode="indeterminate" *ngIf="progressBar"></mat-progress-bar>

</div>