<mat-card-content class="loginPage">
    <mat-card class="bg_block1"></mat-card>
    <mat-card class="bg_block2"></mat-card>
    <mat-card class="bg_block3"></mat-card>
    <mat-card class="loginPageBody">
        <!-- <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/practice2lab_logo.png" alt="practice2lab_logo"> -->
        <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/repid_respose_virtual_payment.webp" alt="practice2lab_logo">
        <h2>Login</h2>

        <form (ngSubmit)="login()" (keydown)="keyDownFunction($event)" autocomplete="off" name="loginForm" [formGroup]="loginForm">

            <span class="messageShow_flag"> <mat-error *ngIf="messageShow_flag">{{messageShow}}</mat-error></span>


            <mat-card-content class="input_field_wrapper">
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" name="email" formControlName="email" required>
                </mat-form-field>
                <ng-container *ngIf="!loginForm.controls['email'].valid && loginForm.controls['email'].touched && loginForm.controls['email'].value==''">
                    <span class="error">Email Address is Required.</span>
                </ng-container>
                <ng-container *ngIf="loginForm.controls['email'].value!=='' && loginForm.controls['email'].errors">
                    <span class="error"> Email not valid.</span>
                </ng-container>
            </mat-card-content>

            <mat-card-content class="input_field_wrapper">
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" name="password" formControlName="password" required>
                    <button mat-icon-button matSuffix (click)="hide = !hide;$event.preventDefault()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <ng-container *ngIf="!loginForm.controls['password'].valid && loginForm.controls['password'].touched">
                    <span class="error"> Password is Required.</span>
                </ng-container>
            </mat-card-content>
            <a href="/forgetpassword" class="link">Forgot Password?</a>
            <button mat-button type="submit">Login</button>
            <ng-container *ngIf="progressLoader">
                <br />
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>
        </form>
    </mat-card>




</mat-card-content>