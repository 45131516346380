import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.css']
})
export class EquipmentListComponent {
  public listprogressBar: boolean = false;
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id'];
  public tabledatatalist: []
  public modalrogressBar: any = false;


  tablename = "equipment";
  editroute = "";
  // updateendpoint = "email/emailslugstatus";
  deleteendpoint = "intake/deleteequipment";
  datacollection: any = "intake/equipment-list";

  searchendpoint = "intake/equipment-list";
  date_search_endpoint: any = "intake/equipment-list";
  date_search_source: any = "equipment";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {

    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];



  modify_header_array: any = {
    equipment_name: "Name",
    description: "Description",
    image:"Image",
    status: "Status",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On"
  };
  search_settings: any = {
    search: [

      {
        label: "Search By Name ", field: 'equipment_name',

        serversearchdata: { endpoint: 'intake/equipmentautocomplete' },

      },

    
    ],



    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [

      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["equipment_name", "updatedon_datetime", "status"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Manage Category',
      //   type: 'button',
      //   name: 'add_category',
      //   tooltip: 'Manage Category',
      //   classname: 'add_adminBTN',
      // },

      // {
      //   label: 'Add Diagnosis',
      //   type: 'button',
      //   name: 'add_diagnostic',
      //   tooltip: 'Add Diagnosis',
      //   classname: 'add_adminBTN',
      // },

      // {
      //   label: 'Add Diagnosis',
      //   type: 'button',
      //   name: 'add_diagnostic',
      //   tooltip: 'Add Diagnosis',
      //   classname: 'add_adminBTN',
      // }
    ],
  }
 
  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: "image", val: "Image" },
      { key: "equipment_name", val: "Name" },
      { key: "description", val: "Description" },
      { key: "status", val: "Status" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" }
    ],
    updateendpoint: "intake/equipmentstatus",
    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "intake/equipmentstatus",

    deleteendpointmany: "intake/deleteequipment",
    tableheaders: ["image","equipment_name", "description", "updatedon_datetime", "status"],

    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'intake/previewequipment',
        otherparam: [],
        // cond:'status',
        // condval:0,
        param: '_id',
        datafields: ['equipment_name', "description", 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
    ],

  }
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  public adduserBTN: boolean = false;
  public daignosis_updatetable: boolean = false;
  public chooseDiagnosisLoader: boolean = false;
  constructor(public dialog: MatDialog, private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService, public matSnackBar: MatSnackBar) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous')) {
      this.router.navigateByUrl('404');
    }
  }

ngOnInit(){
this.progressLoader = true;
    this.userListFetching();
    this.activatedRoute.data.subscribe((response: any) => {
      this.listprogressBar = true
      console.log("activatedRoute responseff", response)
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);
        if (this.tabledatatalist.length == 0) {

          // console.log("no record")

          setTimeout(() => {
            this.adduserBTN = true

            let myRow = document.querySelector('.adminBody');
            myRow.classList.add('libListNoDataCSS');
          }, 200);


        }
      }
    })
}


  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }
    
    this.httpService.httpViaPost('intake/equipment-list-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }
    })
  }

  listenLiblistingChange(data: any = null) {
    // console.log("listenLiblistingChange", data);
    // console.log("listenLiblistingChange", data.custombuttonclick.data._id);
    // practice/intake-form-preview/view
    console.log("Op=====>1", data);
  if (data.action == "custombuttonclick") {
      switch (data.custombuttonclick.btninfo.label) {
        case "Edit":
          
            this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/equipment-edit/${data.custombuttonclick.data._id}`);
          
          break;
        case "Delete":
          // this.deleteListItem(data.custombuttonclick.data)
          break;
        default: null
      }
    }
  }
  listenFormFieldChange(val: any) { }
  onLiblistingButtonChange(val: any) { }
  addEquipment() {
    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/equipment-add`)
  }
}
