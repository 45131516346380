<app-header></app-header>
<div class="button_titlebar leadPreviewDiv" [ngClass]="clinicalReportAvailable?'reportAvailable':''">
    <!-- <div class="button_titlebar"> -->
    <mat-card *ngIf="statusCodeArrayFlag">



        <mat-card-content class="statusBlockCon" [ngClass]="{'statusBlockCon_doctor': calculatDecisionDocFlag, 'statusBlockCon_other': !calculatDecisionDocFlag}">
            <mat-card-content class="statusBlockCon_newsub">

                <span [ngClass]="statusCodeArray.assessment == 1 ? 'statusdone' : 'statuspending'">Patient
                    Assessment</span>
                <span [ngClass]="statusCodeArray.ordersheet == 1 ? 'statusdone' : 'statuspending'">Order-sheet
                    Generated</span>
                <span [ngClass]="statusCodeArray.sign == 1 ? 'statusdone' : 'statuspending'">Order Signed & Sent</span>
                <span [ngClass]="statusCodeArray.accepte == 1 ? 'statusdone' : 'statuspending'">Order Accepted</span>
                <span [ngClass]="statusCodeArray.reject == 1 ? 'statusdone' : 'statuspending'">Order Rejected</span>
                <span [ngClass]="statusCodeArray.complete == 1 ? 'statusdone' : 'statuspending'">Report Uploaded</span>
                <span [ngClass]="statusCodeArray.complete == 1 ? 'statusdone' : 'statuspending'">Order Completed</span>
                <!-- <button class="btn_download" (click)="handleGeneratePdf()"><i class="fa fa-download" aria-hidden="true"></i></button> -->

            </mat-card-content>
            <div class="calculatDDblock" (click)="DecisionDoccal()" *ngIf="calculatDecisionDocFlag"><button class="calculatDDbtn">Calculate DecisionDoc</button></div>
        </mat-card-content>
    </mat-card>
    <ng-container *ngIf="pdfDownloadLoader">
        <span class="addFormLoadercss"><mat-progress-bar mode="indeterminate"></mat-progress-bar></span>
    </ng-container>
    <button class="btn_download_pdf" (click)="handleGeneratePdf()" title="Download Patient Report" *ngIf="downloadBtnFlag"><i class="fa fa-download" aria-hidden="true"></i></button>
    <div class="alertMsgBlock" *ngIf="rejectedNoteShowFlag">
        <strong>Rejection Notes: </strong> {{notesDataTxt}}
    </div>


    <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabview($event)" [ngClass]="isEmailPreviewIntake?'fullTab':''">
        <mat-tab label="Patient Details">
            <app-lead-intakes *ngIf="!tabviewFlag"></app-lead-intakes>
        </mat-tab>
        <mat-tab label="Patient History">
            <app-physician-examination *ngIf="physician_examinationFlag"></app-physician-examination>
        </mat-tab>
        <ng-container *ngIf="!isEmailPreviewIntake && patientCurrentStatus > 1">
            <mat-tab label="Order Sheet">
                <ng-container *ngIf="addFormLoader">
                    <span class="addFormLoadercss"><mat-progress-bar mode="indeterminate"></mat-progress-bar></span>
                </ng-container>
                <ng-container *ngIf="cookiedata.user_type != 'laboratory'">
                    <app-lead-order-sheet [ordersheetdata]="order_sheet_data" [ordersheetSign]="order_sheet_sign" [orderstatusreject]="fororderreject" [needSignFlag]="needSignFlag" *ngIf="tabviewFlag && order_sheet_data !== null"></app-lead-order-sheet>
                </ng-container>

                <ng-container *ngIf="cookiedata.user_type == 'laboratory'">
                    <app-lab-order-sheet [ordersheetdata]="order_sheet_data" [ordersheetSign]="order_sheet_sign" [orderstatusreject]="fororderreject" *ngIf="tabviewFlag && order_sheet_data !== null"></app-lab-order-sheet>
                </ng-container>

            </mat-tab>
            <ng-container *ngIf="isLennyLab">
                <mat-tab label="Device Order">
                    <app-device-order [tapDeviceOrderValue]="tapDeviceOrder"></app-device-order>
                </mat-tab>
                <mat-tab label="Lab Reports">
                    <div class="labReportsDiv">
                        <app-lab-report></app-lab-report>
                    </div>
                </mat-tab>
                <mat-tab label="Evaluation Management">
                    <div class="evaluationManagementDiv">
                        <app-evaluation-management></app-evaluation-management>
                    </div>
                </mat-tab>
            </ng-container>
        </ng-container>

        <mat-tab label="Clinical Report" *ngIf="clinicalReportAvailable">
            <div class="addEditPageWrapper intake_formwrapper">
                <div class="addEditForm clinicalReportAvailable">
                    <div class="" [ngClass]="isPDFFile?'isPDFFile':''" *ngFor="let pdfVal of pdfFileName">
                        <!-- <ng-container *ngIf="!isPDFFile">
                            <img src="http://practice2lab.com.s3.amazonaws.com/{{clinicalReportData.path}}{{clinicalReportData.fileservername}}">
                        </ng-container> -->
                        <ng-container *ngIf="isPDFFile">
                            <embed [src]="pdfVal" type="application/pdf" height="100%" width="100%" class="responsive">
                        </ng-container>
                    </div>
                    <ng-container *ngIf="pdfFileName.length > 1">
                        <button class="previousSlide slideBtn" [disabled]="slideIndex==0" (click)="slideClicked('pre')">
                            <mat-icon style="cursor: pointer;"> keyboard_arrow_left</mat-icon>
                        </button>
                        <button class="nextSlide slideBtn" [disabled]="pdfFileName.length-1 == slideIndex" (click)="slideClicked('next')">
                            <mat-icon style="cursor: pointer;"> keyboard_arrow_right</mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>


    <div class="pdf_block" ref={reportTemplateRef} id='template1'>
        <app-intake-pdf-sheet [patientid]="paramID"></app-intake-pdf-sheet>
    </div>
    <!-- </div> -->
</div>

<span class="footerBlock"><app-footer></app-footer></span>