<div class="signPageDialogBody">
    <h2>Digital Signature
        <a href="javascript:void(0)"></a>
    </h2>

    <div class="signPageDialogCon">
        <div class="termsNcond">
            <div class="box">
                <h3> Term and Condition </h3>
                <div>
                    <div>
                        <h4> Practice initiatives to comply with HIPAA Clauses </h4>
                        <span class="practiceinfo"> <b>{{practiceData.practice_name}}</b> </span> agrees to comply with the applicable regulations for the Health Insurance Portability and Accountability Act (HIPAA) and shall defend and hold. Company and
                        practice2lab, LLC harmless from any sanctions received by Practice to the extent permitted by law, for breach of these regulations. Practice also agrees <br>(i) that patients to whom services are rendered are third- party beneficiaries
                        of this section; <br>(ii) to prohibit any unauthorized disclosures or use of protected information; <br>(iii) to put in place appropriate safeguards ensuring only permitted uses and disclosures; <br>(iv) to immediately report to
                        Company reports of any unauthorized uses or disclosures; <br>(v) to ensure that subcontractors of Practice agree to the provisions of this section; <br>(vi) to consent to patient access to their own health information; <br>(vii)
                        to make protected information available to the Federal Department of Health and Human Services as well as all internal compliance policies and procedures; <br>(viii) to provide for the destruction of protected information upon
                        expiration or termination of this Agreement unless it must be retained to comply with another provision of law; and <br>(ix) to ensure appropriate correction or amendment of records. <br>
                    </div>
                    <div>
                        <h4> practice2lab technical Initiatives to Comply with HIPAA Clauses </h4>
                        <p>Our entire tech is being stored and hosted by the cloud service provider, Amazon Web services. AWS offers many tools that help secure Medical data or data of any sort, using the latest technology on the server side and client side
                            encryption. With respect to the security of the data that we acquire through our portal, all of our sensitive data and reports are stored in AWS S3 storage services, Mongodb dedicated clusters and the web application itself
                            is hosted by AWS Cloudfront Delivery System, hence eliminating all Chances for public access of our data. Detailed information on each service is listed below.
                        </p>
                    </div>
                    <div class="bullets_wrpr">
                        <ul>
                            <h4> Storage Unit (AWS S3) </h4>
                            <li>Our s3 Bucket(The Storage Unit) is Completely Private and the content of said bucket cannot be accessed by the public or any third party application. AWS has their own security protocol in place for that.</li>
                            <li>All Content of our bucket is encrypted using algorithm AES256 that Amazon S3 supports.
                            </li>
                            <li>The IAM users and their respective access and secret keys are rotated on a weekly basis, and strong protocol has been implemented to ensure the confidentiality of such keys, with 24 hours key usage monitoring by AWS Cloudtrail
                                and our in house cloud technician.
                            </li>
                        </ul>
                        <ul>
                            <h4> Database Dedicated Cluster (MongoDB Atlas)</h4>
                            <li>Our mongodb data sets are secured by standard username and password sets. The password is kept confidential at all times and is made sure that under no circumstances made public even through git repositories.</li>
                            <li>Our sensitive data in the database has been encrypted when in rest and in Transport, using AES256-CBC or AES256-GCM encryption algorithm.</li>
                        </ul>
                        <ul>
                            <h4> Content Delivery Network (AWS CloudFront) </h4>
                            <li>Our global content delivery network (CDN) service that is provided by AWS Cloudfront, has been configured to use HTTPS end-to-end from the origin to the viewer, ensuring no data leakage.</li>
                        </ul>
                        <ul>
                            <h4> Endpoint API Server Side (AWS API Gateway) </h4>
                            <li> Each of our AWS Lambda APIs have been configured to respond to requests from a very specific and private source that are controlled by confidential header tags, which again are rotated.</li>
                        </ul>
                    </div>
                    <div>
                        <h4> Our Cloud security measures: </h4>
                        <ul>
                            <li> Every access key and secret key that the developers have access to has been policy restricted to have the least amount of administrative access and has no permission to access or download data from our private storage bucket.
                                Moreover each such key is rotated weekly and strict monitoring is maintained about activity induced from each key using AWS Cloudtrail.</li>
                            <li>Only a Specific panel of developers are working with data oriented tech and they have agreed to our non-disclosure policy.</li>
                        </ul>
                    </div>
                    <div>
                        <h4> Patient authorization and acceptance </h4>
                        <p>The patient authorises the release of individuals test results, with all identifiable information removed, to practise details with location and other details and practice2lab, LLC to be used in aggregating data which allows for
                            improving the effectiveness of testing and treatment. The test results will not have any impact on individuals care, treatment or individuals cost of care or treatment.</p>
                    </div>
                    <div>
                        <h4> No unlawful or prohibited use/Intellectual Property </h4>
                        <p>You are granted a non-exclusive, non-transferable, revocable license to access and use practice2lab™ strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to practice2lab ™that you
                            will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use
                            and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site. All content included as part of the Service,
                            such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of practice2lab™ or its suppliers and protected by copyright and other laws that protect intellectual
                            property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices.
                        </p>
                    </div> <br>
                    <div>Welcome to practice2lab. The practice2lab website (the "Site") is comprised of various web pages operated by practice2lab is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained
                        herein (the "Terms"). Your use of practice2lab constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference. The <span class="practiceinfo"> <b>{{practiceData.practice_name}}</b> </span>                        is responsible to secure any personal data of the patient, only share as much as needed where necessary. Technical safeguards are followed through <span class="practiceinfo"> <span><b>{{practiceData.practice_name}}</b></span> |
                        <span> <b>{{practiceData.phone}}</b></span> | <span class="emailspan"> <b>{{practiceData.email}}</b></span> </span>
                        and practice2lab system that address access controls, data in motion, and data at rest requirements. The practice2lab system has implemented technical policies and procedures for computing systems that maintain PHI data to restrict access to only those
                        persons that have been granted access rights. These physical safeguards include: Restricted provisions access and control with authorised access in place Related policies about the utilisation to workstations and electronic media
                        Confinement of transferring, removing, disposing, and re-using electronic media and ePHI Using unique user IDS, emergency access procedures, automatic log off, and encryption and decryption.
                    </div>
                    <div>
                        <h4> Privacy</h4>Your use of practice2lab™ is subject to practice2lab™'s Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.
                    </div>
                </div>
            </div>
            <div class="box">
                <h3> Privacy Policy </h3> <br>
                <p>Protecting your private information is our priority. This Statement of Privacy applies to practice2lab governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to practice2lab include
                    practice2lab. The practice2lab website is an eCommerce site. By using the practice2lab website, you consent to the data practices described in this statement. If you have questions or concerns regarding this policy, you should first
                    contact us at support@practice2labportal.com.</p>
                <div>
                    <h4> Collection of your Personal Information </h4>
                    <p>practice2lab may collect personally identifiable information, such as your: - Name - Address - E-mail Address - Phone Number</p>
                    <p>If you purchase practice2lab's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction. practice2labmay also collect anonymous demographic information, which
                        is not unique to you, such as your: - Age - Gender We may gather additional personal or non-personal information in the future. Information about your computer hardware and software may be automatically collected by practice2lab.
                        This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide
                        general statistics regarding use of the practice2lab website. Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through practice2lab's public message boards, this
                        information may be collected and used by others. practice2lab encourages you to review the privacy statements of websites you choose to link to from practice2lab so that you can understand how those websites collect, use and share
                        your information. practice2lab is not responsible for the privacy statements or other content on websites outside of the practice2lab website.
                    </p>
                </div>
                <div>
                    <h4> Use of your Personal Information </h4>practice2lab collects and uses your personal information to operate its website(s) and deliver the services you have requested. practice2lab may also use your personally identifiable information
                    to inform you of other products or services available from practice2lab™ and its affiliates. practice2lab may also contact you via surveys to conduct research about your opinion of current services or of potential new services that
                    may be offered. practice2lab does not sell, rent or lease its customer lists to third parties. practice2lab may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest
                    to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is transferred to the third party. practice2lab may share data with trusted partners to help perform statistical analysis,
                    send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to practice2lab, and they are required
                    to maintain the confidentiality of your information. practice2lab may keep track of the websites and pages our users visit within practice2lab, in order to determine what practice2lab services are the most popular. This data is used
                    to deliver customized content and advertising within practice2lab to customers whose behavior indicates that they are interested in a particular subject area. practice2lab will disclose your personal information, without notice, only
                    if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on practice2lab or the site; (b) protect and defend the rights or property
                    of practice2lab; and, (c) act under exigent circumstances to protect the personal safety of users of practice2lab, or the public.
                </div>
                <div>
                    <h4> Use of Cookies </h4>The practice2lab website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or
                    deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. One of the primary purposes of cookies is to provide a convenience feature to
                    save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize practice2lab pages, or register with practice2lab site or services, a cookie helps practice2lab
                    to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same practice2lab website,
                    the information you previously provided can be retrieved, so you can easily use the practice2lab features that you customized. You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you
                    can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the practice2lab services or websites you visit.
                </div>
                <div>
                    <h4>Security of your Personal Information </h4>
                    <p>practice2lab secures your personal information from unauthorized access, use, or disclosure. practice2lab uses the following methods for this purpose: - SSL Protocol When personal information (such as a credit card number) is transmitted
                        to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>
                </div>
                <div>
                    <h4>Children Under Thirteen </h4>
                    <p>practice2lab does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</p>
                </div>
                <div>
                    <h4>Children Under Thirteen </h4>
                    <p>You will be able to connect your practice2lab account to third party accounts. BY CONNECTING YOUR practice2lab ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION
                        ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THE THIS
                        FEATURE. You may disconnect your account from a third party account at any time.</p>
                </div>
                <div>
                    <h4> Opt-Out &amp; Unsubscribe </h4>
                    <p>We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain information. Users may opt-out of receiving any or all communications from practice2lab by contacting us here: <br>- Web page: www.practice2labportal.com
                        <br>- Email:
                        <a href="mailto:support@practice2labportal.com">support@practice2labportal.com</a>
                        <br>-Phone:
                        <a href="tel:208.440.0928">208.440.0928</a>
                    </p>
                </div>

                <div>

                </div>
                <div>
                    <h4> Changes to this Statement </h4>
                    <p>GEO Fence will occasionally update this Statement of Privacy to reflect practice2lab and customer feedback. practice2lab encourages you to periodically review this Statement to be informed of how practice2lab is protecting your information.</p>
                </div>
                <!-- <div>
                    <h4> Contact Information</h4>
                    <p>practice2lab welcomes your questions or comments regarding this Statement of Privacy. If you believe that
                        The practice2lab Medical Device platform and Managed Services has not adhered to this Statement, please
                        contact practice2lab at: <br>- 18 E Main Street, Rexburg, ID 83440<br>- Email Address:
                        Support@practice2labportal.com <br>- Telephone Number: 208-999-3394</p>
                </div> -->
            </div>
        </div>


    </div>

    <div class="signPageDialogConForm">

        <h5>By signing this agreement, you agree with the Terms & Conditions and Privacy Statement above. </h5>

        <div class="signPageDialogConFormDiv">

            <form (ngSubmit)="addsign()" autocomplete="off" name="addsignForm" [formGroup]="addsignForm">

                <mat-form-field>
                    <mat-label>Type Your Name Here</mat-label>
                    <input matInput name="signinput" formControlName="signinput" (keyup)="myFunction($event)" required>

                </mat-form-field>
                <mat-error *ngIf="!addsignForm.controls['signinput'].valid && addsignForm.controls['signinput'].touched">
                    <span class="errortext">This Field is Required</span>
                </mat-error>

                <div class="signFormat">{{signvalu}}</div>



                <button type="submit" mat-raised-button color="primary">Sign</button>

                <button type="button" mat-raised-button color="accent" (click)="onNoClick()">Cancel</button> &nbsp;


            </form>

        </div>



    </div>



</div>