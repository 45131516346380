<div class="orderDeviceDiv">
    <ng-container *ngIf="addFormLoader">
        <div class="order_sheet_wrapper">
            <div class="order_progress order_progress-striped">
                <div class="order_progress-bar">
                </div>
            </div>

            <label class="pleasewaittext">Please Wait.</label>
        </div>
    </ng-container>
    <ng-container *ngIf="!addFormLoader">
        <h1>Orders Segmented by Devices</h1>

        <ng-container *ngFor="let order of deviceOrderList">
            <div class="orderDeviceDivCon diagnostic_cat_id sameAndHide">
                <h2 class="orderAllConh2">
                    {{order.equipment_name}}
                </h2>
                <!-- <h2>{{order.equipment_name}}</h2> -->

                <div class="orderDeviceDivConBody">

                    <div class="orderDeviceDivConBodyIMG">
                        <img [src]="order.equipment_images" alt="{{order.equipment_name}}">
                    </div>

                    <div class="orderDeviceDivConBodyTEXT">


                        <div class="orderDeviceTableBody">
                            <div class="orderDeviceTableBodyText" *ngFor="let symptomValue of order.order_sheet">
                                <h3>{{symptomValue.diagnostic_name}}</h3>
                                <div class="Tableconrow">
                                    <ng-container *ngIf="symptomValue.cpt_code_available">
                                        <table>
                                            <tr *ngFor="let cptItem of symptomValue.assay_name">
                                                <td>{{cptItem.name}}</td>
                                                <td>{{cptItem.cpt_code}}</td>
                                                <td>${{cptItem.cpt_price}} </td>
                                            </tr>
                                        </table>
                                        <table class="tableBottomCss">
                                            <tr>
                                                <td>Total Earning: </td>
                                                <td>&nbsp;</td>
                                                <td>${{symptomValue.total_cost}}</td>
                                            </tr>
                                        </table>
                                    </ng-container>
                                    <ng-container *ngIf="!symptomValue.cpt_code_available">
                                        <table>
                                            <tr>
                                                <td>ALB</td>
                                                <td>82040QW</td>
                                                <td>$4.95 </td>
                                            </tr>
                                            <tr>
                                                <td>ALP</td>
                                                <td>84075QW</td>
                                                <td>$5.18 </td>
                                            </tr>
                                            <tr>
                                                <td>ALT</td>
                                                <td>84460QW</td>
                                                <td>$6.48</td>
                                            </tr>
                                            <tr>
                                                <td>Amylase</td>
                                                <td>82150QW</td>
                                                <td>$5.30 </td>
                                            </tr>
                                            <tr>
                                                <td>AST</td>
                                                <td>84450QW</td>
                                                <td>$5.18 </td>
                                            </tr>
                                            <tr>
                                                <td>BUN</td>
                                                <td>84520QW</td>
                                                <td>$3.95 </td>
                                            </tr>
                                            <tr>
                                                <td>Total Calcium</td>
                                                <td>82310QW</td>
                                                <td>$5.16</td>
                                            </tr>
                                            <tr>
                                                <td>Creatinine</td>
                                                <td>82565QW</td>
                                                <td>$5.12 </td>
                                            </tr>
                                            <tr>
                                                <td>GGT</td>
                                                <td>82977QW</td>
                                                <td>$7.20</td>
                                            </tr>
                                            <tr>
                                                <td>GLU</td>
                                                <td>82947QW</td>
                                                <td>$5.02 </td>
                                            </tr>
                                            <tr>
                                                <td>Bilurubiun (total) </td>
                                                <td>82247QW</td>
                                                <td>$3.67 </td>
                                            </tr>
                                            <tr>
                                                <td>Protein (total) </td>
                                                <td>84155QW</td>
                                                <td>$7.20 </td>
                                            </tr>
                                            <tr>
                                                <td>Uric Acid</td>
                                                <td>84550QW</td>
                                                <td> $4.52 </td>
                                            </tr>
                                        </table>
                                        <table class="tableBottomCss">
                                            <tr>
                                                <td>Total Earning: </td>
                                                <td>&nbsp;</td>
                                                <td>$65.66</td>
                                            </tr>
                                        </table>
                                    </ng-container>
                                </div>
                            </div>
                            <!-- <div class="orderDeviceTableBodyText">
                        <h3>Liver Panel Plus</h3>
                        <div class="Tableconrow">
                            <table>
                                <tr>
                                    <td>Albumin </td>
                                    <td>82040-QW </td>
                                    <td>$4.95</td>
                                </tr>
                                <tr>
                                    <td>Alkaline phosphatase </td>
                                    <td>84075-QW </td>
                                    <td>$5.18</td>
                                </tr>
                                <tr>
                                    <td>ALT </td>
                                    <td>84460-QW </td>
                                    <td>$5.30</td>
                                </tr>
                                <tr>
                                    <td>AST </td>
                                    <td>84450-QW </td>
                                    <td>$5.18</td>
                                </tr>
                                <tr>
                                    <td>Amylase </td>
                                    <td>82150-QW </td>
                                    <td>$6.48</td>
                                </tr>
                                <tr>
                                    <td>GGT </td>
                                    <td>82977-QW </td>
                                    <td>$7.20</td>
                                </tr>
                                <tr>
                                    <td>Total bilirubin </td>
                                    <td>82247-QW </td>
                                    <td>$5.02</td>
                                </tr>
                                <tr>
                                    <td>Total protein </td>
                                    <td>84155-QW </td>
                                    <td>$3.67</td>
                                </tr>
                            </table>
                            <table class="tableBottomCss">
                                <tr>
                                    <td>Total Earning: </td>
                                    <td>&nbsp;</td>
                                    <td>$42.98</td>
                                </tr>
                            </table>
                        </div>
                    </div> -->
                        </div>
                        <div class="orderDeviceTableBodyBottom">
                            <div class="orderDeviceTableBodyBottomText">
                                <h4>Total earning:</h4>
                                <div class="orderDeviceTableBodyBottomTextCon">
                                    <div class="labelDiv1">
                                        <label>{{order.diagnostic_name}}</label>
                                        <span>- $65.66 </span>
                                    </div>
                                    <!-- <div class="labelDiv1">
                                <label>Liver Panel Plus</label>
                                <span>- $42.98</span>
                            </div> -->
                                </div>
                            </div>
                            <div class="orderDeviceTableBodyBottomText">
                                <h4>Total Expenses:</h4>
                                <div class="orderDeviceTableBodyBottomTextCon">
                                    <div class="labelDiv1">
                                        <label>Reagent Cost</label>
                                        <span>- $11.00</span>
                                    </div>
                                    <div class="labelDiv1">
                                        <label>Fractional Run Time Cost</label>
                                        <span>- $5.00</span>
                                    </div>
                                </div>
                            </div>
                            <div class="orderDeviceTableBodyBottomText bottomdivtext greenBG">

                                <h4>Profitability</h4>

                                <div class="orderDeviceTableBodyBottomTextCon">

                                    <span>$65.66 + $ 42.98 - $16.00 = $108.64</span>


                                </div>




                            </div>
                        </div>
                        <div class="Orderbutton">
                            <button>Order</button>
                        </div>
                    </div>
                </div>

            </div>
        </ng-container>

    </ng-container>


    <!--
<div class="orderDeviceDivCon">

            <h2>ABBOTT PICCOLO XPRESS</h2>

            <div class="orderDeviceDivConBody">

                <div class="orderDeviceDivConBodyIMG"> <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/img_1.webp" alt="#"> </div>

                <div class="orderDeviceDivConBodyTEXT">

                    <div class="orderDeviceTableBody">

                        <div class="orderDeviceTableBodyText">
                            <h3>General Chemistry 13</h3>
                            <div class="Tableconrow">
                                <table>
                                    <tr>
                                        <td>ALB</td>
                                        <td>82040QW</td>
                                        <td>$4.95 </td>
                                    </tr>
                                    <tr>
                                        <td>ALP</td>
                                        <td>84075QW</td>
                                        <td>$5.18 </td>
                                    </tr>
                                    <tr>
                                        <td>ALT</td>
                                        <td>84460QW</td>
                                        <td>$6.48</td>
                                    </tr>

                                    <tr>
                                        <td>Amylase</td>
                                        <td>82150QW</td>
                                        <td>$5.30 </td>
                                    </tr>

                                    <tr>
                                        <td>AST</td>
                                        <td>84450QW</td>
                                        <td>$5.18 </td>
                                    </tr>

                                    <tr>
                                        <td>BUN</td>
                                        <td>84520QW</td>
                                        <td>$3.95 </td>
                                    </tr>
                                    <tr>
                                        <td>Total Calcium</td>
                                        <td>82310QW</td>
                                        <td>$5.16</td>
                                    </tr>

                                    <tr>
                                        <td>Creatinine</td>
                                        <td>82565QW</td>
                                        <td>$5.12 </td>
                                    </tr>

                                    <tr>
                                        <td>GGT</td>
                                        <td>82977QW</td>
                                        <td>$7.20</td>
                                    </tr>

                                    <tr>
                                        <td>GLU</td>
                                        <td>82947QW</td>
                                        <td>$5.02 </td>
                                    </tr>

                                    <tr>
                                        <td>Bilurubiun (total) </td>
                                        <td>82247QW</td>
                                        <td>$3.67 </td>
                                    </tr>

                                    <tr>
                                        <td>Protein (total) </td>
                                        <td>84155QW</td>
                                        <td>$7.20 </td>
                                    </tr>
                                    <tr>
                                        <td>Uric Acid</td>
                                        <td>84550QW</td>
                                        <td> $4.52 </td>
                                    </tr>

                                </table>

                                <table class="tableBottomCss">

                                    <tr>
                                        <td>Total Earning: </td>
                                        <td>&nbsp;</td>
                                        <td>$65.66</td>
                                    </tr>

                                </table>
                            </div>

                        </div>

                        <div class="orderDeviceTableBodyText">
                            <h3>Liver Panel Plus</h3>
                            <div class="Tableconrow">
                                <table>
                                    <tr>
                                        <td>Albumin </td>
                                        <td>82040-QW </td>
                                        <td>$4.95</td>

                                    </tr>
                                    <tr>
                                        <td>Alkaline phosphatase </td>
                                        <td>84075-QW </td>
                                        <td>$5.18</td>

                                    </tr>
                                    <tr>
                                        <td>ALT </td>
                                        <td>84460-QW </td>
                                        <td>$5.30</td>

                                    </tr>
                                    <tr>
                                        <td>AST </td>
                                        <td>84450-QW </td>
                                        <td>$5.18</td>
                                    </tr>
                                    <tr>
                                        <td>Amylase </td>
                                        <td>82150-QW </td>
                                        <td>$6.48</td>
                                    </tr>
                                    <tr>
                                        <td>GGT </td>
                                        <td>82977-QW </td>
                                        <td>$7.20</td>
                                    </tr>
                                    <tr>
                                        <td>Total bilirubin </td>
                                        <td>82247-QW </td>
                                        <td>$5.02</td>
                                    </tr>
                                    <tr>
                                        <td>Total protein </td>
                                        <td>84155-QW </td>
                                        <td>$3.67</td>
                                    </tr>
                                </table>
                                <table class="tableBottomCss">
                                    <tr>
                                        <td>Total Earning: </td>
                                        <td>&nbsp;</td>
                                        <td>$42.98</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="orderDeviceTableBodyBottom">
                        <div class="orderDeviceTableBodyBottomText">
                            <h4>Total earning:</h4>
                            <div class="orderDeviceTableBodyBottomTextCon">
                                <div class="labelDiv1">
                                    <label>General Chemistry 13</label>
                                    <span>- $65.66 </span>
                                </div>
                                <div class="labelDiv1">
                                    <label>Liver Panel Plus</label>
                                    <span>- $42.98</span>
                                </div>
                            </div>
                        </div>
                        <div class="orderDeviceTableBodyBottomText">
                            <h4>Total Expenses:</h4>
                            <div class="orderDeviceTableBodyBottomTextCon">
                                <div class="labelDiv1">
                                    <label>Reagent Cost</label>
                                    <span>- $11.00</span>
                                </div>
                                <div class="labelDiv1">
                                    <label>Fractional Run Time Cost</label>
                                    <span>- $5.00</span>
                                </div>
                            </div>
                        </div>
                        <div class="orderDeviceTableBodyBottomText bottomdivtext greenBG">
                            <h4>Profitability</h4>
                            <div class="orderDeviceTableBodyBottomTextCon">
                                <span>$65.66 + $ 42.98 - $16.00 = $108.64</span>
                            </div>
                        </div>
                    </div>
                    <div class="Orderbutton">
                        <button>Order</button>
                    </div>
                </div>
            </div>
        </div>

    <div class="orderDeviceDivCon">

        <h2>ABBOTT i-STAT 1</h2>

        <div class="orderDeviceDivConBody">

            <div class="orderDeviceDivConBodyIMG"> <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/IMG_2.webp" alt="#"> </div>

            <div class="orderDeviceDivConBodyTEXT">

                <div class="orderDeviceTableBody">

                    <div class="orderDeviceTableBodyText">
                        <h3>CG4+</h3>
                        <div class="Tableconrow">
                            <table>
                                <tr>
                                    <td>pH, PCO2, PO2. TCO2, HCO3, BEecf, sO2</td>
                                    <td>82803</td>
                                    <td>$26.07 </td>
                                </tr>
                                <tr>
                                    <td>Lactate</td>
                                    <td>83605</td>
                                    <td>$11.57 </td>
                                </tr>


                            </table>

                            <table class="tableBottomCss">

                                <tr>
                                    <td>Total Earning: </td>
                                    <td>&nbsp;</td>
                                    <td>$37.64 </td>
                                </tr>

                            </table>
                        </div>

                    </div>

                    <div class="orderDeviceTableBodyText">
                        <h3>CG8+</h3>
                        <div class="Tableconrow">
                            <table>
                                <tr>
                                    <td>Blood Gas</td>
                                    <td>82803</td>
                                    <td>$26.07</td>

                                </tr>
                                <tr>
                                    <td>Sodium</td>
                                    <td>84295</td>
                                    <td>$4.81</td>

                                </tr>
                                <tr>
                                    <td>Potassium</td>
                                    <td>84132</td>
                                    <td>$4.76</td>

                                </tr>
                                <tr>
                                    <td> Hematocrit</td>
                                    <td>85014</td>
                                    <td>$2.37</td>

                                </tr>
                                <tr>
                                    <td> Ionized Calcium</td>
                                    <td>82330</td>
                                    <td>$13.68</td>

                                </tr>
                                <tr>
                                    <td>Glucose
                                        <td>82947
                                            <td>$3.93</td>

                                </tr>


                            </table>

                            <table class="tableBottomCss">

                                <tr>
                                    <td>Total Earning: </td>
                                    <td>&nbsp;</td>
                                    <td>$55.62</td>
                                </tr>

                            </table>
                        </div>


                    </div>

                </div>

                <div class="orderDeviceTableBodyBottom">

                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total earning:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <div class="labelDiv1">
                                <label>CG4+ </label>
                                <span>- $37.64</span>
                            </div>

                            <div class="labelDiv1">
                                <label>CG8+</label>
                                <span>- $55.62</span>
                            </div>


                        </div>




                    </div>
                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total Expenses:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <div class="labelDiv1">
                                <label>Reagent Cost</label>
                                <span>- $11.00</span>
                            </div>

                            <div class="labelDiv1">
                                <label>Fractional Run Time Cost</label>
                                <span>- $5.00</span>
                            </div>


                        </div>




                    </div>

                    <div class="orderDeviceTableBodyBottomText bottomdivtext orangeBG">

                        <h4>Profitability</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <span>$37.64 + $ 55.62 - $16.00 = $77.26</span>


                        </div>




                    </div>




                </div>

                <div class="Orderbutton">

                    <button>Order</button>
                </div>


            </div>



        </div>


    </div>

    <div class="orderDeviceDivCon">

        <h2>SYSMEX XN-330</h2>

        <div class="orderDeviceDivConBody">

            <div class="orderDeviceDivConBodyIMG"> <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/IMG_3.webp" alt="#"> </div>

            <div class="orderDeviceDivConBodyTEXT">

                <div class="orderDeviceTableBody">

                    <div class="orderDeviceTableBodyText">
                        <h3>CBC</h3>
                        <div class="Tableconrow">
                            <table>
                                <tr>
                                    <td>CBC- 3-part differential </td>
                                    <td>85025QW</td>
                                    <td>$7.77 </td>
                                </tr>



                            </table>

                            <table class="tableBottomCss">

                                <tr>
                                    <td>Total Earning: </td>
                                    <td>&nbsp;</td>
                                    <td>$7.77 </td>
                                </tr>

                            </table>
                        </div>

                    </div>

                    <div class="orderDeviceTableBodyText">

                        <div class="Tableconrow">

                            <div class="noNotes"><strong>no Medical necessity</strong></div>

                        </div>


                    </div>

                </div>

                <div class="orderDeviceTableBodyBottom">

                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total earning:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">



                            <div class="labelDiv1 labelDivline1">
                                <label>CBC</label>
                                <span> - $7.77</span>
                            </div>




                        </div>




                    </div>
                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total Expenses:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <div class="labelDiv1">
                                <label>Reagent Cost</label>
                                <span>- $11.00</span>
                            </div>

                            <div class="labelDiv1">
                                <label>Fractional Run Time Cost</label>
                                <span>- $5.00</span>
                            </div>


                        </div>




                    </div>

                    <div class="orderDeviceTableBodyBottomText bottomdivtext redBG">

                        <h4>Profitability</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <span>$7.77 - $16.00 = -$8.23</span>


                        </div>




                    </div>




                </div>

                <div class="Orderbutton">

                    <button>Order</button>
                </div>


            </div>



        </div>


    </div>


    <div class="orderDeviceDivCon">

        <h2>AFIAS (Boditech)</h2>

        <div class="orderDeviceDivConBody">

            <div class="orderDeviceDivConBodyIMG"> <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/IMG_4.webp" alt="#"> </div>

            <div class="orderDeviceDivConBodyTEXT">

                <div class="orderDeviceTableBody ">

                    <div class="orderDeviceTableBodyText">
                        <h3>Free Prostate-specific antigen</h3>
                        <div class="Tableconrow">
                            <table>
                                <tr>
                                    <td>fPDA</td>
                                    <td>84153</td>
                                    <td>$18.72</td>
                                </tr>



                            </table>

                            <table class="tableBottomCss">

                                <tr>
                                    <td>Total Earning: </td>
                                    <td>&nbsp;</td>
                                    <td>$18.72</td>
                                </tr>

                            </table>
                        </div>

                    </div>

                    <div class="orderDeviceTableBodyText">

                        <div class="Tableconrow">

                            <div class="noNotes"><strong>no Medical necessity</strong></div>

                        </div>


                    </div>

                </div>

                <div class="orderDeviceTableBodyBottom">

                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total earning:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">



                            <div class="labelDiv1 labelDivline1">
                                <label>fPDA</label>
                                <span> - $18.72</span>
                            </div>




                        </div>




                    </div>
                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total Expenses:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <div class="labelDiv1">
                                <label>Reagent Cost</label>
                                <span>- $11.00</span>
                            </div>

                            <div class="labelDiv1">
                                <label>Fractional Run Time Cost</label>
                                <span>- $5.00</span>
                            </div>


                        </div>




                    </div>

                    <div class="orderDeviceTableBodyBottomText bottomdivtext orangeBG">

                        <h4>Profitability</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <span>$18.72 - $16.00 = $2.72</span>


                        </div>




                    </div>




                </div>

                <div class="Orderbutton">

                    <button>Order</button>
                </div>





            </div>



        </div>


    </div>
    <div class="orderDeviceDivCon">

        <h2>UC1000 (Sysmex)</h2>

        <div class="orderDeviceDivConBody">

            <div class="orderDeviceDivConBodyIMG"> <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/IMG_5.webp" alt="#"> </div>

            <div class="orderDeviceDivConBodyTEXT">

                <div class="orderDeviceTableBody ">

                    <div class="orderDeviceTableBodyText">
                        <h3>Toxicology</h3>
                        <div class="Tableconrow">
                            <table>
                                <tr>
                                    <td>Cocaine COC300 </td>
                                    <td>80305</td>
                                    <td>$12.60 </td>
                                </tr>
                                <tr>
                                    <td>Amphetamine AMP1000 </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Opiates OPI2000, </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Phencyclidine PCP25</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Marijuana THC50</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Barbiturates BAR300</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Benzodiazepines BZO300</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>



                            </table>

                            <table class="tableBottomCss">

                                <tr>
                                    <td>Total Earning: </td>
                                    <td>&nbsp;</td>
                                    <td>$12.60</td>
                                </tr>

                            </table>
                        </div>

                    </div>

                    <div class="orderDeviceTableBodyText">

                        <div class="Tableconrow">

                            <div class="noNotes"><strong>no Medical necessity</strong></div>

                        </div>


                    </div>

                </div>

                <div class="orderDeviceTableBodyBottom">

                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total earning:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">



                            <div class="labelDiv1 labelDivline1">
                                <label>TOXICOLOGY</label>
                                <span> - $12.60</span>
                            </div>




                        </div>




                    </div>
                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total Expenses:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <div class="labelDiv1">
                                <label>Reagent Cost</label>
                                <span>- $11.00</span>
                            </div>

                            <div class="labelDiv1">
                                <label>Fractional Run Time Cost</label>
                                <span>- $5.00</span>
                            </div>


                        </div>




                    </div>

                    <div class="orderDeviceTableBodyBottomText bottomdivtext redBG">

                        <h4>Profitability</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <span> $12.60 - $16.00 = -$3.40</span>


                        </div>




                    </div>




                </div>

                <div class="Orderbutton">

                    <button>Order</button>
                </div>





            </div>



        </div>


    </div>
    <div class="orderDeviceDivCon">

        <h2>RP 500e (Siemens)</h2>

        <div class="orderDeviceDivConBody">

            <div class="orderDeviceDivConBodyIMG"> <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/IMG_6.webp" alt="#"> </div>

            <div class="orderDeviceDivConBodyTEXT">

                <div class="orderDeviceTableBody ">

                    <div class="orderDeviceTableBodyText">
                        <h3>ACR</h3>
                        <div class="Tableconrow">
                            <table>
                                <tr>
                                    <td>Albumin-to-Creatinine ratio </td>
                                    <td>82570</td>
                                    <td>$18.72 </td>
                                </tr>




                            </table>

                            <table class="tableBottomCss">

                                <tr>
                                    <td>Total Earning: </td>
                                    <td>&nbsp;</td>
                                    <td>$18.72</td>
                                </tr>

                            </table>
                        </div>

                    </div>

                    <div class="orderDeviceTableBodyText">

                        <div class="Tableconrow">

                            <div class="noNotes"><strong>no Medical necessity</strong></div>

                        </div>


                    </div>

                </div>

                <div class="orderDeviceTableBodyBottom">

                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total earning:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">



                            <div class="labelDiv1 labelDivline1">
                                <label>ACR</label>
                                <span> - $12.60</span>
                            </div>




                        </div>




                    </div>
                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total Expenses:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <div class="labelDiv1">
                                <label>Reagent Cost</label>
                                <span>- $11.00</span>
                            </div>

                            <div class="labelDiv1">
                                <label>Fractional Run Time Cost</label>
                                <span>- $5.00</span>
                            </div>


                        </div>




                    </div>

                    <div class="orderDeviceTableBodyBottomText bottomdivtext orangeBG">

                        <h4>Profitability</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <span>$18.72 - $16.00 = $2.72</span>


                        </div>




                    </div>




                </div>

                <div class="Orderbutton">

                    <button>Order</button>
                </div>





            </div>



        </div>


    </div>
    <div class="orderDeviceDivCon">

        <h2>pocH-100i (Sysmex)</h2>

        <div class="orderDeviceDivConBody">

            <div class="orderDeviceDivConBodyIMG"> <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/IMG_7.webp" alt="#"> </div>

            <div class="orderDeviceDivConBodyTEXT">

                <div class="orderDeviceTableBody ">

                    <div class="orderDeviceTableBodyText">
                        <h3>WBC - 3 Part Differential</h3>
                        <div class="Tableconrow">
                            <table>
                                <tr>
                                    <td>CBC and Differential WBC Count </td>
                                    <td>85025 </td>
                                    <td> $18.72</td>
                                </tr>




                            </table>

                            <table class="tableBottomCss">

                                <tr>
                                    <td>Total Earning: </td>
                                    <td>&nbsp;</td>
                                    <td>$18.72</td>
                                </tr>

                            </table>
                        </div>

                    </div>

                    <div class="orderDeviceTableBodyText">

                        <div class="Tableconrow">

                            <div class="noNotes"><strong>no Medical necessity</strong></div>

                        </div>


                    </div>

                </div>

                <div class="orderDeviceTableBodyBottom">

                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total earning:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">



                            <div class="labelDiv1 labelDivline1">
                                <label>CBC and Differential WBC Count</label>
                                <span> - $12.60</span>
                            </div>




                        </div>




                    </div>
                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total Expenses:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <div class="labelDiv1">
                                <label>Reagent Cost</label>
                                <span>- $11.00</span>
                            </div>

                            <div class="labelDiv1">
                                <label>Fractional Run Time Cost</label>
                                <span>- $5.00</span>
                            </div>


                        </div>




                    </div>

                    <div class="orderDeviceTableBodyBottomText bottomdivtext orangeBG">

                        <h4>Profitability</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <span> $18.72 - $16.00 = $2.72</span>


                        </div>




                    </div>




                </div>

                <div class="Orderbutton">

                    <button>Order</button>
                </div>





            </div>


        </div>


    </div>
    <div class="orderDeviceDivCon">

        <h2>Gene Expert (CEPHEID)</h2>

        <div class="orderDeviceDivConBody">

            <div class="orderDeviceDivConBodyIMG"> <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/IMG_8.webp" alt="#"> </div>

            <div class="orderDeviceDivConBodyTEXT">

                <div class="orderDeviceTableBody ">

                    <div class="orderDeviceTableBodyText">
                        <h3>Acinetobacter Baumannii</h3>
                        <div class="Tableconrow">
                            <table>
                                <tr>
                                    <td>Acinetobacter Baumannii</td>
                                    <td>87640 </td>
                                    <td>$18.72</td>
                                </tr>




                            </table>

                            <table class="tableBottomCss">

                                <tr>
                                    <td>Total Earning: </td>
                                    <td>&nbsp;</td>
                                    <td>$18.72</td>
                                </tr>

                            </table>
                        </div>

                    </div>

                    <div class="orderDeviceTableBodyText">

                        <div class="Tableconrow">

                            <div class="noNotes"><strong>no Medical necessity</strong></div>

                        </div>


                    </div>

                </div>

                <div class="orderDeviceTableBodyBottom">

                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total earning:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">



                            <div class="labelDiv1 labelDivline1">
                                <label>Acinetobacter Baumannii</label>
                                <span> - $18.72</span>
                            </div>




                        </div>




                    </div>
                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total Expenses:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <div class="labelDiv1">
                                <label>Reagent Cost</label>
                                <span>- $11.00</span>
                            </div>

                            <div class="labelDiv1">
                                <label>Fractional Run Time Cost</label>
                                <span>- $5.00</span>
                            </div>


                        </div>




                    </div>

                    <div class="orderDeviceTableBodyBottomText bottomdivtext orangeBG">

                        <h4>Profitability</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <span> $18.72 - $16.00 = $2.72</span>


                        </div>




                    </div>




                </div>

                <div class="Orderbutton">

                    <button>Order</button>
                </div>





            </div>



        </div>


    </div>
    <div class="orderDeviceDivCon">

        <h2>m16 (EDAN)</h2>

        <div class="orderDeviceDivConBody">

            <div class="orderDeviceDivConBodyIMG"> <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/IMG_9.webp" alt="#"> </div>

            <div class="orderDeviceDivConBodyTEXT">

                <div class="orderDeviceTableBody ">

                    <div class="orderDeviceTableBodyText">
                        <h3>Carcinoembryonic antigen</h3>
                        <div class="Tableconrow">
                            <table>
                                <tr>
                                    <td>CEA</td>
                                    <td>82570 </td>
                                    <td>$18.72</td>
                                </tr>




                            </table>

                            <table class="tableBottomCss">

                                <tr>
                                    <td>Total Earning: </td>
                                    <td>&nbsp;</td>
                                    <td>$18.72</td>
                                </tr>

                            </table>
                        </div>

                    </div>

                    <div class="orderDeviceTableBodyText">

                        <div class="Tableconrow">

                            <div class="noNotes"><strong>no Medical necessity</strong></div>

                        </div>


                    </div>

                </div>

                <div class="orderDeviceTableBodyBottom">

                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total earning:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">



                            <div class="labelDiv1 labelDivline1">
                                <label>CEA</label>
                                <span> - $18.72</span>
                            </div>




                        </div>




                    </div>
                    <div class="orderDeviceTableBodyBottomText">

                        <h4>Total Expenses:</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <div class="labelDiv1">
                                <label>Reagent Cost</label>
                                <span>- $11.00</span>
                            </div>

                            <div class="labelDiv1">
                                <label>Fractional Run Time Cost</label>
                                <span>- $5.00</span>
                            </div>


                        </div>




                    </div>

                    <div class="orderDeviceTableBodyBottomText bottomdivtext orangeBG">

                        <h4>Profitability</h4>

                        <div class="orderDeviceTableBodyBottomTextCon">

                            <span> $18.72 - $16.00 = $2.72</span>


                        </div>




                    </div>




                </div>

                <div class="Orderbutton">

                    <button>Order</button>
                </div>





            </div>



        </div>


    </div>

-->

</div>