import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-training-center-category-list',
  templateUrl: './training-center-category-list.component.html',
  styleUrls: ['./training-center-category-list.component.css']
})
export class TrainingCenterCategoryListComponent {

  public categoryList: any = [];
  @Input() set categoryListFetched(value: any) {
    if (value) {
      this.categoryList = value
    }
  }
  @Output() trainingChanging: any = new EventEmitter();

  public cookieData: any = null;
  constructor(public apiservice: HttpServiceService, public activateRoute: ActivatedRoute, public router: Router, public cookieService: CookieService) { }
  ngOnInit() {
    console.log("activateRoute", this.activateRoute);
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
if (!window.location.pathname.includes('training')) {
      this.router.navigateByUrl('404')
    }
    // this.fetchAllLessons();
  }

  fetchAllLessons() {
    // this.loadingCategory.emit(true);
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }
    this.apiservice.httpViaPost('training/trainingcategorylist', data).subscribe((response: any) => {
      console.log("fetchAllCategories", response)
      if (response) {

        // this.loadingCategory.emit(false);
        if (response.results && response.results.res) {
          if (response.results.res.length > 0) {
            this.categoryList = response.results.res;
            if (this.categoryList.length > 0) {
              this.categoryList.forEach((e: any) => {
                if (e._id == this.activateRoute.snapshot.params["training_id"]) {
                  e.active = true;
                }
              })
            }
          };
        }
      }
    })
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log("SimpleChanges categoryList", changes)
  // }
  selectTraining(category: any) {
    console.log("category", category)
    if (category) {
      this.trainingChanging.emit(true);
      if (category.lesson_details && category.lesson_details.length > 0) {
        this.router.navigateByUrl(`${this.cookieData.user_type}/training/training-center/${category._id}/${category.lesson_details[0]._id}`);
      } else {
        this.router.navigateByUrl(`${this.cookieData.user_type}/training/training-center/${category._id}`);
        this.categoryList.forEach((e: any) => {
          e.active = false;
          if (e._id == category._id) {
            e.active = true;
          }
        })
      }
    }
  }
}
