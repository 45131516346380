<app-header></app-header>
<mat-card class="adminBody">
    <!-- <mat-card-content class="addEditPageBody">
        <mat-card class="addEditPageWrapper">

            <ng-container *ngIf="addFormLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </ng-container>

                
            <h2>{{isedit?'Edit':'Add'}} Doctor</h2>
            <mat-card class="addEditForm">

                <form (ngSubmit)="addEditDoctor()" autocomplete="off" name="addEditDoctorForm" [formGroup]="addEditDoctorForm">
                    <mat-form-field appearance="fill" class="inputBlock2line">
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="First Name" name="firstname" formControlName="firstname" required>
                        <ng-container *ngIf="!addEditDoctorForm.controls['firstname'].valid && addEditDoctorForm.controls['firstname'].touched">
                            <span class="error"> First Name is Required.</span>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="inputBlock2line">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Last Name" name="lastname" formControlName="lastname" required>

                        <ng-container *ngIf="!addEditDoctorForm.controls['lastname'].valid && addEditDoctorForm.controls['lastname'].touched">

                            <span class="error"> Last Name is Required.</span>
                        </ng-container>

                    </mat-form-field>

                    <mat-form-field appearance="fill" class="inputBlock3line">
                        <mat-label>Email Address</mat-label>
                        <input matInput placeholder="Email Address" name="email" formControlName="email" required>


                        <ng-container *ngIf="!addEditDoctorForm.controls['email'].valid && addEditDoctorForm.controls['email'].touched && addEditDoctorForm.controls['email'].value==''">
                            <span class="error">Email Address is Required.</span>
                        </ng-container>

                        <ng-container *ngIf="addEditDoctorForm.controls['email'].value!=='' && addEditDoctorForm.controls['email'].errors">
                            <span class="error"> Email not valid.</span>
                        </ng-container>



                    </mat-form-field>



                    <mat-form-field appearance="fill" class="inputBlock3line">
                        <mat-label>Phone No</mat-label>
                        


                        <input matInput placeholder="Phone" name="phone" formControlName="phone" required (input)="phoneFormatting($event)" maxlength="14" required >
                       

                        <ng-container *ngIf="!addEditDoctorForm.controls['phone'].valid && addEditDoctorForm.controls['phone'].touched && addEditDoctorForm.controls['phone'].value==''">
                            <span class="error">Phone No is Required.</span>
                        </ng-container>

                        <ng-container *ngIf="addEditDoctorForm.controls['phone'].value!=='' && addEditDoctorForm.controls['phone'].errors">
                            <span class="error"> Phone No not valid.</span>
                        </ng-container>



                    </mat-form-field>

                    <mat-form-field appearance="fill" class="inputBlock3line">
                        <mat-label>Fax</mat-label>
                 
                          <input matInput placeholder="Fax" name="fax" formControlName="fax" required (input)="faxFormatting($event)" maxlength="14">
                

                        <ng-container *ngIf="!addEditDoctorForm.controls['fax'].valid && addEditDoctorForm.controls['fax'].touched && addEditDoctorForm.controls['fax'].value==''">
                            <span class="error">Fax is Required.</span>
                        </ng-container>

                        <ng-container *ngIf="addEditDoctorForm.controls['fax'].value!=='' && addEditDoctorForm.controls['fax'].errors">
                            <span class="error"> Fax not valid.</span>
                        </ng-container>

                        
                    </mat-form-field>


                    <mat-form-field appearance="fill" class="inputBlock4line">
                        <mat-label>Select Taxonomies</mat-label>
                        <select matNativeControl name="txonomies" formControlName="txonomies"  required>
                        <option value="txonomies1">Select Taxonomies</option>                        
                    </select>
                        <ng-container *ngIf="!addEditDoctorForm.controls['txonomies'].valid && addEditDoctorForm.controls['txonomies'].touched">
                            <span class="error"> Taxonomies is Required.</span>
                        </ng-container>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="inputBlock4line">
                        <mat-label>Select Practice Name</mat-label>
                        <select matNativeControl name="practice_name" formControlName="practice_name" required>
                        <option value="practice">Practice Name</option>
                        
                    </select>

                        <ng-container *ngIf="!addEditDoctorForm.controls['practice_name'].valid && addEditDoctorForm.controls['practice_name'].touched">

                            <span class="error"> Practice Name is Required.</span>
                        </ng-container>

                    </mat-form-field>


                    <mat-form-field appearance="fill" class="inputBlock4line">
                        <mat-label> NPI(Select practice NPI)</mat-label>
                        <input matInput type="number" placeholder="NPI" name="npi" formControlName="npi" required maxlength="14">
                        <ng-container *ngIf="!addEditDoctorForm.controls['npi'].valid && addEditDoctorForm.controls['npi'].touched">
                            <span class="error"> NPI is Required.</span>
                        </ng-container>
                    </mat-form-field>


                    <mat-form-field appearance="fill" class="inputBlock4line">
                        <mat-label>Choose Location</mat-label>
                        <input matInput placeholder="Choose Location" name="location" formControlName="location" required>
                        <ng-container *ngIf="!addEditDoctorForm.controls['location'].valid && addEditDoctorForm.controls['location'].touched">

                            <span class="error"> Choose Location is Required.</span>
                        </ng-container>

                    </mat-form-field>


                    <ng-container *ngIf="!isedit">
                        <mat-form-field appearance="fill" class="inputBlock2line">
                            <mat-label>Password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" name="password" formControlName="password" required>
                            <button mat-icon-button matSuffix (click)="hide = !hide;$event.preventDefault()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>

                            <ng-container *ngIf="!addEditDoctorForm.controls['password'].valid && addEditDoctorForm.controls['password'].touched">
                                <span class="error"> Password is Required.</span>
                            </ng-container>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="inputBlock2line">
                            <mat-label>Confirm Password</mat-label>
                            <input matInput [type]="hidec ? 'password' : 'text'" name="confirmpassword" formControlName="confirmpassword" required>
                            <button mat-icon-button matSuffix (click)="hidec = !hidec;$event.preventDefault()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidec">
                        <mat-icon>{{hidec ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                            <ng-container *ngIf="!addEditDoctorForm.controls['confirmpassword'].valid && addEditDoctorForm.controls['confirmpassword'].touched && addEditDoctorForm.controls['confirmpassword'].value.length == 0">
                                <ng-container>
                                    <span class="error">Confirm Password required.</span>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="addEditDoctorForm.controls['confirmpassword'].touched && addEditDoctorForm.controls['password'].value !== '' && addEditDoctorForm.controls['confirmpassword'].value !== '' && (addEditDoctorForm.controls['password'].value !== addEditDoctorForm.controls['confirmpassword'].value)">
                                <ng-container>
                                    <span class="error"> Password does not match</span>
                                </ng-container>
                            </ng-container>
                        </mat-form-field>
                    </ng-container>



                    <mat-checkbox name="status" formControlName="status" class="inputBlock1line">Active </mat-checkbox>
                    <mat-card class="addEditButton">

                    <button mat-button type="submit">{{isedit?'Update':'Submit'}}</button>
                    <button mat-button type="reset" (click)="resetForm()">Reset </button>
                    <button mat-button type="button" (click)="backPage()">Back </button>

                    </mat-card>




                </form>

            </mat-card>


        </mat-card>

    </mat-card-content> -->


    <mat-card-content class="addEditPageWrapper addEditDoctor">

        <ng-container *ngIf="addFormLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>


        <h2 class="listingpageh2">{{isedit?'Edit':'Add'}} Doctor</h2>
        <div class="AddEditBlog location_style">

         <div class="addEditForm">
            <lib-showform class=" addEditDoctor" [formdata]="doctorformdata" [formfieldrefresh]="formfieldrefresh" [formfieldrefreshdata]="formfieldrefreshdata" (onFormFieldChange)="listenFormFieldChange($event)">
            </lib-showform>

            <ng-container *ngIf="addFormLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>

        </div>
        </div>

    </mat-card-content>

    <span class="footerBlock"><app-footer></app-footer></span>
</mat-card>