<div>
    <h2 style="text-align: center;"><u>{{modaldata.heading}}</u></h2>

    <!-- <P>{{modaldata.value | json}}</P> -->

    <div>

        <form (ngSubmit)="addLocation()" autocomplete="off" name="addLocationForm" [formGroup]="addLocationForm" class="addLocationForm">
            <mat-form-field class="example-full-width">
                <mat-label>Location Name</mat-label>
                <input matInput name="location_name" formControlName="location_name">
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Address</mat-label>
                <input matInput name="address" formControlName="address">
            </mat-form-field>
            <mat-form-field appearance="fill" class="inputBlock3line">
                <mat-label>Phone No</mat-label>
                <input matInput placeholder="Phone" name="phone" formControlName="phone" required (input)="phoneFormatting($event)" maxlength="14">
                <!-- <ng-container *ngIf="!addLocationForm.controls['phone'].valid && addLocationForm.controls['phone'].touched">
                    <span class="error"> Phone No is Required.</span>
                </ng-container> -->

                <ng-container *ngIf="!addLocationForm.controls['phone'].valid && addLocationForm.controls['phone'].touched && addLocationForm.controls['phone'].value==''">
                    <span class="error">Phone No is Required.</span>
                </ng-container>

                <ng-container *ngIf="addLocationForm.controls['phone'].value!=='' && addLocationForm.controls['phone'].errors">
                    <span class="error"> Phone No not valid.</span>
                </ng-container>

            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>City</mat-label>
                <input matInput name="city" formControlName="city">
            </mat-form-field>
            <mat-form-field appearance="fill" class="inputBlock3line">
                <mat-label>State</mat-label>
                <mat-select name="state" formControlName="state" required>
                    <mat-option *ngFor="let val of stateList" value="{{val.name}}">{{val.name}}</mat-option>
                </mat-select>
                <ng-container *ngIf="!addLocationForm.controls['state'].valid && addLocationForm.controls['state'].touched">
                    <span class="error"> State is Required.</span>
                </ng-container>
            </mat-form-field>
            <mat-form-field appearance="fill" class="inputBlock3line">
                <mat-label>Zip</mat-label>
                <input matInput placeholder="Zip" name="zip" formControlName="zip" required maxlength="5">
                <ng-container *ngIf="!addLocationForm.controls['zip'].valid && addLocationForm.controls['zip'].touched">
                    <span class="error">Zip is Required.</span>
                </ng-container>
            </mat-form-field>
            <ng-container *ngIf="resolveLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>
            <button type="button" mat-raised-button color="accent" (click)="onNoClick()">Cancel</button> &nbsp;
            <button type="submit" mat-raised-button color="primary">Add Data</button>


        </form>

    </div>

    <span>

    </span>

</div>