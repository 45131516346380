import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-training-center-main',
  templateUrl: './training-center-main.component.html',
  styleUrls: ['./training-center-main.component.css']
})
export class TrainingCenterMainComponent {

  public lessonList: any = [];
  public categoryList: any = [];
  public lessonLoading: boolean = true;
  public categoryLoading: boolean = true;
  public currentSelectedCategory: any = null;
  public cookieData: any = null;
  public totalLessonCount: number = null;
  public alldonelessonData: any = null;
  constructor(public apiservice: HttpServiceService, public activateRoute: ActivatedRoute, public router: Router, public cookieService: CookieService) { }
  ngOnInit() {
    // this.fetchAllLessons();
    // this.fetchAllCategory();
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      this.fetchAllTrainingCompleteReport();
    }
    this.activateRoute.data.subscribe((response: any) => {
      console.log("data response", response, "this.activateRouterrr", this.activateRoute)
      if (response) {
        if (response.data && response.data.results && response.data.results.res) {
          this.categoryList = response.data.results.res;
          if (this.categoryList.length > 0) {
            // this.categoryList.forEach((e: any, i: number) => {
            //   if (this.activateRoute.snapshot.params["training_id"]) {
            //     if (e._id == this.activateRoute.snapshot.params["training_id"]) {
            //       // e.active = true;
            //       this.currentSelectedCategory = e;
            //       this.lessonList = e.lesson_details;
            //     }
            //   } else {
            // this.currentSelectedCategory = this.categoryList[0];
            // this.lessonList = this.categoryList[0].lesson_details;
            //     if (this.cookieData.user_type == 'admin') {
            //       this.categoryList[0].active = true;
            //       this.lessonList[0].active = true;
            //     } else {
            //     }
            //   }
            // })
            if (!this.activateRoute.snapshot.params["training_id"]) {
              // this.currentSelectedCategory = this.categoryList[0];
              // this.lessonList = this.categoryList[0].lesson_details;
              // if (this.cookieData.user_type == 'admin') {
              //   this.categoryList[0].active = true;
              //   this.lessonList[0].active = true;
              // }
            }
            setTimeout(() => {
              console.log("alldonelessonData", this.alldonelessonData)
            }, 2000)
          }
        }
        if (response.data.totallesson) {
          this.totalLessonCount = response.data.totallesson;
        }
        this.lessonLoading = false;
        this.categoryLoading = false;
      }
    })
  }
  fetchAllLessons() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }
    this.apiservice.httpViaPost('training/lessonlist', data).subscribe((response: any) => {
      console.log("fetchAllCategories", response)
      if (response) {
        if (response.results && response.results.res) {
          if (response.results.res.length > 0) {
            this.lessonList = response.results.res;
          };
        }
        this.lessonLoading = false;
      }
    })
  }

  fetchAllCategory() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }
    this.apiservice.httpViaPost('training/trainingcategorylist', data).subscribe((response: any) => {
      console.log("fetchAllCategories", response)
      if (response) {
        // this.loadingCategory.emit(false);
        if (response.results && response.results.res) {
          if (response.results.res.length > 0) {
            this.categoryList = response.results.res;
            if (this.categoryList.length > 0) {
              this.categoryList.forEach((e: any) => {
                if (e._id == this.activateRoute.snapshot.params["_id"]) {
                  e.active = true;
                }
              })
            }
          };
        }
        this.categoryLoading = false;
      }
    })
  }

  fetchAllTrainingCompleteReport() {
    let data: any = {
      user_id: this.cookieData._id
    }
    this.apiservice.httpViaPost('training/alldonelesson', data).subscribe((response: any) => {
      console.log("fetchAllTrainingCompleteReport", response)
      if (response && response.data) {
        this.alldonelessonData = response.data;
      }
    })
  }

  trainingChangedFetched(event: any) {
    console.log("trainingChangedFetched", event)
    if (event) {
      this.lessonLoading = true;
    }
  }
}
