import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-device-order',
  templateUrl: './device-order.component.html',
  styleUrls: ['./device-order.component.css']
})
export class DeviceOrderComponent {

  public deviceOrderList: any = [
    {
      "_id": "65424b2138d4e6978ffde072",
      "diagnostic_name": "Arterial Blood Gas Analysis",
      "diagnostic_des": "01. J44.0 Chronic obstructive pulmonary disease with (acute) lower respiratory infection.\n02. J44.1 Chronic obstructive pulmonary disease with (acute) exacerbation.\n03. J44.9 Chronic obstructive pulmonary disease, unspecified.\n04. J80 - Acute respiratory distress syndrome.\n05. R65.20 - Severe sepsis without septic shock.\n06. R65.21 - Severe sepsis with septic shock.\n07. R57.1 - Hypovolemic shock.\n08. E11.10 - Type 2 diabetes mellitus with ketoacidosis without coma.\n09. E10.10 - Type 1 diabetes mellitus with ketoacidosis without coma.\n10. N25.9 - Disorder resulting from impaired renal tubular function, unspecified.\n11. J96. 00 - Acute respiratory failure, unspecified whether with hypoxia or hypercapnia.\n12. I50.9 - Heart failure, unspecified.\n13. I46.9 - Cardiac arrest, cause unspecified.\n14. J45.901 - Unspecified asthma with (acute) exacerbation.\n15. J45.902 - Unspecified asthma with status asthmaticus.\n16. J45.909 - Unspecified asthma uncomplicated.",
      "diagnostic_category_id": "645b4b088d7d669a36227217",
      "status": 1,
      "diagnostic_reason": "Arterial blood gas (ABG) analysis is an essential part of diagnosing and managing a patient's oxygenation status and acid–base balance.",
      "diago_reference_link": "https://muhealth.testcatalog.org/show/ABG",
      "equipment_id": "6490077052be2c89974898ab",
      "diagnosis_id": "645ca41b50715e659368f028",
      "matched_percentage": 100,
      "patient_id": "6542463e404d3769b7f9b6a7",
      "parent_laboratory": "6486ffedea843c3af6adee38",
      "practice_id": "6487f92b4c1f466d464a7d0e",
      "doctor_id": "648805ac14e3130ac521a096",
      "notes_by_doctor": "Based on  symptoms arterial blood gas analysis lab test need  to be done.",
      "diagnostic_categories_name": "BIOCHEMISTRY",
      "doctor_name": "Henry Williams",
      "matched_symptoms": "<span>Blue fingernails Six Months</span>",
      "assay_names": [
        {
          "name": "pH",
          "link": "https://www.lipsum.com/"
        },
        {
          "name": "pCO2",
          "link": ""
        },
        {
          "name": "pO2",
          "link": ""
        }
      ],
      "assay_name": "<span>pH <a href=\"https://www.lipsum.com/\" target=\"_blank\"><i class=\"fa fa-info-circle\"></i></a></span><span>pCO2 <a href=\"\" target=\"_blank\"><i class=\"fa fa-info-circle\"></i></a></span><span>pO2 <a href=\"\" target=\"_blank\"><i class=\"fa fa-info-circle\"></i></a></span>",
      "equipment_name": "ABL800 RADIOMETER"
    },
    {
      "_id": "65424b2138d4e6978ffde06d",
      "diagnostic_name": "CO-oximetry Tests",
      "diagnostic_des": "R09.02 - Hypoxemia",
      "diagnostic_category_id": "64900a84f9ffcf0b6245a6ce",
      "status": 1,
      "diagnostic_reason": "CO-oximetry provides the means for automated spectrophotometric measurement of the concentration of total hemoglobin (ctHb) in blood and the percentages of the four hemoglobin derivatives that total hemoglobin comprises: oxyhemoglobin (O2Hb); deoxyhemoglobin (HHb); carboxyhemoglobin (COHb); and methemoglobin (MetHb).",
      "diago_reference_link": "https://www.mayocliniclabs.com/test-catalog/overview/8649",
      "equipment_id": "6490077052be2c89974898ab",
      "diagnosis_id": "645cb9d4420a1ff3596f28e1",
      "matched_percentage": 100,
      "patient_id": "6542463e404d3769b7f9b6a7",
      "parent_laboratory": "6486ffedea843c3af6adee38",
      "practice_id": "6487f92b4c1f466d464a7d0e",
      "doctor_id": "648805ac14e3130ac521a096",
      "notes_by_doctor": "Based on  symptoms CO-oximetry  lab test need  to be done.",
      "diagnostic_categories_name": "HEMATOLOGY (CBC)",
      "doctor_name": "Henry Williams",
      "matched_symptoms": "<span>Blue fingernails Six Months</span>",
      "assay_names": [
        {
          "name": "Total hemoglobin(tHb)",
          "link": ""
        },
        {
          "name": "Deoxyhemoglobin(HHb)",
          "link": ""
        },
        {
          "name": "Oxyhemoglobin(O2Hb)",
          "link": ""
        },
        {
          "name": "Oxygen saturation(sO2)",
          "link": ""
        },
        {
          "name": "Carboxyhemoglobin(COHb)",
          "link": ""
        },
        {
          "name": "Methemoglobin(MetHb)",
          "link": ""
        }
      ],
      "assay_name": "<span>Total hemoglobin(tHb) <a href=\"\" target=\"_blank\"><i class=\"fa fa-info-circle\"></i></a></span><span>Deoxyhemoglobin(HHb) <a href=\"\" target=\"_blank\"><i class=\"fa fa-info-circle\"></i></a></span><span>Oxyhemoglobin(O2Hb) <a href=\"\" target=\"_blank\"><i class=\"fa fa-info-circle\"></i></a></span><span>Oxygen saturation(sO2) <a href=\"\" target=\"_blank\"><i class=\"fa fa-info-circle\"></i></a></span><span>Carboxyhemoglobin(COHb) <a href=\"\" target=\"_blank\"><i class=\"fa fa-info-circle\"></i></a></span><span>Methemoglobin(MetHb) <a href=\"\" target=\"_blank\"><i class=\"fa fa-info-circle\"></i></a></span>",
      "equipment_name": "ABL800 RADIOMETER"
    },
    {
      "_id": "65424b2138d4e6978ffde073",
      "diagnostic_name": "Acinetobacter Baumannii",
      "diagnostic_des": "01. J15.6 - Pneumonia due to other Gram-negative bacteria.\n02. A49.9 - Bacterial infection, unspecified.\n03. G00.9 - Bacterial meningitis, unspecified.\n04. M72.6 - Necrotizing fasciitis.\n05. R78.81 - Bacteremia.\n06. A41.50 - Gram-negative sepsis, unspecified.",
      "diagnostic_category_id": "649021778e1b0f6184f04fe1",
      "status": 1,
      "diagnostic_reason": "An Acinetobacter Baumannii infection is caused by the Acinetobacter baumannii bacteria",
      "diago_reference_link": "https://loremipsum.io/",
      "equipment_id": "648ff427bb46a4dd448e95c3",
      "diagnosis_id": "645c89a454896e101c56ce03",
      "matched_percentage": 100,
      "patient_id": "6542463e404d3769b7f9b6a7",
      "parent_laboratory": "6486ffedea843c3af6adee38",
      "practice_id": "6487f92b4c1f466d464a7d0e",
      "doctor_id": "648805ac14e3130ac521a096",
      "notes_by_doctor": "based on  symptoms acinetobacter baumannii lab test need  to be done.",
      "diagnostic_categories_name": "MICROBIOLOGY",
      "doctor_name": "Henry Williams",
      "matched_symptoms": "<span>Fever Last Month</span>",
      "assay_names": [
        {
          "name": "Acinetobacter Baumannii",
          "link": null
        }
      ],
      "assay_name": "<span>Acinetobacter Baumannii</span>",
      "equipment_name": "Gene Expert (CEPHEID)"
    },
  ];
  public cptCodeAvailable: boolean = false;

  @Input() set tapDeviceOrderValue(value: any) {
    console.log("tapDeviceOrderValue", value);
    if (value) {
      this.addFormLoader = false;
      if (value.length > 0) {
        value.forEach((e: any, i: number) => {
          if (e.order_sheet && e.order_sheet.length > 0) {
            e.order_sheet.forEach((f: any, j: number) => {
              if (f.assay_name && f.assay_name.length > 0) {
                f.assay_name.forEach((g: any, k: number) => {
                  if (g.cpt_code) {
                    f.cpt_code_available = true;
                  }
                })
              }
            })
          }
        })
      }
      setTimeout(() => {
        this.deviceOrderList = value;
      }, 200)
    }
  }

  public addFormLoader: boolean = true;

  ngOnInit() {
    console.log("tapDeviceOrderValue", this.deviceOrderList);
  }


}
