<mat-progress-bar mode="indeterminate" *ngIf="spinnerFlag"></mat-progress-bar>
<div class="addEditForm clinicalReportAvailable clinicalReportView">
    <div class="" [ngClass]="isPDFFile?'isPDFFile':''" *ngFor="let pdfVal of pdfFileName">
        <ng-container *ngIf="isPDFFile">
            <embed [src]="pdfVal" type="application/pdf" height="100%" width="100%" class="responsive">
        </ng-container>
    </div>
    <ng-container *ngIf="pdfFileName.length > 1">
        <button class="previousSlide slideBtn" [disabled]="slideIndex==0" (click)="slideClicked('pre')">
            <mat-icon style="cursor: pointer;"> keyboard_arrow_left</mat-icon>
        </button>
        <button class="nextSlide slideBtn" [disabled]="pdfFileName.length-1 == slideIndex" (click)="slideClicked('next')">
            <mat-icon style="cursor: pointer;"> keyboard_arrow_right</mat-icon>
        </button>
    </ng-container>
</div>