<div class="delete-notes-modal">
    <ng-container *ngIf="addFormLoader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>


    <h2>Delete Records</h2>
    <p> Are you sure you want to delete this note?</p>

    <div class="mat-dialog-actions">
        <button mat-button>
        <span (click)="yesclick()">Confirm</span>
  
    </button>
        <button mat-button>
        <span (click)="noclick()">Cancel</span>
    </button>
    </div>



</div>