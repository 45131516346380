import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-add-edit-assay',
  templateUrl: './add-edit-assay.component.html',
  styleUrls: ['./add-edit-assay.component.css']
})
export class AddEditAssayComponent {
  data: any;
  flag: any;
  externaldatavalue: any;
  name: any;
  value: any;

  public addFormLoader: boolean = false;
  public cookieData: any = {};
  public practiceformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  public cptAutocompleteObj: any = null;


  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];

  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous')) {
      this.router.navigateByUrl('404')
    }
  }
  ngOnInit() {
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
          }
        }
      });
      // this.editForm();
    } else {
    }
    console.log("editFormData", this.editFormData);
    this.initailForm();
  }



  initailForm() {
    this.practiceformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      cancelroute: `${this.cookieData.user_type}/miscellaneous/assay-list`, // use for redirect after cancel the form

      fields: [

        {
          label: 'Name',
          name: 'assay_name',
          // group: "grp1",
          value: this.editFormData && this.editFormData.assay_name ? this.editFormData.assay_name : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Assay Name is Required' },

          ],
        },
        {
          label: "CPT Code",
          name: "cpt_code_id",
          // group: "grp1",
          type: 'autocomplete',
          endpoint: "intake/cptcodeautocomplete",
          search_field: "name_search",
          // val: this.editFormData && Object.keys(this.editFormData).length > 0 ? [{ key: this.editFormData.cpt_code_id_val.key, val: this.editFormData.cpt_code_id_val.val }] : [],
          val: this.editFormData && this.editFormData.cpt_code_id_val ? [{ key: this.editFormData.cpt_code_id_val.key, val: this.editFormData.cpt_code_id_val.val }] : [],

          value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.cpt_code_id_val : [],
          validations: [
            { rule: 'required', message: 'CPT Code  is Required' }
          ]

        },
        {
          label: "Description",
          name: "assay_des",
          type: "textarea",
          // group: "grp1",
          rows: 1,
          cols: 2,
          value: this.editFormData && this.editFormData.assay_des ? this.editFormData.assay_des : '',
          // hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Assay Description field Needs to be required" },
          ]
        },
        {
          label: 'Reference Link',
          name: 'reference_link',
          value: this.editFormData && this.editFormData.reference_link ? this.editFormData.reference_link : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            // { rule: 'required', message: 'Reference Link is Required' },

          ],
        },
        // {
        //   label: 'MedPlus Link',
        //   name: 'medplus_link',
        //   value: this.editFormData && this.editFormData.medplus_link ? this.editFormData.medplus_link : '',
        //   type: 'text',
        //   classname: 'aaa',
        //   validations: [
        //     { rule: 'required', message: 'MedPlus Link is Required' },

        //   ],
        // },

        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          val: this.statusarr,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
          validations: [
            // { rule: 'required', message: 'Required Type' },
          ]
        },


      ]
    }
    if (this.editFormData) {
      console.log("sdfsd")
      this.formfieldrefreshdata = {
        field: "removefromcontrol",
        value: ["password", "confirmpassword"],
      };
      // this.locationFieldManage(this.editFormData.location)
    }

  }

  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChangeval", val)
    if (val.autocompletedata) {
      this.cptAutocompleteObj = val.autocompletedata;
    }
    if (val.field && val.field == "fromsubmit") {
      this.addFormLoader = true;
      let dataobj = {
        data: val.fromval
      }
      dataobj.data.cpt_code_id_val = this.cptAutocompleteObj;
      if (this.editFormData) {
        dataobj.data._id = this.editFormData._id
      }

      // if (this.cookieData.user_type == "laboratory") {
      //   dataobj.data.addedby = this.cookieData._id
      // }

      console.log('dataobjTest', dataobj)
      this.apiservice.httpViaPost('intake/addassay', dataobj).subscribe((response) => {
        console.log("dataobj", response)
        if (response && response.status == 'success') {
          this.matSnackBar.open(response.message, "Ok", {
            duration: 1000
          });
          this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/assay-list`);
          this.addFormLoader = false;
        }

        if (response && response.status == 'error') {
          this.matSnackBar.open(response.message, "", {
            duration: 1000
          });
          this.addFormLoader = false;
        }

      })

    }
    if (val.field && val.field == "formreset") {
      this.formfieldrefreshdata = {
        field: "assay_des",
        value: '',
      };
    }
  }


}
