import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-change-lab-logo',
  templateUrl: './change-lab-logo.component.html',
  styleUrls: ['./change-lab-logo.component.css']
})
export class ChangeLabLogoComponent {
  public imgShowVal:any;
  public cookieData:any;
  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, private activateRoute: ActivatedRoute, public matSnackBar: MatSnackBar) {
    this.activateRoute.data.subscribe((response: any) => {
      this.imgShowVal = response.data.results.res[0].image;
    })

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
  }
  ngOnInit(){

  }
  imageUpadete(){

  }
  cancelFun(){
    this.router.navigateByUrl(this.cookieData.user_type + '/dashboard');
  }
}
