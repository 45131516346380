<button class="close_button" style="right: 0;top: 0;">
        <mat-icon style="cursor: pointer;" (click)="closeModal()"> cancel</mat-icon>
</button>

<div class="copyurl_bodal_container">
        <div class="url_head_txt">
                <span>Email sent!</span> <br />
                <span>If your patient requires you to resend the link, you may copy it here:</span>
        </div>
        <!-- <h2>Email sent! If your patient requires you to resend the link, you may copy it here:</h2> -->
        <div *ngIf="emailintakeData">
                <p>{{Url}}{{emailintakeData._id}}</p>
                <a matTooltip="Copy" (click)="copyLink(emailintakeData._id)"><i class="fa fa-clipboard" aria-hidden="true"></i></a>
        </div>
</div>
