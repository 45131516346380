import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-list-icd-code',
  templateUrl: './list-icd-code.component.html',
  styleUrls: ['./list-icd-code.component.css']
})
export class ListIcdCodeComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'usertype'];
  public tabledatatalist: []
  tablename = "users";
  editroute = "";
  updateendpoint = "intake/icdcodestatus";
  deleteendpoint = "intake/deleteicdcode";
  datacollection: any = "intake/icdcodelist";
  public listprogressBar: any = false;

  searchendpoint = "";
  date_search_endpoint: any = "intake/icdcodelist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  modify_header_array: any = {
    icd_code: "ICD Code",
    icd_description: "Description",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On",
    status: "Status"
  };
  search_settings: any = {


    search: [{
      label: "Search By ICD Code", field: 'icd_code',
      serversearchdata: { endpoint: 'intake/icdcodeautocomplete' },
    },
    ],

    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],

    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: [  "createdon_datetime", "updatedon_datetime", "status", "icd_code"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
    ],
  }
  libdata: any = {
    basecondition: {},

    detailview_override: [
      { key: "icd_code", val: "ICD Code" },
      { key: "icd_description", val: "Description" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "status", val: 'Status' }



    ],
    updateendpoint: "intake/icdcodestatus",

    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "intake/icdcodestatus",

    deleteendpointmany: "intake/deleteicdcode",
    tableheaders: ["icd_code", "icd_description", "status", "updatedon_datetime"],


    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'intake/previewicdcode',
        otherparam: [],
        param: '_id',
        datafields: ['icd_code','icd_description', 'createdon_datetime', 'updatedon_datetime', 'status'],
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
    ],

  }
  public taxonomy_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous')) {
      this.router.navigateByUrl('404')
    }
  }

  ngOnInit() {



    this.progressLoader = true;
    this.userListFetching();

  }
 
addBtn() { this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/add-icd`); }
  
  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}


    }

    if (this.cookieData.user_type == "laboratory") {
      data.searchcondition = {

        addedby: this.cookieData._id,
      }

    }



    this.activatedRoute.data.subscribe((response: any) => {
      this.listprogressBar = true
      console.log("activatedRoute", response)
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);
      }
    })

    this.httpService.httpViaPost('intake/icdcodelist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }



  onLiblistingButtonChange(val: any) {
    // throw new Error('Function not implemented.');
    console.log("test>>>>>>>", val);
    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/add-icd`);



  }
  listenLiblistingChange(data: any = null) {
    console.log("test", data);
    if (data.action == "custombuttonclick") {
      if (data.custombuttonclick.btninfo.id == 'edit_btn') {
        this.editListItem(data.custombuttonclick.data);
      }
    }
    if (data.action == "multipleselectionchange") {
      const actionbuttonwrapperDiv = document.querySelector('.tablewrapper');
      if (data.selecteddata.length > 0) {
        actionbuttonwrapperDiv.classList.add("remove");
      } else {
        actionbuttonwrapperDiv.classList.remove("remove");
      }
    }
    if (data.action == "statusupdate") {
      if (data.totalresults.status == 'success') {
        console.log('statusupdate>>>>', data);
        this.taxonomy_updatetable = !this.taxonomy_updatetable;

      }

    }
    if (data.action == "multiplestatusupdate") {
      console.log('statusupdate>>>>', data);
      this.taxonomy_updatetable = !this.taxonomy_updatetable;
    }
  }
  editListItem(item: any) {
    if (item) {
      console.log("Op=====>1", item);
      this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/edit-icd/${item._id}`);


    }
  }

}
