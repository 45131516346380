<h2 class="alerth2">
    <mat-icon>report_problem</mat-icon>&nbsp;&nbsp;Alert
</h2>
<ng-container *ngIf="!isUncheckedSymptom">
    <label class="labelRext">Please Select a Diagnosis first prior Signing and Sending the order to Laboratory</label>
    <button mat-button (click)="onNoClick()" class="closealert"> <mat-icon>close</mat-icon></button>
</ng-container>
<ng-container *ngIf="isUncheckedSymptom">
    <label class="labelRext">Are you sure you want to cancel this request? </label>
    <div class="signmodalgroup">
        <button type="submit" mat-raised-button color="primary" (click)="symptomRemove('yes')">Yes</button>
        <button type="button" mat-raised-button color="accent" (click)="symptomRemove('no')">No</button>
    </div>
</ng-container>