import { Component } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public resolveLoader: boolean = false;
  title = 'practice2lab';
  constructor(public router: Router) { }
  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      console.log("routeevent", event)
      switch (true) {
        case event instanceof NavigationStart: {
          this.resolveLoader = true;
          break
        }
        case event instanceof NavigationEnd: {
          this.resolveLoader = false;
          break;
        }
      }
    })
  }

}
