<div class="ModalTabSubConPage">
    <h1>POSSIBLE DIAGNOSIS</h1>
    <div class="possibleDiagnosis previewTabLeftContent diagnosis" [innerHtml]="possibleDiagnosisDataset"></div>


    <!-- <div class="diagnosis">
 
        <div class="diagnosis-item">
          <h4>1. Congestive Heart Failure</h4>
          <p>Congestive heart failure occurs when the heart is unable to pump enough blood to meet the body's needs. Symptoms such as swelling of the ankles, fatigue, exercise intolerance, and frequent urination can be indicative of fluid retention and decreased cardiac output.</p>
          <ul>
            <li>The swelling of the ankles is caused by fluid accumulation in the lower extremities due to impaired circulation.</li>
            <li>Frequent urination occurs as the kidneys try to compensate for the decreased cardiac output by increasing urine production.</li>
            <li>Fatigue and exercise intolerance result from the inadequate supply of oxygen and nutrients to the muscles.</li>
            <li>Dizziness or lightheadedness can occur due to decreased blood flow to the brain.</li>
            <li>Headaches may be a result of increased pressure in the blood vessels of the brain.</li>
            <li>Vomiting and nausea can be caused by the backup of fluid into the gastrointestinal system.</li>
          </ul>
        </div>
        <div class="diagnosis-item">
          <h4>2. Chronic Kidney Disease</h4>
          <p>Chronic kidney disease is a condition where the kidneys gradually lose their function over time. Symptoms such as swelling of the ankles, frequent urination, fatigue, and nausea can be indicative of kidney dysfunction.</p>
          <ul>
            <li>Swelling of the ankles occurs due to fluid retention caused by the kidneys' inability to properly filter waste and excess fluid from the body.</li>
            <li>Frequent urination can be a result of the kidneys' inability to concentrate urine, leading to increased urine production.</li>
            <li>Fatigue is a common symptom of chronic kidney disease due to the buildup of waste products in the blood.</li>
            <li>Nausea can occur as a result of the accumulation of toxins in the body.</li>
            <li>Headaches may be a result of high blood pressure, which is a common complication of chronic kidney disease.</li>
            <li>Vomiting can be a consequence of the buildup of waste products in the gastrointestinal system.</li>
          </ul>
        </div>
        <div class="diagnosis-item">
          <h4>3. Gastroesophageal Reflux Disease (GERD)</h4>
          <p>GERD is a chronic condition where stomach acid flows back into the esophagus, causing symptoms such as heartburn, nausea, and vomiting.</p>
          <ul>
            <li>Heartburn is a common symptom of GERD and occurs when stomach acid irritates the lining of the esophagus.</li>
            <li>Nausea and vomiting can be a result of the irritation caused by stomach acid in the esophagus.</li>
            <li>Headaches may be a consequence of the increased pressure in the abdomen due to frequent vomiting.</li>
            <li>Frequent urination can occur as a result of the increased production of gastric acid, which stimulates the kidneys to produce more urine.</li>
            <li>Fatigue can be a consequence of disrupted sleep patterns due to nighttime reflux symptoms.</li>
            <li>Dizziness or lightheadedness can occur due to dehydration caused by vomiting.</li>
          </ul>
        </div>
        <div class="diagnosis-item">
          <h4>4. Anemia</h4>
          <p>Anemia is a condition characterized by a decrease in the number of red blood cells or a decrease in the amount of hemoglobin in the blood. Symptoms such as fatigue, fainting, exercise intolerance, and dizziness can be indicative of anemia.</p>
          <ul>
            <li>Fatigue is a common symptom of anemia due to the decreased oxygen-carrying capacity of the blood.</li>
            <li>Fainting can occur as a result of inadequate blood flow to the brain.</li>
            <li>Exercise intolerance is a consequence of the reduced oxygen supply to the muscles.</li>
            <li>Dizziness or lightheadedness can occur due to the decreased blood flow to the brain.</li>
            <li>Headaches may be a result of the decreased oxygen supply to the brain.</li>
            <li>Vomiting and nausea can be caused by the underlying cause of anemia, such as gastrointestinal bleeding.</li>
          </ul>
        </div>
        <div class="diagnosis-item">
          <h4>5. Hypothyroidism</h4>
          <p>Hypothyroidism is a condition where the thyroid gland does not produce enough thyroid hormones. Symptoms such as fatigue, weight gain, swelling of the ankles, and frequent urination can be indicative of hypothyroidism.</p>
          <ul>
            <li>Fatigue is a common symptom of hypothyroidism due to the decreased metabolic rate.</li>
            <li>Swelling of the ankles can occur as a result of fluid retention caused by the decreased cardiac output.</li>
            <li>Frequent urination can be a consequence of the decreased kidney function.</li>
            <li>Headaches may be a result of the altered hormonal balance in the body.</li>
            <li>Heartburn can occur due to the decreased motility of the gastrointestinal system.</li>
            <li>Weight gain can be a consequence of the slowed metabolism.</li>
          </ul>
        </div>
      </div> -->

</div>