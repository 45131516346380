<mat-progress-bar mode="indeterminate" *ngIf="progressBalFlag"></mat-progress-bar>
<ng-container *ngIf="showHtmlContent">
    <div class="main_block_intake subjectiveViewModalContainer">
        <!-- <mat-icon>account_circle</mat-icon> -->
        <!-- <div class="show_img_block">
        <span class="show_img"></span>
    </div> -->

        <div class="subjectiveSymptomBlock">
            <h2 class="additonal_head"> Symptoms</h2>
            <div class="content_block">
                <div class="symptomItem" *ngIf="intakeData.headaches_six_months || intakeData.headaches_last_month || intakeData.headaches_today">
                    <div class="heading">Headaches</div>
                    <div class="notes" *ngIf="intakeData.headaches_notes">
                        <ng-container *ngIf="intakeData.headaches_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.headaches_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.headaches_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>
                <div class="symptomItem" *ngIf="intakeData.skin_six_months || intakeData.skin_last_month || intakeData.skin_today">
                    <div class="heading">Skin and eyes that appear yellowish (jaundice)</div>
                    <div class="notes" *ngIf="intakeData.skin_notes">
                        <ng-container *ngIf="intakeData.skin_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.skin_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.skin_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.abdominal_six_months || intakeData.abdominal_last_month || intakeData.abdominal_today">
                    <div class="heading">Abdominal pain and swelling</div>
                    <div class="notes" *ngIf="intakeData.abdominal_notes">
                        <ng-container *ngIf="intakeData.abdominal_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.abdominal_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.abdominal_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.selling_legs_six_months || intakeData.selling_legs_last_month || intakeData.selling_legs_today">
                    <div class="heading">Swelling in the legs and ankles</div>
                    <div class="notes" *ngIf="intakeData.selling_legs_notes">
                        <ng-container *ngIf="intakeData.selling_legs_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.selling_legs_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.selling_legs_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.itchy_skin_six_months || intakeData.itchy_skin_last_month || intakeData.itchy_skin_today">
                    <div class="heading">Dry,itchy skin</div>
                    <div class="notes" *ngIf="intakeData.itchy_skin_notes">
                        <ng-container *ngIf="intakeData.itchy_skin_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.itchy_skin_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.itchy_skin_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.dark_urine_color_six_months || intakeData.dark_urine_color_last_month || intakeData.dark_urine_color_today">
                    <div class="heading">Dark urine color</div>
                    <div class="notes" *ngIf="intakeData.dark_urine_color_notes">
                        <ng-container *ngIf="intakeData.dark_urine_color_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.dark_urine_color_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.dark_urine_color_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.pale_stool_color_six_months || intakeData.pale_stool_color_last_month || intakeData.pale_stool_color_today">
                    <div class="heading">Pale stool color</div>
                    <div class="notes" *ngIf="intakeData.pale_stool_color_notes">
                        <ng-container *ngIf="intakeData.pale_stool_color_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.pale_stool_color_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.pale_stool_color_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.chronic_fatigue_six_months || intakeData.chronic_fatigue_last_month || intakeData.chronic_fatigue_today">
                    <div class="heading">Chronic fatigue</div>
                    <div class="notes" *ngIf="intakeData.chronic_fatigue_notes">
                        <ng-container *ngIf="intakeData.chronic_fatigue_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.chronic_fatigue_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.chronic_fatigue_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <!-- ========================================================================================================================================== -->

                <div class="symptomItem" *ngIf="intakeData.vomiting_six_months || intakeData.vomiting_last_month || intakeData.vomiting_today">
                    <div class="heading">Nausea or vomiting</div>
                    <div class="notes" *ngIf="intakeData.vomiting_notes">
                        <ng-container *ngIf="intakeData.vomiting_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.vomiting_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.vomiting_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.lossofappetite_six_months || intakeData.lossofappetite_last_month || intakeData.lossofappetite_today">
                    <div class="heading">Loss of appetite</div>
                    <div class="notes" *ngIf="intakeData.lossofappetite_notes">
                        <ng-container *ngIf="intakeData.lossofappetite_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.lossofappetite_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.lossofappetite_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.bruise_six_months || intakeData.bruise_last_month || intakeData.bruise_today">
                    <div class="heading">Tendency to bruise easily</div>
                    <div class="notes" *ngIf="intakeData.bruise_notes">
                        <ng-container *ngIf="intakeData.bruise_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.bruise_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.bruise_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.fever_six_months || intakeData.fever_last_month || intakeData.fever_today">
                    <div class="heading">Fever</div>
                    <div class="notes" *ngIf="intakeData.fever_notes">
                        <ng-container *ngIf="intakeData.fever_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.fever_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.fever_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.insomnia_six_months || intakeData.insomnia_last_month || intakeData.insomnia_today">
                    <div class="heading">Insomnia</div>
                    <div class="notes" *ngIf="intakeData.insomnia_notes">
                        <ng-container *ngIf="intakeData.insomnia_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.insomnia_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.insomnia_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>


                <div class="symptomItem" *ngIf="intakeData.urinating_less_six_months || intakeData.urinating_less_last_month || intakeData.urinating_less_today">
                    <div class="heading">Urinating less</div>
                    <div class="notes" *ngIf="intakeData.urinating_less_notes">
                        <ng-container *ngIf="intakeData.urinating_less_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.urinating_less_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.urinating_less_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.muscle_cramps_six_months || intakeData.muscle_cramps_last_month || intakeData.muscle_cramps_today">
                    <div class="heading">Muscle cramps</div>
                    <div class="notes" *ngIf="intakeData.muscle_cramps_notes">
                        <ng-container *ngIf="intakeData.muscle_cramps_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.muscle_cramps_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.muscle_cramps_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.swelling_feet_ankles_six_months || intakeData.swelling_feet_ankles_last_month || intakeData.swelling_feet_ankles_today">
                    <div class="heading">Swelling of feet and ankles</div>
                    <div class="notes" *ngIf="intakeData.swelling_feet_ankles_notes">
                        <ng-container *ngIf="intakeData.swelling_feet_ankles_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.swelling_feet_ankles_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.swelling_feet_ankles_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.shortness_of_breath_six_months || intakeData.shortness_of_breath_last_month || intakeData.shortness_of_breath_today">
                    <div class="heading">Shortness of breath</div>
                    <div class="notes" *ngIf="intakeData.shortness_of_breath_notes">
                        <ng-container *ngIf="intakeData.shortness_of_breath_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.shortness_of_breath_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.shortness_of_breath_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.chest_pain_six_months || intakeData.chest_pain_last_month || intakeData.chest_pain_today">
                    <div class="heading">Chest pain during urin</div>
                    <div class="notes" *ngIf="intakeData.chest_pain_notes">
                        <ng-container *ngIf="intakeData.chest_pain_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.chest_pain_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.chest_pain_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.back_pain_six_months || intakeData.back_pain_last_month || intakeData.back_pain_today">
                    <div class="heading">Back Pain</div>
                    <div class="notes" *ngIf="intakeData.back_pain_notes">
                        <ng-container *ngIf="intakeData.back_pain_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.back_pain_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.back_pain_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.frequent_urination_six_months || intakeData.frequent_urination_last_month || intakeData.frequent_urination_today">
                    <div class="heading">Frequent urination</div>
                    <div class="notes" *ngIf="intakeData.frequent_urination_notes">
                        <ng-container *ngIf="intakeData.frequent_urination_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.frequent_urination_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.frequent_urination_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.foamy_urine_six_months || intakeData.foamy_urine_last_month || intakeData.foamy_urine_today">
                    <div class="heading">Foamy urine</div>
                    <div class="notes" *ngIf="intakeData.foamy_urine_notes">
                        <ng-container *ngIf="intakeData.foamy_urine_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.foamy_urine_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.foamy_urine_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.blood_in_your_urine_six_months || intakeData.blood_in_your_urine_last_month || intakeData.blood_in_your_urine_today">
                    <div class="heading">Blood in your urine</div>
                    <div class="notes" *ngIf="intakeData.blood_in_your_urine_notes">
                        <ng-container *ngIf="intakeData.blood_in_your_urine_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.blood_in_your_urine_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.blood_in_your_urine_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.dizziness_six_months || intakeData.dizziness_last_month || intakeData.dizziness_today">
                    <div class="heading">Dizziness</div>
                    <div class="notes" *ngIf="intakeData.dizziness_notes">
                        <ng-container *ngIf="intakeData.dizziness_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.dizziness_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.dizziness_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.swelling_around_your_eyes_six_months || intakeData.swelling_around_your_eyes_last_month || intakeData.swelling_around_your_eyes_today">
                    <div class="heading">Swelling around your eyess</div>
                    <div class="notes" *ngIf="intakeData.swelling_around_your_eyes_notes">
                        <ng-container *ngIf="intakeData.swelling_around_your_eyes_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.swelling_around_your_eyes_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.swelling_around_your_eyes_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.high_blood_sugar_six_months || intakeData.high_blood_sugar_last_month || intakeData.high_blood_sugar_today">
                    <div class="heading">High blood sugar</div>
                    <div class="notes" *ngIf="intakeData.high_blood_sugar_notes">
                        <ng-container *ngIf="intakeData.high_blood_sugar_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.high_blood_sugar_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.high_blood_sugar_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.weight_loss_six_months || intakeData.weight_loss_last_month || intakeData.weight_loss_today">
                    <div class="heading">Weight loss</div>
                    <div class="notes" *ngIf="intakeData.weight_loss_notes">
                        <ng-container *ngIf="intakeData.weight_loss_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.weight_loss_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.weight_loss_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.joint_pain_six_months || intakeData.joint_pain_last_month || intakeData.joint_pain_today">
                    <div class="heading">Joint pain</div>
                    <div class="notes" *ngIf="intakeData.joint_pain_notes">
                        <ng-container *ngIf="intakeData.joint_pain_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.joint_pain_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.joint_pain_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.joint_inflammation_six_months || intakeData.joint_inflammation_last_month || intakeData.joint_inflammation_today">
                    <div class="heading">Joint Inflammation</div>
                    <div class="notes" *ngIf="intakeData.joint_inflammation_notes">
                        <ng-container *ngIf="intakeData.joint_inflammation_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.joint_inflammation_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.joint_inflammation_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>


                <div class="symptomItem" *ngIf="intakeData.limited_range_motion_six_months || intakeData.limited_range_motion_last_month || intakeData.limited_range_motion_today">
                    <div class="heading">Limited range of motion</div>
                    <div class="notes" *ngIf="intakeData.limited_range_motion_notes">
                        <ng-container *ngIf="intakeData.limited_range_motion_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.limited_range_motion_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.limited_range_motion_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.coughing_especially_at_night_six_months || intakeData.coughing_especially_at_night_last_month || intakeData.coughing_especially_at_night_today">
                    <div class="heading">Coughing, especially at night</div>
                    <div class="notes" *ngIf="intakeData.coughing_especially_at_night_notes">
                        <ng-container *ngIf="intakeData.coughing_especially_at_night_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.coughing_especially_at_night_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.coughing_especially_at_night_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.wheezing_squeaking_when_you_breathe_six_months || intakeData.wheezing_squeaking_when_you_breathe_last_month || intakeData.wheezing_squeaking_when_you_breathe_today">
                    <div class="heading">Wheezing or squeaking when you breathe</div>
                    <div class="notes" *ngIf="intakeData.wheezing_squeaking_when_you_breathe_notes">
                        <ng-container *ngIf="intakeData.wheezing_squeaking_when_you_breathe_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.wheezing_squeaking_when_you_breathe_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.wheezing_squeaking_when_you_breathe_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.chest_tightness_pain_six_months || intakeData.chest_tightness_pain_last_month || intakeData.chest_tightness_pain_today">
                    <div class="heading">Chest tightness or pain</div>
                    <div class="notes" *ngIf="intakeData.chest_tightness_pain_notes">
                        <ng-container *ngIf="intakeData.chest_tightness_pain_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.chest_tightness_pain_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.chest_tightness_pain_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.low_energy_six_months || intakeData.low_energy_last_month || intakeData.low_energy_today">
                    <div class="heading">Low energy</div>
                    <div class="notes" *ngIf="intakeData.low_energy_notes">
                        <ng-container *ngIf="intakeData.low_energy_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.low_energy_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.low_energy_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.blue_fingernails_six_months || intakeData.blue_fingernails_last_month || intakeData.blue_fingernails_today">
                    <div class="heading">Blue fingernails</div>
                    <div class="notes" *ngIf="intakeData.blue_fingernails_notes">
                        <ng-container *ngIf="intakeData.blue_fingernails_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.blue_fingernails_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.blue_fingernails_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.frequent_colds_flu_six_months || intakeData.frequent_colds_flu_last_month || intakeData.frequent_colds_flu_today">
                    <div class="heading">Frequent colds or flu</div>
                    <div class="notes" *ngIf="intakeData.frequent_colds_flu_notes">
                        <ng-container *ngIf="intakeData.frequent_colds_flu_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.frequent_colds_flu_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.frequent_colds_flu_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.coughing_up_lots_mucus_six_months || intakeData.coughing_up_lots_mucus_last_month || intakeData.coughing_up_lots_mucus_today">
                    <div class="heading">Coughing up lots of mucus</div>
                    <div class="notes" *ngIf="intakeData.coughing_up_lots_mucus_notes">
                        <ng-container *ngIf="intakeData.coughing_up_lots_mucus_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.coughing_up_lots_mucus_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.coughing_up_lots_mucus_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.cough_that_doesnt_go_away_six_months || intakeData.cough_that_doesnt_go_away_last_month || intakeData.cough_that_doesnt_go_away_today">
                    <div class="heading">A cough that doesn't go away</div>
                    <div class="notes" *ngIf="intakeData.cough_that_doesnt_go_away_notes">
                        <ng-container *ngIf="intakeData.cough_that_doesnt_go_away_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.cough_that_doesnt_go_away_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.cough_that_doesnt_go_away_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.depression_anxiety_six_months || intakeData.depression_anxiety_last_month || intakeData.depression_anxiety_today">
                    <div class="heading">Depression or anxiety</div>
                    <div class="notes" *ngIf="intakeData.depression_anxiety_notes">
                        <ng-container *ngIf="intakeData.depression_anxiety_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.depression_anxiety_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.depression_anxiety_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.restlessness_while_sleeping_six_months || intakeData.restlessness_while_sleeping_last_month || intakeData.restlessness_while_sleeping_today">
                    <div class="heading">Restlessness while sleeping</div>
                    <div class="notes" *ngIf="intakeData.restlessness_while_sleeping_notes">
                        <ng-container *ngIf="intakeData.restlessness_while_sleeping_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.restlessness_while_sleeping_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.restlessness_while_sleeping_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.fatigue_six_months || intakeData.fatigue_last_month || intakeData.fatigue_today">
                    <div class="heading">Fatigue</div>
                    <div class="notes" *ngIf="intakeData.fatigue_notes">
                        <ng-container *ngIf="intakeData.fatigue_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.fatigue_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.fatigue_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.nausea_vomiting_six_months || intakeData.nausea_vomiting_last_month || intakeData.nausea_vomiting_today">
                    <div class="heading">Nausea and vomitings</div>
                    <div class="notes" *ngIf="intakeData.nausea_vomiting_notes">
                        <ng-container *ngIf="intakeData.nausea_vomiting_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.nausea_vomiting_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.nausea_vomiting_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.arrhythmia_six_months || intakeData.arrhythmia_last_month || intakeData.arrhythmia_today">
                    <div class="heading">Arrhythmia: Irregular heartbeat rhythm
                    </div>
                    <div class="notes" *ngIf="intakeData.arrhythmia_notes">
                        <ng-container *ngIf="intakeData.arrhythmia_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.arrhythmia_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.arrhythmia_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.confusion_six_months || intakeData.confusion_last_month || intakeData.confusion_today">
                    <div class="heading">Confusion</div>
                    <div class="notes" *ngIf="intakeData.confusion_notes">
                        <ng-container *ngIf="intakeData.confusion_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.confusion_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.confusion_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.blurred_vision_six_months || intakeData.blurred_vision_last_month || intakeData.blurred_vision_today">
                    <div class="heading">Blurred vision</div>
                    <div class="notes" *ngIf="intakeData.blurred_vision_notes">
                        <ng-container *ngIf="intakeData.blurred_vision_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.blurred_vision_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.blurred_vision_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.extreme_thirst_six_months || intakeData.extreme_thirst_last_month || intakeData.extreme_thirst_today">
                    <div class="heading">Extreme thirst</div>
                    <div class="notes" *ngIf="intakeData.extreme_thirst_notes">
                        <ng-container *ngIf="intakeData.extreme_thirst_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.extreme_thirst_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.extreme_thirst_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.extreme_hunger_six_months || intakeData.extreme_hunger_last_month || intakeData.extreme_hunger_today">
                    <div class="heading">Extreme Hunger</div>
                    <div class="notes" *ngIf="intakeData.extreme_hunger_notes">
                        <ng-container *ngIf="intakeData.extreme_hunger_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.extreme_hunger_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.extreme_hunger_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.painful_urination_six_months || intakeData.painful_urination_last_month || intakeData.painful_urination_today">
                    <div class="heading">Painful urination</div>
                    <div class="notes" *ngIf="intakeData.painful_urination_notes">
                        <ng-container *ngIf="intakeData.painful_urination_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.painful_urination_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.painful_urination_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.numb_or_tingling_feet_six_months || intakeData.numb_or_tingling_feet_last_month || intakeData.numb_or_tingling_feet_today">
                    <div class="heading">Numb or tingling feet</div>
                    <div class="notes" *ngIf="intakeData.numb_or_tingling_feet_notes">
                        <ng-container *ngIf="intakeData.numb_or_tingling_feet_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.numb_or_tingling_feet_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.numb_or_tingling_feet_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.urinary_problems_six_months || intakeData.urinary_problems_last_month || intakeData.urinary_problems_today">
                    <div class="heading">Urinary problems</div>
                    <div class="notes" *ngIf="intakeData.urinary_problems_notes">
                        <ng-container *ngIf="intakeData.urinary_problems_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.urinary_problems_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.urinary_problems_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.sexual_difficulties_six_months || intakeData.sexual_difficulties_last_month || intakeData.sexual_difficulties_today">
                    <div class="heading">Sexual Difficulties</div>
                    <div class="notes" *ngIf="intakeData.sexual_difficulties_notes">
                        <ng-container *ngIf="intakeData.sexual_difficulties_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.sexual_difficulties_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.sexual_difficulties_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.tingling_hands_feet_six_months || intakeData.tingling_hands_feet_last_month || intakeData.tingling_hands_feet_today">
                    <div class="heading">Tingling Hands & Feet</div>
                    <div class="notes" *ngIf="intakeData.tingling_hands_feet_notes">
                        <ng-container *ngIf="intakeData.tingling_hands_feet_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.tingling_hands_feet_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.tingling_hands_feet_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.burning_sensation_six_months || intakeData.burning_sensation_last_month || intakeData.burning_sensation_today">
                    <div class="heading">Burning Sensation
                    </div>
                    <div class="notes" *ngIf="intakeData.burning_sensation_notes">
                        <ng-container *ngIf="intakeData.burning_sensation_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.burning_sensation_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.burning_sensation_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.angina_six_months || intakeData.angina_last_month || intakeData.angina_today">
                    <div class="heading">Angina (Chest pain that goes away with rest)</div>
                    <div class="notes" *ngIf="intakeData.angina_notes">
                        <ng-container *ngIf="intakeData.angina_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.angina_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.angina_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.chest_pain_that_goesaway_with_rest_six_months || intakeData.chest_pain_that_goesaway_with_rest_last_month || intakeData.chest_pain_that_goesaway_with_rest_today">
                    <div class="heading">Chest Pain That Goes Away With Rest</div>
                    <div class="notes" *ngIf="intakeData.chest_pain_that_goesaway_with_rest_notes">
                        <ng-container *ngIf="intakeData.chest_pain_that_goesaway_with_rest_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.chest_pain_that_goesaway_with_rest_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.chest_pain_that_goesaway_with_rest_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.heartburn_six_months || intakeData.heartburn_last_month || intakeData.heartburn_today">
                    <div class="heading">Heartburn</div>
                    <div class="notes" *ngIf="intakeData.heartburn_notes">
                        <ng-container *ngIf="intakeData.heartburn_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.heartburn_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.heartburn_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.pain_in_calves_six_months || intakeData.pain_in_calves_last_month || intakeData.pain_in_calves_today">
                    <div class="heading">Pain In Calves</div>
                    <div class="notes" *ngIf="intakeData.pain_in_calves_notes">
                        <ng-container *ngIf="intakeData.pain_in_calves_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.pain_in_calves_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.pain_in_calves_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.dizziness_lightheadedness_six_months || intakeData.dizziness_lightheadedness_last_month || intakeData.dizziness_lightheadedness_today">
                    <div class="heading">Dizziness Or Lightheadednesss</div>
                    <div class="notes" *ngIf="intakeData.dizziness_lightheadedness_notes">
                        <ng-container *ngIf="intakeData.dizziness_lightheadedness_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.dizziness_lightheadedness_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.dizziness_lightheadedness_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.irregular_heartbeat_toofastslow_six_months || intakeData.irregular_heartbeat_toofastslow_last_month || intakeData.irregular_heartbeat_toofastslow_today">
                    <div class="heading">Irregular Heartbeat, Too Fast/Slow</div>
                    <div class="notes" *ngIf="intakeData.irregular_heartbeat_toofastslow_notes">
                        <ng-container *ngIf="intakeData.irregular_heartbeat_toofastslow_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.irregular_heartbeat_toofastslow_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.irregular_heartbeat_toofastslow_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.heart_attack_six_months || intakeData.heart_attack_last_month || intakeData.heart_attack_today">
                    <div class="heading">Heart Attack</div>
                    <div class="notes" *ngIf="intakeData.heart_attack_notes">
                        <ng-container *ngIf="intakeData.heart_attack_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.heart_attack_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.heart_attack_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.stroke_six_months || intakeData.stroke_last_month || intakeData.stroke_today">
                    <div class="heading">Stroke</div>
                    <div class="notes" *ngIf="intakeData.stroke_notes">
                        <ng-container *ngIf="intakeData.stroke_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.stroke_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.stroke_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.blood_clot_inavein_six_months || intakeData.blood_clot_inavein_last_month || intakeData.blood_clot_inavein_today">
                    <div class="heading">Blood Clot In A Vein (Venous Thrombosis)</div>
                    <div class="notes" *ngIf="intakeData.blood_clot_inavein_notes">
                        <ng-container *ngIf="intakeData.blood_clot_inavein_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.blood_clot_inavein_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.blood_clot_inavein_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.tia_mini_stroke_six_months || intakeData.tia_mini_stroke_last_month || intakeData.tia_mini_stroke_today">
                    <div class="heading">Tia (Mini Stroke)</div>
                    <div class="notes" *ngIf="intakeData.tia_mini_stroke_notes">
                        <ng-container *ngIf="intakeData.tia_mini_stroke_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.tia_mini_stroke_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.tia_mini_stroke_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.chest_painor_discomfort_six_months || intakeData.chest_painor_discomfort_last_month || intakeData.chest_painor_discomfort_today">
                    <div class="heading">Chest Pain Or Discomfort</div>
                    <div class="notes" *ngIf="intakeData.chest_painor_discomfort_notes">
                        <ng-container *ngIf="intakeData.chest_painor_discomfort_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.chest_painor_discomfort_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.chest_painor_discomfort_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.fainting_six_months || intakeData.fainting_last_month || intakeData.fainting_today">
                    <div class="heading">Fainting</div>
                    <div class="notes" *ngIf="intakeData.fainting_notes">
                        <ng-container *ngIf="intakeData.fainting_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.fainting_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.fainting_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.weakness_six_months || intakeData.weakness_last_month || intakeData.weakness_today">
                    <div class="heading">Weakness</div>
                    <div class="notes" *ngIf="intakeData.weakness_notes">
                        <ng-container *ngIf="intakeData.weakness_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.weakness_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.weakness_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.lingering_discomfort_six_months || intakeData.lingering_discomfort_last_month || intakeData.lingering_discomfort_today">
                    <div class="heading">Lingering discomfort</div>
                    <div class="notes" *ngIf="intakeData.lingering_discomfort_notes">
                        <ng-container *ngIf="intakeData.lingering_discomfort_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.lingering_discomfort_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.lingering_discomfort_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.pain_in_affected_joint_six_months || intakeData.pain_in_affected_joint_last_month || intakeData.pain_in_affected_joint_today">
                    <div class="heading">Pain in affected joint</div>
                    <div class="notes" *ngIf="intakeData.pain_in_affected_joint_notes">
                        <ng-container *ngIf="intakeData.pain_in_affected_joint_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.pain_in_affected_joint_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.pain_in_affected_joint_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.swelling_in_affected_joint_six_months || intakeData.swelling_in_affected_joint_last_month || intakeData.swelling_in_affected_joint_today">
                    <div class="heading">Swelling in affected joint</div>
                    <div class="notes" *ngIf="intakeData.swelling_in_affected_joint_notes">
                        <ng-container *ngIf="intakeData.swelling_in_affected_joint_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.swelling_in_affected_joint_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.swelling_in_affected_joint_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.redness_in_affected_joint_six_months || intakeData.redness_in_affected_joint_last_month || intakeData.redness_in_affected_joint_today">
                    <div class="heading">Redness in affected joint</div>
                    <div class="notes" *ngIf="intakeData.redness_in_affected_joint_notes">
                        <ng-container *ngIf="intakeData.redness_in_affected_joint_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.redness_in_affected_joint_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.redness_in_affected_joint_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.chills_six_months || intakeData.chills_last_month || intakeData.chills_today">
                    <div class="heading">Chills</div>
                    <div class="notes" *ngIf="intakeData.chills_notes">
                        <ng-container *ngIf="intakeData.chills_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.chills_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.chills_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.cough_six_months || intakeData.cough_last_month || intakeData.cough_today">
                    <div class="heading">Cough</div>
                    <div class="notes" *ngIf="intakeData.cough_notes">
                        <ng-container *ngIf="intakeData.cough_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.cough_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.cough_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.chest_pain_when_you_breatheorcough_six_months || intakeData.chest_pain_when_you_breatheorcough_last_month || intakeData.chest_pain_when_you_breatheorcough_today">
                    <div class="heading">Chest pain when you breathe or coug</div>
                    <div class="notes" *ngIf="intakeData.chest_pain_when_you_breatheorcough_notes">
                        <ng-container *ngIf="intakeData.chest_pain_when_you_breatheorcough_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.chest_pain_when_you_breatheorcough_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.chest_pain_when_you_breatheorcough_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.nausea_six_months || intakeData.nausea_last_month || intakeData.nausea_today">
                    <div class="heading">Nausea</div>
                    <div class="notes" *ngIf="intakeData.nausea_notes">
                        <ng-container *ngIf="intakeData.nausea_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.nausea_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.nausea_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.painorburning_while_urinating_six_months || intakeData.painorburning_while_urinating_last_month || intakeData.painorburning_while_urinating_today">
                    <div class="heading">Pain or burning while urinating</div>
                    <div class="notes" *ngIf="intakeData.painorburning_while_urinating_notes">
                        <ng-container *ngIf="intakeData.painorburning_while_urinating_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.painorburning_while_urinating_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.painorburning_while_urinating_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.bloody_urine_six_months || intakeData.bloody_urine_last_month || intakeData.bloody_urine_today">
                    <div class="heading">Bloody urine</div>
                    <div class="notes" *ngIf="intakeData.bloody_urine_notes">
                        <ng-container *ngIf="intakeData.bloody_urine_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.bloody_urine_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.bloody_urine_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.runny_nose_six_months || intakeData.runny_nose_last_month || intakeData.runny_nose_today">
                    <div class="heading">Runny nose</div>
                    <div class="notes" *ngIf="intakeData.runny_nose_notes">
                        <ng-container *ngIf="intakeData.runny_nose_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.runny_nose_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.runny_nose_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.sore_throat_six_months || intakeData.sore_throat_last_month || intakeData.sore_throat_today">
                    <div class="heading">Sore throat</div>
                    <div class="notes" *ngIf="intakeData.sore_throat_notes">
                        <ng-container *ngIf="intakeData.sore_throat_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.sore_throat_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.sore_throat_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.swollen_glands_six_months || intakeData.swollen_glands_last_month || intakeData.swollen_glands_today">
                    <div class="heading">Swollen glands</div>
                    <div class="notes" *ngIf="intakeData.swollen_glands_notes">
                        <ng-container *ngIf="intakeData.swollen_glands_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.swollen_glands_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.swollen_glands_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.trouble_breathing_six_months || intakeData.trouble_breathing_last_month || intakeData.trouble_breathing_today">
                    <div class="heading">Trouble breathing</div>
                    <div class="notes" *ngIf="intakeData.trouble_breathing_notes">
                        <ng-container *ngIf="intakeData.trouble_breathing_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.trouble_breathing_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.trouble_breathing_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.high_pitched_sound_when_breathing_six_months || intakeData.high_pitched_sound_when_breathing_last_month || intakeData.high_pitched_sound_when_breathing_today">
                    <div class="heading">High-pitched sound when breathing in</div>
                    <div class="notes" *ngIf="intakeData.high_pitched_sound_when_breathing_notes">
                        <ng-container *ngIf="intakeData.high_pitched_sound_when_breathing_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.high_pitched_sound_when_breathing_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.high_pitched_sound_when_breathing_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.burning_and_pain_while_urinating_six_months || intakeData.burning_and_pain_while_urinating_last_month || intakeData.burning_and_pain_while_urinating_today">
                    <div class="heading">Burning and pain while urinating</div>
                    <div class="notes" *ngIf="intakeData.burning_and_pain_while_urinating_notes">
                        <ng-container *ngIf="intakeData.burning_and_pain_while_urinating_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.burning_and_pain_while_urinating_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.burning_and_pain_while_urinating_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.red_eyes_six_months || intakeData.red_eyes_last_month || intakeData.red_eyes_today">
                    <div class="heading">Red eyes</div>
                    <div class="notes" *ngIf="intakeData.red_eyes_notes">
                        <ng-container *ngIf="intakeData.red_eyes_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.red_eyes_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.red_eyes_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.discharge_from_your_eyes_six_months || intakeData.discharge_from_your_eyes_last_month || intakeData.discharge_from_your_eyes_today">
                    <div class="heading">Discharge from your eyes</div>
                    <div class="notes" *ngIf="intakeData.discharge_from_your_eyes_notes">
                        <ng-container *ngIf="intakeData.discharge_from_your_eyes_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.discharge_from_your_eyes_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.discharge_from_your_eyes_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.abdominal_pain_six_months || intakeData.abdominal_pain_last_month || intakeData.abdominal_pain_today">
                    <div class="heading">Abdominal pain</div>
                    <div class="notes" *ngIf="intakeData.abdominal_pain_notes">
                        <ng-container *ngIf="intakeData.abdominal_pain_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.abdominal_pain_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.abdominal_pain_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.change_in_urine_color_six_months || intakeData.change_in_urine_color_last_month || intakeData.change_in_urine_color_today">
                    <div class="heading">Change in urine color</div>
                    <div class="notes" *ngIf="intakeData.change_in_urine_color_notes">
                        <ng-container *ngIf="intakeData.change_in_urine_color_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.change_in_urine_color_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.change_in_urine_color_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.painful_ejaculation_six_months || intakeData.painful_ejaculation_last_month || intakeData.painful_ejaculation_today">
                    <div class="heading">Painful ejaculation</div>
                    <div class="notes" *ngIf="intakeData.painful_ejaculation_notes">
                        <ng-container *ngIf="intakeData.painful_ejaculation_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.painful_ejaculation_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.painful_ejaculation_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.nausea_withorwithout_vomiting_six_months || intakeData.nausea_withorwithout_vomiting_last_month || intakeData.nausea_withorwithout_vomiting_today">
                    <div class="heading">Nausea, with or without vomiting</div>
                    <div class="notes" *ngIf="intakeData.nausea_withorwithout_vomiting_notes">
                        <ng-container *ngIf="intakeData.nausea_withorwithout_vomiting_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.nausea_withorwithout_vomiting_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.nausea_withorwithout_vomiting_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.light_spotting_six_months || intakeData.light_spotting_last_month || intakeData.light_spotting_today">
                    <div class="heading">Light spotting</div>
                    <div class="notes" *ngIf="intakeData.light_spotting_notes">
                        <ng-container *ngIf="intakeData.light_spotting_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.light_spotting_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.light_spotting_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.amissed_period_six_months || intakeData.amissed_period_last_month || intakeData.amissed_period_today">
                    <div class="heading">A missed period</div>
                    <div class="notes" *ngIf="intakeData.amissed_period_notes">
                        <ng-container *ngIf="intakeData.amissed_period_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.amissed_period_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.amissed_period_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.swollenortender_breasts_six_months || intakeData.swollenortender_breasts_last_month || intakeData.swollenortender_breasts_today">
                    <div class="heading">Swollen or tender breasts</div>
                    <div class="notes" *ngIf="intakeData.swollenortender_breasts_notes">
                        <ng-container *ngIf="intakeData.swollenortender_breasts_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.swollenortender_breasts_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.swollenortender_breasts_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.cramping_six_months || intakeData.cramping_last_month || intakeData.cramping_today">
                    <div class="heading">Cramping</div>
                    <div class="notes" *ngIf="intakeData.cramping_notes">
                        <ng-container *ngIf="intakeData.cramping_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.cramping_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.cramping_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.bloating_six_months || intakeData.bloating_last_month || intakeData.bloating_today">
                    <div class="heading">Bloating</div>
                    <div class="notes" *ngIf="intakeData.bloating_notes">
                        <ng-container *ngIf="intakeData.bloating_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.bloating_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.bloating_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.mood_swings_six_months || intakeData.mood_swings_last_month || intakeData.mood_swings_today">
                    <div class="heading">Mood swings</div>
                    <div class="notes" *ngIf="intakeData.mood_swings_notes">
                        <ng-container *ngIf="intakeData.mood_swings_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.mood_swings_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.mood_swings_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.skin_dimpling_six_months || intakeData.skin_dimpling_last_month || intakeData.skin_dimpling_today">
                    <div class="heading">Skin irritation or dimpling</div>
                    <div class="notes" *ngIf="intakeData.skin_dimpling_notes">
                        <ng-container *ngIf="intakeData.skin_dimpling_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.skin_dimpling_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.skin_dimpling_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.breast_pain_six_months || intakeData.breast_pain_last_month || intakeData.breast_pain_today">
                    <div class="heading">Breast or nipple pain</div>
                    <div class="notes" *ngIf="intakeData.breast_pain_notes">
                        <ng-container *ngIf="intakeData.breast_pain_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.breast_pain_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.breast_pain_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>


                <div class="symptomItem" *ngIf="intakeData.nipple_retraction_six_months || intakeData.nipple_retraction_last_month || intakeData.nipple_retraction_today">
                    <div class="heading">Nipple retraction</div>
                    <div class="notes" *ngIf="intakeData.nipple_retraction_notes">
                        <ng-container *ngIf="intakeData.nipple_retraction_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.nipple_retraction_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.nipple_retraction_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.redness_six_months || intakeData.redness_last_month || intakeData.redness_today">
                    <div class="heading">Redness, scaliness, or thickening of the nipple or breast skin</div>
                    <div class="notes" *ngIf="intakeData.redness_notes">
                        <ng-container *ngIf="intakeData.redness_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.redness_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.redness_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.nipple_discharge_six_months || intakeData.nipple_discharge_last_month || intakeData.nipple_discharge_today">
                    <div class="heading">Nipple discharge</div>
                    <div class="notes" *ngIf="intakeData.nipple_discharge_notes">
                        <ng-container *ngIf="intakeData.nipple_discharge_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.nipple_discharge_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.nipple_discharge_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.abdominal_swelling_six_months || intakeData.abdominal_swelling_last_month || intakeData.abdominal_swelling_today">
                    <div class="heading">Abdominal bloating or swelling</div>
                    <div class="notes" *ngIf="intakeData.abdominal_swelling_notes">
                        <ng-container *ngIf="intakeData.abdominal_swelling_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.abdominal_swelling_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.abdominal_swelling_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.quickly_feeling_six_months || intakeData.quickly_feeling_last_month || intakeData.quickly_feeling_today">
                    <div class="heading">Quickly feeling full when eating</div>
                    <div class="notes" *ngIf="intakeData.quickly_feeling_notes">
                        <ng-container *ngIf="intakeData.quickly_feeling_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.quickly_feeling_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.quickly_feeling_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.discomfort_six_months || intakeData.discomfort_last_month || intakeData.discomfort_today">
                    <div class="heading">Discomfort in the pelvic area</div>
                    <div class="notes" *ngIf="intakeData.discomfort_notes">
                        <ng-container *ngIf="intakeData.discomfort_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.discomfort_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.discomfort_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.blood_stool_six_months || intakeData.blood_stool_last_month || intakeData.blood_stool_today">
                    <div class="heading">Blood in Stool</div>
                    <div class="notes" *ngIf="intakeData.blood_stool_notes">
                        <ng-container *ngIf="intakeData.blood_stool_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.blood_stool_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.blood_stool_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.constipation_six_months || intakeData.constipation_last_month || intakeData.constipation_today">
                    <div class="heading">Constipation</div>
                    <div class="notes" *ngIf="intakeData.constipation_notes">
                        <ng-container *ngIf="intakeData.constipation_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.constipation_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.constipation_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.diarrhea_six_months || intakeData.diarrhea_last_month || intakeData.diarrhea_today">
                    <div class="heading">Diarrhea</div>
                    <div class="notes" *ngIf="intakeData.diarrhea_notes">
                        <ng-container *ngIf="intakeData.diarrhea_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.diarrhea_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.diarrhea_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.coughing_six_months || intakeData.coughing_last_month || intakeData.coughing_today">
                    <div class="heading">Coughing up blood</div>
                    <div class="notes" *ngIf="intakeData.coughing_notes">
                        <ng-container *ngIf="intakeData.coughing_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.coughing_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.coughing_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.dark_colored_urine_six_months || intakeData.dark_colored_urine_last_month || intakeData.dark_colored_urine_today">
                    <div class="heading">Dark colored urine</div>
                    <div class="notes" *ngIf="intakeData.dark_colored_urine_notes">
                        <ng-container *ngIf="intakeData.dark_colored_urine_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.dark_colored_urine_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.dark_colored_urine_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.stools_black_six_months || intakeData.stools_black_last_month || intakeData.stools_black_today">
                    <div class="heading">Stools that look black</div>
                    <div class="notes" *ngIf="intakeData.stools_black_notes">
                        <ng-container *ngIf="intakeData.stools_black_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.stools_black_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.stools_black_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.belly_pain_six_months || intakeData.belly_pain_last_month || intakeData.belly_pain_today">
                    <div class="heading">Belly pain</div>
                    <div class="notes" *ngIf="intakeData.belly_pain_notes">
                        <ng-container *ngIf="intakeData.belly_pain_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.belly_pain_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.belly_pain_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.trouble_swallowing_six_months || intakeData.trouble_swallowing_last_month || intakeData.trouble_swallowing_today">
                    <div class="heading">Trouble swallowing</div>
                    <div class="notes" *ngIf="intakeData.trouble_swallowing_notes">
                        <ng-container *ngIf="intakeData.trouble_swallowing_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.trouble_swallowing_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.trouble_swallowing_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.feeling_bloated_six_months || intakeData.feeling_bloated_last_month || intakeData.feeling_bloated_today">
                    <div class="heading">Feeling bloated after eating</div>
                    <div class="notes" *ngIf="intakeData.feeling_bloated_notes">
                        <ng-container *ngIf="intakeData.feeling_bloated_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.feeling_bloated_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.feeling_bloated_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.indigestion_six_months || intakeData.indigestion_last_month || intakeData.indigestion_today">
                    <div class="heading">Indigestion</div>
                    <div class="notes" *ngIf="intakeData.indigestion_notes">
                        <ng-container *ngIf="intakeData.indigestion_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.indigestion_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.indigestion_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>


                <div class="symptomItem" *ngIf="intakeData.diff_urination_six_months || intakeData.diff_urination_last_month || intakeData.diff_urination_today">
                    <div class="heading">Difficulty starting urination</div>
                    <div class="notes" *ngIf="intakeData.diff_urination_notes">
                        <ng-container *ngIf="intakeData.diff_urination_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.diff_urination_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.diff_urination_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>


                <div class="symptomItem" *ngIf="intakeData.weak_urine_six_months || intakeData.weak_urine_last_month || intakeData.weak_urine_today">
                    <div class="heading">Weak or interrupted flow of urine</div>
                    <div class="notes" *ngIf="intakeData.weak_urine_notes">
                        <ng-container *ngIf="intakeData.weak_urine_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.weak_urine_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.weak_urine_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.urin_often_six_months || intakeData.urin_often_last_month || intakeData.urin_often_today">
                    <div class="heading">Urinating often, especially at night</div>
                    <div class="notes" *ngIf="intakeData.urin_often_notes">
                        <ng-container *ngIf="intakeData.urin_often_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.urin_often_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.urin_often_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.trouble_empty_six_months || intakeData.trouble_empty_last_month || intakeData.trouble_empty_today">
                    <div class="heading">Trouble emptying the bladder completely</div>
                    <div class="notes" *ngIf="intakeData.trouble_empty_notes">
                        <ng-container *ngIf="intakeData.trouble_empty_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.trouble_empty_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.trouble_empty_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.pain_during_urin_six_months || intakeData.pain_during_urin_last_month || intakeData.pain_during_urin_today">
                    <div class="heading">Pain or burning during urination</div>
                    <div class="notes" *ngIf="intakeData.pain_during_urin_notes">
                        <ng-container *ngIf="intakeData.pain_during_urin_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.pain_during_urin_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.pain_during_urin_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.pain_hips_six_months || intakeData.pain_hips_last_month || intakeData.pain_hips_today">
                    <div class="heading">Pain in the back, hips, or pelvis that doesn't go away</div>
                    <div class="notes" *ngIf="intakeData.pain_hips_notes">
                        <ng-container *ngIf="intakeData.pain_hips_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.pain_hips_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.pain_hips_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.lump_or_swelling_six_months || intakeData.lump_or_swelling_last_month || intakeData.lump_or_swelling_today">
                    <div class="heading">A lump or swelling in either testicle</div>
                    <div class="notes" *ngIf="intakeData.lump_or_swelling_notes">
                        <ng-container *ngIf="intakeData.lump_or_swelling_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.lump_or_swelling_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.lump_or_swelling_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.feel_heaviness_six_months || intakeData.feel_heaviness_last_month || intakeData.feel_heaviness_today">
                    <div class="heading">A feeling of heaviness in the scrotum</div>
                    <div class="notes" *ngIf="intakeData.feel_heaviness_notes">
                        <ng-container *ngIf="intakeData.feel_heaviness_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.feel_heaviness_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.feel_heaviness_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.dull_ache_lower_belly_six_months || intakeData.dull_ache_lower_belly_last_month || intakeData.dull_ache_lower_belly_today">
                    <div class="heading">A dull ache in the lower belly or groin</div>
                    <div class="notes" *ngIf="intakeData.dull_ache_lower_belly_notes">
                        <ng-container *ngIf="intakeData.dull_ache_lower_belly_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.dull_ache_lower_belly_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.dull_ache_lower_belly_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.sudden_swelling_scrotum_six_months || intakeData.sudden_swelling_scrotum_last_month || intakeData.sudden_swelling_scrotum_today">
                    <div class="heading">Sudden swelling in the scrotum</div>
                    <div class="notes" *ngIf="intakeData.sudden_swelling_scrotum_notes">
                        <ng-container *ngIf="intakeData.sudden_swelling_scrotum_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.sudden_swelling_scrotum_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.sudden_swelling_scrotum_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.discomfort_testicle_six_months || intakeData.discomfort_testicle_last_month || intakeData.discomfort_testicle_today">
                    <div class="heading">Pain or discomfort in a testicle or the scrotum</div>
                    <div class="notes" *ngIf="intakeData.discomfort_testicle_notes">
                        <ng-container *ngIf="intakeData.discomfort_testicle_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.discomfort_testicle_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.discomfort_testicle_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.lump_front_neck_six_months || intakeData.lump_front_neck_last_month || intakeData.lump_front_neck_today">
                    <div class="heading">A lump in the front of your neck</div>
                    <div class="notes" *ngIf="intakeData.lump_front_neck_notes">
                        <ng-container *ngIf="intakeData.lump_front_neck_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.lump_front_neck_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.lump_front_neck_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.swollen_lymph_six_months || intakeData.swollen_lymph_last_month || intakeData.swollen_lymph_today">
                    <div class="heading">Swollen lymph nodes in your neck</div>
                    <div class="notes" *ngIf="intakeData.swollen_lymph_notes">
                        <ng-container *ngIf="intakeData.swollen_lymph_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.swollen_lymph_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.swollen_lymph_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.pain_throat_neck_six_months || intakeData.pain_throat_neck_last_month || intakeData.pain_throat_neck_today">
                    <div class="heading">Pain in your throat and/or neck</div>
                    <div class="notes" *ngIf="intakeData.pain_throat_neck_notes">
                        <ng-container *ngIf="intakeData.pain_throat_neck_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.pain_throat_neck_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.pain_throat_neck_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.change_voice_six_months || intakeData.change_voice_last_month || intakeData.change_voice_today">
                    <div class="heading">Change to your voice, such as hoarseness</div>
                    <div class="notes" *ngIf="intakeData.change_voice_notes">
                        <ng-container *ngIf="intakeData.change_voice_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.change_voice_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.change_voice_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>

                <div class="symptomItem" *ngIf="intakeData.tenderness_breast_tissue_six_months || intakeData.tenderness_breast_tissue_last_month || intakeData.tenderness_breast_tissue_today">
                    <div class="heading">Enlargement or tenderness of the breast tissue</div>
                    <div class="notes" *ngIf="intakeData.tenderness_breast_tissue_notes">
                        <ng-container *ngIf="intakeData.tenderness_breast_tissue_notes.unsure == false">
                            <p><strong><mat-icon>description</mat-icon></strong><span>{{intakeData.tenderness_breast_tissue_notes.note}}</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="intakeData.tenderness_breast_tissue_notes.unsure == true">
                            <p><strong><mat-icon>description</mat-icon></strong><span>Unsure how to describe the
                                    symptom</span></p>
                        </ng-container>
                    </div>
                </div>



            </div>
        </div>

        <div class="subjectiveOrderSheetBlock">
            <h2 class="additonal_head"> Order Sheet</h2>
            <div class="content_block">
                <div class="orderDataItem" *ngFor="let order of orderData">
                    <p class="categoryName">{{order.diagnostic_categories_name}}</p>
                    <p class="heading">{{order.diagnostic_name}}</p>
                    <p class="diagnosisReason">* {{order.diagnostic_reason}}</p>
                    <p class="notes"><span><mat-icon>description</mat-icon></span> {{order.notes_by_doctor}}</p>
                    <div class="matchedSymptoms">
                        <h3>Matched Symptoms:</h3>
                        <div class="spantext" [innerHtml]="order.matched_symptoms"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>