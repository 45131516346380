import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-insurance-add-edit',
  templateUrl: './insurance-add-edit.component.html',
  styleUrls: ['./insurance-add-edit.component.css']
})
export class InsuranceAddEditComponent {
  data: any;
  flag: any;
  externaldatavalue: any;
  name: any;
  value: any;

  public addFormLoader: boolean = false;
  public cookieData: any = {};
  public insuranceFormData: any = {};
  public diagnosticcatautocompleteData: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  public diagnosticcatautocompleteDataNew: any = {};


  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];
  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {

    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);

  }
  ngOnInit() {
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
            console.log("Edit data", this.editFormData);

          }
        }
      })
    }

    this.initailForm();
  }

  initailForm() {
    this.insuranceFormData =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      endpoint: 'intake/addupdateinsurance',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      cancelroute: 'admin/manage/insurance-list', // use for redirect after cancel the form,
      redirectpath:'admin/manage/insurance-list',
      fields: [

        {
          label: 'Insurance Name',
          name: 'insurance_name',
          value: this.editFormData && this.editFormData.insurance_name ? this.editFormData.insurance_name : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'insurance Name is Required' },

          ],
        },
        {
          label: "Description",
          name: "insurance_des",
          type: "textarea",
          // group: "grp1",
          rows: 1,
          cols: 2,
          value: this.editFormData && this.editFormData.insurance_des ? this.editFormData.insurance_des : '',
          // hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Description field Needs to be required" },
          ]
        },

        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          val: this.statusarr,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
          validations: [
            // { rule: 'required', message: 'Required Type' },
          ]
        },

        {
          label: 'Id',
          name: '_id',
          type: 'hidden',
          value: this.editFormData && this.editFormData._id ? this.editFormData._id : '',
          validations: [
            // { rule: 'required', message: 'Required Type' },
          ]
        },


      ]
    }

  }

}

