<div class="signPageDialogBody symptomReasonModal">

    <h2>Rejection Notes</h2>
    <p>Please add all the reasons for the rejection of this order.</p>
    <button class="close_button" style="right: 0;top: 0;">
    <mat-icon style="cursor: pointer;" (click)="modalClose()"> cancel</mat-icon>
</button>
    <form (ngSubmit)="addRejectNotes()" autocomplete="off" name="addRejectForm" [formGroup]="addRejectForm" class="addRejectForm">
        <mat-form-field class="example-full-width">
            <mat-label>Notes</mat-label>
            <textarea matInput placeholder="Add Notes" name="addnotes" formControlName="addnotes" required></textarea>

        </mat-form-field>
        <p *ngIf="!addRejectForm.controls['addnotes'].valid && addRejectForm.controls['addnotes'].touched">
            <span class="error"> Notes is Required.</span>
        </p>
        <button mat-button type="submit">Submit</button>
        <mat-progress-bar mode="indeterminate" *ngIf="approveProgressBar"></mat-progress-bar>
    </form>
</div>