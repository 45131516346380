import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CookieService } from 'ngx-cookie-service';
import { AddUserBottomSheet } from '../../../usermanagement/user-list/user-list.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-email-intake-list',
  templateUrl: './email-intake-list.component.html',
  styleUrls: ['./email-intake-list.component.css']
})
export class EmailIntakeListComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'usertype'];
  public tabledatatalist: []
  public listprogressBar: any = false;
  tablename = "users";
  editroute = "";
  updateendpoint = "fdfd";
  deleteendpoint = "fdf";
  datacollection: any = "email/emailintake";

  searchendpoint = "email/emailintake";
  date_search_endpoint: any = "email/emailintake";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: true, name: "Complete" },
    { val: false, name: "Pending" },
  ];
  modify_header_array: any = {
    patient_email: "Patient Email",
    patient_name: "Patient Name",
    priority: "Priority",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On",
    // status_text: "Status"
  };
  search_settings: any = {


    search: [
      {
        label: "Search By Patient Name", field: '_id',
        serversearchdata: { endpoint: 'email/autocompleteemailnamesearch' },
      },
      {
        label: "Search By Patient Email", field: 'patient_email',
        serversearchdata: { endpoint: 'email/autocompleteemailsearch' },
      },
      // { label: "Search By Patient Name", field: 'patient_name' },
    ],

    textsearch: [
      // { label: "Search By Patient Name", field: 'patient_name' },
      // { label: "Search By Patient Email", field: 'patient_email' },
    ],
    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'createdon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],
    selectsearch: [
      { label: 'Search By Action', field: 'intake_submit', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["patient_name", "patient_email", "usertype"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      {
        label: 'Resend Email',
        type: 'button',
        name: 'resend_email',
        tooltip: 'Resend Email',
        classname: 'add_adminBTN',
      },
    ],
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: "patient_email", val: "Patient Email" },
      { key: "patient_name", val: "patient Name" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "priority", val: "Priority" },
      // { key: "status_text", val: "Status" }


    ],
    updateendpoint: "email/emailintake",

    hidedeletebutton: true,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    hidemultipleselectbutton: false,
    hideupdatemany: true,
    hidedeletemany: true,
    hideaction: false,
    updateendpointmany: "email/emailintake",

    deleteendpointmany: "users/deleteTaxo",
    tableheaders: ["patient_name", "patient_email", "createdon_datetime"],
    customselectbuttons: [
      {
        label: "Resend Email",
        id: "resen_email_select",
      },
    ],

    custombuttons: [
      // {
      //   label: "Edit",
      //   type: 'listner',
      //   id: 'edit_btn',
      //   tooltip: 'Edit',
      //   name: 'edit',
      //   classname: 'edit_btn'
      // },

      {
        label: "resend",
        type: 'action',
        datatype: 'api',
        endpoint: 'email/resendemailintake',
        otherparam: [],
        cond: 'resend_emai',
        condval: 0,
        param: '_id',
        //  refreshdata: true,
        headermessage: 'Resend',
        tooltip: 'Resend',
        classname: 'resend'
      },
      {
        label: "Preview",
        type: "listner",
        id: "preview_btn",
        tooltip: 'Preview',
        cond: "resend_emai",
        condval: 1,
        classname: 'previewButton',
      },

    ],

  }
  public taxonomy_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;

  public demoDoctorDashboard: boolean = false;

  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService, public dialog: MatDialog, public snackbar: MatSnackBar) {
    if (!window.location.pathname.includes('doctor/email-intake-list') && !window.location.pathname.includes('practice/email-intake-list') && !window.location.pathname.includes('sales_person/doctor')) {
      this.router.navigateByUrl('404')
    }
  }
  openBottomSheet(): void {
    this._bottomSheet.open(AddUserBottomSheet);
  }

  ngOnInit() {
    this.progressLoader = true;

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      switch (this.cookieData.user_type) {
        case 'admin':
          this.isAdminUser = true;

          break;
        case 'doctor':
          this.isDoctorUser = true;
          break;
      }
    }
    this.userListFetching();
    console.log("cookieService", this.cookieData)

    this.libdata.basecondition = { added_by: this.cookieData._id };

    if (window.location.pathname.includes('sales_person/doctor')) {
      this.demoDoctorDashboard = true;
    }
  }
  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {
        added_by: this.cookieData._id
      }
    }
    if (this.cookieData) {
      console.log("this.cookieData>>>>>>>>", data);

    }

    this.activatedRoute.data.subscribe((response: any) => {
      console.log("activatedRoute", response)
      this.listprogressBar = true
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);

      }
    })

    this.httpService.httpViaPost('email/emailintake-count', data).subscribe((response: any) => {
      console.log("response test", response, data)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }

  addBtn() {
    if (this.demoDoctorDashboard) {
      this.router.navigateByUrl(this.cookieData.user_type + `/doctor/add-email-intake`);
    } else {
      this.router.navigateByUrl(this.cookieData.user_type + `/add-email-intake`);
    }
  }

  onLiblistingButtonChange(val: any) {
    // throw new Error('Function not implemented.');
    console.log("listenLiblistingChange email", val);
    if (val.action == "customlistenbutton") {
      if (val.buttondata.name == "resend_email") {
        // this.router.navigateByUrl(`admin/taxonomies/add`);
        const dialogRef = this.dialog.open(ResendConfirmModal, {
          panelClass: 'signPageDialog'
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result == 'yes') {
            this.resendEmailForAll(1, []);
          }
        })
      }
    }


  }
  listenLiblistingChange(data: any = null) {
    console.log("listenLiblistingChange email", data);

    if (data.action == 'custombuttonclick') {
      if (data.custombuttonclick.btninfo.id == "preview_btn") {
        // this.router.navigateByUrl(`${this.cookieData.user_type}/preview-intake/${data.custombuttonclick.data._id}`);
        console.log("data.custombuttonclick.data", data.custombuttonclick.data);


        window.open(`${this.cookieData.user_type}/email-preview-intake/${data.custombuttonclick.data.patient_id}`, '_blank')
      }
    }

    if (data.action == "multipleselectoptionclick") {
      if (data.btndata.id == "resen_email_select") {
        // this.router.navigateByUrl(`admin/taxonomies/add`);
        const dialogRef = this.dialog.open(ResendConfirmModal, {
          panelClass: 'signPageDialog'
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result == 'yes') {
            let idsArray = [];
            for (const key in data.selecteddata) {
              if (data.selecteddata[key].resend_emai == 0) {
                idsArray.push(data.selecteddata[key]._id);
              }
            }
            console.log('key>>', idsArray);
            this.resendEmailForAll(2, idsArray);
          }
        })
      }
    }

  }

  resendEmailForAll(flag, value) {
    let data = {};
    if (flag == 1) {
      data = {
        "intake_submit": false,
        "added_by": this.cookieData._id
      }
    }

    if (flag == 2) {
      data = {
        "ids": value
      }
    }

    this.httpService.httpViaPost('email/selectedresendemailintake', data).subscribe((response: any) => {
      console.log("response test", response, data)
      if (response.status == 'success') {
        this.snackbar.open("Email(s) sent successfully!", "ok", {
          duration: 4000
        });
      }

      // this.progressLoader = false;
    })
  }

}


@Component({
  selector: 'resend-email-conform',
  templateUrl: 'resend-email-conform.html',
})
export class ResendConfirmModal {

  constructor(
    public dialogalert: MatDialogRef<ResendConfirmModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpService: HttpServiceService,
    public cookieService: CookieService
  ) { }
  ngOnInit() {

  }
  resendemail(val) {
    this.dialogalert.close(val);
  }

  onNoClick(): void {
    this.dialogalert.close();
  }
}