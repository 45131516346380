<div class="ModalTabSubConPage">
    <h1>Treatment Protocal</h1>
 

    <div class="treatmentProtocal previewTabLeftContent diagnosis" [innerHtml]="TreatmentProtocalDataset"></div>
    <!-- <div class="diagnosis">
        <div class="diagnosis-item">
          <h4>Congestive Heart Failure</h4>
          <p>Congestive Heart Failure (CHF) is a condition where the heart is unable to pump enough blood to meet the body's needs. The treatment protocol for CHF includes:</p>
          <ul>
            <li>Limiting salt intake to reduce fluid retention</li>
            <li>Following a heart-healthy diet low in saturated fats and cholesterol</li>
            <li>Engaging in regular physical activity as recommended by a healthcare professional</li>
            <li>Taking prescribed medications, such as diuretics, beta-blockers, and ACE inhibitors</li>
            <li>Monitoring weight and reporting any sudden weight gain or swelling to the healthcare provider</li>
            <li>Managing underlying conditions, such as high blood pressure or diabetes</li>
            <li>Quitting smoking and avoiding exposure to secondhand smoke</li>
            <li>Limiting alcohol consumption</li>
            <li>Getting vaccinated against influenza and pneumonia</li>
            <li>Attending regular follow-up appointments with the healthcare provider</li>
          </ul>
        </div>
        <div class="diagnosis-item">
          <h4>Chronic Kidney Disease</h4>
          <p>Chronic Kidney Disease (CKD) is a progressive condition where the kidneys gradually lose their function. The treatment protocol for CKD includes:</p>
          <ul>
            <li>Controlling blood pressure through lifestyle modifications and medications</li>
            <li>Following a kidney-friendly diet low in sodium, phosphorus, and potassium</li>
            <li>Limiting protein intake to reduce the workload on the kidneys</li>
            <li>Managing blood sugar levels in case of diabetes</li>
            <li>Taking medications to manage complications, such as anemia or bone disease</li>
            <li>Quitting smoking and avoiding exposure to secondhand smoke</li>
            <li>Engaging in regular physical activity as recommended by a healthcare professional</li>
            <li>Monitoring and managing cholesterol levels</li>
            <li>Avoiding over-the-counter medications that may harm the kidneys</li>
            <li>Getting vaccinated against influenza and pneumonia</li>
            <li>Attending regular follow-up appointments with the healthcare provider</li>
          </ul>
        </div>
        <div class="diagnosis-item">
          <h4>Gastroesophageal Reflux Disease (GERD)</h4>
          <p>Gastroesophageal Reflux Disease (GERD) is a chronic condition where stomach acid flows back into the esophagus, causing symptoms like heartburn. The treatment protocol for GERD includes:</p>
          <ul>
            <li>Avoiding trigger foods and beverages, such as spicy foods, citrus fruits, and carbonated drinks</li>
            <li>Eating smaller, more frequent meals</li>
            <li>Avoiding lying down or bending over after meals</li>
            <li>Elevating the head of the bed while sleeping</li>
            <li>Quitting smoking and avoiding exposure to secondhand smoke</li>
            <li>Managing stress through relaxation techniques</li>
            <li>Wearing loose-fitting clothing</li>
            <li>Avoiding tight belts or waistbands</li>
            <li>Using over-the-counter antacids or acid reducers as recommended by a healthcare professional</li>
            <li>Discussing with a healthcare provider about prescription medications, if necessary</li>
            <li>Attending regular follow-up appointments with the healthcare provider</li>
          </ul>
        </div>
        <div class="diagnosis-item">
          <h4>Anemia</h4>
          <p>Anemia is a condition characterized by a decrease in the number of red blood cells or a decrease in the amount of hemoglobin in the blood. The treatment protocol for anemia includes:</p>
          <ul>
            <li>Treating the underlying cause of anemia, such as iron deficiency or vitamin B12 deficiency</li>
            <li>Supplementing with iron, vitamin B12, or other necessary nutrients</li>
            <li>Eating a balanced diet rich in iron, vitamin B12, and folate</li>
            <li>Managing chronic conditions, such as kidney disease or inflammatory bowel disease</li>
            <li>Limiting alcohol consumption</li>
            <li>Quitting smoking and avoiding exposure to secondhand smoke</li>
            <li>Getting vaccinated against influenza and pneumonia</li>
            <li>Avoiding over-the-counter medications that may worsen anemia</li>
            <li>Engaging in regular physical activity as recommended by a healthcare professional</li>
            <li>Attending regular follow-up appointments with the healthcare provider</li>
          </ul>
        </div>
        <div class="diagnosis-item">
          <h4>Hypothyroidism</h4>
          <p>Hypothyroidism is a condition where the thyroid gland does not produce enough thyroid hormones. The treatment protocol for hypothyroidism includes:</p>
          <ul>
            <li>Taking prescribed thyroid hormone replacement medication</li>
            <li>Following a balanced diet rich in iodine and selenium</li>
            <li>Managing stress through relaxation techniques</li>
            <li>Engaging in regular physical activity as recommended by a healthcare professional</li>
            <li>Getting enough sleep and rest</li>
            <li>Avoiding exposure to environmental toxins</li>
            <li>Quitting smoking and avoiding exposure to secondhand smoke</li>
            <li>Limiting alcohol consumption</li>
            <li>Managing other underlying conditions, such as diabetes or high blood pressure</li>
            <li>Attending regular follow-up appointments with the healthcare provider</li>
          </ul>
        </div>
      </div> -->
    </div>