import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent {
  public resolveval: any;
  public taxonomyList: any = [];
  public salespersonList: any = [];
  public stateList: any = [];

  // emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  emailregex: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  public cookieData: any = {};
  public userData: any;
  public userType: any;

  public formdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public externaldatavalue: any = [];

  public addFormLoader: boolean = false;

  public parent_locationList: any = [];
  public userImage: any = null;

  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, private activateRoute: ActivatedRoute, public matSnackBar: MatSnackBar) {

    // if (this.cookieService.getAll()['login_user_details']) {
    //   this.resolveval = JSON.parse(this.cookieService.getAll()['login_user_details']);
    // }



    this.userData = JSON.parse(this.cookieService.get("login_user_details"));


    this.userType = this.userData.user_type;


    // console.log("this.userData", this.userData)



    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }


    this.activateRoute.data.subscribe((response: any) => {


      console.log("edit account this.userData", response)
      if (response) {
        if (response.data && response.data.status == "success") {
          // this.cookieService.delete('login_user_details', '/');
          // setTimeout(() => {
          //   this.cookieService.set('login_user_details', JSON.stringify(response.data.results.res[0]));
          //   //   this.resolveval = response.data.results.res[0]
          // }, 1000)

          this.resolveval = response.data.results.res[0];


          // 
          console.log("this.resolveval", this.resolveval)

          switch (this.userData.user_type) {
            case 'admin':
              this.adminMyAccount();
              this.getStates();
              break;
            case 'laboratory':
              this.laboratoryMyAccount();
              break;

            case 'practice':
              this.practiceyMyAccount();
              this.taxonomyFetching();
              this.salesPersonFetching();

              break;

            case 'sales_person':
              this.salespersonMyAccount();

              this.getStates();
              break;

            case 'doctor':
              this.taxonomyFetching();
              if (this.resolveval && Object.keys(this.resolveval).length > 0 && this.resolveval.practice_id && this.resolveval.practice_id._id) {
                let data: any = { _id: this.resolveval.practice_id._id };
                this.fetchPracticeLocation(data)
              }
              this.doctorMyAccount();

              break;
          }


        }
      }
    })




    console.log("this.userData", this.userData)
    // this.adminMyAccount();



  }




  getStates() {
    this.apiservice.getHttpData("../../../../../assets/data-set/state.json").subscribe(response => {
      console.log(response, "this.stateList")
      // this.stateList = response;
      if (response && response.length > 0) {
        response.forEach((e, i) => {
          let obj = { val: e.abbreviation, name: e.name };
          this.stateList.push(obj)
        })
      }
      console.log('resolveval', this.stateList)
    })
  }



  ngOnInit() {
    // console.log('resolveval', this.stateList)
    // this.fullName=this.resolveval.firstname + ' ' + this.resolveval.lastname;

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    // console.log('cookieData111', this.cookieData.user_type)





  }

  adminMyAccount() {
    this.formdata =
    {
      // from start
      successmessage: 'Updated Successfully', // success message
      submittext: 'Update',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      // redirectpath: "/dashboard",
      resettext: 'Reset',
      canceltext: 'Cancel',
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute: (`${this.cookieData.user_type}/dashboard`),
      hidereset: true,

      fields: [
        {
          label: 'First Name',
          name: 'firstname',
          value: this.resolveval.firstname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'First Name is Required' },

          ],
        },

        {
          heading: '',
          label: 'Last Name',
          name: 'lastname',
          value: this.resolveval.lastname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'Last Name is Required' },

          ],
        },


        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',
          disabled: true,
          value: this.resolveval.email,
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },




        {
          label: "Phone No",
          name: "phone",
          type: 'numberformat',
          value: this.resolveval.phone,
          hint: 'dddd',
          formatflag: true,
          validations: [{ rule: 'required', message: 'phone is Required' }],
        },




        {
          label: 'Address',
          name: 'address',
          value: this.resolveval.address,
          type: 'textarea',
          validations: [
            { rule: 'required', message: 'Address is Required' },
          ],
        },

        {
          label: 'State',
          name: 'state',
          val: this.stateList,
          value: this.resolveval.state,
          type: 'select',
          validations: [{ message: 'State is Required' }],
        },

        {
          label: 'City',
          name: 'city',
          value: this.resolveval.city,
          type: 'text',
          validations: [{ rule: 'required', message: 'City is Required' }],
        },

        {
          label: 'Zip',
          name: 'zip',
          value: this.resolveval.zip,
          type: 'number',
          validations: [
            { rule: 'required', message: 'Zip is Required' },
            {
              rule: 'maxLength',
              value: 5,
              message: 'Please enter the valid Zip ID',
            },
          ],
        },


        {
          label: 'Active',
          name: 'status',
          value: 1,
          type: 'hidden',
        },

        {
          label: 'id',
          name: '_id',
          value: this.resolveval._id,
          type: 'hidden',
        },



        // {
        //   label: 'Priority',
        //   name: 'priority',
        //   value: 1,
        //   type: 'hidden',
        //   // validations: [

        //   // ]
        // },
      ]
    }
    setTimeout(() => {
      let myRow = document.querySelector('.addEditForm');
      myRow.classList.add('adminForm')
    }, 200);
  }


  practiceyMyAccount() {
    console.log("this.resolveval salespersonList", this.salespersonList)
    this.formdata =
    {
      // from start
      successmessage: 'Updated Successfully', // success message
      submittext: 'Update',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      // redirectpath: "/dashboard",
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidecancel: this.resolveval && this.resolveval.info_update !== null && this.resolveval.info_update !== undefined && this.resolveval.info_update == false ? true : false,
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute: (`${this.cookieData.user_type}/dashboard`),
      hidereset: true,

      fields: [
        {
          heading: "<h1>Parent Information</h1>",
          name: "married",

        },

        {
          label: "Parent Laboratory",
          name: "parent_laboratory",
          disabled: true,
          type: 'text',
          // value: this.resolveval && Object.keys(this.resolveval).length > 0 ? this.resolveval.personList : '',
          value: this.resolveval.parent_laboratory.val,

        },

        {
          label: "Salesperson",
          name: "sales_person",
          disabled: this.resolveval && this.resolveval.info_update !== null && this.resolveval.info_update !== undefined && this.resolveval.info_update == false ? false : true,
          type: 'select',
          // value: this.resolveval && Object.keys(this.resolveval).length > 0 ? this.resolveval.personList : '',
          value: this.resolveval.sales_person,
          val: this.salespersonList,

        },

        {
          heading: "<h1>Contact Information</h1>",
          name: "married",
        },

        {
          label: 'First Name',
          name: 'contact_person_firstname',
          value: this.resolveval.contact_person_firstname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'First Name is Required' },

          ],
        },
        {
          label: 'Last Name',
          name: 'contact_person_lastname',
          value: this.resolveval.contact_person_lastname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'Last Name is Required' },

          ],
        },
        {
          label: 'Phone Number',
          name: 'contact_phone',
          value: this.resolveval.contact_phone,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Phone Number is Required' }],
        },
        {
          label: 'Contact Email',
          name: 'contact_email',
          type: 'email',
          hint: '',
          value: this.resolveval.contact_email,
          validations: [
            { rule: 'required', message: 'Contact Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },

        {
          heading: "<h1>Practice Information</h1>",
          name: "married",
        },


        {
          label: 'Practice Name',
          name: 'practice_name',
          value: this.resolveval.practice_name,
          type: 'text',
          // classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Practice Name is Required' },

          ],
        },

        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',
          disabled: true,
          value: this.resolveval.email,
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },



        {
          label: 'Phone',
          name: 'phone',
          value: this.resolveval.phone,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Phone is Required' }],
        },
        {
          label: 'Fax',
          name: 'fax',
          value: this.resolveval.fax,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Fax is Required' }],
        },




        {
          label: "Taxonomies",
          name: "taxonomy",
          // hint: ',0000',
          type: 'select',
          value: this.resolveval.taxonomy,
          val: this.taxonomyList,
          // value:[2021,2022],
          multiple: true,
          validations: [
            { rule: 'required', message: 'Select Taxonomies is Required' }
          ]

        },
        {
          label: 'NPI',
          name: 'npi',
          value: this.resolveval.npi,
          type: 'number',
          validations: [
            { rule: 'required', message: 'NPI is Required' },
          ],
        },
        {
          label: 'Name of EMR software being used',
          name: 'emr',
          value: this.resolveval.emr,
          type: 'text',
          validations: [
            { rule: 'required', message: 'Contact Person is Required' },
          ],
        },
        {
          label: ' EIN Number',
          name: 'ein',
          value: this.resolveval.ein,
          type: 'number',
          validations: [
            { rule: 'required', message: 'EIN Number is Required' },
          ],
        },
        {
          label: 'Request Decision Doc AI Access',
          name: 'has_decisiondoc_ai_access',
          value: this.resolveval.has_decisiondoc_ai_access ? this.resolveval.has_decisiondoc_ai_access : false,
          disabled: false,
          type: 'checkbox',
          // validations: [
          //   { rule: 'required', message: 'EIN Number is Required' },
          // ],
        },
        {
          label: 'Request Decision Doc MX Access',
          name: 'has_decisiondoc_mx_access',
          value: this.resolveval.has_decisiondoc_mx_access ? this.resolveval.has_decisiondoc_mx_access : false,
          type: 'checkbox',
          // validations: [
          //   { rule: 'required', message: 'EIN Number is Required' },
          // ],
        },
        {
          label: 'Active',
          name: 'status',
          value: 1,
          type: 'hidden',
        },
        {
          label: 'id',
          name: '_id',
          value: this.resolveval._id,
          type: 'hidden',
        },



        // {
        //   label: 'Priority',
        //   name: 'priority',
        //   value: 1,
        //   type: 'hidden',
        //   // validations: [

        //   // ]
        // },
      ]
    }
    console.log("this.resolveval salespersonList", this.salespersonList)
    setTimeout(() => {
      let myRow = document.querySelector('.addEditForm');
      myRow.classList.add('practiceForm')
    }, 200);
  }


  laboratoryMyAccount() {

    this.formdata =
    {
      // from start
      successmessage: 'Updated Successfully', // success message
      submittext: 'Update',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      // redirectpath: "/dashboard",
      resettext: 'Reset',
      canceltext: 'Cancel',
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute: (`${this.cookieData.user_type}/dashboard`),
      hidereset: true,
      fields: [


        {
          label: 'Laboratory Name',
          name: 'laboratory_name',
          value: this.resolveval.laboratory_name,
          type: 'text',
          validations: [
            { rule: 'required', message: 'Laboratory Name is Required' },

          ],
        },


        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',
          disabled: true,
          value: this.resolveval.email,
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },

        {
          label: 'NPI',
          name: 'npi',
          value: this.resolveval.npi,
          type: 'number',
          validations: [
            { rule: 'required', message: 'NPI is Required' },
          ],
        },
        {
          label: 'Phone',
          name: 'phone',
          value: this.resolveval.phone,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Phone is Required' }],
        },
        {
          label: 'Fax',
          name: 'fax',
          value: this.resolveval.fax,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Fax is Required' }],
        },

        {
          label: 'Contact Person',
          name: 'contact_person',
          value: this.resolveval.contact_person,
          type: 'text',
          validations: [
            { rule: 'required', message: 'Contact Person is Required' },
          ],
        },
        {
          label: 'Contact Person Email',
          name: 'contact_person_email',
          type: 'email',
          hint: '',
          value: this.resolveval.contact_person_email,
          validations: [
            { rule: 'required', message: 'Contact Person Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },
        {
          label: 'Contact Person Phone',
          name: 'contact_person_phone',
          value: this.resolveval.contact_person_phone,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Contact Person Phone is Required' }],
        },
        // {
        //   label: "Image Choice",
        //   name: "imagechoice",
        //   type: "image",
        //   value: 2,
        //   val: [
        //     {
        //       key: 1,
        //       image: "https://" + this.resolveval.image,
        //     }
        //   ],
        // },

        {
          label: this.resolveval ? "Edit Logo" : "Add Logo",
          name: "logo",
          type: "file",
          // multiple: false,
          prefix: Date.now(),
          // path: "laboratory/",
          // baseurl: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
          // bucket: "pece-training-files",
          // apiurl: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL/",
          // apideleteurl: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket",
          path: 'practice2lab/',
          bucket: 'all-frontend-assets',
          baseurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/',
          apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
          apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          value: this.resolveval && this.resolveval.logo && this.resolveval.logo.name ? { url: this.resolveval.logo.baseurl, type: 'image', name: this.resolveval.logo.name } : undefined
        },

        {
          label: 'Active',
          name: 'status',
          value: 1,
          type: 'hidden',
        },

        {
          label: 'id',
          name: '_id',
          value: this.resolveval._id,
          type: 'hidden',
        },



        // {
        //   label: 'Priority',
        //   name: 'priority',
        //   value: 1,
        //   type: 'hidden',
        //   // validations: [

        //   // ]
        // },
      ]
    }
    setTimeout(() => {
      let myRow = document.querySelector('.addEditForm');
      myRow.classList.add('laboratoryForm');
      if (this.resolveval.logo && Object.keys(this.resolveval.logo).length > 0) {
        const divNode = document.createElement("div");
        divNode.classList.add("logoImageDiv");
        const node = document.createElement("img");
        node.classList.add("logoImage");
        node.src = `https://pece-training-files.s3.amazonaws.com/${this.resolveval.logo.path}${this.resolveval.logo.fileservername}`;
        divNode.appendChild(node)
        document.querySelector(".filecontainerdiv").prepend(divNode);
      }
    }, 200);

  }


  salespersonMyAccount() {


    this.formdata =
    {
      // from start
      successmessage: 'Updated Successfully', // success message
      submittext: 'Update',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      // redirectpath: "/dashboard",
      resettext: 'Reset',
      canceltext: 'Cancel',
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute: (`${this.cookieData.user_type}/dashboard`),
      hidereset: true,
      fields: [








        {
          label: 'First Name',
          name: 'firstname',
          value: this.resolveval.firstname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'First Name Name is Required' },

          ],
        },

        {
          label: 'Last Name',
          name: 'lastname',
          value: this.resolveval.lastname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'Last Name Name is Required' },

          ],
        },




        {
          label: "Parent Laboratory",
          name: "parent_laboratory",
          disabled: true,
          type: 'text',
          // multiple: false, 
          search_field: "name_search",
          value: this.resolveval.parent_laboratory.val,
        },


        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',
          disabled: true,
          value: this.resolveval.email,
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },




        {
          label: "Phone No",
          name: "phone",
          type: 'numberformat',
          value: this.resolveval.phone,
          hint: 'dddd',
          formatflag: true,
          validations: [{ rule: 'required', message: 'phone is Required' }],
        },


        {
          label: 'Fax',
          name: 'fax',
          value: this.resolveval.fax,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Fax is Required' }],
        },

        {
          label: 'Address',
          name: 'address',
          value: this.resolveval.address,
          type: 'textarea',
          validations: [
            { rule: 'required', message: 'Address is Required' },
          ],
        },

        {
          label: 'State',
          name: 'state',
          val: this.stateList,
          value: this.resolveval.state,
          type: 'select',
          validations: [{ message: 'State is Required' }],
        },

        {
          label: 'City',
          name: 'city',
          value: this.resolveval.city,
          type: 'text',
          validations: [{ rule: 'required', message: 'City is Required' }],
        },

        {
          label: 'Zip',
          name: 'zip',
          value: this.resolveval.zip,
          type: 'number',
          validations: [
            { rule: 'required', message: 'Zip is Required' },
            {
              rule: 'maxLength',
              value: 5,
              message: 'Please enter the valid Zip ID',
            },
          ],
        },




        {
          label: 'Active',
          name: 'status',
          value: 1,
          type: 'hidden',
        },


        {
          label: 'id',
          name: '_id',
          value: this.resolveval._id,
          type: 'hidden',
        },



        // {
        //   label: 'Priority',
        //   name: 'priority',
        //   value: 1,
        //   type: 'hidden',
        //   // validations: [

        //   // ]
        // },
      ]
    }
    setTimeout(() => {
      let myRow = document.querySelector('.addEditForm');
      myRow.classList.add('salespersonForm');
    }, 200);


  }

  taxonomyFetching() {
    let data: any = {
      sort: { type: 'desc', field: '_id' },
      searchcondition: {}
    }
    this.apiservice.httpViaPost('users/taxonomyautocomplete', data).subscribe((response) => {
      console.log("taxonomy", response)
      if (response) {
        if (response.status == "success") {
          this.taxonomyList = response.res;
          if (this.taxonomyList.length > 0) {
            this.taxonomyList.forEach((e: any, i: number) => {
              e.val = e.key
            })
          }
          this.formdata.fields = this.formdata.fields.filter((e) => e.name == 'taxonomy' ? e.val = this.taxonomyList : []);
        }
      }
    })
  }

  salesPersonFetching() {

    console.log('cookieData0000', this.cookieData)
    let data: any = {
      // sort: { type: 'desc', field: '_id' },
      searchcondition: { _id: Object.keys(this.cookieData.parent_laboratory).length > 0 && this.cookieData.parent_laboratory._id ? this.cookieData.parent_laboratory._id : this.cookieData.parent_laboratory }
      // _id: this.cookieData._i
    }
    this.apiservice.httpViaPost('users/salespersonAutocomplete', data).subscribe((response) => {
      console.log("sales_person", response)
      if (response) {
        if (response.status == "success") {
          this.salespersonList = response.res;
          console.log("sales_persondata", this.salespersonList)

          if (this.salespersonList.length > 0) {
            this.salespersonList.forEach((e: any, i: number) => {
              e.val = e._id

              console.log("000", e)
            })


          }
          this.formdata.fields = this.formdata.fields.filter((e) => e.name == 'sales_person' ? e.val = this.salespersonList : []);


          console.log("formdatatest", this.formdata.fields)

        }
      }
    })
  }





  fetchPracticeLocation(data: any) {
    this.apiservice.httpViaPost('users/fetchpracticelocation', data).subscribe((response) => {
      console.log("fetchPracticeLocation++++", response)
      if (response) {
        this.parent_locationList = response.res;
        if (this.parent_locationList.length > 0) {
          this.parent_locationList.forEach((e: any, i: number) => {
            e.val = e._id
          })
        };
        // if (!this.ispracticenameValueUpdate) {
        this.formdata.fields = this.formdata.fields.filter((e) => e.name == 'parent_location' ? e.val = this.parent_locationList : []);
        console.log("this.formdata.fields", this.formdata.fields)
        // this.ispracticenameValueUpdate = true;
        // }
      }
    })
  }

  doctorMyAccount() {

    this.formdata =
    {
      // from start
      successmessage: 'Updated Successfully', // success message
      submittext: 'Update',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      // redirectpath: "/dashboard",
      resettext: 'Reset',
      canceltext: 'Cancel',
      // jwttoken: this.cookieService.get('jwtToken'),
      cancelroute: (`${this.cookieData.user_type}/dashboard`),
      hidereset: true,
      fields: [

        {
          label: 'First Name',
          name: 'firstname',
          value: this.resolveval.firstname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'First Name Name is Required' },

          ],
        },

        {
          label: 'Last Name',
          name: 'lastname',
          value: this.resolveval.lastname,
          type: 'text',
          validations: [
            { rule: 'required', message: 'Last Name Name is Required' },

          ],
        },

        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',
          disabled: true,
          value: this.resolveval.email,
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },

        {
          label: "Phone No",
          name: "phone",
          type: 'numberformat',
          value: this.resolveval.phone,
          hint: 'dddd',
          formatflag: true,
          validations: [{ rule: 'required', message: 'phone is Required' }],
        },


        {
          label: 'Fax',
          name: 'fax',
          value: this.resolveval.fax,
          formatflag: true,
          type: 'numberformat',
          // type: 'number',
          validations: [{ rule: 'required', message: 'Fax is Required' }],
        },

        {
          label: "Parent Laboratory",
          name: "parent_laboratory",
          value: this.resolveval.parent_laboratory.name,
          formatflag: true,
          type: 'text',
          // type: 'number',
          // validations: [{ rule: 'required', message: 'Parent Laboratory is Required' }],
          disabled: true,
        },

        {
          label: "Practice Name",
          name: "practice_id",
          value: this.resolveval.practice_id.name,
          formatflag: true,
          type: 'text',
          // type: 'number',
          // validations: [{ rule: 'required', message: 'Parent Laboratory is Required' }],
          disabled: true,
        },


        {
          label: "Choose Location",
          name: 'parent_location',
          type: 'select',
          val: this.parent_locationList,
          value: this.resolveval.parent_location,
          multiple: true,
          // formatflag: true,
          // type: 'number',
          // validations: [{ rule: 'required', message: 'Parent Laboratory is Required' }],
          // disabled: true,
        },


        {
          label: "Taxonomies",
          name: "taxonomy",
          // hint: ',0000',
          type: 'select',
          value: this.resolveval.taxonomy,
          val: this.taxonomyList,
          // value:[2021,2022],
          multiple: true,
          validations: [
            { rule: 'required', message: 'Select Taxonomies is Required' }
          ]
        },

        {
          label: 'NPI',
          // label: 'NPI(Select practice NPI)',
          name: 'npi',
          value: this.resolveval.npi,
          type: 'number',
          validations: [
            { rule: 'required', message: 'NPI is Required' },
          ],
        },
        {
          label: 'Active',
          name: 'status',
          value: 1,
          type: 'hidden',
        },


        {
          label: 'id',
          name: '_id',
          value: this.resolveval._id,
          type: 'hidden',
        },



        // {
        //   label: 'Priority',
        //   name: 'priority',
        //   value: 1,
        //   type: 'hidden',
        //   // validations: [

        //   // ]
        // },
      ]
    }

    setTimeout(() => {

      let myRow = document.querySelector('.addEditForm');
      myRow.classList.add('doctorForm');
    }, 200);



  }


  public cookieValue: any;

  listenFormFieldChange(val) {

    console.log('listenFormFieldChange val+++++', val)

    if (val) {
      if (this.cookieData.user_type == 'practice') {
        if (val.field && val.field.name == "has_decisiondoc_mx_access") {
          if (val.fieldval) {
            this.formfieldrefreshdata = {
              field: "has_decisiondoc_ai_access",
              value: true,
              disabled: true,
            };
          } else {
            this.formfieldrefreshdata = {
              field: "has_decisiondoc_ai_access",
              value: false,
              disabled: false
            };
          }
        }
        if (val.field && val.field.name == "has_decisiondoc_ai_access") {
          if (val.fieldval == false) {
            if (val.fromval && val.fromval.has_decisiondoc_mx_access) {
              this.formfieldrefreshdata = {
                field: "has_decisiondoc_ai_access",
                value: true
              };
              this.matSnackBar.open("MX access includes AI access. So you can't opt-out AI access!!", "", {
                duration: 3000
              });
            } else {
              this.formfieldrefreshdata = {
                field: "has_decisiondoc_ai_access",
                value: false
              };
            }
          }
          console.log("formdata", this.formdata)
        }
      }

      if (val.field && val.field == "fromsubmit") {
        this.addFormLoader = true;
        // get user details from cookie & update local cookie objet
        this.cookieValue = JSON.parse(this.cookieService.get('login_user_details'));

        for (let key in this.cookieValue) {
          for (let name in val.fromval) {
            if (key == name) {
              this.cookieValue[key] = val.fromval[name]
            }
          }
        }

        if (this.resolveval && this.resolveval._id) {
          val.fromval._id = this.resolveval._id
        }
        let dataobj = {
          data: val.fromval
        }
        if (this.cookieData.user_type == 'practice') {
          dataobj.data.parent_laboratory = this.resolveval.parent_laboratory._id;
          if (this.cookieData.info_update !== null && this.cookieData.info_update !== undefined && this.cookieData.info_update == false) {
            dataobj.data.info_update = true;
          }
          if (Object.keys(this.cookieValue.parent_laboratory).length > 0 && this.cookieValue.parent_laboratory._id) {
            this.cookieValue.parent_laboratory = this.cookieValue.parent_laboratory._id;
          }
          dataobj.data.requested_datetime = new Date().getTime();
          console.log("dataobj", dataobj)
        }

        this.apiservice.httpViaPost('users/addUpdateData', dataobj).subscribe((response) => {
          console.log("dataobj", response)
          if (response && response.status == 'success') {
            this.matSnackBar.open(response.message, "Ok", {
              duration: 1000
            });
            // cookie delete of User details
            this.cookieService.delete('login_user_details', '/');
            setTimeout(() => {
              this.addFormLoader = false;
              // cookie set User details after api get success
              this.cookieService.set('login_user_details', JSON.stringify(this.cookieValue));
              this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard`);
            }, 1000);
            if (this.cookieData.user_type == 'practice') {
              if (dataobj.data && (dataobj.data.has_decisiondoc_ai_access || dataobj.data.has_decisiondoc_mx_access)) {
                this.apiservice.httpViaPost('users/emailtodecisiondoc', { _id: dataobj.data._id }).subscribe((emailtodecisiondocresponse) => {
                  console.log("emailtodecisiondocresponse", emailtodecisiondocresponse)
                })
              }
            }
          }

          if (response && response.status == 'error') {
            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }

        })


      }
    }
  }

  listenLiblistingChange(data: any = null) {
    console.log("test listenLiblistingChange", data);

  }


  // locationFind() {
  //   let data: any = {
  //     "user_id": this.cookieData._id
  //   }
  //   this.apiservice.httpViaPost('users/fetchLocationDetails', data).subscribe((response) => {
  //     console.warn("loccc", response)
  //     if (response.status = "success") {
  //       for (const key in response.results.res) {
  //         this.parent_location.push({ val: response.results.res[key]._id, name: response.results.res[key].location_name });
  //       }
  //     }
  //   })
  // }



}





