<app-header *ngIf="(resolveval.landing_page && resolveval.info_update) || (resolveval.landing_page == false || resolveval.landing_page == null || resolveval.landing_page == undefined)"></app-header>
<mat-card class="adminBody">

    <mat-card-content class="addEditPageWrapper accountSettings form_title_style">

        <ng-container *ngIf="addFormLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>


        <h2 class="listingpageh2">My Account </h2>
        <div class="AddEditBlog">
            <lib-showform class="addEditForm" [formdata]="formdata" [formfieldrefresh]="formfieldrefresh" [formfieldrefreshdata]="formfieldrefreshdata" (onFormFieldChange)="listenFormFieldChange($event)" (onLiblistingChange)="listenLiblistingChange($event)">
            </lib-showform>
        </div>
    </mat-card-content>

    <span class="footerBlock"><app-footer></app-footer></span>
</mat-card>