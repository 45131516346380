<button class="close_button">
  <mat-icon style="cursor: pointer;" (click)="onNoClick()"> Cancel</mat-icon>
</button>



<div class="professional_opinionmain">

  <mat-progress-bar mode="indeterminate" *ngIf="progressBarLoader"></mat-progress-bar>

  <h1>Professional Opinion by {{data.data}}</h1>

   
    <div class="possibleDiagnosis previewTabLeftContent diagnosis" [innerHtml]="professionalopinionHTML"></div>
  

</div>