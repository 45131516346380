import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-physician-examination',
  templateUrl: './physician-examination.component.html',
  styleUrls: ['./physician-examination.component.css']
})
export class PhysicianExaminationComponent {
  public physicianExaminationForm: any = null;
  emailregex: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public laboratory_details: any;
  public sealsperson_details: any = null;
  public locationList: any;
  public logoUrl: any = {};
  public progressBar: any = false;
  public editFormData: any = null;
  public addFormLoader: boolean = false;
  public stateList: any = [];
  public paramslink: any;
  public subBtn: any = false;
  public aspinnerLoader: any = true;
  objectKeys = Object.keys;
  public patient_intake_flag: boolean = false;
  public quick_intake_flag: boolean = false;
  public email_intake_flag: boolean = false;
  public intake_sign: any;

  public intakesigninput: any;


  public patientInfoForm: FormGroup;

  // public signlibCon: any = false;

  public errorclass: boolean = false;

  public cookiedata: any = '';


  public resetformcss: boolean = false;


  public datafetchIMG: any = null;


  public intake_submitted_by: any = '';

  public emailparams: any = '';

  public emailIntakeData: any = '';

  public preview_intakesignblock: boolean = false;
  public intakeAuthorize: boolean = false;

  public practiceDetail: any = '';
  public resubmitBtnFlag: boolean = false;
  public emailintakeAlreadyDoneFlag: boolean = false;
  public demoDoctorDashboard: boolean = false;
  public primaryinsurancename: any;
  public secondaryinsurancename: any;
  public formSubmissionLoader: boolean = false;
  public examinationData: any = null;
  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, private apiservice: HttpServiceService, public activatedRoute: ActivatedRoute, public matSnackBar: MatSnackBar, public router: Router, public cookieService: CookieService) {

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookiedata = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }




    console.log('cookiedata', this.cookiedata)


    this.paramslink = this.activatedRoute.snapshot.params
    console.log('activatedRoutelink', this.activatedRoute)


    // if (this.activatedRoute.snapshot.routeConfig.path.includes('patient-folderview/:_id')) {
    //  this.subBtn = true;
    // }

    if (this.activatedRoute.snapshot.routeConfig.path.includes('preview-intake/:_id') || this.activatedRoute.snapshot.routeConfig.path.includes('patient-folderview/:_id')) {
      this.subBtn = true;
      if (this.cookiedata.user_type == 'doctor') {
        this.subBtn = false;
        this.resubmitBtnFlag = true;
      }
    }

    if (this.activatedRoute.snapshot.routeConfig.path.includes('patient-intake')) {
      if (this.cookieService.getAll()['login_user_details']) {
        this.cookiedata = JSON.parse(this.cookieService.getAll()['login_user_details']);
        if (this.cookiedata.user_type !== 'admin' && this.cookiedata.user_type !== 'doctor' && this.cookiedata.user_type !== 'practice' && !window.location.pathname.includes('sales_person/doctor')) {
          this.router.navigateByUrl('404')
        }
      }
      if (window.location.pathname.includes('sales_person/doctor')) {
        this.demoDoctorDashboard = true;
      }
      this.patient_intake_flag = true;
    }

    if (window.location.pathname.includes('sales_person/preview-intake')) {
      this.subBtn = false;
    }



    console.log('activatedRoute+++', this.activatedRoute)
    if (this.activatedRoute.snapshot.routeConfig.path.includes('email-intake/:_id') || this.activatedRoute.snapshot.routeConfig.path.includes('intake-authorization/:_id')) {

      this.preview_intakesignblock = true;

      this.activatedRoute.data.subscribe((response: any) => {
        if (response.data.results.res.length > 0) {
          if (response.data.results.res[0].resend_emai && response.data.results.res[0].resend_emai == 1) {
            this.emailintakeAlreadyDoneFlag = true;
          }

          this.emailIntakeData = response.data.results.res[0]

        }
        console.log('emailIntakeData', this.emailIntakeData)
      });


      if (this.activatedRoute.snapshot.routeConfig.path.includes('intake-authorization/:_id')) {
        this.email_intake_flag = false;
        this.patient_intake_flag = true;
        this.intakeAuthorize = true
      } else {
        this.email_intake_flag = true;
      }


      // if (this.cookiedata.user_type == 'doctor') {

      //   this.practiceLogo();

      // }


    }


    if (this.activatedRoute.snapshot.routeConfig.path.includes('quick-intake/:_id') || window.location.pathname.includes('demo/quick-intake')) {

      this.preview_intakesignblock = true;

      this.quick_intake_flag = true;

      // if (this.cookiedata.user_type == 'doctor') {

      //   this.practiceLogo();

      // }
      console.log("quick-intake here")


    }

    if (window.location.pathname.includes('demo/quick-intake')) {
      this.demoDoctorDashboard = true;
    }

    this.getStates();
  }

  ngOnInit() {

    // console.log("this.activatedRoute", );
    

    this.patientInfoForm = new FormGroup({
      patient_name: new FormControl(''),
      patient_email: new FormControl(''),
      patient_phone: new FormControl(''),
      patient_gender: new FormControl(''),
      patient_address: new FormControl(''),
      state: new FormControl(''),
      city: new FormControl(''),
      zip: new FormControl(''),
      patient_dob: new FormControl(''),
      height: new FormControl(''),
      weight: new FormControl(''),
    })


    console.log('apinnerLoader', this.patient_intake_flag)



    if (this.activatedRoute.snapshot.params && this.activatedRoute.snapshot.params["_id"]) {


      this.activatedRoute.data.subscribe((response: any) => {
        console.log("editdata", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            // this.aspinnerLoader =true;
            this.editFormData = response.data.results.res[0];
            console.log("this.editFormData", this.editFormData)
            this.patientInfoForm.patchValue({
              patient_name: this.editFormData.patient_name,
              patient_email: this.editFormData.patient_email,
              patient_phone: this.editFormData.patient_phone,
              patient_gender: this.editFormData.patient_gender == 'male' ? "Male" : "Female",
              patient_address: this.editFormData.patient_address,
              // state: this.editFormData.state,
              city: this.editFormData.city,
              zip: this.editFormData.zip,
              patient_dob: moment(this.editFormData.patient_dob).format('L'),
              height: this.editFormData.height,
              weight: this.editFormData.weight,
            })
            if (this.editFormData.status > 1) {
              this.getExaminationData(this.editFormData._id);
            } else {
              this.physicianExaminationFormCreate();
            }

            if (typeof this.intake_sign != 'undefined') {
              // this.preview_intakesignblock = true;
              // this.patient_intake_flag = 
            }
            // fetchUserDetails data call for practice detail
            let practicedata: any = {
              searchcondition: {
                _id: this.editFormData.practice_id
              }
            }

            this.apiservice.httpViaPost('users/fetchUserDetails', practicedata).subscribe((response: any) => {
              if (response && response.status == "success") {
                console.log('response++++++', response.results.res[0])
                this.practiceDetail = response.results.res[0]
              }
            })
            // fetchUserDetails data call for practice detail end
            // this.intake_sign = this.editFormData.patient_signature ? this.editFormData.patient_signature : null
          }
        }
      });
    } else {

      this.physicianExaminationFormCreate()
    }

  }

  getExaminationData(patient_id: any) {
    let dataObj: any = {
      searchcondition: {
        patient_id: patient_id
      }
    }
    this.apiservice.httpViaPost('intake/getexamination', dataObj).subscribe((response: any) => {
      console.warn("examinationData", response);
      if (response) {
        console.log("intake/getexamination", response.results.res[0])
        if (response.results && response.results.res && response.results.res.length > 0) {
          this.examinationData = response.results.res[0];
          console.warn("examinationData", this.examinationData);

          this.physicianExaminationFormCreate();
        }
      }
    })
  }

  physicianExaminationFormCreate() {
    console.log("intake/getexamination 2222", this.examinationData, this.subBtn)


    this.physicianExaminationForm =
    {
      successmessage: 'Update Successfully !!',
      submittext: this.subBtn ? '' : 'Submit',
      // submittext: 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'email/addtemplate',
      // redirectpath: "admin/email-template/template-list",
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: true,
      hidecancel: true,
      groups: ["heading", "grp0", 'grp0body', 'grp2', 'grp2body', 'grp4', 'grp4body', 'grp6', 'grp6body', 'grp8', 'grp8body', 'grp10'],
      jwttoken: '',
      // cancelroute: `admin/email-template/template-list`, // use for redirect after cancel the form
      fields: [

        {
          heading: "PATIENT HISTORY QUESTIONNAIRE",
          group: "heading"
        },
        {
          heading: "Neurological",
          group: "grp0"
        },
        {
          label: 'Seizure Disorder (Epilepsy)',
          name: 'seizure_disorder',
          type: 'radio',
          group: "grp0body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "seizure_disorder_notes",
                type: 'textarea',
                group: "grp0body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Seizure Disorder (Epilepsy) Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Dizziness/Fainting',
          name: 'dizziness_or_fainting',
          type: 'radio',
          group: "grp0body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "dizziness_or_fainting_notes",
                type: 'textarea',
                group: "grp0body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Dizziness/Fainting Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Weakness/Paralysis',
          name: 'weakness',
          type: 'radio',
          group: "grp0body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "weakness_notes",
                type: 'textarea',
                group: "grp0body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Weakness/Paralysis Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Swelling of lower extremities',
          name: 'swelling_of_lower_extremities',
          type: 'radio',
          group: "grp0body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "swelling_of_lower_extremities_notes",
                type: 'textarea',
                group: "grp0body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Swelling of lower extremities Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          heading: "Infectious Disease",
          group: "grp2"
        },
        {
          label: 'Tuberculosis',
          name: 'tuberculosis',
          type: 'radio',
          group: "grp2body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "tuberculosis_notes",
                type: 'textarea',
                group: "grp2body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Tuberculosis Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Hepatitis',
          name: 'hepatitis',
          type: 'radio',
          group: "grp2body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "hepatitis_notes",
                type: 'textarea',
                group: "grp2body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Hepatitis Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Mumps',
          name: 'mumps',
          type: 'radio',
          group: "grp2body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "mumps_notes",
                type: 'textarea',
                group: "grp2body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Mumps Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Measles',
          name: 'measles',
          type: 'radio',
          group: "grp2body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "measles_notes",
                type: 'textarea',
                group: "grp2body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Measles Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Syphilis',
          name: 'syphilis',
          type: 'radio',
          group: "grp2body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "syphilis_notes",
                type: 'textarea',
                group: "grp2body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Syphilis Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Gonorrhea',
          name: 'gonorrhea',
          type: 'radio',
          group: "grp2body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "gonorrhea_notes",
                type: 'textarea',
                group: "grp2body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Gonorrhea Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Venereal',
          name: 'venereal',
          type: 'radio',
          group: "grp2body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "venereal_notes",
                type: 'textarea',
                group: "grp2body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Venereal Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          heading: "Drug Use/Abuse",
          group: "grp4"
        },
        {
          label: 'Depressants',
          name: 'depressants',
          type: 'radio',
          group: "grp4body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "depressants_notes",
                type: 'textarea',
                group: "grp4body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Depressants Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Stimulants',
          name: 'stimulants',
          type: 'radio',
          group: "grp4body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "stimulants_notes",
                type: 'textarea',
                group: "grp4body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Stimulants Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Narcotics',
          name: 'narcotics',
          type: 'radio',
          group: "grp4body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "narcotics_notes",
                type: 'textarea',
                group: "grp4body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Narcotics Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Alcohol',
          name: 'alcohol',
          type: 'radio',
          group: "grp4body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "alcohol_notes",
                type: 'textarea',
                group: "grp4body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Alcohol Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          heading: "Congenital or Genetic",
          group: "grp6"
        },
        {
          label: 'Malformations/Deformities',
          name: 'malformations',
          type: 'radio',
          group: "grp6body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "malformations_notes",
                type: 'textarea',
                group: "grp6body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Malformations/Deformities Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Blood Diseases',
          name: 'blood_diseases',
          type: 'radio',
          group: "grp6body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "blood_diseases_notes",
                type: 'textarea',
                group: "grp6body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Blood Diseases Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Multiple Births',
          name: 'multiple_births',
          type: 'radio',
          group: "grp6body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "multiple_births_notes",
                type: 'textarea',
                group: "grp6body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Multiple Births Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          heading: "Chronic Diseases",
          group: "grp8"
        },
        {
          label: 'Neurological Diseases',
          name: 'neurological',
          type: 'radio',
          group: "grp8body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "neurological_notes",
                type: 'textarea',
                group: "grp8body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Neurological Diseases Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Pulmonary (Lung) Diseases',
          name: 'pulmonary',
          type: 'radio',
          group: "grp8body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "pulmonary_notes",
                type: 'textarea',
                group: "grp8body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Pulmonary (Lung) Diseases Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Renal (Kidney) Diseases',
          name: 'renal_kidney',
          type: 'radio',
          group: "grp8body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "renal_kidney_notes",
                type: 'textarea',
                group: "grp8body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Renal (Kidney) Diseases Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Immune',
          name: 'immune',
          type: 'radio',
          group: "grp8body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "immune_notes",
                type: 'textarea',
                group: "grp8body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Immune Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Hypertension',
          name: 'hypertension',
          type: 'radio',
          group: "grp8body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "hypertension_notes",
                type: 'textarea',
                group: "grp8body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Hypertension Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          label: 'Atherosclerosis',
          name: 'atherosclerosis',
          type: 'radio',
          group: "grp8body",
          val: [{ key: true, val: 'Yes' }, { key: false, val: 'No' }],
          value: false,
          validations: [
            { rule: 'required', message: 'This Field is Required' },
          ],
          dependent: [
            {
              condval: false,
              field:
              {
                label: "Notes",
                name: "atherosclerosis_notes",
                type: 'textarea',
                group: "grp8body",
                validations: [
                  { rule: 'required' },
                  { rule: 'min', value: 1 },
                  { rule: 'max', value: 400, message: "Atherosclerosis Notes must be more than 400 " }
                ]
              },
            },
          ]
        },
        {
          heading: "Additional Notes by Physician",
          group: "grp10"
        },
        {
          label: 'Additional Note',
          name: 'additional_notes',
          type: 'textarea',
          group: "grp10",
          val: '',
          value: '',
          validations: [
            // { rule: 'required', message: 'This Field is Required' },
          ],

        },
      ]

    }
    setTimeout(() => {
      if (this.examinationData == null) {
        this.wrapUpWithDiv();
      }
      this.aspinnerLoader = false;
    }, 500);
  }

  wrapUpWithDiv() {
    let grp0 = document.querySelector(".grp0");
    let grp0body = document.querySelector(".grp0body");
    let createGrp0DivElement = document.createElement("div");
    createGrp0DivElement.classList.add("innerDiv");
    createGrp0DivElement.append(grp0, grp0body);
    let grp2 = document.querySelector(".grp2");
    let grp2body = document.querySelector(".grp2body");
    let createGrp2DivElement = document.createElement("div");
    createGrp2DivElement.classList.add("innerDiv");
    createGrp2DivElement.append(grp2, grp2body);
    let grp4 = document.querySelector(".grp4");
    let grp4body = document.querySelector(".grp4body");
    let createGrp4DivElement = document.createElement("div");
    createGrp4DivElement.classList.add("innerDiv");
    createGrp4DivElement.append(grp4, grp4body);
    let grp6 = document.querySelector(".grp6");
    let grp6body = document.querySelector(".grp6body");
    let createGrp6DivElement = document.createElement("div");
    createGrp6DivElement.classList.add("innerDiv");
    createGrp6DivElement.append(grp6, grp6body);
    let grp8 = document.querySelector(".grp8");
    let grp8body = document.querySelector(".grp8body");
    let createGrp8DivElement = document.createElement("div");
    createGrp8DivElement.classList.add("innerDiv");
    createGrp8DivElement.append(grp8, grp8body);
    let grp10 = document.querySelector(".grp10");
    let createGrp10DivElement = document.createElement("div");
    createGrp10DivElement.classList.add("innerDiv");
    createGrp10DivElement.append(grp10);

    let physicianExaminationForm = document.querySelector('.physicianExaminationForm .form');
    physicianExaminationForm.appendChild(createGrp0DivElement);
    physicianExaminationForm.appendChild(createGrp4DivElement);
    physicianExaminationForm.appendChild(createGrp2DivElement);
    physicianExaminationForm.appendChild(createGrp8DivElement);
    physicianExaminationForm.appendChild(createGrp6DivElement);
    physicianExaminationForm.appendChild(createGrp10DivElement);
    console.log("wrapup", physicianExaminationForm)
  }

  getStates() {
    this.apiservice.getHttpData("../../../../../assets/data-set/state.json").subscribe(response => {
      console.log(response, "this.stateList")
      // this.stateList = response;
      if (response && response.length > 0) {
        response.forEach((e, i) => {
          let obj = { val: e.abbreviation, name: e.name };
          // if (this.editFormData && this.editFormData.state) {
          if (e.abbreviation == this.editFormData.state) {
            this.patientInfoForm.patchValue({
              state: e.name
            })
          }
          // }
          this.stateList.push(obj);
        })
      }
      console.log('resolveval', this.stateList)
    })
  }

  listenFormFieldChange(val) {
    console.log("listenFormFieldChange", val)
    if (val) {
      if (val.field == "fromsubmit") {
        this.formSubmissionLoader = true;
        let dataObj: any = val.fromval;
        let labelArr: any = [];
        if (val.formdataval && val.formdataval.fields && val.formdataval.fields.length > 0) {
          val.formdataval.fields.forEach((e: any, i: number) => {
            for (let key in dataObj) {
              if (key == e.name && dataObj[key] == true) {
                labelArr.push(e.label)
              }
            }
          })
        }
        console.log("symptomsss true 1", labelArr)
        dataObj.physician_examination_label = labelArr;
        dataObj.patient_id = this.editFormData._id;
        dataObj.physician_examination = true;
        console.log("dataObj", dataObj)
        if (dataObj.undefined == '') {
          delete dataObj.undefined;
        }
        console.log("dataObj", dataObj)
        this.apiservice.httpViaPost('intake/submitexamination', dataObj).subscribe((response) => {
          if (response) {
            console.log("response==========>>>>", response);
            
              this.pathParametersFunction()
              this.apiservice.httpViaPost('intake/rapidresponsestepinvokefun', {}).subscribe((response) => {

            })

            this.matSnackBar.open("Submitted Successfully", "", {
              duration: 1000
            });
            this.formSubmissionLoader = false;
            if (window.location.pathname.includes('sales_person/preview-intake')) {
              this.router.navigateByUrl(`${this.cookiedata.user_type}/dashboard/doctor`);
            }
            else {
              this.router.navigateByUrl(`${this.cookiedata.user_type}/dashboard`);
            }
          }
        })
      }
    }
  }


  pathParametersFunction(){
    let body = {
      pathParameters: {
        path: "physicianexamdata"
      },
      queryStringParameters: { 
        _id:this.activatedRoute.snapshot.params['_id']       
      }
    }
    this.apiservice.httpViaPost('intake/dockerfunctioninvoke', body).subscribe((response) => {
      console.log("dataobj", response)    
      if (response.status=='sucess') {
        this.ordersheetData()
      }

    })


  }
  ordersheetData(){
    let body = {
      pathParameters: {
        path: "ordersheet-data"
      },
      queryStringParameters: { 
        _id:this.activatedRoute.snapshot.params['_id']       
      }
    }
    this.apiservice.httpViaPost('intake/dockerfunctioninvoke', body).subscribe((response) => {
      console.log("dataobj", response)    

    })
  }
}
