import { Component, Input } from '@angular/core';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-possible-diagnosis',
  templateUrl: './possible-diagnosis.component.html',
  styleUrls: ['./possible-diagnosis.component.css']
})
 
export class PossibleDiagnosisComponent {

  public possibleDiagnosisDataset:any;

 

  @Input() set possibleDiagnosis(value: any) {

    if (value) {
      // console.log("possibleDiagnosis=========>>>>>", value)

      this.possibleDiagnosisDataset=value;

   
    }


  }

  constructor(private httpService: HttpServiceService,){



  }

 

  

}

