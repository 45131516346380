<button class="close_button" style="right: 0;top: 0;">
    <mat-icon style="cursor: pointer;" (click)="closeModal()"> cancel</mat-icon>
</button>

<ng-container *ngIf="progressLoader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>




 
    <div class="addblock" *ngIf="showForm">
    <mat-card class="adminBody">

        <mat-card-content class="addEditPageWrapper practice_form_style form_title_style">

            <!-- <ng-container *ngIf="progressLoader">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container> -->


            <h2 class="listingpageh2">{{isedit?'Edit':'Add'}} Assay Range </h2>
            <div class="AddEditBlog taxonomies_formstyle">
                <div class="addEditForm addEditAssay">
                    <lib-showform [formdata]="practiceformdata" [formfieldrefresh]="formfieldrefresh"
                        [formfieldrefreshdata]="formfieldrefreshdata"
                        (onFormFieldChange)="listenFormFieldChange($event)">
                    </lib-showform>

                    <ng-container *ngIf="progressLoader">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </ng-container>

                </div>
            </div>

        </mat-card-content>

       
    </mat-card>
</div>
 



<mat-card class="adminBody assay_listwrapper Assaylistblock" *ngIf="!showForm">
    <mat-card-content class="Emailtemplatelist listingpage_wrapper list_style1">

        <div class="admin-list_v1 searchcol4">
            <div class="listingtableheading">
                <h2 class="listingpageh2"> Assay Range </h2>
                <div class="listingtableheading_wrapper">
                    <h2> Assay Range List </h2>
                    <!-- <button class="add_btn" (click)="addBtn()" *ngIf="!isdatahas">Add Assay Range</button> -->
                    <button class="add_btn" (click)="addBtn()">Add Assay Range</button>
                </div>
            </div>
            <mat-progress-bar mode="indeterminate" [ngClass]="listprogressBar==true? 'show':'hide' "></mat-progress-bar>
            <div class="taxonomies_liststyle" [ngClass]="listprogressBar==true? 'hide':'show' ">
                <lib-listing class="adminlisttable_v1 " *ngIf="tabledatatalist != null && tabledatatalist.length > 0"
                    [datasource]="tabledatatalist" [skip]="tabledata_header_skip"
                    [modify_header_array]="modify_header_array" [apiurl]="api_url_for_managebanner"
                    [deleteendpoint]="deleteendpoint" [updateendpoint]="updateendpoint"
                    [date_search_source]="date_search_source" [date_search_endpoint]="date_search_endpoint"
                    [sourcedata]="tablename" [editroute]="editroute" [statusarr]="statusarray"
                    [detail_skip_array]="tabledata_detail_skip" [jwttoken]="jwttokenformanagebanner"
                    [search_settings]="search_settings" [searchendpoint]="searchendpoint" [sortdata]="sortdata"
                    [datacollection]="datacollection" [date_search_source_count]="date_search_source_count"
                    [libdata]="libdata" [limitcond]="limitcond" [customlistenbutton]="customlistenbutton"
                    [updatetable]="modal_updatetable" (onLiblistingButtonChange)="onLiblistingButtonChange($event)"
                    (onLiblistingChange)="listenLiblistingChangemodal($event)">
                </lib-listing>
            </div>

            <ng-container *ngIf="tabledatatalist != null && tabledatatalist.length == 0">
                <div class="noFoundTextinner">
                    <span>Oops!<br /> No Record Found</span>
                </div>
            </ng-container>
        </div>
    </mat-card-content>
    <!-- <span class="footerBlock"><app-footer></app-footer></span> -->
</mat-card>