<mat-card class="bottomSheetWrap">
    <ng-container *ngIf="isAdminUser">
        <button mat-button (click)="closeBottomSheet('admin')">Admin</button>
    </ng-container>
    <ng-container *ngIf="!isLaboratoryUser">
        <button mat-button (click)="closeBottomSheet('laboratory')">Laboratory</button>
    </ng-container>
    <ng-container *ngIf="!ispracticeUser">
        <button mat-button (click)="closeBottomSheet('practice')">Practice</button>
    </ng-container>
    <ng-container *ngIf="!isSalespersonUser">
        <button mat-button (click)="closeBottomSheet('sales_person')">Salesperson</button>
    </ng-container>
    <ng-container *ngIf="!isDoctorUser">
        <button mat-button (click)="closeBottomSheet('doctor')">Doctor</button>
    </ng-container>
</mat-card>