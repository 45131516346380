import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-insurance-list',
  templateUrl: './insurance-list.component.html',
  styleUrls: ['./insurance-list.component.css']
})
export class InsuranceListComponent {
  public listprogressBar: boolean = false;
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id'];
  public tabledatatalist: []
  public modalrogressBar: any = false;


  tablename = "equipment";
  editroute = "admin/manage/insurance-edit";
  // updateendpoint = "email/emailslugstatus";
  deleteendpoint = "intake/deleteinsurance";
  datacollection: any = "intake/insurancelist";

  searchendpoint = "intake/insurancelist";
  date_search_endpoint: any = "intake/insurancelist";
  date_search_source: any = "insurance";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {

    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];



  modify_header_array: any = {
    insurance_name: "Name",
    insurance_des: "Description",
    status: "Status",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On"
  };
  search_settings: any = {
    search: [

      {
        label: "Search By Name ", field: '_id',

        serversearchdata: { endpoint: 'intake/insuranceautocomplete' },

      },

    
    ],



    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
      },
      {
        startdatelabel: 'Create On Start Date',
        enddatelabel: 'Create On End Date',
        submit: 'Search',
        field: 'createdon_datetime',
      }
    ],
    selectsearch: [

      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ['insurance_name', "insurance_des",'createdon_datetime', 'updatedon_datetime', 'status'],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
    ],
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
      { key: "insurance_name", val: "Insurance Name" },
      { key: "insurance_des", val: "Description" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "status", val: "Status" },
    ],
    updateendpoint: "intake/insurancestatus",
    hidedeletebutton: false,
    hideviewbutton: true,
    hideeditbutton: false,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "intake/insurancestatus",

    deleteendpointmany: "intake/deleteinsurance",
    tableheaders: ['insurance_name', "insurance_des",'createdon_datetime',  'updatedon_datetime', 'status'],

    custombuttons: [
      // {
      //   label: "Edit",
      //   type: 'listner',
      //   id: 'edit_btn',
      //   tooltip: 'Edit',
      //   name: 'edit',
      //   classname: 'edit_btn'
      // },
      // {
      //   label: "Preview",
      //   type: 'action',
      //   datatype: 'api',
      //   endpoint: 'intake/previewequipment',
      //   otherparam: [],
      //   // cond:'status',
      //   // condval:0,
      //   param: '_id',
      //   datafields: ['insurance_name', "insurance_des", 'createdon_datetime', 'updatedon_datetime', 'status'],
      //   //  refreshdata: true,
      //   headermessage: 'Preview',
      //   tooltip: 'Preview',
      //   classname: 'previewButton'
      // },
    ],

  }
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  public adduserBTN: boolean = false;
  public daignosis_updatetable: boolean = false;
  public chooseDiagnosisLoader: boolean = false;
  constructor(public dialog: MatDialog, private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService, public matSnackBar: MatSnackBar) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
  }

ngOnInit(){
this.progressLoader = true;
    this.userListFetching();
    this.activatedRoute.data.subscribe((response: any) => {
      this.listprogressBar = true
      console.log("activatedRoute responseff", response)
      if (response) {
        // console.log("response=======+++", response);
        
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);
      }
    })
}


  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}
    }
    
    this.httpService.httpViaPost('intake/insurancelist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }
    })
  }
  addInsurance() {
    this.router.navigateByUrl('admin/manage/insurance-add')
  }
}

