<ng-container *ngIf="logoutLoader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>
<mat-card-content class="headerBody">
    <mat-card class="headerIcon">
        <a (click)="dashboardPage()" *ngIf="logo_data_flag && (cookieData.user_type == 'practice' || cookieData.user_type == 'doctor')"><img [src]="logo_data" alt="practice2lab_logo" class="toplogo"></a>
        <!-- <a (click)="dashboardPage()" *ngIf="!logo_data_flag && cookieData.user_type !== 'practice' && cookieData.user_type !== 'doctor'"><img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/practice2lab_logo.png" alt="practice2lab_logo" class="toplogo"></a> -->
        <a (click)="dashboardPage()" *ngIf="!logo_data_flag && cookieData.user_type !== 'practice' && cookieData.user_type !== 'doctor'"><img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/repid_respose_virtual_payment.webp" alt="practice2lab_logo" class="toplogo"></a>
         

       
        <!-- <a (click)="dashboardPage()"><img
                src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/practice2lab_logo.png"
                alt="practice2lab_logo" class="toplogo"></a> -->
    </mat-card>
    <mat-card class="headerMenu">
        <mat-card-content class="headerBlock" [ngClass]="{showData: toggleStatus}">
            <mat-nav-list>
                <a mat-list-item [ngClass]="activemenu=='dashboard'?'active':''" (click)="dashboardPage()"> Dashboard
                </a>
                <!-- <a mat-list-item [ngClass]="[activemenu=='user'?'active': '' , userType == 'sales_person'? 'hide':'', userType == 'doctor'? 'hide':'']" (click)="userListPage()"> Users </a> -->
                <ng-container *ngIf="this.cookieData.user_type == 'doctor' || this.cookieData.user_type == 'practice' || this.cookieData.user_type == 'admin'">
                    <a mat-list-item [ngClass]="activemenu=='partial'?'active':''" (click)="partialLeads()"> Partial
                        Patient </a>
                </ng-container>
                <ng-container *ngIf="this.cookieData.user_type == 'admin'">
                    <a mat-list-item [ngClass]="activemenu=='training'?'active':''" [matMenuTriggerFor]="trainingMenu"> Training</a>
                </ng-container>
                <ng-container *ngIf="this.cookieData.user_type == 'admin'">
                    <a mat-list-item [ngClass]="activemenu=='email'?'active':''" [matMenuTriggerFor]="emailMenu"> Email
                        Template </a>
                </ng-container>
                <ng-container *ngIf="this.cookieData.user_type == 'admin'">
                    <a mat-list-item [ngClass]="activemenu=='taxonomies'?'active':''" (click)="taxonomies()"> Taxonomies
                    </a>
                </ng-container>
                <ng-container *ngIf="this.cookieData.user_type == 'admin'">
                    <a mat-list-item [ngClass]="activemenu=='assay'?'active':'' " [matMenuTriggerFor]="miscellaneous">
                        Tests Management </a>
                </ng-container>
                <ng-container *ngIf="this.cookieData.user_type == 'laboratory'">
                    <a mat-list-item [ngClass]="activemenu=='assay'?'active':'' " (click)="labDiagnosis()"> Available
                        Tests
                    </a>
                </ng-container>

                <!-- <ng-container *ngIf="this.cookieData.user_type == 'doctor'|| this.cookieData.user_type == 'practice' || demoDoctorDashboard">
                    <a mat-list-item [ngClass]="activemenu=='emailintake'?'active':''" [matMenuTriggerFor]="intakeMenu">
                        Intake</a>
                </ng-container> -->

                <ng-container *ngIf="this.cookieData.user_type == 'admin'">
                    <a mat-list-item [ngClass]="activemenu=='insurance'?'active':''" (click)="insurance()"> Insurance
                    </a>
                </ng-container>

                <!-- <ng-container *ngIf="this.cookieData.user_type == 'admin'">
                    <a mat-list-item [ngClass]="activemenu=='insurance'?'active':''" (click)="labReport()">Report </a>
                </ng-container> -->

                <ng-container *ngIf="this.cookieData.user_type == 'admin'">
                    <a mat-list-item [ngClass]="activemenu=='reports'?'active':'' " [matMenuTriggerFor]="reports">
                        Reports </a>
                </ng-container>






            </mat-nav-list>

            <mat-menu #reports="matMenu" class="menuCard">
                <button mat-menu-item (click)="labReport()">Lab Reports</button>

                <button mat-menu-item (click)="aiReport()">Ai Reports</button>

            </mat-menu>

            <mat-menu #intakeMenu="matMenu" class="menuCard">
                <button mat-menu-item (click)="emailintake()">Email Intake</button>

                <button mat-menu-item (click)="patientlintake()">Patient Intake</button>

            </mat-menu>
            <mat-menu #trainingMenu="matMenu" class="menuCard diagnosisMenu">
                <button mat-menu-item (click)="trainingCenter()">Training Center</button>
                <button mat-menu-item (click)="trainingCategory()">Category</button>
                <button mat-menu-item (click)="trainingLesson()">Lesson</button>
                <button mat-menu-item>Quiz</button>
            </mat-menu>

            <mat-menu #emailMenu="matMenu" class="menuCard">
                <button mat-menu-item (click)="emailTemplate()">Email</button>
                <button mat-menu-item (click)="emailSlug()">Email Slug</button>
            </mat-menu>

            <mat-menu #miscellaneous="matMenu" class="menuCard diagnosisMenu">
                <button mat-menu-item (click)="assay()"> Assay</button>
                <!-- <button mat-menu-item (click)="diagnosticCategory()"> Category</button> -->
                <button mat-menu-item (click)="diagnostic()"> Available Tests</button>

                <button mat-menu-item (click)="symptoms()" *ngIf="this.cookieData.user_type == 'admin'">
                    Symptoms</button>
                <button mat-menu-item (click)="mechinery()" *ngIf="this.cookieData.user_type == 'admin'">
                    Equipments</button>

                    <button mat-menu-item (click)="cptCodeRoute()" *ngIf="this.cookieData.user_type == 'admin'"> CPT Code Manage</button>
                    <button mat-menu-item (click)="icdCodeRoute()" *ngIf="this.cookieData.user_type == 'admin'"> ICD Code Manage</button>


                    <button mat-menu-item (click)="evaluation()">Evaluation</button>

                
            </mat-menu>

        </mat-card-content>
    </mat-card>

    <mat-card class="headerAccount">
        <button mat-button [matMenuTriggerFor]="menu"> <mat-icon>account_circle</mat-icon>
            <h2 title="{{fullName}}">{{fullName}}</h2>
        </button>
        <mat-menu #menu="matMenu" class="menu_Panel accountSubMenu">
            <h1>Account Settings</h1>
            <button mat-menu-item (click)="myAccount()">
                <mat-icon>account_circle</mat-icon> Update Profile
            </button>

            <!-- <button mat-menu-item (click)="changeLabLogo()" *ngIf="cookieData.user_type == 'laboratory'">
                <mat-icon>image</mat-icon> Manage Logo
            </button> -->


            <ng-container *ngIf="isLaboratoryUser || ispracticeUser">
                <button mat-menu-item (click)="manageLocation()">
                    <mat-icon>room</mat-icon> Manage Location
                </button>
            </ng-container>


            <!-- <ng-container *ngIf="ispracticeUser">
                <button mat-menu-item (click)="manageLocation()">
                    <mat-icon>room</mat-icon> Manage Location
                </button>
            </ng-container> -->



            <button mat-menu-item (click)="changePassword()">
                <mat-icon>vpn_key</mat-icon> Change Password
            </button>

            <button mat-menu-item (click)="logout()">
                <mat-icon>power_settings_new</mat-icon> Logout
            </button>
        </mat-menu>

        <button mat-flat-button (click)="toggleMenu()" class="togle_menu"><mat-icon>menu</mat-icon></button>
    </mat-card>

</mat-card-content>