
<app-header *ngIf="patient_intake_flag" ></app-header>

<div class="addEditPageWrapper intake_formwrapper">

    <ng-container *ngIf="datafetchIMG">

        <div class="intakeLogo"> <img src="https://{{datafetchIMG}}"> </div>

    </ng-container>

    <div class="addEditForm">
        <ng-container *ngIf="addFormLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>


        <ng-container *ngIf="aspinnerLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>
        
 
  

        <ng-container *ngIf=" objectKeys(intakeForm).length > 5">
            <div class="intakeFormDiv" [ngClass]="aspinnerLoader == false ? '' : 'hide'">
                <div [ngClass]="!subBtn ? 'libsigndiv' : 'libsigndivnone'">

                    <lib-showform  [ngClass]="resetformcss ? 'resetformcss' : ''" [formdata]="intakeForm" [formfieldrefresh]="formfieldrefresh"
                        [formfieldrefreshdata]="formfieldrefreshdata"
                        (onFormFieldChange)="listenFormFieldChange($event)">
                    </lib-showform>






                    <div class="sign_wrapper" *ngIf="!patient_intake_flag && preview_intakesignblock">
                        <h2>PATIENT AUTHORIZATION AND SIGNATURE </h2>
                        <div class="sign_body">
                            <p>I authorize the release of my test results, with all identifiable information removed, to <strong>{{practiceDetail?.practice_name}}</strong> | <strong>{{practiceDetail?.phone}}</strong> |
                                <strong>{{practiceDetail?.email}}</strong> and practice2lab, LLC to be used in aggregating data which allows for improving the effectiveness of testing and treatment. The test results will not have any impact on my
                                care, treatment or my cost of care or treatment.
                            </p>

                            <div class="sign_Formbody">

                                <div *ngIf="subBtn" class="signclass">
                                    <label>Digital Signature</label>

                                    <span>{{intake_sign}}</span>
                                </div>

                                <div class="sign_FormInput" *ngIf="!subBtn">



                                    <form (ngSubmit)="intakesign()" autocomplete="off" name="intakesignForm"
                                        [formGroup]="intakesignForm">

                                        <!-- <mat-label>{{intakesignForm.controls['intake_sign'].value}}</mat-label> -->
                                        <mat-form-field>
                                            <mat-label>Digital Signature</mat-label>
                                            <!-- <input matInput  name="intake_sign"   disabled [ngModel]="intake_sign" required> -->
                                            <input matInput name="intake_sign" formControlName="intake_sign" required>
                                        </mat-form-field>



                                        <mat-error [ngClass]="errorclass == false ? 'errorShow' : 'errorHide'"
                                            *ngIf="!intakesignForm.controls['intake_sign'].valid && intakesignForm.controls['intake_sign'].touched">
                                            <span class="errortext">Signature is Required</span>
                                        </mat-error>

                                    </form>




                                </div>
                                <button mat-raised-button class="signBTN" (click)="signDialog()" *ngIf="!subBtn">Sign
                                    for Permission to Review</button>


                            </div>

                        </div>



                    </div>
                    
                </div>
            </div>
        </ng-container>



        <ng-container *ngIf="addFormLoader">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>



    </div>

</div>


<span class="footerBlock" *ngIf="patient_intake_flag"><app-footer></app-footer></span>