import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Location } from "@angular/common";
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-addedit-sales-person',
  templateUrl: './addedit-sales-person.component.html',
  styleUrls: ['./addedit-sales-person.component.css']
})
export class AddeditSalesPersonComponent {
  public salesPersonFormData: any = {};
  public cookieData: any = {};
  public externaldatavalue: any = [];
  // emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  emailregex: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  phoneregex: RegExp = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;


  public addFormLoader: boolean = false;

  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];
  public stateList: any = [];
  public isedit: boolean = false;

  public editFormData: any = null;

  public isSalesPersonValueUpdate: boolean = false;
  public personList: any = [];
  public otherUserFormFields: any = [];
  public laboratoryUserFormFields: any = [];

  constructor(private location: Location, public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes(`${this.cookieData.user_type}/user-management`)) {
      this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard`);
    }
  }
  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      console.log('call param', this.activateRoute.snapshot.params)
      if (this.activateRoute.snapshot.params && !this.activateRoute.snapshot.params["_id"]) {
        if (this.cookieData.user_type == 'laboratory') {
          this.laboratoryUserInitialForm()
        } else {
          this.otherUserInitialForm();
        }
      }
    }
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
            if (this.cookieData.user_type == 'laboratory') {
              this.laboratoryUserInitialForm()
            } else {
              this.otherUserInitialForm();
            }
            // let tempArr: any = [];
            //     for (let i in this.addLocationForm.value) {
            //       let tempObj = { key: i, label: this.humanize(i), val: this.addLocationForm.value[i] };
            //       tempArr.push(tempObj)
            //     }

          }
        }
      });
    }


    this.getStates();
    this.initailForm();
  }

  getStates() {
    this.apiservice.getHttpData("../../../../../assets/data-set/state.json").subscribe(response => {
      console.log(response, "this.stateList")
      // this.stateList = response;
      if (response && response.length > 0) {
        response.forEach((e, i) => {
          let obj = { val: e.abbreviation, name: e.name };
          this.stateList.push(obj)
        })
      }
      console.log('resolveval', this.stateList)
    })
  }

  otherUserInitialForm() {
    this.otherUserFormFields = [
      {
        label: 'First Name',
        name: 'firstname',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.firstname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'First Name is Required' },

        ],
      },
      {
        label: 'Last Name',
        name: 'lastname',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.lastname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'Last Name is Required' },

        ],
      },
      {
        label: 'Email',
        name: 'email',
        type: 'email',
        disabled: this.editFormData ? true : false,
        hint: '',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.email : '',
        validations: [
          { rule: 'required', message: 'Email is required' },
          {
            rule: 'pattern',
            value: this.emailregex,
            message: 'Must be a valid Email',
          },
        ],
      },
      {
        label: 'Phone',
        name: 'phone',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.phone : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Phone is Required' }],
      },
      {
        label: 'Fax',
        name: 'fax',
        value: this.editFormData && this.editFormData.fax ? this.editFormData.fax : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Fax is Required' }],
      },
      {
        label: "Parent Laboratory",
        name: "parent_laboratory",
        // hint: 'In months',
        type: 'autocomplete',
        // multiple: false,
        endpoint: "users/laboratoryAutocomplete",
        search_field: "name_search",
        val: this.editFormData && Object.keys(this.editFormData).length > 0 ? [{ key: this.editFormData.parent_laboratory.key, val: this.editFormData.parent_laboratory.val }] : [],
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.parent_laboratory._id : [],
        validations: [
          { rule: 'required', message: 'Parent Laboratory is Required' }
        ]
      },
      {
        label: 'Address',
        name: 'address',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.address : '',
        type: 'textarea',
        validations: [
          { rule: 'required', message: 'Address is Required' },
        ],
      },

      {
        label: 'State',
        name: 'state',
        val: this.stateList,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.state : '',
        type: 'select',
        validations: [{ message: 'State is Required' }],
      },

      {
        label: 'City',
        name: 'city',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.city : '',
        type: 'text',
        validations: [{ rule: 'required', message: 'City is Required' }],
      },

      {
        label: 'Zip',
        name: 'zip',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.zip : '',
        type: 'number',
        validations: [
          { rule: 'required', message: 'Zip is Required' },
          {
            rule: 'maxLength',
            value: 5,
            message: 'Please enter the valid Zip ID',
          },
        ],
      },

      {
        label: 'Password',
        name: 'password',
        passwordflag: true,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.password : undefined,
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Password is Required' }, { rule: 'pattern', value: this.passwordregex, message: "Must contain a Capital Letter and a Number" }],
      },
      {
        label: 'Confirm Password',
        name: 'confirmpassword',
        passwordflag: true,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.password : undefined,
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Confirm Password is Required' }],
      },
      {
        label: 'Active',
        name: 'status',
        type: 'checkbox',
        val: this.statusarr,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.status : 0,
        validations: [
          // { rule: 'required', message: 'Required Type' },
        ]
      },

      {
        label: 'User Type',
        name: 'user_type',
        value: "sales_person",
        type: 'hidden'
      },

    ]
  }
  laboratoryUserInitialForm() {
    this.laboratoryUserFormFields = [
      {
        label: 'First Name',
        name: 'firstname',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.firstname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'First Name is Required' },

        ],
      },
      {
        label: 'Last Name',
        name: 'lastname',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.lastname : '',
        type: 'text',
        validations: [
          { rule: 'required', message: 'Last Name is Required' },

        ],
      },
      {
        label: 'Email',
        name: 'email',
        type: 'email',
        disabled: this.editFormData ? true : false,
        hint: '',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.email : '',
        validations: [
          { rule: 'required', message: 'Email is required' },
          {
            rule: 'pattern',
            value: this.emailregex,
            message: 'Must be a valid Email',
          },
        ],
      },
      {
        label: 'Phone',
        name: 'phone',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.phone : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Phone is Required' }],
      },
      {
        label: 'Fax',
        name: 'fax',
        value: this.editFormData && this.editFormData.fax ? this.editFormData.fax : '',
        formatflag: true,
        type: 'numberformat',
        // type: 'number',
        validations: [{ rule: 'required', message: 'Fax is Required' }],
      },
      {
        label: 'Address',
        name: 'address',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.address : '',
        type: 'textarea',
        validations: [
          { rule: 'required', message: 'Address is Required' },
        ],
      },

      {
        label: 'State',
        name: 'state',
        val: this.stateList,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.state : '',
        type: 'select',
        validations: [{ message: 'State is Required' }],
      },

      {
        label: 'City',
        name: 'city',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.city : '',
        type: 'text',
        validations: [{ rule: 'required', message: 'City is Required' }],
      },

      {
        label: 'Zip',
        name: 'zip',
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.zip : '',
        type: 'number',
        validations: [
          { rule: 'required', message: 'Zip is Required' },
          {
            rule: 'maxLength',
            value: 5,
            message: 'Please enter the valid Zip ID',
          },
        ],
      },

      {
        label: 'Password',
        name: 'password',
        passwordflag: true,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.password : undefined,
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Password is Required' }, { rule: 'pattern', value: this.passwordregex, message: "Must contain a Capital Letter and a Number" }],
      },
      {
        label: 'Confirm Password',
        name: 'confirmpassword',
        passwordflag: true,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.password : undefined,
        type: this.editFormData ? 'hidden' : 'password',
        validations: [{ rule: 'required', message: 'Confirm Password is Required' }],
      },
      {
        label: 'Active',
        name: 'status',
        type: 'checkbox',
        val: this.statusarr,
        value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.status : 0,
        validations: [
          // { rule: 'required', message: 'Required Type' },
        ]
      },

      {
        label: 'User Type',
        name: 'user_type',
        value: "sales_person",
        type: 'hidden'
      },
      {
        label: 'Parent Laboratory',
        name: "parent_laboratory",
        value: this.cookieData._id,
        type: 'hidden'
      }
    ]
  }

  initailForm() {
    this.salesPersonFormData =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      // cancelroute: `${this.cookieData.user_type}/user-management/users`, // use for redirect after cancel the form
      fields: this.cookieData.user_type == 'laboratory' ? this.laboratoryUserFormFields : this.otherUserFormFields
      // [
      //         {
      //           label: 'First Name',
      //           name: 'firstname',
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.firstname : '',
      //           type: 'text',
      //           validations: [
      //             { rule: 'required', message: 'First Name is Required' },

      //           ],
      //         },
      //         {
      //           label: 'Last Name',
      //           name: 'lastname',
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.lastname : '',
      //           type: 'text',
      //           validations: [
      //             { rule: 'required', message: 'Last Name is Required' },

      //           ],
      //         },
      //         {
      //           label: 'Email',
      //           name: 'email',
      //           type: 'email',
      //           disabled: this.editFormData ? true : false,
      //           hint: '',
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.email : '',
      //           validations: [
      //             { rule: 'required', message: 'Email is required' },
      //             {
      //               rule: 'pattern',
      //               value: this.emailregex,
      //               message: 'Must be a valid Email',
      //             },
      //           ],
      //         },
      //         {
      //           label: 'Phone',
      //           name: 'phone',
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.phone : '',
      //           formatflag: true,
      //           type: 'numberformat',
      //           // type: 'number',
      //           validations: [{ rule: 'required', message: 'Phone is Required' }],
      //         },
      //         {
      //           label: 'Fax',
      //           name: 'fax',
      //           value: this.editFormData && this.editFormData.fax ? this.editFormData.fax : '',
      //           formatflag: true,
      //           type: 'numberformat',
      //           // type: 'number',
      //           validations: [{ rule: 'required', message: 'Fax is Required' }],
      //         },
      //         {
      //           label: "Parent Laboratory",
      //           name: "parent_laboratory",
      //           // hint: 'In months',
      //           type: 'autocomplete',
      //           // multiple: false,
      //           endpoint: "users/laboratoryAutocomplete",
      //           search_field: "name_search",
      //           val: this.editFormData && Object.keys(this.editFormData).length > 0 ? [{ key: this.editFormData.parent_laboratory.key, val: this.editFormData.parent_laboratory.val }] : [],
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.parent_laboratory._id : [],
      //           validations: [
      //             { rule: 'required', message: 'Parent Laboratory is Required' }
      //           ]
      //         },
      //         {
      //           label: 'Address',
      //           name: 'address',
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.address : '',
      //           type: 'textarea',
      //           validations: [
      //             { rule: 'required', message: 'Address is Required' },
      //           ],
      //         },

      //         {
      //           label: 'State',
      //           name: 'state',
      //           val: this.stateList,
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.state : '',
      //           type: 'select',
      //           validations: [{ message: 'State is Required' }],
      //         },

      //         {
      //           label: 'City',
      //           name: 'city',
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.city : '',
      //           type: 'text',
      //           validations: [{ rule: 'required', message: 'City is Required' }],
      //         },

      //         {
      //           label: 'Zip',
      //           name: 'zip',
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.zip : '',
      //           type: 'number',
      //           validations: [
      //             { rule: 'required', message: 'Zip is Required' },
      //             {
      //               rule: 'maxLength',
      //               value: 5,
      //               message: 'Please enter the valid Zip ID',
      //             },
      //           ],
      //         },

      //         {
      //           label: 'Password',
      //           name: 'password',
      //           passwordflag: true,
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.password : undefined,
      //           type: this.editFormData ? 'hidden' : 'password',
      //           validations: [{ rule: 'required', message: 'Password is Required' }],
      //         },
      //         {
      //           label: 'Confirm Password',
      //           name: 'confirmpassword',
      //           passwordflag: true,
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.password : undefined,
      //           type: this.editFormData ? 'hidden' : 'password',
      //           validations: [{ rule: 'required', message: 'Confirm Password is Required' }],
      //         },
      //         {
      //           label: 'Active',
      //           name: 'status',
      //           type: 'checkbox',
      //           val: this.statusarr,
      //           value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.status : 0,
      //           validations: [
      //             // { rule: 'required', message: 'Required Type' },
      //           ]
      //         },

      //         {
      //           label: 'User Type',
      //           name: 'user_type',
      //           value: "sales_person",
      //           type: 'hidden'
      //         },

      //       ]
    };

    console.log("this.salesPersonFormData", this.salesPersonFormData)
    if (this.editFormData) {
      console.log("sdfsd")
      this.formfieldrefreshdata = {
        field: "removefromcontrol",
        value: ["password", "confirmpassword"],
      };
    }
  }

  formatPhoneText(value) {
    value = value.trim().replaceAll("-", "");
    value = value.trim().replaceAll("(", "");
    value = value.trim().replaceAll(")", "");
    value = value.trim().replaceAll(" ", "");

    if (value.length > 3 && value.length <= 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3);
    else if (value.length > 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3, 6) + "-" + value.slice(6);

    return value;
  }


  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChange++++", val);

    if (val.field && val.field == "formcancel") {
      this.location.back();

    }
    if (val) {
      if (val.field && val.field == "fromsubmit") {
        this.addFormLoader = true;
        if (this.isedit) {
          val.fromval._id = this.editFormData._id;
        }
        let dataobj = {
          data: val.fromval
        }
        if (dataobj.data.phone.length > 14) {
          dataobj.data.phone = dataobj.data.phone.substring(0, 14)
        }
        if (dataobj.data.fax.length > 14) {
          dataobj.data.fax = dataobj.data.fax.substring(0, 14)
        }
        dataobj.data.added_by = this.cookieData._id;

        this.apiservice.httpViaPost('users/addUpdateData', dataobj).subscribe((response) => {
          console.log("dataobj", response)
          if (response && response.status == 'success') {
            this.matSnackBar.open(response.message, "Ok", {
              duration: 1000
            });
            this.location.back();
            // this.router.navigateByUrl(`${this.cookieData.user_type}/user-management/users`);

            this.addFormLoader = false;
          }

          if (response && response.status == 'error') {
            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }


        })


      }

    }
  }

  listenLiblistingChange(data: any = null) {
    console.log("test", data);

  }

}
