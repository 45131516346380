<ng-container *ngIf="patientID == null">
    <ng-container *ngIf="confirmType">
        <button class="close_button" style="right: 0;top: 0;">
            <mat-icon style="cursor: pointer;" (click)="modalClose()"> cancel</mat-icon>
        </button>

        <h2 class="alerth2">
            <!-- <mat-icon>report_problem</mat-icon>&nbsp;&nbsp; -->
            Please verify the patient details prior to uploading the clinical report
        </h2>
        <p>Note: Once the clinical report is uploaded, the patient record will automatically marked as completed and will move to the {{cookieData.user_type == 'laboratory'?'"Lab Test Results Sent"':'"Test Results Received"'}} tab under "Order Summary".</p>

        <div class="modal-button-wrapper">
            <button mat-button (click)="confirmdata()">Yes</button>
            <button mat-button (click)="modalClose()">No</button>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="approveProgressBar"></mat-progress-bar>
    </ng-container>
    <ng-container *ngIf="!confirmType && !reUploadFlag">
        <button class="close_button" style="right: 0;top: 0;">
            <mat-icon style="cursor: pointer;" (click)="modalClose()"> cancel</mat-icon>
        </button>

        <h2 class="alerth2">
            <mat-icon>report_problem</mat-icon>&nbsp;&nbsp;Alert
        </h2>
        <p>Are you sure you want to change the status of this record ?</p>

        <div class="modal-button-wrapper">
            <button mat-button (click)="confirmdata()">Confirm</button>
            <button mat-button (click)="modalClose()">Cancel</button>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="approveProgressBar"></mat-progress-bar>
    </ng-container>
</ng-container>
<ng-container *ngIf="patientID">

    <ng-container *ngIf="!pdfDownloadLoader">
        <button class="close_button" style="right: 0;top: 0;">
            <mat-icon style="cursor: pointer;" (click)="modalClose()"> cancel</mat-icon>
        </button>


        <h2 class="alerth2">
            Confirmation
        </h2>

        <p *ngIf="!pdfDownloadLoader">Are you sure you want to download this record?</p>

        <div class="modal-button-wrapper">
            <button mat-button (click)="handleGeneratePdf()">Download</button>
            <button mat-button (click)="modalClose()">Cancel</button>
        </div>
    </ng-container>

    <div class="clinical_report_download_modal" *ngIf="pdfDownloadLoader">
        <div class="order_progress order_progress-striped">
            <div class="order_progress-bar">
            </div>
        </div>

        <label class="pleasewaittext">Please Wait.</label>
    </div>
</ng-container>


<ng-container *ngIf="reUploadFlag">
    <button class="close_button" style="right: 0;top: 0;">
        <mat-icon style="cursor: pointer;" (click)="modalClose()"> cancel</mat-icon>
    </button>

    <h2 class="alerth2">
        <mat-icon>report_problem</mat-icon>&nbsp;&nbsp;Alert
    </h2>
    <p>Are you sure you want to change the test reports ?</p>

    <div class="modal-button-wrapper">
        <button mat-button (click)="reupload()">Re-upload</button>
        <button mat-button (click)="editReports()">Edit Files</button>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="approveProgressBar"></mat-progress-bar>
</ng-container>


<!-- <a hidden #myDiv id="tutor-price" href="{{reportLink}}"></a> -->
<div class="pdf_block" ref={reportTemplateRef} id='template1'>
    <app-intake-pdf-sheet [patientid]="patientID"></app-intake-pdf-sheet>
</div>