import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-lab-order-sheet',
  templateUrl: './lab-order-sheet.component.html',
  styleUrls: ['./lab-order-sheet.component.css']
})
export class LabOrderSheetComponent {
  public chakeboxdataID: any = []

  public order_sheet_valu: any

  public paramID: any

  public order_sheet_sign_value: boolean = false;

  public ordersheetloderflag: boolean = false;

  public addFormLoader: boolean = true;

  public isPatientFolderView: boolean = false;
  public fororderreject: boolean = false;

  @Input()
  set ordersheetdata(value: any) {

    // console.log("inputupdates", value);
    if (value) {
      const newValue = value.filter((obj: any, index: number) => value.findIndex((item: any) => item.diagnosis_id === obj.diagnosis_id) === index);
      console.log("inputupdates newValue response0000", newValue)
      this.order_sheet_valu = newValue;
      if (this.order_sheet_valu.length > 0) {
        this.order_sheet_valu.forEach((e: any) => {
          e.selected = false;
          if (e.status == 1) {
            e.selected = true;
            this.chakeboxdataID.push(e._id);
          }
        })
      }
      setTimeout(() => {
        this.dataFetching();
      }, 4000)
    }

  }


  @Input()
  set ordersheetSign(value: any) {
    console.log("inputupdatessign", value);
    if (value) {
      this.order_sheet_sign_value = value;
    }
  }

  @Input()
  set orderstatusreject(value: any) {
    console.log("fororderreject", value);
    if (value) {
      this.fororderreject = value;
    }
  }

  public cookieData: any = null;



  constructor(public dialog: MatDialog, public activatedRoute: ActivatedRoute, private httpService: HttpServiceService, public cookieService: CookieService) {
    this.paramID = this.activatedRoute.snapshot.params['_id'];
    console.log('activatedRoutelinktest++++', this.paramID)
  }
  // public name: string = 'Order sheet'
  // loadingPercent = 0;
  // isStart = false;
  // res: Observable<null | string> = of(null);
  // intervalId = {} as any;
  // constructor() { }


  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      console.log("this.cookieData", this.cookieData)
      if (this.cookieData.user_type !== 'doctor' && this.cookieData.user_type !== 'sales_person') {
        this.isPatientFolderView = true;
      }
    }
    if (this.activatedRoute.snapshot.routeConfig.path.includes('patient-folderview/:_id')) {
      this.isPatientFolderView = true;
      if (this.fororderreject) {
        this.isPatientFolderView = false;

      }
      // setTimeout(() => {
      //   let chakeboxclass = document.querySelector('.orderAllConSubTop');
      //   chakeboxclass.classList.add('chakeboxremove')
      // }, 4000);

    }
    // this.startLoading()
    // setTimeout(() => {
    //   this.addFormLoader=false
    //  }, 4000);

  }


  // showOptions(chakeboxdata: any, item: any) {
  //   console.warn('chakeboxdata>>>0', chakeboxdata.checked, 'item', item);

  //   if (chakeboxdata.checked) {
  //     item.selected = true;
  //     const dialogRef = this.dialog.open(SymptomReasonModal, {
  //       panelClass: 'signPageDialog',
  //       data: item
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //       console.log('The dialog was closed');
  //       // this.animal = result;
  //       if (result) {
  //         this.chakeboxdataID.push(item._id);
  //         this.ordersheetpage();
  //       } else {
  //         item.selected = false;
  //       }
  //     });
  //   } else {
  //     const dialogalert = this.dialog.open(alertModal, {
  //       panelClass: 'signPageDialog',
  //       data: 'uncheckedSymptom'
  //     });
  //     dialogalert.afterClosed().subscribe(result => {
  //       console.log('The dialog was closed', result);
  //       // this.animal = result;
  //       if (result) {
  //         if (result == 'yes') {
  //           item.selected = false;
  //           this.chakeboxdataID = this.chakeboxdataID.filter((e: any) => {
  //             return e !== item._id
  //           })
  //         } else {
  //           item.selected = true;
  //           chakeboxdata.checked = true;
  //         }
  //       } else {
  //         item.selected = true;
  //         chakeboxdata.checked = true;
  //       }
  //     })

  //     // this.chakeboxdataID.push(item._id)
  //   }

  //   console.log('chakeboxdataID', this.chakeboxdataID)

  //   this.relateddiagnosis(item.related_diagnosis, chakeboxdata.checked);

  // }
  // veiwDoctorNotes(item: any) {
  //   const dialogRef = this.dialog.open(SymptomReasonModal, {
  //     panelClass: 'signPageDialog',
  //     data: item
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     if (result) {
  //       this.ordersheetpage();
  //     }

  //   });
  // }

  relateddiagnosis(val: any, checkevalu: any) {
    let relateddiagnosisName = document.querySelectorAll(".orderAllConSubbody");
    console.log('relateddiagnosisName', checkevalu)

    relateddiagnosisName.forEach((spandiagnosis) => {
      let diagnosisclass = spandiagnosis.id.replace("diagnostic_", "");

      console.log(val, 'diagnosisclass', diagnosisclass)

      for (const key in val) {
        if (val[key] == diagnosisclass) {
          if (checkevalu) {
            spandiagnosis.classList.add("disibaleechake");
          }
          if (!checkevalu) {
            spandiagnosis.classList.remove("disibaleechake");
          }
        }
      }


    })
  }



  dataFetching() {
    // setTimeout(() => {
    console.log('order_sheet_sign_value', this.order_sheet_sign_value)

    if (this.order_sheet_valu.length == 0) {
      this.order_sheet_sign_value = false

    }

    let currentpractise = null
    let i = 0;

    // console.log('order_sheet_valu', this.order_sheet_valu)
    let allcategoryName = document.querySelectorAll(".orderAllConh2");

    // console.log('allcategoryName', allcategoryName)

    allcategoryName.forEach((spanElement) => {

      let phead = spanElement.id.replace("diagnostic_cat_", "");
      // console.log('phead', phead)
      if (currentpractise == null || currentpractise != phead) {
        // console.log('>>>>>>');
        spanElement.classList.remove("hidecon");
      }
      // else{
      //   console.log('>>>>>>',currentpractise);

      // }
      // else console.log("no header", phead, currentpractise, i);
      currentpractise = phead;
      i++;


    })

    this.addFormLoader = false

    // }, 1500);
  }

  // startLoading() {
  //   this.isStart = true;
  //   this.intervalId = setInterval(() => {
  //     if (this.loadingPercent < 100) {
  //       this.loadingPercent += 1;
  //     }
  //   }, 100);
  // }
  // progressInLoading() {
  //   if (this.loadingPercent === 100) {
  //     clearInterval(this.intervalId);
  //     // this.res = of("Item Loaded");
  //   }
  //   console.log('Loading: ' + this.loadingPercent + '% completed.');
  // }


  // opensignDialog(): void {
  //   console.log('lengthdataID', this.chakeboxdataID)
  //   if (this.chakeboxdataID != '') {
  //     const dialogRef = this.dialog.open(SignModal, {
  //       data: { idsData: this.chakeboxdataID, paramIDcon: this.paramID, isRejectOrder: this.fororderreject },
  //       panelClass: 'signPageDialog'
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //       console.log('The dialog was closed');
  //       // this.animal = result;
  //     });
  //   }
  //   else {
  //     const dialogalert = this.dialog.open(alertModal, {
  //       panelClass: 'signPageDialog'
  //     });
  //   }
  // }

  ordersheetpage() {
    let data: any = {
      searchcondition: {
        patient_id: this.paramID,
        is_trash: { $exists: false }
      }
    }
    if (this.activatedRoute.snapshot.routeConfig.path.includes('patient-folderview/:_id')) {
      data.searchcondition.status = 1
    }
    if (this.fororderreject) {
      data.searchcondition.status = { $in: [1, 0] };
    }
    this.httpService.httpViaPost('intake/ordersheet', data).subscribe((response: any) => {
      if (response && response.status == 'success') {
        // console.log('response0000', response.results.res)
        const newValue = response.results.res.filter((obj: any, index: number) => response.results.res.findIndex((item: any) => item.diagnosis_id === obj.diagnosis_id) === index);
        console.log('response0000', newValue)
        this.order_sheet_valu = newValue;
        if (this.chakeboxdataID.length > 0 && this.order_sheet_valu.length > 0) {
          this.order_sheet_valu.forEach((e: any, i: number) => {
            this.chakeboxdataID.forEach((f: any) => {
              if (e._id == f) {
                e.selected = true;
              }
              if (e.status == 1) {
                e.selected = true;
              }
            })
          })
        }
        this.addFormLoader = false;
        setTimeout(() => {
          this.dataFetching();
        }, 100)
      }
    })
  }





}





// @Component({
//   selector: 'SignModal',
//   templateUrl: 'SignPage.html',
// })
// export class SignModal {

//   public signvalu: any = '';
//   public addsignForm: FormGroup;
//   public modaldata: any = {};

//   public cookieData: any = {};


//   public addsignLoader: boolean = false;


//   constructor(
//     public dialogRef: MatDialogRef<SignModal>,
//     @Inject(MAT_DIALOG_DATA) public data: any, private apiservice: HttpServiceService, public activatedRoute: ActivatedRoute, public snackbar: MatSnackBar, public router: Router, public cookieService: CookieService
//   ) {

//     this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);

//     // console.log('cookieData+++', this.cookieData)

//   }

//   myFunction(event) {

//     // var div = document.getElementById('divID');
//     console.log('event', event.target.value)

//     this.signvalu = event.target.value

//     console.log('signvalu', this.signvalu)

//   }


//   ngOnInit() {
//     console.log("SignModal data", this.data)
//     this.addsignForm = new FormGroup({
//       signinput: new FormControl('', Validators.required),
//     });

//   }

//   addsign() {
//     this.addsignForm.markAllAsTouched()
//     if (this.addsignForm.valid) {
//       if (this.modaldata.item) {
//         this.addsignForm.patchValue({
//           doctor_signature: this.modaldata.item.signinput,
//         });
//       }
//       // this.dialogRef.close(this.addsignForm.value);

//       if (this.cookieData.user_type == 'sales_person') {
//         this.dialogRef.close();
//         this.snackbar.open("This is for Sales Person Demo purpose!", "", {
//           duration: 2000
//         });
//         setTimeout(() => {
//           this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard`);
//         }, 2000);
//       } else {

//         this.addsignLoader = true;
//         let dataset: any = {
//           data: {
//             ids: this.data.idsData,
//             status: 1
//           }
//         }

//         let datasetchange: any = {
//           _id: this.data.paramIDcon,
//           status: 3,
//           doctor_signature: this.signvalu
//         }

//         if (this.cookieData.user_type == 'doctor') {
//           datasetchange.doctor_id = this.cookieData._id;
//         }

//         if (this.data.isRejectOrder) {
//           dataset.data.patient_id = this.data.paramIDcon;
//           this.apiservice.httpViaPost('intake/reorderordersheet', dataset).subscribe((response: any) => {
//             if (response) {
//               this.addsignLoader = false;
//               this.snackbar.open(response.message, "", {
//                 duration: 1000
//               });
//               if (response.status == 'success') {
//                 this.dialogRef.close();
//                 this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard`);
//               }
//             }
//           })
//         } else {
//           this.apiservice.httpViaPost('intake/acceptedordersheet', dataset).subscribe((response: any) => {
//             if (response && response.status == 'success') {
//               this.apiservice.httpViaPost('intake/leadstatuschange', datasetchange).subscribe((res: any) => {
//                 if (res && res.status == 'success') {
//                   this.addsignLoader = false;
//                   this.dialogRef.close();
//                   this.snackbar.open('Order Sheet Sign Successfully!', "", {
//                     duration: 1000
//                   });
//                   this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard`);
//                 }
//               })
//             }
//           })
//         }
//       }
//     }
//   }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }
// }



// @Component({
//   selector: 'alertModal',
//   templateUrl: 'alertModal.html',
// })
// export class alertModal {
//   public isUncheckedSymptom: boolean = false;
//   constructor(
//     public dialogalert: MatDialogRef<alertModal>,
//     @Inject(MAT_DIALOG_DATA) public data: any,
//   ) { }
//   ngOnInit() {
//     console.log("alertModal data", this.data);
//     if (this.data) {
//       if (this.data == 'uncheckedSymptom') {
//         this.isUncheckedSymptom = true;
//       }
//     }
//   }
//   symptomRemove(type: string) {
//     this.dialogalert.close(type);
//   }

//   onNoClick(): void {
//     this.dialogalert.close();
//   }
// }

