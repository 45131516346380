import { Component, Input } from '@angular/core';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-potential-diagnosis',
  templateUrl: './potential-diagnosis.component.html',
  styleUrls: ['./potential-diagnosis.component.css']
})
 
export class PotentialDiagnosisComponent {

  public potentialdiagnosisData:any;

  

 

  @Input() set potentialdiagnosis(value: any) {

    if (value) {
      console.log("potentialdiagnosisDataInput>>>>>>>>>>>>", value)

      this.potentialdiagnosisData=value.all_icd_codes;

      console.log("potentialdiagnosisData+++++++", this.potentialdiagnosisData)

      
 
    }


  }

  constructor(private httpService: HttpServiceService,){



  }

 

  

}
