import { Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription, debounceTime, throttleTime, fromEvent, distinctUntilChanged, Subject } from 'rxjs';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';


import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type } from 'os';
import { ResolveService } from 'src/app/service/resolve.service';
import * as html2pdf from "html2pdf.js";
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { response } from 'express';

// import 'rxjs/add/operator/debounceTime';
// import 'rxjs/add/operator/throttleTime';
// import 'rxjs/add/observable/fromEvent';
export interface DialogData {
  data: any;
  flag: any;
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent {
  public usertype_selected: string = '';
  public progressLoader: boolean = false;
  public UsersSummaryLoader: boolean = false;
  public datasource: any;
  public tabledatalist: any = [];
  public laboratoryData: any = null;
  public searchModalData: any = null;
  public firstBlockData: any = '';
  public modalFlag: any;
  public testdone_txt: any = 'Test Results Received';


  public usertypecount: any = {};
  public labDemoUsertypecount: any = {};
  public UniqueLIstData: any = [];

  public signupLink: any;
  public signupLinkpac: any;
  public btnflag: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  tabledata_header_skip: any = ["_id"]; // use for Table Header Skip
  tabledata_detail_skip: any = ['_id', 'status', 'login_counts', 'usertype', 'logo']; // view details data skip
  tablename = "users";
  editroute = "";
  updateendpoint = "users/statusChange";
  deleteendpoint = "users/deleteUsers";
  datacollection: any = "users/fetchUsers";
  searchendpoint = "";
  date_search_endpoint: any = "tert";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  tableheader: any = [];
  usertype: any = "";

  public dashboard_updatetable: boolean = false;
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  userTypeArray: any = [
    { val: 'admin', name: "Admin" },
    { val: 'doctor', name: "Doctor" },
    { val: 'laboratory', name: "Laboratory" },
    { val: 'sales_person', name: "Salesperson" },
  ]

  modify_header_array: any = {

    fullname: "Full Name",
    phone: "Phone",
    email: "Email",
    fax: "Fax",
    usertype: "Type",
    status: "Status",
    // createdon_datetime: "Created On",
    updatedon_datetime: "Updated On",
    parent_laboratory_name: "Laboratory",
    practicename: "Practice"
  };



  search_settings: any = {
    // textsearch: [
    //   { label: "Search By Name", field: 'fullname' },
    //   { label: "Search By Email", field: 'email' },

    // ],
    search: [{
      label: "Search By Name", field: 'fullname',
      // values: this.authval,
      // serversearchdata: { endpoint: 'users/userNameAutocomplete' },
      // value: [{ val: 'jessica', name: 'jessica' }]
    },
    {
      label: "Search By Email ", field: 'email',
      // values: this.authval,
      // serversearchdata: { endpoint: 'users/userEmailAutocomplete' },
      // value: [{ val: 'jessica', name: 'jessica' }]
    },
    ],



    // datesearch: [
    // {
    //   startdatelabel: 'Create On Start Date',
    //   enddatelabel: 'Create On End Date',
    //   submit: 'Search',
    //   field: 'createdon_datetime',
    //   // value: {}
    // },


    // {
    //   startdatelabel: 'Update On Start Date',
    //   enddatelabel: 'Update On End Date',
    //   submit: 'Search',
    //   field: 'updatedon_datetime',
    //   // value: {}
    // },
    // ],


    selectsearch: [
      // { label: 'Search By User Type', field: 'user_type', values: this.userTypeArray, multiple: true, },
      // { label: 'Search By Status', field: 'status', values: this.statusarray },
      // {label: 'Search By State', field: 'status', values: this.statusarray},
    ],
  }

  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["email", "fullname", "updatedon_datetime", "status", "usertype"],
  };

  libdata: any = {
    basecondition: {},
    preview_header: {

      header: "Preview Details",
      class: 'preheadercls'
    },
    detailview_override: [
      { key: "address", val: "Address" },
      { key: "status_text", val: "Status" },
      { key: "contact_person", val: "Contact Person" },
      { key: "contact_person_email", val: "Contact Person Email" },
      { key: "contact_person_phone", val: "Contact Person Phone" },
      { key: "fullname", val: "Full Name" },
      { key: "email", val: "Email" },
      { key: "phone", val: "Phone" },
      { key: "fax", val: "Fax" },
      { key: "npi", val: "NPI" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "notescount", val: "Notes" },
      // {key: 'login_counts',val:"Login Counts"},
      // { key: "last_login_time", val: "Last Login" }
      { key: "last_login_datetime", val: "Last Login" },
      { key: "parent_laboratory_name", val: "Laboratory" },
      { key: "practice", val: "Practice" },



    ],
    updateendpoint: "users/statusChange",

    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "users/statusChange",
    deleteendpointmany: "users/deleteUsers",
    tableheaders: ["fullname", "phone", "email", "fax", "usertype", "status", "updatedon_datetime"],
    // tableheaders: [this.tableheader],




    custombuttons: [
      // {
      //   label: "Edit",
      //   type: 'listner',
      //   id: 'edit_btn',
      //   tooltip: 'Edit',
      //   name: 'edit',
      //   classname: 'edit_btn'
      // },

      // {
      //   label: "Preview00",
      //   tooltip: 'Preview00',
      //   type: "internallink",


      // },


      {
        label: 'Folder View',
        type: "listner",
        id: "folderview",
        classname: "OpenFolderView",
        tooltip: 'Folder View',
      },
      {
        label: "Preview",
        type: 'action',
        // id: "folderview",
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 1,
        param: '_id',
        datafields: ['name', 'email', 'phone', 'fax', 'usertype', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'

      },
      {
        label: "Preview",
        type: 'action',
        // id: "folderview",
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 2,
        param: '_id',
        datafields: ['name', 'phone', 'email', 'fax', 'usertype', 'createdon_datetime', 'updatedon_datetime', 'status', 'image'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'

      },
      {
        label: "Preview",
        type: 'action',
        // id: "folderview",
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 3,
        param: '_id',
        datafields: ['name', 'phone', 'email', 'fax', 'usertype', 'parent_laboratory_name', 'createdon_datetime', 'updatedon_datetime', 'status', 'image'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'

      },
      {
        label: "Preview",
        type: 'action',
        // id: "folderview",
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 4,
        param: '_id',
        datafields: ['name', 'phone', 'email', 'fax', 'usertype', 'parent_laboratory_name', 'practicename', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'

      },
      {
        label: "Preview",
        type: 'action',
        // id: "folderview",
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 5,
        param: '_id',
        datafields: ['name', 'phone', 'email', 'fax', 'usertype', 'parent_laboratory_name', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'

      },
      //       {
      //         label: "Delete",
      //         type: 'listner',
      //         id: 'delete_btn',
      //         tooltip: 'Delete',
      //         name: 'delete',
      //         classname: 'delete_btn'
      //       },
      // {
      //         label: "Status Change",
      //         type: 'listner',
      //         id: 'status_change_btn',
      //         tooltip: 'Status Change',
      //         name: 'status_change_btn',
      //         classname: 'status_change_btn'
      //       },
    ]
  };

  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;
  public isLaboratoryUser: boolean = false;
  public env = environment;
  public customlistenbutton: any = {
    flag: false,
    tooltipflag: false,
    buttons: [
      {
        label: 'Add User',
        type: 'button',
        name: 'add_user',
        tooltip: 'Add User',
        classname: 'add_adminBTN',
      },

    ],
  };

  public userTypeList: any = [
    { position: 0, val: 'admin', value: "Admin" },
    { position: 1, val: 'laboratory', value: "Laboratory" },
    { position: 2, val: 'practice', value: "Practice" },
    { position: 3, val: 'sales_person', value: "Salesperson" },
    { position: 4, val: 'doctor', value: "Doctor" },

  ]

  public userTypeInSearchCondition: any = null;

  public searchItems: any = {};
  public searchCount: number = 0;
  public leadListFlag: boolean = false;
  public isLead: boolean = false;
  public reportSummarySelected: number = null;
  public selectedOrderSummary: number = null;
  public labDemoReportSummarySelected: number = null;
  public labDemoSelectedOrderSummary: number = null;
  public reportLink: any = '';

  public demoDoctorDashboard: boolean = false;
  @ViewChild('userTypeButtn') userTypeButtn: ElementRef;
  @BlockUI() blockUI: NgBlockUI;

  public newAddButton: any = null;
  public addingPatientButton: any = null;
  public downloadCSVButton: any = null;
  public finalLandingPageLink: any = null;
  public getCurrentUser: string = null;
  public notificationCount: number = 0;
  public csvArray: any = [];

  public currTime: any = new Date().getTime();
  public currentDate: any = null;
  public dayAfterFourteen: any = null;
  public globalUserSearch: any;
  public globalPagination: any = { "limit": 10, "skip": 0 };
  public resetprogressBar: boolean = false;
  public rapidResponseAccessUserlist: any = [];
  public rapidResponseAccessUserlistCount: number = 0;

  constructor(private _bottomSheet: MatBottomSheet, private httpService: HttpServiceService, public cookieService: CookieService, public activatedRoute: ActivatedRoute, public dialog: MatDialog, public router: Router, public matSnackBar: MatSnackBar, public resolveService: ResolveService, private metaService: Meta, private titleService: Title, private renderer: Renderer2, private el: ElementRef,) {
    // console.log("env++++", this.env);
    if (this.env.slug == 'development') {
      this.signupLink = 'https://dev.practice2lab.influxiq.com/practice-signup/'
      if (window.location.pathname.includes('dashboard/doctor')) {
        this.signupLinkpac = 'https://dev.practice2lab.influxiq.com/demo/lead-add/'
      } else {
        this.signupLinkpac = 'https://dev.practice2lab.influxiq.com/lead-add/'
      }
    } else {
      this.signupLink = 'https://practice2lab.influxiq.com/practice-signup/';
      if (window.location.pathname.includes('dashboard/doctor')) {
        this.signupLinkpac = 'https://dev.practice2lab.influxiq.com/demo/lead-add/'
      } else {
        this.signupLinkpac = 'https://dev.practice2lab.influxiq.com/lead-add/'
      }
    }

    this.resolveService.reportUploader.subscribe((value: any) => {
      console.log("resolveService value", value);
      if (value) {
        this.refreshSearchedList('fileUploaded');
        if (this.demoDoctorDashboard) {
          this.labDemoreportSummary(6, 'leads')
        }
      }
    })


  }



  ngOnInit() {


    if (window.location.pathname.includes('sales_person/dashboard/doctor')) {
      this.demoDoctorDashboard = true;
    } else {
      this.demoDoctorDashboard = false;
    }


    this.newAddButton = document.querySelector(".addUserButton");
    this.addingPatientButton = document.querySelector(".addPatientButton");
    this.downloadCSVButton = document.querySelector(".downloadCSVButton");



    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      this.metaService.addTags([
        { name: 'keywords', content: `${this.cookieData.user_type.charAt(0).toUpperCase() + this.cookieData.user_type.slice(1)}  Dashboard` },
        { name: 'description', content: `${this.cookieData.user_type.charAt(0).toUpperCase() + this.cookieData.user_type.slice(1)}  Dashboard` },
        { name: 'robots', content: 'index, follow' }
      ]);
      this.titleService.setTitle(`${this.cookieData.user_type.charAt(0).toUpperCase() + this.cookieData.user_type.slice(1)}  Dashboard`);
      console.log("this.cookieData>>>>", this.cookieData)
      if (this.cookieData.user_type === 'practice') {
        if (this.cookieData.accept_ai == undefined || this.cookieData.accept_ai == null) {
          this.dayAfterFourteen = this.cookieData.createdon_datetime + 336 * 3600 * 1000;
          if (this.dayAfterFourteen < this.currTime) {
            const dialogRef = this.dialog.open(PracticeUpgradeAlert, {
              data: {},
              panelClass: 'dashboard-search-modal',
              disableClose: true
            });

            dialogRef.afterClosed().subscribe(result => { })
          } else {
          }
        }
      }
      console.log("login response time ", this.currTime, this.dayAfterFourteen)
      // this.firstBlockData = this.cookieData.last_login_time;

      // if (this.isLead) {
      //   console.log('aaaaaaaaaaaaaaaaa');

      // }
      if (this.cookieData.user_type == 'laboratory') {
        this.testdone_txt = 'Lab Test Results Sent';
      }

      if (this.cookieData) {
        this.isAdminUser = true;
        this.libdata.notes = {
          label: "Notes",
          tooltip: 'Add Notes',
          listendpoint: "users/listnotedata",
          deleteendpoint: "users/deletenotedata",
          addendpoint: "users/addupdateNotes",
          user: this.cookieData._id,
          currentuserfullname: this.cookieData.firstname + " " + this.cookieData.lastname,
          header: "fullname",
        }
        switch (this.cookieData.user_type) {
          case "laboratory":
            this.libdata.notes.currentuserfullname = this.cookieData.name
            break;
          case "practice":
            this.libdata.notes.currentuserfullname = this.cookieData.name
            break;

        }
      }

    };

    if (this.cookieService.get('currentUser')) {
      console.log("currentUser", this.cookieService.get('currentUser'));
      if (window.location.pathname.includes('sales_person/dashboard/doctor')) {
        this.getCurrentUser = 'leads';
      } else {
        this.getCurrentUser = this.cookieService.get('currentUser');
      }
    }
    if (this.cookieService.get('currentUserStatus') && this.getCurrentUser == 'leads') {
      this.reportSummarySelected = JSON.parse(this.cookieService.get('currentUserStatus'));
      this.selectedOrderSummary = JSON.parse(this.cookieService.get('currentUserStatus'));
      // if (!window.location.pathname.includes('dashboard/doctor') ) {
      if (window.location.pathname.includes('sales_person/dashboard/doctor')) {
        this.reportSummary(this.reportSummarySelected, 'leads', 'resolve')
      }
      if (this.cookieData.user_type == 'laboratory' || this.cookieData.user_type == 'admin') {
        this.reportSummary(this.reportSummarySelected, 'leads', 'resolve')
      }
    }
    let fetchUserDetailsBody = {
      'condition': {
        'limit': 10, 'skip': 0
      },
      sort: {
        'type': 'desc', 'field': '_id'
      },
      searchcondition: {
        _id: this.cookieData.parent_laboratory
      }
    }
    // else{
    this.activatedRoute.data.subscribe((response: any) => {
      console.log("response test", response)
      if (response) {
        if ((this.cookieData.user_type !== 'doctor' && this.getCurrentUser !== 'leads')) {
          this.tabledatalist = response.data.results.res;
          console.log("listenLiblistingChange", this.selectedOrderSummary);
          if (Object.keys(response.requestcondition.searchcondition).length > 0) {
            this.userTypeInSearchCondition = response.requestcondition.searchcondition;
            this.libdata.basecondition = response.requestcondition.searchcondition;
            this.usertypes(this.userTypeInSearchCondition.user_type[0]);
          }



          this.httpService.httpViaPost('users/fetchUsers-count', response.requestcondition).subscribe((response: any) => {
            // console.log(response)
            // this.progressLoader = true;
            if (response) {
              // this.progressLoader = true;
              this.date_search_source_count = response.count;


            }

            // <<<<<<< HEAD
            // this.progressLoader = false;
          })
          //           if (this.cookieData && (this.cookieData.user_type == 'sales_person')) {
          //             if (window.location.pathname.includes('dashboard/doctor')) {
          //               this.demoDoctorDashboard = true;
          //               if (this.getCurrentUser == 'leads') {
          //                 this.reportSummary(this.reportSummarySelected, 'leads')
          //               } else {
          //                 this.reportSummary(1, 'leads')
          //               }
          //             }
          //           }
          // console.log("this.demoDoctorDashboard",this.demoDoctorDashboard)

          // =======
          // if (this.cookieData && (this.cookieData.user_type == 'sales_person')) {
          //   console.log("this.cookieData+++", this.cookieData)
          //   // console.log("test >>>>>>>>");
          //   let data = {
          //     'condition': {
          //       'limit': 10, 'skip': 0
          //     },
          //     sort: {
          //       'type': 'desc', 'field': '_id'
          //     },
          //     searchcondition: {
          //       _id: this.cookieData.parent_laboratory
          //     }
          //   }
          //   this.httpService.httpViaPost('users/fetchUserDetails', data).subscribe((response: any) => {
          //     // this.progressLoader = true;
          //     // console.log("test>>>>>>>>>", response)
          //     if (response && response.status == 'success') {
          //       this.laboratoryData = response.results.res[0]
          //     }
          //     console.log("laboratoryData++++++", this.laboratoryData);
          //   })

          // }
          // let fetchUserDetailsBody = {
          //   'condition': {
          //     'limit': 10, 'skip': 0
          //   },
          //   sort: {
          //     'type': 'desc', 'field': '_id'
          //   },
          //   searchcondition: {
          //     _id: this.cookieData.parent_laboratory
          //   }
          // }
          if (this.cookieData.user_type === 'admin') {
            if (!this.demoDoctorDashboard) {
              if (this.getCurrentUser) {
                if (this.getCurrentUser !== 'leads') {
                  this.useSummary(this.getCurrentUser)
                }
              } else {
                this.useSummary("admin")
              }
            }
          }

          // if (this.cookieData.user_type === 'sales_person') {
          //   this.uniqueData();
          //   if (!this.demoDoctorDashboard) {
          //     if (this.getCurrentUser) {
          //       if (this.getCurrentUser !== 'leads') {
          //         this.useSummary(this.getCurrentUser)
          //       }
          //     } else {
          //       this.useSummary("practice")
          //     }
          //   }
          //   // let data = {
          //   //   'condition': {
          //   //     'limit': 10, 'skip': 0
          //   //   },
          //   //   sort: {
          //   //     'type': 'desc', 'field': '_id'
          //   //   },
          //   //   searchcondition: {
          //   //     _id: this.cookieData.parent_laboratory
          //   //   }
          //   // }
          //   this.httpService.httpViaPost('users/fetchUserDetails', fetchUserDetailsBody).subscribe((response: any) => {
          //     // this.progressLoader = true;
          //     // console.log("test>>>>>>>>>", response)
          //     if (response && response.status == 'success') {
          //       this.laboratoryData = response.results.res[0]
          //     }
          //     console.log("laboratoryData++++++", this.laboratoryData);
          //   })
          // }

          if (this.cookieData && this.cookieData.user_type == 'practice') {
            if (this.getCurrentUser) {
              if (this.getCurrentUser !== 'leads') {
                this.useSummary(this.getCurrentUser)
              }
            } else {
              this.useSummary("doctor");
            }
            // let data = {
            //   'condition': {
            //     'limit': 10, 'skip': 0
            //   },
            //   sort: {
            //     'type': 'desc', 'field': '_id'
            //   },
            //   searchcondition: {
            //     _id: this.cookieData.parent_laboratory
            //   }
            // }
            this.httpService.httpViaPost('users/fetchUserDetails', fetchUserDetailsBody).subscribe((response: any) => {
              if (response && response.status == 'success') {
                this.laboratoryData = response.results.res[0]
              }
            })

          }
          else if (this.cookieData && this.cookieData.user_type == 'laboratory') {
            if (this.getCurrentUser) {
              if (this.getCurrentUser !== 'leads') {
                this.useSummary(this.getCurrentUser)
              }
            } else {
              this.useSummary("practice")
            }
          }

          // else if (this.cookieData && this.cookieData.user_type == 'sales_person') {
          //   this.useSummary("practice")
          // }


          // if (this.cookieData && (this.cookieData.user_type == 'practice')) {
          //   let data = {
          //     'condition': {
          //       'limit': 10, 'skip': 0
          //     },
          //     sort: {
          //       'type': 'desc', 'field': '_id'
          //     },
          //     searchcondition: {
          //       _id: this.cookieData.parent_laboratory
          //     }

          //   }
          //   this.httpService.httpViaPost('users/fetchUserDetails', data).subscribe((response: any) => {
          //     if (response && response.status == 'success') {
          //       this.laboratoryData = response.results.res[0]
          //     }
          //   })


          // }





          // if (this.cookieData && (this.cookieData.user_type == 'practice')) {
          //   this.useSummary("doctor")
          // }
          // else if (this.cookieData && (this.cookieData.user_type == 'laboratory')) {
          //   this.useSummary("practice")
          // }

        }

        if (this.cookieData.user_type == 'doctor') {
          // if (response) {

          if (response.data.results.length == 0) {
            this.btnflag = true;
          } else {
            this.btnflag = false;
          }

          this.UniqueLIstData = response.data.results
          console.log("finduniquecodebyuser", this.UniqueLIstData)
          // }
          if (this.getCurrentUser == 'leads') {
            this.reportSummary(this.reportSummarySelected, 'leads')
          } else {
            this.reportSummary(1, 'leads')
          }
        }
      }

    });


    if (this.cookieData.user_type == 'laboratory') {
      this.uniqueData();
    }
    // }

    if (this.cookieData.user_type == 'practice') {
      this.uniqueData();
    }
    console.log("usertype_selected", this.usertype_selected);
    // if (this.userTypeButtn) {
    //   this.userTypeButtn.nativeElement.addEventListener('click', function () {
    //     console.log('from there');
    //   })
    // }
    if (this.cookieData && (this.cookieData.user_type == 'sales_person')) {
      if (this.demoDoctorDashboard) {
        // this.demoDoctorDashboard = true;
        if (this.getCurrentUser == 'leads') {
          this.reportSummary(this.reportSummarySelected, 'leads')
        } else {
          this.reportSummary(1, 'leads')
        }
      }



      this.uniqueData();
      if (!this.demoDoctorDashboard) {
        console.log("this.getCurrentUser", this.getCurrentUser)
        if (this.getCurrentUser) {
          if (this.getCurrentUser !== 'leads') {
            this.useSummary(this.getCurrentUser)
          }
        } else {
          this.useSummary("practice")
        }
      }
      // let data = {
      //   'condition': {
      //     'limit': 10, 'skip': 0
      //   },
      //   sort: {
      //     'type': 'desc', 'field': '_id'
      //   },
      //   searchcondition: {
      //     _id: this.cookieData.parent_laboratory
      //   }
      // }
      this.httpService.httpViaPost('users/fetchUserDetails', fetchUserDetailsBody).subscribe((response: any) => {
        // this.progressLoader = true;
        // console.log("test>>>>>>>>>", response)
        if (response && response.status == 'success') {
          this.laboratoryData = response.results.res[0]
        }
        console.log("laboratoryData++++++", this.laboratoryData);
      })
      if (!this.demoDoctorDashboard) {
        console.log("this.userTypeInSearchCondition", this.userTypeInSearchCondition)
        this.usertypes('practice');
        this.useSummary("practice")
        // this.httpService.httpViaPost('users/fetchUsers-count', response.requestcondition).subscribe((response: any) => {
        //   if (response) {
        //     this.date_search_source_count = response.count;

        //   }
        // })
      }
    }
    console.log("this.demoDoctorDashboard", this.demoDoctorDashboard)

    if (this.cookieData.user_type == 'admin') {
      this.testNotificationCount();
      this.fetchRapidResponseAccessUserlist();
    }

    this.userCount();
  }

  geanareteCsv(newarry: any) {
    let csv = '';
    let filename = this.usertype_selected + '-';
    if (this.usertype_selected == 'Leads') {
      filename = 'patients-'
    }
    // Loop the array of objects
    for (let row = 0; row < newarry.length; row++) {
      let keysAmount = Object.keys(newarry[row]).length
      let keysCounter = 0
      // If this is the first row, generate the headings
      if (row === 0) {
        // Loop each property of the object
        for (let key in newarry[row]) {
          // This is to not add a comma at the last cell
          // The '\r\n' adds a new line
          csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
          keysCounter++
        }
      }
      keysCounter = 0;
    }

    //create total amount and test run
    // newarry.push({
    //   "Patient Name": ''
    // })
    for (let row = 0; row < newarry.length; row++) {
      let keysAmount = Object.keys(newarry[row]).length
      let keysCounter = 0;
      for (let key in newarry[row]) {
        csv += newarry[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n');

        keysCounter++
      }
      keysCounter = 0;

    }
    var dateval = new Date();
    let dateForFileName = ('0' + (dateval.getMonth() + 1)).slice(-2) + '-' + ('0' + dateval.getDate()).slice(-2) + '-' + dateval.getFullYear();
    // console.warn('Date>>>>',dateForFileName);

    // Once we are done looping, download the .csv by creating a link
    let link = document.createElement('a')
    link.id = 'download-csv'
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
    // link.setAttribute('download', this.randomString(5, 'aA#!') + '.csv');
    link.setAttribute('download', filename + dateForFileName + '.csv');
    document.body.appendChild(link);
    let yourElem = <HTMLElement>document.querySelector('#download-csv')
    yourElem.click();
    yourElem.remove();
    link.remove();
    csv = '';
    newarry = [];
  }

  testNotificationCount() {
    let data = {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "type": "desc",
        "field": "_id"
      },
      "searchcondition": {
        "isNew": true
      }
    }
    this.httpService.httpViaPost('intake/diagnosticlist-count', data).subscribe((response: any) => {
      if (response.status == "success") {
        this.notificationCount = response.count;
      }
    })
  }
  reviewNewTest() {
    this.router.navigateByUrl('admin/miscellaneous/pending-diagnostic-list');
  }

  fetchRapidResponseAccessUserlist() {

    let payload = {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "type": "desc",
        "field": "_id"
      },
      "searchcondition": {}
    }

    this.httpService.httpViaPostRapidResponse('api5/rapidresponsaccessuserlist', payload).subscribe((response: any) => {
      console.log("rapid response", response)
      if (response && response.status && response.status == "success") {
        if (response.results && response.results.res && response.results.res.length > 0) {
          this.rapidResponseAccessUserlist = response.results.res;
        }
      }
    })
    this.httpService.httpViaPostRapidResponse('api5/rapidresponsaccessuserlist-count', payload).subscribe((response: any) => {
      console.log("rapid response count", response)
      if (response && response.status && response.status == "success") {
        if (response.count !== undefined || response.count !== null) {
          this.rapidResponseAccessUserlistCount = response.count;
        }
      }
    });
  }

  viewRapidResponseList() {
    const dialogRef = this.dialog.open(RapidResponseVirtualAccessModal, {
      data: { list: this.rapidResponseAccessUserlist, count: this.rapidResponseAccessUserlistCount },
      panelClass: 'rapidResponseVirtualAccessModal',
      // disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchRapidResponseAccessUserlist();
      }
    })
  }



  refreshSearchedList(type?: string) {
    console.log("this.searchCount", this.searchCount)
    this.UsersSummaryLoader = true;
    this.searchItems = {};
    let data: any = {
      'condition': {
        'limit': 10, 'skip': 0
      },
      sort: {
        'type': 'desc', 'field': '_id'
      },
      searchcondition: {
      }
    }
    if (type) {
      this.reportSummarySelected = 6;
    }
    if (this.reportSummarySelected) {
      if (this.reportSummarySelected == 3) {
        data.searchcondition.status = { $in: [3, 7] }
      } else {
        data.searchcondition.status = this.reportSummarySelected
      }
    }
    if (this.cookieData.user_type !== 'doctor') {
      if (!this.demoDoctorDashboard && !this.isLead) {
        console.log("this.usertype_selected", this.usertype_selected)
        data.searchcondition.user_type = [this.userTypeInSearchCondition && this.userTypeInSearchCondition.user_type && this.userTypeInSearchCondition.user_type[0] ? this.userTypeInSearchCondition.user_type[0].toLowerCase() : this.usertype_selected.toLowerCase()]
      }


      if (this.cookieData.user_type == 'laboratory') {
        data.searchcondition.parent_laboratory = this.cookieData._id;
        this.libdata.basecondition.parent_laboratory = this.cookieData._id;
      }
      if (this.cookieData.user_type == 'practice') {
        data.searchcondition.practice_id = this.cookieData._id;
        this.libdata.basecondition.practice_id = this.cookieData._id;

      }
      if (this.cookieData.user_type == 'sales_person') {

        if (this.demoDoctorDashboard) {
          data.searchcondition.is_demo = true;
          this.libdata.basecondition.is_demo = true;
          data.searchcondition.parent_laboratory = this.cookieData.parent_laboratory;
          this.libdata.basecondition.parent_laboratory = this.cookieData.parent_laboratory;
          data.searchcondition['is_partial_done'] = false;
        } else {
          data.searchcondition.refferd_sealsperson = this.cookieData._id;
          this.libdata.basecondition.refferd_sealsperson = this.cookieData._id;
        }
      }
      // data.searchcondition['is_partial_done'] = false;
      if (this.searchCount > 0) {

        // console.log("data>>>", data, this.isLead)
        if (this.isLead) {

          delete data.searchcondition.user_type;
          // console.log("data>>>111", data)

          this.searchingLeadItems(data)
        }
        else {
          this.searchingItems(data);
        }
        this.searchCount = 0;
        // this.searchingItems(data);
        // this.searchCount = 0;

        // this.UsersSummaryLoader = false;
      } else {
        // if (this.cookieData.user_type == 'laboratory') {
        //   data.searchcondition.parent_laboratory = this.cookieData._id;
        //   this.libdata.basecondition.parent_laboratory = this.cookieData._id;
        // }
        // if (this.cookieData.user_type == 'practice') {
        //   data.searchcondition.practice_id = this.cookieData._id;
        //   this.libdata.basecondition.practice_id = this.cookieData._id;
        // }
        // if (this.cookieData.user_type == 'sales_person') {
        //   data.searchcondition.refferd_sealsperson = this.cookieData._id;
        //   this.libdata.basecondition.refferd_sealsperson = this.cookieData._id;
        // }
        if (this.isLead) {
          // console.log("data>>>111", data)

          delete data.searchcondition.user_type;
          this.searchingLeadItems(data)
        }
        else {
          this.searchingItems(data);
        }
        // this.dashboard_updatetable = !this.dashboard_updatetable;
        this.UsersSummaryLoader = false;
      }
    } else {
      data.searchcondition = {};
      if (this.reportSummarySelected) {

        if (this.reportSummarySelected == 3) {
          data.searchcondition.status = { $in: [3, 7] }
        } else {
          data.searchcondition.status = this.reportSummarySelected
        }
      }
      // data.searchcondition.doctor_id = this.cookieData._id;
      data.searchcondition.$or = [{ doctor_id: this.cookieData._id }]; //{ practice_id: this.cookieData.practice_id }
      if (this.demoDoctorDashboard) {
        data.searchcondition.is_demo = true;
      } else {
        data.searchcondition.is_demo = false;
      }
      data.searchcondition.is_partial_done = false;
      this.searchingLeadItems(data)
    }
    this.userCount()

  }

  searchingLeadItems(data: any) {
    this.httpService.httpViaPost('intake/leadlist', data).subscribe((response: any) => {

      // console.log('modalData respose', response);
      if (response) {
        this.tabledatalist = [];
        this.UsersSummaryLoader = true;
        setTimeout(() => {
          this.tabledatalist = response.results.res;
          if (this.reportSummarySelected == 3) {
            if (this.tabledatalist.length > 0) {
              setTimeout(() => {
                this.tabledatalist.forEach((e: any, i: number) => {
                  if (e.status == 7) {
                    let tableRow = document.querySelectorAll('tr');
                    if (tableRow.length > 0) {
                      tableRow.forEach((f: any, j: number) => {
                        tableRow[i + 1].classList.add('reOrderRow')
                      })
                    }
                  }
                })
              }, 500)
            }
          }
          this.UsersSummaryLoader = false;
        }, 10);
        // this.dashboard_updatetable = !this.dashboard_updatetable;
      }
    }, error => {
      console.log('Oooops!');
    });
    this.httpService.httpViaPost('intake/leadlist-count', data).subscribe((response: any) => {
      console.log('modalData respose', response);
      if (response) {
        this.date_search_source_count = response.count;
        console.log("date_search_source_count", this.date_search_source_count);
        if (this.cookieData.user_type == 'practice' || this.cookieData.user_type == 'doctor' || window.location.pathname.includes('dashboard/doctor')) {
          if (this.reportSummarySelected) {
            setTimeout(() => {
              let containerCard = document.querySelector(".adminlisttable_v2 .container .mdc-card");
              if (this.reportSummarySelected == 1) {
                this.addingPatientButton.classList.add('show');
                if (containerCard) {
                  containerCard.prepend(this.addingPatientButton)
                } else {
                  let noFoundTextinner = document.querySelector(".noFoundTextinner");
                  noFoundTextinner.prepend(this.addingPatientButton)
                }
              }

            }, 500)
          }
        }
        if (this.cookieData.user_type) {
          setTimeout(() => {
            let containerCard = document.querySelector(".adminlisttable_v2 .container .mdc-card");
            console.log("downloadCSVButton", containerCard)
            if (containerCard) {
              this.downloadCSVButton.classList.add("show");
              if (this.cookieData.user_type == 'admin') {
                this.downloadCSVButton.classList.add("patientBlock");
              }
              if (this.cookieData.user_type == 'doctor' || this.cookieData.user_type == 'practice') {
                this.downloadCSVButton.classList.add("patientBlock");
                if (this.reportSummarySelected == 1) {
                  this.downloadCSVButton.classList.remove("patientBlock");
                }
              }
              if (this.cookieData.user_type == 'laboratory') {
                this.downloadCSVButton.classList.add("patientBlock");
              }
              containerCard.prepend(this.downloadCSVButton)
            }
          }, 500)
        }
      }
    }, error => {
      console.log('Oooops!');
    });
  }


  openSearchDialog(): void {
    // console.log("The dialog modalData was open", this.searchItems)
    console.log("this.userTypeInSearchCondition", this.userTypeInSearchCondition, this.usertype_selected)
    // return
    const dialogRef = this.dialog.open(Searchmodal, {
      data: { searchItem: this.searchItems, userType: this.cookieData.user_type !== 'doctor' ? this.demoDoctorDashboard ? 'doctor' : this.userTypeInSearchCondition && this.userTypeInSearchCondition.user_type && this.userTypeInSearchCondition.user_type[0] ? this.userTypeInSearchCondition.user_type[0] : this.usertype_selected.toLowerCase() : 'doctor', isLead: this.isLead },
      panelClass: 'dashboard-search-modal'
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log('The dialog modalData was closed this.modalData', result);
      //  this.dashboard_updatetable = false;

      if (this.isLead) {
        if (result) {

          if (result.modalData && Object.keys(result.modalData).length > 0) {
            this.searchItems = {};
            this.searchItems = result
            this.UsersSummaryLoader = true;
            let searchStartDate: any = null;
            let searchEndDate: any = null;
            if (result.modalData.startdate) {
              searchStartDate = new Date(result.modalData.startdate).getTime();
            }
            if (result.modalData.enddate) {
              searchEndDate = new Date(result.modalData.enddate).getTime() + (23 * 60 * 60 * 1000);
            }
            let data: any = {
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "_id"
              },
              "searchcondition": {
                // user_type: [this.userTypeInSearchCondition.user_type[0]],
                _id: result.modalData.fullname && result.modalData.fullname !== '' ? result.modalData.fullname : undefined,
                // email: result.modalData.email && result.modalData.email !== '' ? result.modalData.email : undefined,
                // status: result.modalData.status && result.modalData.status !== '' ? parseInt(result.modalData.status) : undefined,
                status: this.reportSummarySelected ? this.reportSummarySelected : undefined,
                updatedon_datetime: result.modalData.startdate && result.modalData.enddate ? { $gte: searchStartDate, $lte: searchEndDate } : undefined,
                // patient_email: result.modalData.patient_email
                patient_email: result.modalData.patient_email && result.modalData.patient_email !== '' ? result.modalData.patient_email : undefined,
                practice_id: result.modalData.practice_name && result.modalData.practice_name !== '' ? result.modalData.practice_name : undefined,
                doctor_id: result.modalData.doctor_name && result.modalData.doctor_name !== '' ? result.modalData.doctor_name : undefined,

              }
            }
            console.warn(data.searchcondition, "globalUserSearch444>>>>>");
            if (this.cookieData.user_type == 'laboratory') {
              data.searchcondition.parent_laboratory = this.cookieData._id;
            }
            if (this.cookieData.user_type == 'practice') {
              data.searchcondition.practice_id = this.cookieData._id;
            }
            if (this.cookieData.user_type == 'sales_person') {
              if (!this.demoDoctorDashboard) {
                data.searchcondition.refferd_sealsperson = this.cookieData._id;
              }
            }
            this.searchCount++
            if (this.cookieData.user_type == 'doctor') {
              data.searchcondition.$or = [{ doctor_id: this.cookieData._id }]; //{ practice_id: this.cookieData.practice_id }
              if (this.demoDoctorDashboard) {
                data.searchcondition.is_demo = true;
              } else {
                data.searchcondition.is_demo = false;
              }
            }
            data.searchcondition.is_partial_done = false;
            this.globalUserSearch = data.searchcondition;
            console.warn(data.searchcondition, "globalUserSearch>>>>>", this.globalUserSearch);

            this.searchingLeadItems(data);

          } else {
            this.refreshSearchedList();
            this.searchItems = {};
          }
        }

      }
      else {
        if (result) {
          if (result.modalData && Object.keys(result.modalData).length > 0) {
            this.searchItems = {};
            this.searchItems = result
            this.UsersSummaryLoader = true;
            let searchStartDate: any = null;
            let searchEndDate: any = null;
            if (result.modalData.startdate) {
              searchStartDate = new Date(result.modalData.startdate).getTime();
            }
            if (result.modalData.enddate) {
              searchEndDate = new Date(result.modalData.enddate).getTime() + (23 * 60 * 60 * 1000);
            }
            let data: any = {
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "desc",
                "field": "_id"
              },
              "searchcondition": {
                user_type: [this.userTypeInSearchCondition && this.userTypeInSearchCondition.user_type && this.userTypeInSearchCondition.user_type[0] ? this.userTypeInSearchCondition.user_type[0] : this.usertype_selected.toLowerCase()],
                _id: result.modalData.fullname && result.modalData.fullname !== '' ? result.modalData.fullname : undefined,
                email: result.modalData.email && result.modalData.email !== '' ? result.modalData.email : undefined,
                status: result.modalData.status && result.modalData.status !== '' ? parseInt(result.modalData.status) : undefined,
                updatedon_datetime: result.modalData.startdate && result.modalData.enddate ? { $gte: searchStartDate, $lte: searchEndDate } : undefined,
              }
            }
            if (this.cookieData.user_type == 'laboratory') {
              data.searchcondition.parent_laboratory = this.cookieData._id;
            }
            if (this.cookieData.user_type == 'practice') {
              data.searchcondition.practice_id = this.cookieData._id;
            }
            if (this.cookieData.user_type == 'sales_person') {
              data.searchcondition.refferd_sealsperson = this.cookieData._id;
            }

            this.searchCount++
            this.searchingItems(data);
            this.globalUserSearch = data.searchcondition;
            console.warn(data.searchcondition, "globalUserSearch>>>>>", this.globalUserSearch);
          } else {
            this.refreshSearchedList();
            this.searchItems = {};
          }
        }
      }
    });
  }

  searchingItems(data: any) {

    this.httpService.httpViaPost('users/fetchUsers', data).subscribe((response: any) => {

      // console.log('modalData respose', response);
      if (response) {
        this.tabledatalist = [];
        this.UsersSummaryLoader = true;
        setTimeout(() => {
          this.tabledatalist = response.results.res;
          if (this.reportSummarySelected && this.reportSummarySelected == 3) {
            if (this.tabledatalist.length > 0) {
              setTimeout(() => {
                this.tabledatalist.forEach((e: any, i: number) => {
                  if (e.status == 7) {
                    let tableRow = document.querySelectorAll('tr');
                    if (tableRow.length > 0) {
                      tableRow.forEach((f: any, j: number) => {
                        tableRow[i + 1].classList.add('reOrderRow')
                      })
                    }
                  }
                })
              }, 500)
            }
          }
          this.UsersSummaryLoader = false;
        }, 10);
        // this.dashboard_updatetable = !this.dashboard_updatetable;
      }
    }, error => {
      console.log('Oooops!');
    });
    this.httpService.httpViaPost('users/fetchUsers-count', data).subscribe((response: any) => {
      console.log('modalData respose', response);
      if (response) {
        this.date_search_source_count = response.count;
        console.log("date_search_source_count", this.date_search_source_count);
        setTimeout(() => {
          let containerCard = document.querySelector(".adminlisttable_v2 .container .mdc-card");
          console.log("newAddButton", this.newAddButton)
          this.newAddButton.classList.add('show');
          if (containerCard) {
            this.downloadCSVButton.classList.add("show");
            containerCard.prepend(this.newAddButton)
            containerCard.prepend(this.downloadCSVButton)
          } else {
            let noFoundTextinner = document.querySelector(".noFoundTextinner");
            noFoundTextinner.prepend(this.newAddButton)
          }
          let selectAllCheckbox = document.querySelector(".adminlisttable_v2 .container .tablewrapper .mat-mdc-header-row mat-checkbox .mdc-form-field label");
          if (selectAllCheckbox) {
            selectAllCheckbox.classList.add("selectAllCheckbox");
            selectAllCheckbox.innerHTML = "Select All"
          }
        }, 500)
      }
    }, error => {
      console.log('Oooops!');
    });
  }

  usertypes(type: string) {
    this.searchItems = {}
    switch (type) {
      case 'admin':
        this.usertype_selected = "Admin";

        break;
      case 'laboratory':
        this.usertype_selected = "Laboratory";
        break;
      case 'practice':
        this.usertype_selected = "Practice";
        break;
      case 'sales_person':
        this.usertype_selected = "Salesperson";
        break;
      case 'doctor':
        this.usertype_selected = "Doctor";
        break;
      case 'leads':
        this.usertype_selected = "Leads";
        this.leadListFlag = true;
        break;

    }
    // this.leadListFlag = false;
    // break;
  }

  useSummary(type: string, from?: string) {
    if (this.cookieService.get('currentUserStatus')) {
      this.cookieService.delete('currentUserStatus', '/');
    }
    if (this.cookieService.get('demoView')) {
      this.cookieService.delete('demoView', '/');
    }
    this.isLead = false;
    this.reportSummarySelected = null;
    this.selectedOrderSummary = null;
    this.libdata.hidedeletebutton = false,
      this.libdata.hideviewbutton = false
    this.libdata.hideeditbutton = true
    this.libdata.hidestatustogglebutton = false
    this.libdata.hidemultipleselectbutton = false
    this.libdata.custombuttons = []
    this.dashboard_updatetable = !this.dashboard_updatetable;
    this.updateendpoint = "users/statusChange";
    this.deleteendpoint = "users/deleteUsers";
    this.datacollection = "users/fetchUsers";
    this.addingPatientButton.classList.remove('show');
    this.downloadCSVButton.classList.remove("patientBlock");
    this.cookieService.set('currentUser', type);


    if (type == "admin") {

    }

    this.sortdata = {
      type: "desc",
      field: "_id",
      options: ["email", "fullname", "updatedon_datetime", "status", "usertype"],
    };

    this.libdata.custombuttons = [
      // {
      //   label: "Edit",
      //   type: 'listner',
      //   id: 'edit_btn',
      //   tooltip: 'Edit',
      //   name: 'edit',
      //   classname: 'edit_btn'
      // },

      // {
      //   label: "Preview00",
      //   tooltip: 'Preview00',
      //   type: "internallink",


      // },


      {
        label: 'Folder View',
        type: "listner",
        id: "folderview",
        classname: "OpenFolderView",
        tooltip: 'Folder View',
      },
      {
        label: "Preview",
        type: 'action',
        // id: "folderview",
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 1,
        param: '_id',
        datafields: ['name', 'email', 'phone', 'fax', 'usertype', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'

      },
      {
        label: "Preview",
        type: 'action',
        // id: "folderview",
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 2,
        param: '_id',
        datafields: ['name', 'phone', 'email', 'fax', 'usertype', 'createdon_datetime', 'updatedon_datetime', 'status', 'image'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'

      },
      {
        label: "Preview",
        type: 'action',
        // id: "folderview",
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 3,
        param: '_id',
        datafields: ['name', 'phone', 'email', 'fax', 'usertype', 'parent_laboratory_name', 'createdon_datetime', 'updatedon_datetime', 'status', 'image'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'

      },
      {
        label: "Preview",
        type: 'action',
        // id: "folderview",
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 4,
        param: '_id',
        datafields: ['name', 'phone', 'email', 'fax', 'usertype', 'parent_laboratory_name', 'practicename', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'

      },
      {
        label: "Preview",
        type: 'action',
        // id: "folderview",
        datatype: 'api',
        endpoint: 'users/previewusers',
        otherparam: [],
        cond: 'usertypecond',
        condval: 5,
        param: '_id',
        datafields: ['name', 'phone', 'email', 'fax', 'usertype', 'parent_laboratory_name', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'

      },
      //       {
      //         label: "Delete",
      //         type: 'listner',
      //         id: 'delete_btn',
      //         tooltip: 'Delete',
      //         name: 'delete',
      //         classname: 'delete_btn'
      //       },
      // {
      //         label: "Status Change",
      //         type: 'listner',
      //         id: 'status_change_btn',
      //         tooltip: 'Status Change',
      //         name: 'status_change_btn',
      //         classname: 'status_change_btn'
      //       },
    ]
    console.log("test>>", type, this.userTypeInSearchCondition);
    if (this.userTypeInSearchCondition) {
      this.userTypeInSearchCondition.user_type[0] = type;
    }
    this.UsersSummaryLoader = true;
    this.usertypes(type);
    this.tabledatalist = []
    let data: any = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {
        user_type: [type]
      }
    }
    console.log("useSummarytype", type)
    this.libdata.basecondition = { user_type: [type] };
    console.log("this.libdata.basecondition", this.libdata.basecondition);




    if (type == "doctor") {
      this.libdata.tableheaders = ["fullname", "phone", "email", "fax", "practicename", "parent_laboratory_name", "usertype", "status", "updatedon_datetime"]
      this.modify_header_array = { fullname: "Full Name", phone: "Phone", practicename: "Practice", parent_laboratory_name: "Laboratory", updatedon_datetime: 'Updated On', email: "Email", fax: "Fax", usertype: "Type", status: "Status", }

    }
    else if (type == "practice") {
      this.libdata.tableheaders = ["fullname", "phone", "email", "fax", "parent_laboratory_name", "usertype", "status", "updatedon_datetime"]
      this.modify_header_array = {
        fullname: "Practice Name", parent_laboratory_name: "Laboratory", updatedon_datetime: 'Updated On', phone: "Phone", email: "Email", fax: "Fax", usertype: "Type", status: "Status",
      }

    }
    else if (type == "laboratory") {
      this.libdata.tableheaders = ["fullname", "phone", "email", "fax", "usertype", "status", "updatedon_datetime"]
      this.modify_header_array = { fullname: "Laboratory Name", updatedon_datetime: 'Updated On', email: "Email", fax: "Fax", usertype: "Type", status: "Status", }

    }
    else if (type == "sales_person") {
      this.libdata.tableheaders = ["fullname", "phone", "email", "fax", "parent_laboratory_name", "usertype", "status", "updatedon_datetime"]
      this.modify_header_array = { fullname: "Salesperson Name", parent_laboratory_name: "Laboratory", updatedon_datetime: 'Updated On', email: "Email", fax: "Fax", usertype: "Type", status: "Status", }

    }
    else {
      this.libdata.tableheaders = ["fullname", "phone", "email", "fax", "usertype", "status", "updatedon_datetime"]
      this.modify_header_array = { fullname: "Full Name", parent_laboratory_name: "Laboratory", updatedon_datetime: 'Updated On', email: "Email", fax: "Fax", usertype: "Type", status: "Status", }

    }
    if (this.cookieData.user_type == 'laboratory') {
      data.searchcondition.parent_laboratory = this.cookieData._id;
      this.libdata.basecondition.parent_laboratory = this.cookieData._id;

    }
    if (this.cookieData.user_type == 'practice') {
      data.searchcondition.practice_id = this.cookieData._id;
      this.libdata.basecondition.practice_id = this.cookieData._id;
    }
    if (this.cookieData.user_type == 'sales_person') {
      data.searchcondition.refferd_sealsperson = this.cookieData._id;
      this.libdata.basecondition.refferd_sealsperson = this.cookieData._id;
      this.libdata.hidestatustogglebutton = true;

    }
    this.limitcond = {
      limit: 10,
      skip: 0,
      pagecount: 1
    };
    if (from !== 'isResolve') {

      this.httpService.httpViaPost('users/fetchUsers', data).subscribe((response: any) => {
        // this.progressLoader = true;
        // console.log(response)
        if (response) {
          // console.log("tabledatalist", response);
          if (response.status == 'success') {
            this.UsersSummaryLoader = false;
            this.tabledatalist = response.results.res;

            if (this.reportSummarySelected == 3) {
              if (this.tabledatalist.length > 0) {
                setTimeout(() => {
                  this.tabledatalist.forEach((e: any, i: number) => {
                    if (e.status == 7) {
                      let tableRow = document.querySelectorAll('tr');
                      if (tableRow.length > 0) {
                        tableRow.forEach((f: any, j: number) => {
                          tableRow[i + 1].classList.add('reOrderRow')
                        })
                      }
                    }
                  })
                }, 500)
              }
            }
          }
          // this.UsersSummaryLoader = false;       
        }
        // this.progressLoader = false;
      })
    }

    this.httpService.httpViaPost('users/fetchUsers-count', data).subscribe((response: any) => {
      // console.log(response)
      // this.progressLoader = true;
      if (response) {
        // this.progressLoader = true;
        this.date_search_source_count = response.count;

        setTimeout(() => {
          let containerCard = document.querySelector(".adminlisttable_v2 .container .mdc-card");
          // console.log("newAddButton", this.newAddButton);
          this.newAddButton.classList.add('show');
          if (containerCard) {
            this.downloadCSVButton.classList.add("show");
            containerCard.prepend(this.newAddButton);
            containerCard.prepend(this.downloadCSVButton)
          } else {
            let noFoundTextinner = document.querySelector(".noFoundTextinner");
            if (noFoundTextinner) {
              noFoundTextinner.prepend(this.newAddButton)
            }
          }
          let selectAllCheckbox = document.querySelector(".adminlisttable_v2 .container .tablewrapper .mat-mdc-header-row mat-checkbox .mdc-form-field label");
          if (selectAllCheckbox) {
            selectAllCheckbox.classList.add("selectAllCheckbox");
            selectAllCheckbox.innerHTML = "Select All"
          }
          console.log("selectAllCheckbox", selectAllCheckbox)
        }, 600)
      }

      // this.progressLoader = false;
    })
  }

  addingUser(user: string) {
    console.log(user)
    if (user == 'Salesperson') {
      user = 'sales_person';
    }
    user = user.toLowerCase();
    if (user) {
      switch (this.cookieData.user_type) {
        case 'admin':
          switch (user) {
            case 'admin':
              this.router.navigateByUrl("admin/user-management/admin/add")
              break;
            case 'doctor':
              this.router.navigateByUrl("admin/user-management/doctor/add")
              break;
            case 'laboratory':
              this.router.navigateByUrl("admin/user-management/laboratory/add")
              break;
            case 'sales_person':
              this.router.navigateByUrl("admin/user-management/sales_person/add")
              break;
            case 'practice':
              this.router.navigateByUrl("admin/user-management/practice/add")
              break;
          }
          break;
        case 'doctor':
          switch (user) {
            case 'doctor':
              this.router.navigateByUrl("doctor/user-management/doctor/add")
              break;

            case 'sales_person':
              this.router.navigateByUrl("doctor/user-management/sales_person/add")
              break;
            case 'practice':
              this.router.navigateByUrl("doctor/user-management/practice/add")
              break;
          }
          break;

        case 'laboratory':
          switch (user) {
            case 'doctor':
              this.router.navigateByUrl("laboratory/user-management/doctor/add")
              break;
            case 'sales_person':
              this.router.navigateByUrl("laboratory/user-management/sales_person/add")
              break;
            case 'practice':
              this.router.navigateByUrl("laboratory/user-management/practice/add")
              break;
          }
          break;


        case 'practice':
          switch (user) {
            case 'doctor':
              this.router.navigateByUrl("practice/user-management/doctor/add")
              break;
            case 'sales_person':
              this.router.navigateByUrl("practice/user-management/sales_person/add")
              break;
          }
          break;
        case 'sales_person':
          switch (user) {
            case 'practice':
              this.router.navigateByUrl("sales_person/user-management/practice/add")
              break;
          }
          break;



      }




    }
  }

  addingPatient() {
    // this.router.navigateByUrl(`${this.cookieData.user_type}/patient-intake`);
    const bottomSheetRef = this._bottomSheet.open(ApproveDialog, {
      data: { usertype: this.cookieData.user_type },
    });
    bottomSheetRef.afterDismissed().subscribe(x => {
    })
  }
  downloadCSV() {
    console.log(this.isLead);
    console.log(this.usertype_selected);
    console.log(this.reportSummarySelected);
    console.warn("this.globalUserSearch", this.globalUserSearch);

    this.csvArray.length = 0;
    let data = { "condition": this.globalPagination, "sort": { "type": "desc", "field": "_id" }, "searchcondition": {} }
    let endpoint = 'users/fetchUsers';

    if (this.usertype_selected == 'Admin') {
      data.searchcondition["user_type"] = "admin";
    }
    if (this.usertype_selected == 'Laboratory') {
      data.searchcondition["user_type"] = "laboratory";
    }
    if (this.usertype_selected == 'Practice') {
      data.searchcondition["user_type"] = "practice";
      if (this.cookieData.user_type == 'laboratory') {
        data.searchcondition['parent_laboratory'] = this.cookieData._id;
      }
      if (this.cookieData.user_type == 'sales_person') {
        data.searchcondition['refferd_sealsperson'] = this.cookieData._id;
      }

    }
    if (this.usertype_selected == 'Doctor') {
      data.searchcondition["user_type"] = "doctor";
      if (this.cookieData.user_type == 'laboratory') {
        data.searchcondition['parent_laboratory'] = this.cookieData._id;
      }
      if (this.cookieData.user_type == 'practice') {
        data.searchcondition['practice_id'] = this.cookieData._id;
      }
    }
    if (this.usertype_selected == 'Salesperson') {
      data.searchcondition["user_type"] = "sales_person";
      if (this.cookieData.user_type == 'laboratory') {
        data.searchcondition['parent_laboratory'] = this.cookieData._id;
      }
    }
    if (this.usertype_selected == 'Leads') {
      endpoint = 'intake/leadlist'
      if (this.cookieData.user_type == 'admin') {
        data.searchcondition["status"] = this.reportSummarySelected;
        data.searchcondition["is_demo"] = false;
      }
      if (this.cookieData.user_type == 'doctor') {
        data.searchcondition['$or'] = [{ doctor_id: this.cookieData._id }];
        data.searchcondition["status"] = this.reportSummarySelected;
        data.searchcondition["is_demo"] = false;
      }

      if (this.cookieData.user_type == 'practice') {
        data.searchcondition['$or'] = [{ practice_id: this.cookieData._id }];
        data.searchcondition["status"] = this.reportSummarySelected;
        data.searchcondition["is_demo"] = false;
      }

      if (this.cookieData.user_type == 'laboratory') {
        data.searchcondition['$or'] = [{ parent_laboratory: this.cookieData._id }];
        data.searchcondition["status"] = this.reportSummarySelected;
        data.searchcondition["is_demo"] = false;
      }
      data.searchcondition['is_partial_done'] = false;
      if (this.demoDoctorDashboard) {
        data.searchcondition['$or'] = [{ doctor_id: this.cookieData._id }];
        data.searchcondition["status"] = this.reportSummarySelected;
        data.searchcondition["is_demo"] = true;
      }
    }

    if (this.globalUserSearch && Object.keys(this.globalUserSearch).length > 0) {
      if (this.globalUserSearch.email != 'undefined') {
        data.searchcondition["email"] = this.globalUserSearch.email;
      }
      if (this.globalUserSearch.parent_laboratory != 'undefined') {
        data.searchcondition["parent_laboratory"] = this.globalUserSearch.parent_laboratory;
      }
      if (this.globalUserSearch.status != 'undefined') {
        data.searchcondition["status"] = this.globalUserSearch.status;
      }
      if (this.globalUserSearch.updatedon_datetime != 'undefined') {
        data.searchcondition["updatedon_datetime"] = this.globalUserSearch.updatedon_datetime;
      }
      if (this.globalUserSearch.user_type != 'undefined') {
        data.searchcondition["user_type"] = this.globalUserSearch.user_type;
      }
      if (this.globalUserSearch._id != 'undefined') {
        data.searchcondition["_id"] = this.globalUserSearch._id;
      }
      if (this.globalUserSearch.doctor_id != 'undefined') {
        data.searchcondition["doctor_id"] = this.globalUserSearch.doctor_id;
      }
      if (this.globalUserSearch.patient_email != 'undefined') {
        data.searchcondition["patient_email"] = this.globalUserSearch.patient_email;
      }
      if (this.globalUserSearch.practice_id != 'undefined') {
        data.searchcondition["practice_id"] = this.globalUserSearch.practice_id;
      }

    }


    this.httpService.httpViaPost(endpoint, data).subscribe((response: any) => {
      if (response) {
        if (response.status == 'success') {
          if (this.usertype_selected == 'Admin') {
            for (const val of response.results.res) {
              this.csvArray.push({
                "Full Name": val.fullname,
                "Phone": val.phone,
                "Email": val.email,
                "Fax": val.fax,
                "Status": val.status_text,
                "Updated On": moment(val.updatedon_datetime).format("MM/DD/YYYY"),
              });
            }
          }

          if (this.usertype_selected == 'Laboratory') {
            for (const val of response.results.res) {
              this.csvArray.push({
                "Lab Name": val.fullname,
                "Phone": val.phone,
                "Email": val.email,
                "Fax": val.fax,
                "Status": val.status_text,
                "Updated On": moment(val.updatedon_datetime).format("MM/DD/YYYY"),
              });
            }
          }

          if (this.usertype_selected == 'Practice') {
            for (const val of response.results.res) {
              this.csvArray.push({
                "Practice Name": val.fullname,
                "Phone": val.phone,
                "Email": val.email,
                "Fax": val.fax,
                "Laboratory": val.parent_laboratory_name,
                "Status": val.status_text,
                "Updated On": moment(val.updatedon_datetime).format("MM/DD/YYYY"),
              });
            }
          }
          if (this.usertype_selected == 'Doctor') {
            for (const val of response.results.res) {
              this.csvArray.push({
                "Full Name": val.fullname,
                "Phone": val.phone,
                "Email": val.email,
                "Fax": val.fax,
                "Practice Name": val.practicename,
                "Laboratory": val.parent_laboratory_name,
                "Status": val.status_text,
                "Updated On": moment(val.updatedon_datetime).format("MM/DD/YYYY"),
              });
            }
          }
          if (this.usertype_selected == 'Salesperson') {
            for (const val of response.results.res) {
              this.csvArray.push({
                "Salesperson Name": val.fullname,
                "Phone": val.phone,
                "Email": val.email,
                "Fax": val.fax,
                "Laboratory": val.parent_laboratory_name,
                "Status": val.status_text,
                "Updated On": moment(val.updatedon_datetime).format("MM/DD/YYYY"),
              });
            }
          }
          if (this.usertype_selected == 'Leads') {
            for (const val of response.results.res) {
              this.csvArray.push({
                "Patient Name": val.patient_name,
                "Patient Email": val.patient_email,
                "Doctor Name": val.doctor_name,
                "Practice Name": val.practice_name,
                "Laboratory Name": val.laboratory_name,
                "Source": val.intake_source,
                "Created On": moment(val.createdon_datetime).format("MM/DD/YYYY"),
              });
            }
          }
          this.geanareteCsv(this.csvArray);
        }
      }
    })
  }
  reportSummary(statusval: any, type: string, from?: string) {

    console.log("reportSummary", statusval, type, from)
    this.newAddButton.classList.remove('show');

    this.cookieService.set('currentUser', type);
    this.cookieService.set('currentUserStatus', statusval);
    if (this.demoDoctorDashboard) {
      this.cookieService.set('demoView', 'true')
    } else {
      this.cookieService.set('demoView', 'false')
    }

    if (statusval) {
      this.reportSummarySelected = statusval;
      this.selectedOrderSummary = statusval;

      this.labDemoReportSummarySelected = null;
      this.labDemoSelectedOrderSummary = null;
    }

    this.UsersSummaryLoader = true;
    this.usertypes(type);
    this.tabledatalist = []
    let data: any = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {

      }
    }
    this.libdata.hidedeletebutton = true;
    this.libdata.hideviewbutton = true;
    this.libdata.hideeditbutton = true;
    this.libdata.hidestatustogglebutton = true;
    this.libdata.hidemultipleselectbutton = true;
    this.libdata.custombuttons = [
      {
        label: "Patient Folder View",
        type: "listner",
        id: "preview_btn",
        classname: 'OpenFolderView',
        tooltip: 'Patient Folder View',
      }

    ];



    if (this.cookieData) {
      if (this.cookieData.user_type == "laboratory") {

        if (statusval && statusval == 3) {
          this.libdata.custombuttons = [
            {
              label: "Approve",
              type: "listner",
              id: "approve_btn",
              classname: 'approveButton',
              tooltip: 'Status Update',
            },
            {
              label: "Patient Folder View",
              type: "listner",
              id: "patientFolderView",
              classname: 'OpenFolderView',
              tooltip: 'Patient Folder View',
            },

          ]
        }

        if (statusval && statusval == 4) {
          this.libdata.custombuttons = [
            {
              label: "Add Test Results",
              type: "listner",
              id: "markasdone_btn",
              classname: 'markasdoneButton',
              tooltip: 'Add Test Results',
            },
            {
              label: "Reverse Status",
              type: "listner",
              id: "markasdone_reversestatus",
              classname: 'reversestatusButton',
              tooltip: 'Reverse Status',
            },
            {
              label: "Patient Folder View",
              type: "listner",
              id: "patientFolderView",
              classname: 'OpenFolderView',
              tooltip: 'Patient Folder View',
            },
          ]
        }

        if (statusval && statusval == 5) {
          this.libdata.custombuttons = [
            {
              label: "Reverse Status",
              type: "listner",
              id: "markasdone_reversestatus",
              classname: 'reversestatusButton',
              tooltip: 'Reverse Status',
            },
            {
              // label: "Preview For rejection",
              type: 'action',
              // id: "folderview",
              datatype: 'api',
              endpoint: 'intake/previewrejectdata',
              otherparam: [],
              // cond: 'usertypecond',
              // condval: 1,
              param: '_id',
              datafields: ['notes'],
              //  refreshdata: true,
              headermessage: 'Preview For Rejection',
              tooltip: 'Preview For rejection',
              classname: 'previewButton'

            },
            {
              label: "Patient Folder View",
              type: "listner",
              id: "patientFolderView",
              classname: 'OpenFolderView',
              tooltip: 'Patient Folder View',
            },
          ]
        }
        if (statusval && statusval == 6) {
          this.libdata.custombuttons = [
            // {
            //   label: "Preview",
            //   type: "listner",
            //   id: "preview_btn",
            //   classname: 'previewButton',
            //   tooltip: 'Preview',
            // },
            {
              label: "Patient Folder View",
              type: "listner",
              id: "patientFolderView",
              classname: 'OpenFolderView',
              tooltip: 'Patient Folder View',
            },
            {
              label: "Update Test Results",
              type: "listner",
              id: "edit_clinicalresults",
              classname: 'markasdoneButton',
              tooltip: 'Update Test Results',
            },
          ]
        }
      }

      if (this.cookieData.user_type == "doctor" || this.cookieData.user_type == "admin" || this.cookieData.user_type == "practice" || this.cookieData.user_type == "sales_person") {
        if (statusval) {
          if (statusval == 3 || statusval == 4 || statusval == 6) {
            this.libdata.custombuttons = [
              {
                label: "Patient Folder View",
                type: "listner",
                id: "patientFolderView",
                classname: 'OpenFolderView',
                tooltip: 'Patient Folder View',
              },
            ]
          }
          if (statusval == 5) {
            this.libdata.custombuttons = [
              {
                // label: "Preview For rejection",
                type: 'action',
                // id: "folderview",
                datatype: 'api',
                endpoint: 'intake/previewrejectdata',
                otherparam: [],
                // cond: 'usertypecond',
                // condval: 1,
                param: '_id',
                datafields: ['notes'],
                //  refreshdata: true,
                headermessage: 'Preview For Rejection',
                tooltip: 'Preview For rejection',
                classname: 'previewButton'

              },
              {
                label: "Patient Folder View",
                type: "listner",
                id: "patientFolderView",
                classname: 'OpenFolderView',
                tooltip: 'Patient Folder View',
              }
            ]
          }
        }

      }
      if (statusval) {
        if (statusval == 2 || statusval == 3 || statusval == 4 || statusval == 5 || statusval == 6) {
          this.libdata.custombuttons.push({
            label: "Download Patient Details", //Clinical Report
            type: "listner",
            id: "download_patient_details",
            classname: 'download_patient_details_button',
            tooltip: 'Download Patient Details',
          },)
        }
      }
    }

    this.sortdata = {
      type: "desc",
      field: "_id",
      options: ['patient_name', 'patient_email', "createdon_datetime"]
    };
    this.datacollection = 'intake/leadlist'
    this.libdata.basecondition = {};
    this.libdata.basecondition.status = this.reportSummarySelected;
    if (this.libdata.basecondition.status == 3) {
      this.libdata.basecondition.status = { $in: [3, 7] }
    }
    this.libdata.tableheaders = ['patient_name', 'patient_email', 'doctor_name', 'practice_name', 'laboratory_name', 'intake_source', 'createdon_datetime']
    this.modify_header_array = {
      'patient_name': "Patient Name",
      'patient_email': "Patient Email",
      'doctor_name': "Doctor Name",
      'practice_name': "Practice Name",
      'laboratory_name': "Laboratory Name",
      'intake_source': "Source",
      'createdon_datetime': "Created On",
    }
    this.limitcond = {
      limit: 10,
      skip: 0,
      pagecount: 1
    };



    // console.log("this.libdata", this.libdata);
    this.isLead = false;
    // if (from !== 'isResolve') {
    if (this.cookieData.user_type == 'practice') {
      data.searchcondition = { practice_id: this.cookieData._id, status: statusval }
    }
    if (this.cookieData.user_type == 'doctor') {
      data.searchcondition = {
        $or: [{ doctor_id: this.cookieData._id }], //{ practice_id: this.cookieData.practice_id }
        status: statusval
      }
    }
    if (this.cookieData.user_type == 'laboratory') {
      data.searchcondition = { parent_laboratory: this.cookieData._id, status: statusval }
    }
    if (this.cookieData.user_type == 'sales_person' && !this.demoDoctorDashboard) {
      data.searchcondition = { parent_laboratory: this.cookieData.parent_laboratory, status: statusval }
    }
    if (this.cookieData.user_type == 'admin') {
      data.searchcondition = { status: statusval }
    }
    if (statusval == 3) {
      data.searchcondition.status = { $in: [3, 7] }
    }
    data.searchcondition.is_demo = false;
    if (this.cookieData.user_type == 'sales_person' && this.demoDoctorDashboard) {
      data.searchcondition = {
        $or: [{ doctor_id: this.cookieData._id }],
        is_demo: true,
        status: statusval
      }
    }
    data.searchcondition['is_partial_done'] = false;

    if (type == 'ordersheetgenerated') { }
    if (type == 'leads') {
      this.isLead = true;
      this.httpService.httpViaPost('intake/leadlist', data).subscribe((response: any) => {
        // this.progressLoader = true;
        // console.log(response)
        if (response) {
          // console.log("tabledatalist", response);
          if (response.status == 'success') {
            this.UsersSummaryLoader = false;
            this.tabledatalist = response.results.res;
            // if (this.cookieData.user_type == "doctor") {
            if (statusval) {
              if (statusval == 3) {
                if (this.tabledatalist.length > 0) {
                  setTimeout(() => {
                    this.tabledatalist.forEach((e: any, i: number) => {
                      if (e.status == 7) {
                        let tableRow = document.querySelectorAll('tr');
                        if (tableRow.length > 0) {
                          tableRow.forEach((f: any, j: number) => {
                            tableRow[i + 1].classList.add('reOrderRow');
                          })
                        }
                      }
                    })
                  }, 500)
                }
              }
            }
            // }
          }
          // this.UsersSummaryLoader = false;       
          // if (this.cookieData && (this.cookieData.user_type == 'sales_person')) {
          //             if (window.location.pathname.includes('dashboard/doctor')) {
          if (this.cookieData.user_type == 'practice' || this.cookieData.user_type == 'doctor' || window.location.pathname.includes('dashboard/doctor')) {
            if (statusval) {
              setTimeout(() => {
                let containerCard = document.querySelector(".adminlisttable_v2 .container .mdc-card");
                // console.log("downloadCSVButton", containerCard)

                if (statusval == 1) {
                  this.addingPatientButton.classList.add('show');
                  if (containerCard) {
                    containerCard.prepend(this.addingPatientButton);
                  } else {
                    let noFoundTextinner = document.querySelector(".noFoundTextinner");
                    noFoundTextinner.prepend(this.addingPatientButton)
                  }
                }
              }, 500)
            }
          }
          if (this.cookieData.user_type) {
            setTimeout(() => {
              let containerCard = document.querySelector(".adminlisttable_v2 .container .mdc-card");
              console.log("downloadCSVButton", containerCard, this.cookieData.user_type, this.reportSummarySelected)
              if (containerCard) {
                this.downloadCSVButton.classList.add("show");
                if (this.cookieData.user_type == 'admin') {
                  this.downloadCSVButton.classList.add("patientBlock");
                }
                if (this.cookieData.user_type == 'doctor' || this.cookieData.user_type == 'practice') {
                  this.downloadCSVButton.classList.add("patientBlock");
                  if (this.reportSummarySelected == 1) {
                    this.downloadCSVButton.classList.remove("patientBlock");
                  }
                }
                if (this.cookieData.user_type == 'laboratory') {
                  this.downloadCSVButton.classList.add("patientBlock");
                }
                if (this.cookieData.user_type == 'sales_person' && this.demoDoctorDashboard) {
                  this.downloadCSVButton.classList.add("patientBlock");
                  if (this.reportSummarySelected == 1) {
                    this.downloadCSVButton.classList.remove("patientBlock");
                  }
                }
                containerCard.prepend(this.downloadCSVButton)
              }
            }, 500)
          }
        }
        // this.progressLoader = false;
      })

      if (this.cookieData) {
        this.isAdminUser = true;
        this.libdata.notes = {
          label: "Notes",
          tooltip: 'Add Notes',
          listendpoint: "intake/listleadnotedata",
          deleteendpoint: "intake/deleteleadnotedata",
          addendpoint: "intake/addleadsnotes",
          user: this.cookieData._id,
          currentuserfullname: this.cookieData.firstname + " " + this.cookieData.lastname,
          header: "patient_name",
        }
        switch (this.cookieData.user_type) {
          case "laboratory":
            this.libdata.notes.currentuserfullname = this.cookieData.name
            break;
          case "practice":
            this.libdata.notes.currentuserfullname = this.cookieData.name
            break;

        }
      }


    }

    this.httpService.httpViaPost('intake/leadlist-count', data).subscribe((response: any) => {
      // console.log(response)
      // this.progressLoader = true;
      if (response) {
        // this.progressLoader = true;
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })
    // }
    if (this.cookieData) {

      if (this.cookieData.user_type == 'laboratory') {
        this.libdata.basecondition = { parent_laboratory: this.cookieData._id, status: statusval }
      }
      else if (this.cookieData.user_type == 'doctor') {
        this.libdata.basecondition = {
          $or: [{ doctor_id: this.cookieData._id }], //{ practice_id: this.cookieData.practice_id }
          status: statusval
        }
      }
      else if (this.cookieData.user_type == 'practice') {
        this.libdata.basecondition = { practice_id: this.cookieData._id, status: statusval }
      }
      if (statusval == 3) {
        this.libdata.basecondition.status = { $in: [3, 7] }
      }
    }



    // console.log("isLead", this.isLead);

    if (this.isLead) {
      // console.log('aaaaaaaaaaaaaaaa', this.isLead);
      // this.sortdata = {
      //   type: "desc",
      //   field: "_id",
      //   options: ['patient_name', 'patient_email', 'doctor_name', 'practice_name', "createdon_datetime",],
      // };
    }

  }


  labDemoreportSummary(statusval: any, type: string, from?: string) {

    console.log("reportSummary", statusval, type, from)
    this.newAddButton.classList.remove('show');

    this.cookieService.set('currentUser', type);
    this.cookieService.set('currentUserStatus', statusval);
    if (this.demoDoctorDashboard) {
      this.cookieService.set('demoView', 'true')
    } else {
      this.cookieService.set('demoView', 'false')
    }

    if (statusval) {
      this.labDemoReportSummarySelected = statusval;
      this.labDemoSelectedOrderSummary = statusval;
      this.reportSummarySelected = null;
      this.selectedOrderSummary = null;
    }

    this.UsersSummaryLoader = true;
    this.usertypes(type);
    this.tabledatalist = []
    let data: any = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {

      }
    }
    this.libdata.hidedeletebutton = true;
    this.libdata.hideviewbutton = true;
    this.libdata.hideeditbutton = true;
    this.libdata.hidestatustogglebutton = true;
    this.libdata.hidemultipleselectbutton = true;
    this.libdata.custombuttons = [
      {
        label: "Patient Folder View",
        type: "listner",
        id: "preview_btn",
        classname: 'OpenFolderView',
        tooltip: 'Patient Folder View',
      }

    ];



    if (this.cookieData) {

      if (statusval && statusval == 3) {
        this.libdata.custombuttons = [
          {
            label: "Approve",
            type: "listner",
            id: "approve_btn",
            classname: 'approveButton',
            tooltip: 'Status Update',
          },
          {
            label: "Patient Folder View",
            type: "listner",
            id: "patientFolderView",
            classname: 'OpenFolderView',
            tooltip: 'Patient Folder View',
          },

        ]
      }

      if (statusval && statusval == 4) {
        this.libdata.custombuttons = [
          {
            label: "Add Test Results",
            type: "listner",
            id: "markasdone_btn",
            classname: 'markasdoneButton',
            tooltip: 'Add Test Results',
          },
          {
            label: "Reverse Status",
            type: "listner",
            id: "markasdone_reversestatus",
            classname: 'reversestatusButton',
            tooltip: 'Reverse Status',
          },
          {
            label: "Patient Folder View",
            type: "listner",
            id: "patientFolderView",
            classname: 'OpenFolderView',
            tooltip: 'Patient Folder View',
          },
        ]
      }

      if (statusval && statusval == 5) {
        this.libdata.custombuttons = [
          {
            label: "Reverse Status",
            type: "listner",
            id: "markasdone_reversestatus",
            classname: 'reversestatusButton',
            tooltip: 'Reverse Status',
          },
          {
            // label: "Preview For rejection",
            type: 'action',
            // id: "folderview",
            datatype: 'api',
            endpoint: 'intake/previewrejectdata',
            otherparam: [],
            // cond: 'usertypecond',
            // condval: 1,
            param: '_id',
            datafields: ['notes'],
            //  refreshdata: true,
            headermessage: 'Preview For Rejection',
            tooltip: 'Preview For rejection',
            classname: 'previewButton'

          },
          {
            label: "Patient Folder View",
            type: "listner",
            id: "patientFolderView",
            classname: 'OpenFolderView',
            tooltip: 'Patient Folder View',
          },
        ]
      }
      if (statusval && statusval == 6) {
        this.libdata.custombuttons = [
          // {
          //   label: "Preview",
          //   type: "listner",
          //   id: "preview_btn",
          //   classname: 'previewButton',
          //   tooltip: 'Preview',
          // },
          {
            label: "Patient Folder View",
            type: "listner",
            id: "patientFolderView",
            classname: 'OpenFolderView',
            tooltip: 'Patient Folder View',
          },
          // {
          //   label: "Update Test Results",
          //   type: "listner",
          //   id: "edit_clinicalresults",
          //   classname: 'markasdoneButton',
          //   tooltip: 'Update Test Results',
          // },
        ]
      }


      if (statusval) {
        if (statusval == 2 || statusval == 3 || statusval == 4 || statusval == 5 || statusval == 6) {
          this.libdata.custombuttons.push({
            label: "Download Patient Details", //Clinical Report
            type: "listner",
            id: "download_patient_details",
            classname: 'download_patient_details_button',
            tooltip: 'Download Patient Details',
          },)
        }
      }
    }

    this.sortdata = {
      type: "desc",
      field: "_id",
      options: ['patient_name', 'patient_email', "createdon_datetime"]
    };
    this.datacollection = 'intake/leadlist'
    this.libdata.basecondition = {};
    this.libdata.basecondition.status = this.labDemoReportSummarySelected;
    if (this.libdata.basecondition.status == 3) {
      this.libdata.basecondition.status = { $in: [3, 7] }
    }
    this.libdata.tableheaders = ['patient_name', 'patient_email', 'doctor_name', 'practice_name', 'laboratory_name', 'intake_source', 'createdon_datetime']
    this.modify_header_array = {
      'patient_name': "Patient Name",
      'patient_email': "Patient Email",
      'doctor_name': "Doctor Name",
      'practice_name': "Practice Name",
      'laboratory_name': "Laboratory Name",
      'intake_source': "Source",
      'createdon_datetime': "Created On",
    }
    this.limitcond = {
      limit: 10,
      skip: 0,
      pagecount: 1
    };



    // console.log("this.libdata", this.libdata);
    this.isLead = false;


    if (this.cookieData.user_type == 'sales_person') {
      data.searchcondition = { parent_laboratory: this.cookieData.parent_laboratory, status: statusval, is_demo: true }
    }

    if (statusval == 3) {
      data.searchcondition.status = { $in: [3, 7] }
    }
    data.searchcondition['is_partial_done'] = false;
    if (type == 'leads') {
      this.isLead = true;
      this.httpService.httpViaPost('intake/leadlist', data).subscribe((response: any) => {
        // this.progressLoader = true;
        // console.log(response)
        if (response) {
          // console.log("tabledatalist", response);
          if (response.status == 'success') {
            this.UsersSummaryLoader = false;
            this.tabledatalist = response.results.res;
            // if (this.cookieData.user_type == "doctor") {
            if (statusval) {
              if (statusval == 3) {
                if (this.tabledatalist.length > 0) {
                  setTimeout(() => {
                    this.tabledatalist.forEach((e: any, i: number) => {
                      if (e.status == 7) {
                        let tableRow = document.querySelectorAll('tr');
                        if (tableRow.length > 0) {
                          tableRow.forEach((f: any, j: number) => {
                            tableRow[i + 1].classList.add('reOrderRow');
                          })
                        }
                      }
                    })
                  }, 500)
                }
              }
            }
            // }
          }
          // this.UsersSummaryLoader = false;       
          // if (this.cookieData && (this.cookieData.user_type == 'sales_person')) {
          //             if (window.location.pathname.includes('dashboard/doctor')) {

          if (statusval) {
            setTimeout(() => {
              let containerCard = document.querySelector(".adminlisttable_v2 .container .mdc-card");
              // console.log("downloadCSVButton", containerCard)

              if (statusval == 1) {
                this.addingPatientButton.classList.add('show');
                if (containerCard) {
                  containerCard.prepend(this.addingPatientButton);
                } else {
                  let noFoundTextinner = document.querySelector(".noFoundTextinner");
                  noFoundTextinner.prepend(this.addingPatientButton)
                }
              }
            }, 500)
          }
          if (this.cookieData.user_type) {
            setTimeout(() => {
              let containerCard = document.querySelector(".adminlisttable_v2 .container .mdc-card");
              console.log("downloadCSVButton", containerCard, this.cookieData.user_type, this.labDemoReportSummarySelected)
              if (containerCard) {
                this.downloadCSVButton.classList.add("show");
                if (this.cookieData.user_type == 'admin') {
                  this.downloadCSVButton.classList.add("patientBlock");
                }
                if (this.cookieData.user_type == 'doctor' || this.cookieData.user_type == 'practice') {
                  this.downloadCSVButton.classList.add("patientBlock");
                  if (this.labDemoReportSummarySelected == 1) {
                    this.downloadCSVButton.classList.remove("patientBlock");
                  }
                }
                if (this.cookieData.user_type == 'laboratory') {
                  this.downloadCSVButton.classList.add("patientBlock");
                }
                containerCard.prepend(this.downloadCSVButton)
              }
            }, 500)
          }
        }
        // this.progressLoader = false;
      })

      if (this.cookieData) {
        this.isAdminUser = true;
        this.libdata.notes = {
          label: "Notes",
          tooltip: 'Add Notes',
          listendpoint: "intake/listleadnotedata",
          deleteendpoint: "intake/deleteleadnotedata",
          addendpoint: "intake/addleadsnotes",
          user: this.cookieData._id,
          currentuserfullname: this.cookieData.firstname + " " + this.cookieData.lastname,
          header: "patient_name",
        }

      }


    }

    this.httpService.httpViaPost('intake/leadlist-count', data).subscribe((response: any) => {
      // console.log(response)
      // this.progressLoader = true;
      if (response) {
        // this.progressLoader = true;
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })


  }

  openUserInAnotherTab(user: string) {

    // console.log("user", user);
    // if (user == "leads" && statusval) {
    //   let url: string = this.cookieData.user_type + "/lead-list";
    //   window.open(url, "_blank")
    //   console.log("url", url);
    // }

    let url: string = this.cookieData.user_type + "/user-management/users/" + user;
    window.open(url, "_blank")



  }
  patientOpenUserInAnotherTab(statusval: number, user: string) {
    if (statusval) {
      // console.log("user", statusval);
    }

    // return
    if (user == "leads") {
      let url: string = null;
      if (this.demoDoctorDashboard) {
        url = this.cookieData.user_type + "/doctor/lead-list" + "/" + statusval;
      } else {
        url = this.cookieData.user_type + "/lead-list" + "/" + statusval;
      }
      window.open(url, "_blank")
      // console.log("url", url);
    }
  }



  listenLiblistingChange(data: any = null) {
    // console.log("listenLiblistingChange", data.action);

    console.log("listenLiblistingChange", data);
    if (typeof data.limitdata != "undefined") {
      this.globalPagination = {
        'limit': data.limitdata.limit,
        'skip': data.limitdata.skip
      }
    }


    if (data && data.action == 'custombuttonclick') {
      if (data.custombuttonclick.btninfo.id == "preview_btn") {
        // this.router.navigateByUrl(`${this.cookieData.user_type}/preview-intake/${data.custombuttonclick.data._id}`);
        window.open(`${this.cookieData.user_type}/preview-intake/${data.custombuttonclick.data._id}`, '_blank')
      }
      if (data.custombuttonclick.btninfo.id == "approve_btn") {
        const bottomSheetRef = this._bottomSheet.open(ApproveDialog, {
          data: data.custombuttonclick.data,
        });
        bottomSheetRef.afterDismissed().subscribe(x => {
          // console.log("bottomSheetRef afterDismissed",x)
          if (x) {
            if (x == "accept") {
              //  this.modalFlag = 3
              const dialogRef = this.dialog.open(ConfirmModal, {
                data: { dataset: data.custombuttonclick.data, tbnid: data.custombuttonclick.btninfo.id },
                panelClass: 'confirm-modal',

              });

              dialogRef.afterClosed().subscribe(result => {
                console.log("result test +++++", result);
                if (result) {
                  this.refreshSearchedList()
                }
              });

              // this.refreshSearchedList()
            }
            else {
              const dialogRef = this.dialog.open(RejectNotes, {
                data: data.custombuttonclick.data,
                panelClass: 'reject-notes-modal'
              });

              dialogRef.afterClosed().subscribe(result => {
                console.log("result test +++++", result);
                if (result) {
                  this.refreshSearchedList()
                }
              });
            }

          }
        });
      }
      if (data && data.custombuttonclick.btninfo.id == "markasdone_btn") {

        const dialogRef = this.dialog.open(ConfirmModal, {
          data: { dataset: data.custombuttonclick.data, tbnid: data.custombuttonclick.btninfo.id, type: 'markasdone_btn' },
          panelClass: 'confirm-modal',

        });

        dialogRef.afterClosed().subscribe(result => {
          console.log("result test +++++", result);
          if (result) {
            this.refreshSearchedList()
          }
        });


      }

      if (data && data.custombuttonclick.btninfo.id == "markasdone_reversestatus") {
        const dialogRef = this.dialog.open(ConfirmModal, {
          data: { dataset: data.custombuttonclick.data, tbnid: data.custombuttonclick.btninfo.id },
          panelClass: 'confirm-modal',

        });

        dialogRef.afterClosed().subscribe(result => {
          console.log("result test +++++", result);
          if (result) {
            this.refreshSearchedList()
          }
        });

      }

      if (data && data.custombuttonclick.btninfo.id == "edit_clinicalresults") {
        const dialogRef = this.dialog.open(ConfirmModal, {
          data: { dataset: data.custombuttonclick.data, tbnid: data.custombuttonclick.btninfo.id },
          panelClass: ['confirm-modal', 'fileUploadModal'],
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log("result test +++++", result);
          if (result) {
            this.resolveService.reportUpload(true);
          }
        });
      }

    }

    if (data.action == 'delete') {
      if (data.totalresults.status == "success") {
        this.userCount()
      }
    }

    if (data.action == 'custombuttonclick') {
      if (data.custombuttonclick.btninfo.id == "edit_btn") {
        this.progressLoader = true

        this.editListItem(data.custombuttonclick.data)
      }
    }

    if (data.action == 'custombuttonclick') {
      if (data.custombuttonclick.btninfo.id == "folderview") {
        this.progressLoader = true;
        this.FolderViewListItem(data.custombuttonclick.data);
      }
      if (data.custombuttonclick.btninfo.id == "patientFolderView") {
        this.progressLoader = true;
        window.open(`${this.cookieData.user_type}/patient-folderview/${data.custombuttonclick.data._id}`, '_blank')
      }
      if (data.custombuttonclick.btninfo.id == "download_patient_details") {

        const dialogRef = this.dialog.open(ConfirmModal, {
          data: { patientID: data.custombuttonclick.data._id },
          panelClass: 'confirm-modal',
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log("result test +++++", result);
          if (result) { }
        })

        // this.UsersSummaryLoader = true;

        // let dataPayload: any = {
        //   "_id": data.custombuttonclick.data._id
        // }
        // this.httpService.httpViaPost('intake/getcompletereportdata', dataPayload).subscribe((response: any) => {
        //   console.log("dowload respose ", response)
        //   if (response) {
        //     this.UsersSummaryLoader = false;
        //     this.reportLink = response.report_download_link;
        // const dialogRef = this.dialog.open(ConfirmModal, {
        //   data: { reportLink: this.reportLink },
        //   panelClass: 'confirm-modal',
        // });

        // dialogRef.afterClosed().subscribe(result => {
        //   console.log("result test +++++", result);
        //   if (result) { }
        // })

        // this.matSnackBar.open(response.msg, null, {
        //   duration: 3000,
        // });
        // }
        // })

      }

    }


    if (data.action == "multipleselectionchange") {
      const actionbuttonwrapperDiv = document.querySelector('.tablewrapper');
      if (data.selecteddata.length > 0) {
        actionbuttonwrapperDiv.classList.add("remove");
      } else {
        actionbuttonwrapperDiv.classList.remove("remove");
      }
    }
    if (data.action == "paging") {
      // console.log("this.reportSummarySelected", this.reportSummarySelected)
      if (this.reportSummarySelected) {
        this.libdata.basecondition.status = this.reportSummarySelected;
        if (this.libdata.basecondition.status == 3) {
          this.libdata.basecondition.status = { $in: [3, 7] }
        }
      } else {
        this.libdata.basecondition.user_type = [this.userTypeInSearchCondition.user_type[0]];
      }
      // data.searchcondition = this.userTypeInSearchCondition;
      if (this.cookieData.user_type == 'laboratory') {
        this.libdata.basecondition.parent_laboratory = this.cookieData._id;
      }
      if (this.cookieData.user_type == 'practice') {
        this.libdata.basecondition.practice_id = this.cookieData._id;
      }
      if (this.cookieData.user_type == 'doctor') {
        this.libdata.basecondition.$or = [{ doctor_id: this.cookieData._id }] //{ practice_id: this.cookieData.practice_id }

      }
      if (this.tabledatalist.length > 0) {
        setTimeout(() => {
          this.tabledatalist.forEach((e: any, i: number) => {
            if (e.status == 7) {
              let tableRow = document.querySelectorAll('tr');
              if (tableRow.length > 0) {
                tableRow.forEach((f: any, j: number) => {
                  tableRow[i + 1].classList.add('reOrderRow')
                })
              }
            }
          })
        }, 500)
      }
      // this.useSummary(this.userTypeInSearchCondition.user_type[0])
    }
    // this.userTypeInSearchCondition
    if (data.action == "search") {
      if (this.tabledatalist.length > 0) {
        setTimeout(() => {
          this.tabledatalist.forEach((e: any, i: number) => {
            if (e.status == 7) {
              let tableRow = document.querySelectorAll('tr');
              if (tableRow.length > 0) {
                tableRow.forEach((f: any, j: number) => {
                  tableRow[i + 1].classList.add('reOrderRow')
                })
              }
            }
          })
        }, 500)
      }
    }

  }


  // markAsDone(){
  //   let data: any = {
  //     data: {
  //       // _id: this.data.custombuttonclick._id,
  //       status: 6
  //     }
  //   }
  //   this.httpService.httpViaPost('intake/leadstatuschange', data).subscribe((response: any) => {
  //     console.log("finduniquecodebyuser", response)
  //     if (response.status == "success") {
  //     }

  //   })
  // }


  FolderViewListItem(item: any) {
    if (item) {
      // console.log("folderlink", item);
      this.router.navigateByUrl(`${this.cookieData.user_type}/user-management/folder-view/${item._id}`);
    }

  }

  editListItem(item: any) {
    // console.log("Edit item", item);

    this.router.navigateByUrl(`${this.cookieData.user_type}/user-management/${item.usertype.toLowerCase().replace(/ /g, "_")}/edit/${item._id}`);
    // console.log("cookieData", this.cookieData);
    // console.log("link", `${this.cookieData.user_type}/user-management/${item.usertype.toLowerCase().replace(/ /g, "_") }/edit/${item._id}`);



  }
  onLiblistingButtonChange(data: any) {
    // console.log("onLiblistingButtonChangebutton", data);
  }




  userCount() {
    this.UsersSummaryLoader = true
    let dataobj: any = {};
    let endpoint: string = 'users/admin-dashboard-user-summary';

    if (this.cookieData.user_type == 'laboratory') {
      // console.log("this.cookieData.user_type", this.cookieData)
      dataobj.parent_laboratory = this.cookieData._id;
      dataobj['is_partial_done'] = false;
      endpoint = 'users/labdashboardusersummary';
    }
    if (this.cookieData.user_type == 'practice') {
      dataobj.practice_id = this.cookieData._id;
      dataobj['is_partial_done'] = false;
      endpoint = 'users/practicedashboardusersummary'
    };
    if (this.cookieData.user_type == 'sales_person' && !this.demoDoctorDashboard) {
      dataobj.salesperson_id = this.cookieData._id;
      dataobj.parent_laboratory = this.cookieData.parent_laboratory;
      dataobj['is_partial_done'] = false;
      endpoint = 'users/salesdashboardusersummary'
    };

    if (this.cookieData.user_type == 'doctor') {
      dataobj.doctor_id = this.cookieData._id;
      dataobj.practice_id = this.cookieData.practice_id
      dataobj['is_partial_done'] = false;
      endpoint = 'users/doctordashboardsummary'
    };
    console.log("this.demoDoctorDashboard555", this.demoDoctorDashboard)
    if (this.cookieData.user_type == 'sales_person' && this.demoDoctorDashboard) {
      dataobj.doctor_id = this.cookieData._id;
      dataobj.practice_id = this.cookieData._id;
      dataobj.parent_laboratory = this.cookieData.parent_laboratory;
      dataobj['is_partial_done'] = false;
      endpoint = 'users/demodoctordashboardsummary';

    }
    this.httpService.httpViaPost(endpoint, dataobj).subscribe((response) => {
      // console.log("responseuserCount", response);
      if (response && response.status == 'success') {


        if (this.cookieData.user_type == 'practice') {
          this.usertypecount = response.data;


          // console.log('usertypecount', this.usertypecount.leads)

        } else if (this.cookieData.user_type == 'doctor') {
          // console.log("this.usertypecount", this.usertypecount);
          if (response) {
            this.usertypecount = response.data;
            // console.log('usertypecount', this.usertypecount)
          }


        }

        else if (this.cookieData.user_type == 'sales_person') {
          this.usertypecount = response.data;
        } else {
          this.usertypecount = response.data;
        }
        this.UsersSummaryLoader = false
      }
    })
  }



  uniqueData() {

    let data: any = {
      searchcondition: {
        user_id: this.cookieData._id
      }
    }
    this.httpService.httpViaPost('landingpage/finduniquecodebyuser', data).subscribe((response: any) => {
      if (response) {
        if (response.results.length == 0) {
          this.btnflag = true;
        } else {
          this.btnflag = false;
        }

        this.UniqueLIstData = response.results
        console.log("finduniquecodebyuser", this.UniqueLIstData)
      }
    })
  }

  copyLink(code) {
    let linkVal: any = ''
    linkVal = this.signupLink + code;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = linkVal;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let message: any = '" ' + linkVal + ' " ' + " Copied to Clipboard!";
    let action: any = "OK";
    this.matSnackBar.open(message, action, {
      duration: 3000,
    });
  }

  copyLinkpac(code) {
    let linkVal: any = ''
    linkVal = this.signupLinkpac + code;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = linkVal;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let message: any = '" ' + linkVal + ' " ' + " Copied to Clipboard!";
    let action: any = "OK";
    this.matSnackBar.open(message, action, {
      duration: 3000,
    });
  }




  openUniqueNameDialog(uniquecode: any, dataflag: any) {
    const dialogRef = this.dialog.open(UniqueNameDialog, {
      panelClass: 'UniqueName-modal',
      data: { data: uniquecode, flag: dataflag },
    })
    // this.dialog.open(UniqueNameDialog);
    dialogRef.afterClosed().subscribe(result => {
      this.uniqueData();
    });
  }

  switchDoctor() {
    console.log("sales person doctor", this.demoDoctorDashboard);
    if (this.demoDoctorDashboard) {
      this.router.navigateByUrl(`${this.cookieData.user_type}/dashboard`);
      this.cookieService.set('currentUser', 'practice');
    } else {
      this.cookieService.set('currentUser', 'leads');
      window.open(`${this.cookieData.user_type}/dashboard/doctor`, '_blank');
    }

  }


  resetPatient() {

    this.blockUI.start('Reseting all data, Please Wait...');
    let dataSet: any = {
      is_demo: true,
      doctor_id: this.cookieData._id
    }

    this.httpService.httpViaPost('intake/resetdemopatient', dataSet).subscribe((response: any) => {
      if (response.status == 'success') {
        let dataobj: any = {
          doctor_id: this.cookieData._id,
          practice_id: this.cookieData._id,
          parent_laboratory: this.cookieData.parent_laboratory
        }
        this.httpService.httpViaPost('users/demodoctordashboardsummary', dataobj).subscribe((response) => {
          // console.log("responseuserCount", response);
          if (response && response.status == 'success') {

            if (this.cookieData.user_type == 'sales_person') {
              this.usertypecount = response.data;
            } else {
              this.usertypecount = response.data;
            }
            this.UsersSummaryLoader = false
          }
          this.blockUI.stop();
          this.matSnackBar.open('Reset Successfully !', 'Ok', {
            duration: 3000,
          });
        })
      }

      // <<<<<<< HEAD
      // this.progressLoader = false;
    })
  }


}


@Component({
  selector: 'addedituniquename',
  templateUrl: 'addedituniquename.html',
})
export class UniqueNameDialog {
  public addEditUniqueCodeForm: FormGroup;
  public laboratoryData: any = null;
  public progressBar: any = false;
  public cookieData: any = {};
  public Uniquemessage: any = {};
  constructor(
    public dialogRef: MatDialogRef<UniqueNameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public activateRoute: ActivatedRoute,
    private httpService: HttpServiceService,
    public cookieService: CookieService,
  ) {
    console.log('DDD>>', data);


  }

  ngOnInit() {


    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      // console.log("this.cookieData>>>>", this.cookieData)


      // this.firstBlockData = this.cookieData.last_login_time;

    };


    this.addEditUniqueCodeForm = new FormGroup({
      uniquecode: new FormControl(''),
      // user_id: new FormControl(this.cookieData._id),
    });

    // this.uniqueData()

    if (this.data.flag == "edit") {
      // this.addEditUniqueCodeForm.controls['uniquecode'].patchValue(this.data.data.uniquecode)
      this.addEditUniqueCodeForm.controls['uniquecode'].patchValue(this.data.data.uniquecode)
    }




  }


  public onKeydownMain(event): void {
    if (!event.shiftKey && (event.keyCode === 32 || event.key === "Space")) {
      event.preventDefault();
    }
  }


  addEditAdmin() {
    // console.log(">>>>>>>>", this.addEditUniqueCodeForm)
    if (this.addEditUniqueCodeForm.valid) {
      // console.log(">>>>>>>>qq", this.addEditUniqueCodeForm)
      // this.addEditUniqueCodeForm.value.uniquecode = this.addEditUniqueCodeForm.value.uniquecode.replace(/\s/g, "")
      this.progressBar = true;

      let dataobj = {
        // uniquecode: this.addEditUniqueCodeForm.value.uniquecode,
        uniquecode: this.addEditUniqueCodeForm.value.uniquecode.replace(/\s/g, ""),
        user_id: this.cookieData._id,
      }


      // console.log("dataobj.uniquecode", dataobj.uniquecode);
      // if (dataobj.uniquecode){
      //   dataobj.uniquecode = dataobj.uniquecode.replace(/\s/g, "")
      // }

      if (this.data.flag == "edit") {
        dataobj['_id'] = this.data.data._id;
      }
      this.httpService.httpViaPost('landingpage/setuniquename', dataobj).subscribe((response) => {
        console.log('response>>>', response)
        // return      
        if (response.status == "success") {
          this.dialogRef.close();
          // this.uniqueData()
          this.progressBar = false;

        }
        else {
          this.Uniquemessage = response
          this.progressBar = false;
        }
      })
    }
  }


  uniqueData() {

    let data: any = {
      searchcondition: {
        user_id: this.cookieData._id
      }
    }
    this.httpService.httpViaPost('landingpage/finduniquecodebyuser', data).subscribe((response: any) => {
      console.log("finduniquecodebyuser", response)


    })
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'searchmodalg',
  templateUrl: 'searchmodal.html',
})

export class Searchmodal {
  public modalData: any;
  public userType: any;

  public searchformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public cookieData: any = {};
  public editFormData: any = null;
  public progressLoader: boolean = false;
  userNameModalChange = new Subject<string>();
  practiceNameModalChange = new Subject<string>();
  doctorNameModalChange = new Subject<string>();
  userEmailModalChange = new Subject<string>();
  patientEmailModalChange = new Subject<string>();
  nameArr: any = [];
  practicenameArr: any = [];
  doctornameArr: any = [];
  emailArr: any = [];
  patientemailArr: any = [];
  nameselectedData: any = [];
  praticenameselectedData: any = [];
  doctornameselectedData: any = [];
  emailSelectedData: any = [];
  patientemailSelectedData: any = [];
  nameData: any = [];
  emailData: any = [];
  public nameprogressFlag: boolean = false;
  public practicenameprogressFlag: boolean = false;
  public doctornameprogressFlag: boolean = false;
  public isLead: boolean = false;
  emailprogressFlag: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<Searchmodal>,
    public apiservice: HttpServiceService,
    public activateRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public cookieService: CookieService
  ) {
    this.modalData = data.searchItem;
    this.userType = data.userType;
    this.isLead = data.isLead
    console.log("modal Data test this.modalData", data);

    // console.log("The dialog this.modalData", this.modalData)

  }



  ngOnInit() {

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      // this.firstBlockData = this.cookieData.last_login_time;
    };
    if (this.modalData) {
      if (this.modalData.nameArr && this.modalData.nameArr.length > 0) {
        this.modalData.nameArr.forEach((e: any, i: number) => {
          this.namechooseChipItem(e, i, 'patch');
        })
      }
      if (this.isLead) {
        if (this.modalData.practicenameArr && this.modalData.practicenameArr.length > 0) {
          this.modalData.practicenameArr.forEach((e: any, i: number) => {
            this.praticenamechooseChipItem(e, i, 'patch');
          })
        }
        if (this.modalData.doctornameArr && this.modalData.doctornameArr.length > 0) {
          this.modalData.doctornameArr.forEach((e: any, i: number) => {
            this.doctornamechooseChipItem(e, i, 'patch');
          })
        }
        if (this.modalData.patientemailArr && this.modalData.patientemailArr.length > 0) {
          this.modalData.patientemailArr.forEach((e: any, i: number) => {
            this.patientemailChooseChipItem(e, i, 'patch');
          })
        }
        //  if (this.modalData.emailArr && this.modalData.emailArr.length > 0) {
        //         this.modalData.emailArr.forEach((e: any, i: number) => {
        //           this.patientemailChooseChipItem(e, i, 'patch');
        //         })
        //       }
      } else {
        if (this.modalData.emailArr && this.modalData.emailArr.length > 0) {
          this.modalData.emailArr.forEach((e: any, i: number) => {
            this.emailChooseChipItem(e, i, 'patch');
          })
        }
        // if (this.modalData.patientemailArr && this.modalData.patientemailArr.length > 0) {
        //   this.modalData.patientemailArr.forEach((e: any, i: number) => {
        //     this.emailChooseChipItem(e, i, 'patch');
        //   })
        // }
      }
      if (this.modalData.modalData && this.modalData.modalData.status) this.modalData.status = this.modalData.modalData.status;
      if (this.modalData.modalData && this.modalData.modalData.startdate) this.modalData.startdate = this.modalData.modalData.startdate;
      if (this.modalData.modalData && this.modalData.modalData.enddate) this.modalData.enddate = this.modalData.modalData.enddate;
    }

    if (this.isLead) {
      this.userNameModalChange.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe(value => {
          this.nameprogressFlag = true;
          if (value && value !== '') {
            let data: any = {
              "search_str": value,
              "sort": { "field": "_id", "type": "desc" },
              "allSearchCond": [],
              "basecondition": {
                // user_type: { $in: [this.userType] }
              },
              "datasearch": {},
              "textsearch": {},
              "buttonSearch": {},
              "selectsearch": {},
              "secret": "na",
              "token": ""
            }
            if (this.cookieData.user_type == 'laboratory') {
              data.basecondition.parent_laboratory = this.cookieData._id;
            }
            if (this.cookieData.user_type == 'practice') {
              data.basecondition.practice_id = this.cookieData._id;
            }
            this.apiservice.httpViaPost('intake/patientnameautocomplete', data).subscribe((response) => {
              // console.log("The dialog userNameAutocomplete response", response)
              this.nameprogressFlag = false;
              if (response) {
                this.nameArr = response.res
              }
            })
          }
        });

      this.patientEmailModalChange.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe(value => {
          this.emailprogressFlag = true;
          if (value && value !== '') {
            let data: any = {
              "search_str": value,
              "sort": { "field": "_id", "type": "desc" },
              "allSearchCond": [],
              "basecondition": {
                // user_type: { $in: [this.userType] }
              },
              "datasearch": {},
              "textsearch": {},
              "buttonSearch": {},
              "selectsearch": {},
              "secret": "na",
              "token": ""
            }
            if (this.cookieData.user_type == 'laboratory') {
              data.basecondition.parent_laboratory = this.cookieData._id;
            }
            if (this.cookieData.user_type == 'practice') {
              data.basecondition.practice_id = this.cookieData._id;
            }
            this.apiservice.httpViaPost('intake/patientemailautocomplete', data).subscribe((response) => {
              // console.log("The dialog userEmailAutocomplete response", response)
              this.emailprogressFlag = false;
              if (response) {
                this.patientemailArr = response.res
              }
            })
          }
        });
      this.practiceNameModalChange.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe(value => {
          this.practicenameprogressFlag = true;
          if (value && value !== '') {
            let data: any = {
              "search_str": value,
              "sort": { "field": "_id", "type": "desc" },
              "allSearchCond": [],
              "basecondition": {
                user_type: { $in: ['practice'] }
                // user_type: { $in: 'practice' }
              },
              "datasearch": {},
              "textsearch": {},
              "buttonSearch": {},
              "selectsearch": {},
              "secret": "na",
              "token": ""
            }
            if (this.cookieData.user_type == 'laboratory') {
              data.basecondition.parent_laboratory = this.cookieData._id;
            }
            if (this.cookieData.user_type == 'practice') {
              data.basecondition.practice_id = this.cookieData._id;
            }
            this.apiservice.httpViaPost('users/userNameAutocomplete', data).subscribe((response) => {
              // console.log("The dialog userNameAutocomplete response", response)
              this.practicenameprogressFlag = false;
              if (response) {
                this.practicenameArr = response.res
              }
            })
          }
        });



      this.doctorNameModalChange.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe(value => {
          this.doctornameprogressFlag = true;
          if (value && value !== '') {
            let data: any = {
              "search_str": value,
              "sort": { "field": "_id", "type": "desc" },
              "allSearchCond": [],
              "basecondition": {
                user_type: { $in: ['doctor'] }
                // user_type: { $in: 'practice' }
              },
              "datasearch": {},
              "textsearch": {},
              "buttonSearch": {},
              "selectsearch": {},
              "secret": "na",
              "token": ""
            }
            if (this.cookieData.user_type == 'laboratory') {
              data.basecondition.parent_laboratory = this.cookieData._id;
            }
            if (this.cookieData.user_type == 'practice') {
              data.basecondition.practice_id = this.cookieData._id;
            }
            this.apiservice.httpViaPost('users/userNameAutocomplete', data).subscribe((response) => {
              // console.log("The dialog userNameAutocomplete response", response)
              this.doctornameprogressFlag = false;
              if (response) {
                this.doctornameArr = response.res;
                console.log("this.doctornameArr", this.doctornameArr)
              }
            })
          }
        });
    }
    else {

      this.userNameModalChange.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe(value => {
          this.nameprogressFlag = true;
          if (value && value !== '') {
            let data: any = {
              "search_str": value,
              "sort": { "field": "_id", "type": "desc" },
              "allSearchCond": [],
              "basecondition": {
                user_type: { $in: [this.userType] }
              },
              "datasearch": {},
              "textsearch": {},
              "buttonSearch": {},
              "selectsearch": {},
              "secret": "na",
              "token": ""
            }
            if (this.cookieData.user_type == 'laboratory') {
              data.basecondition.parent_laboratory = this.cookieData._id;
            }
            if (this.cookieData.user_type == 'practice') {
              data.basecondition.practice_id = this.cookieData._id;
            }
            this.apiservice.httpViaPost('users/userNameAutocomplete', data).subscribe((response) => {
              // console.log("The dialog userNameAutocomplete response", response)
              this.nameprogressFlag = false;
              if (response) {
                this.nameArr = response.res
              }
            })
          }
        });


      this.userEmailModalChange.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe(value => {
          this.emailprogressFlag = true;
          if (value && value !== '') {
            let data: any = {
              "search_str": value,
              "sort": { "field": "_id", "type": "desc" },
              "allSearchCond": [],
              "basecondition": {
                user_type: { $in: [this.userType] }
              },
              "datasearch": {},
              "textsearch": {},
              "buttonSearch": {},
              "selectsearch": {},
              "secret": "na",
              "token": ""
            }
            if (this.cookieData.user_type == 'laboratory') {
              data.basecondition.parent_laboratory = this.cookieData._id;
            }
            if (this.cookieData.user_type == 'practice') {
              data.basecondition.practice_id = this.cookieData._id;
            }
            this.apiservice.httpViaPost('users/userEmailAutocomplete', data).subscribe((response) => {
              // console.log("The dialog userEmailAutocomplete response", response)
              this.emailprogressFlag = false;
              if (response) {
                this.emailArr = response.res
              }
            })
          }
        });
    }
  }


  namechooseChipItem(item: any, i: number, type?: string) {
    if (this.nameselectedData.length > 0) {
      this.nameselectedData = this.nameselectedData.filter((e) => {
        return e._id !== item._id
      });
    }
    this.nameselectedData.push(item);

    if (!type) {
      this.modalData.fullname = "";
    }
  }


  praticenamechooseChipItem(item: any, i: number, type?: string) {
    if (this.praticenameselectedData.length > 0) {
      this.praticenameselectedData = this.praticenameselectedData.filter((e) => {
        return e._id !== item._id
      });
    }
    this.praticenameselectedData.push(item);

    if (!type) {
      this.modalData.practice_name = "";
    }
  }
  doctornamechooseChipItem(item: any, i: number, type?: string) {
    if (this.doctornameselectedData.length > 0) {
      this.doctornameselectedData = this.doctornameselectedData.filter((e) => {
        return e._id !== item._id
      });
    }
    this.doctornameselectedData.push(item);

    if (!type) {
      this.modalData.doctor_name = "";
    }
  }
  emailChooseChipItem(item: any, i: number, type?: string) {
    if (this.emailSelectedData.length > 0) {
      this.emailSelectedData = this.emailSelectedData.filter((e) => {
        return e._id !== item._id
      });
    }
    this.emailSelectedData.push(item);
    if (!type) {
      this.modalData.email = "";
    }
  }

  patientemailChooseChipItem(item: any, i: number, type?: string) {
    if (this.patientemailSelectedData.length > 0) {
      this.patientemailSelectedData = this.patientemailSelectedData.filter((e) => {
        return e._id !== item._id
      });
    }
    this.patientemailSelectedData.push(item);
    if (!type) {
      this.modalData.patient_email = "";
    }
  }

  remove(value: any, index: any, flag: any) {
    switch (flag) {
      case "email":
        this.emailSelectedData.splice(index, 1);
        this.patientemailSelectedData.splice(index, 1);
        this.emailData.splice(index, 1);
        break;
      case "name":
        this.nameselectedData.splice(index, 1);
        this.praticenameselectedData.splice(index, 1);
        this.doctornameselectedData.splice(index, 1);
        this.nameData.splice(index, 1);
        break;
      default:
        break;
    }
    // console.log(this.nameselectedData)
  }
  onNoClick(): void {
    // user_type: {$in: [this.userType]

    let nameArr: any = [];
    let practicenameArr: any = [];
    let doctornameArr: any = [];
    let emailArr: any = [];
    let patientemailArr: any = [];

    if (this.nameselectedData.length > 0) {
      this.nameselectedData.forEach((e: any, i: number) => {
        nameArr.push(e._id)
      })
      this.modalData.fullname = { $in: nameArr }
    }
    if (this.praticenameselectedData.length > 0) {
      this.praticenameselectedData.forEach((e: any, i: number) => {
        practicenameArr.push(e._id)
      })
      this.modalData.practice_name = { $in: practicenameArr }
    }

    if (this.doctornameselectedData.length > 0) {
      this.doctornameselectedData.forEach((e: any, i: number) => {
        doctornameArr.push(e._id)
      })
      this.modalData.doctor_name = { $in: doctornameArr }
    }
    if (this.emailSelectedData.length > 0) {
      this.emailSelectedData.forEach((e: any, i: number) => {
        emailArr.push(e.name)
      })
      this.modalData.email = { $in: emailArr }
    }
    if (this.patientemailSelectedData.length > 0) {
      this.patientemailSelectedData.forEach((e: any, i: number) => {
        patientemailArr.push(e.name)
      })
      this.modalData.patient_email = { $in: patientemailArr }
    }

    // console.log("closeData modalData", this.modalData)
    let closeData: any = {};
    if (this.modalData && this.modalData.modalData) delete this.modalData.modalData;
    if (this.modalData && this.modalData.nameArr) delete this.modalData.nameArr;
    if (this.modalData && this.modalData.practicenameArr) delete this.modalData.practicenameArr;
    if (this.modalData && this.modalData.patientemailArr) delete this.modalData.patientemailArr;
    if (this.modalData && this.modalData.fullname && this.modalData.fullname == '') delete this.modalData.fullname;
    if (this.modalData && this.modalData.practice_name && this.modalData.practice_name == '') delete this.modalData.practice_name;
    if (this.modalData && this.modalData.email && this.modalData.email == '') delete this.modalData.email;

    closeData = {
      modalData: this.modalData
    }
    if (this.nameselectedData.length > 0) {
      closeData.nameArr = this.nameselectedData
    }
    if (this.praticenameselectedData.length > 0) {
      closeData.practicenameArr = this.praticenameselectedData
    }
    if (this.emailSelectedData.length > 0) {
      closeData.emailArr = this.emailSelectedData
    }
    if (this.patientemailSelectedData.length > 0) {
      closeData.patientemailArr = this.patientemailSelectedData
    }
    if (this.doctornameselectedData.length > 0) {
      closeData.doctornameArr = this.doctornameselectedData
    }
    // console.log("closeData", closeData)
    this.dialogRef.close(closeData);
  }
  modalClose(type?: string) {
    if (type) {
      this.dialogRef.close(type);
    } else {
      this.dialogRef.close();
    }
  }
  // this.searchData.condition["name_search"] = { $in: this.nameData };

  resetForm() {
    console.log("this.modalData", this.modalData)
    this.modalData = {
      fullname: '',
      email: '',
      startdate: null,
      enddate: null,
      status: null
    }
    this.nameselectedData = [];
    this.emailSelectedData = [];
    this.patientemailSelectedData = [];
  }

  initailForm() {
    this.searchformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: 'Search',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/fetchUsers',
      resettext: 'Reset',
      canceltext: 'Cancel',
      jwttoken: '',
      // cancelroute: `${this.cookieData.user_type}/user-management/users`, // use for redirect after cancel the form
      fields: [
        {
          label: "Search By Name",
          name: "_id",
          // hint: 'In months',
          type: 'autocomplete',
          // multiple: false,
          endpoint: "users/userNameAutocomplete",
          search_field: "name_search",
          val: [],
          value: [],
        },
        {
          label: 'Search By Email',
          name: 'contact_email',
          type: 'email',
          hint: '',
          value: this.editFormData && this.editFormData.contact_email ? this.editFormData.contact_email : '',
          // validations: [
          //   { rule: 'required', message: 'Contact Email is required' },
          //   {
          //     rule: 'pattern',
          //     // value: this.emailregex,
          //     message: 'Must be a valid Email',
          //   },
          // ],
        },

        {
          label: "Search By Status",
          name: "status",
          type: 'select',
          //multiple:true,
          val: [
            { val: 0, name: ' Inactive' },
            { val: 1, name: 'Active' },
          ],
          // value: [3,0],
          // validations: [
          //     { rule: 'required' }
          // ]
        },
        {
          label: "Update On Start Date",
          name: "start_date",
          type: "date",
          value: null,
          hint: "05/05/2020",
          minDate: new Date(),
          maxDate: new Date(2024, 8, 31),
          // validations: [
          //     { rule: 'required', message: "Email field Needs to be required" }
          // ]
        },
        {
          label: "Update On End Date",
          name: "end_date",
          type: "date",
          value: null,
          hint: "05/05/2020",
          minDate: new Date(),
          maxDate: new Date(2024, 8, 31),
          // validations: [
          //     { rule: 'required', message: "Email field Needs to be required" }
          // ]
        },
      ]
    }

  }

  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChange++++", val);
    if (val.field && val.field == "formcancel") {
      // console.log("test");
      this.dialogRef.close();
      this.progressLoader = false;

    }
  }
}


@Component({
  selector: 'approvemodal',
  templateUrl: 'approvemodal.html',
})
export class ApproveDialog {
  // public laboratoryData: any = null;
  public approveProgressBar: any = false;
  public cookieData: any = {};
  public Uniquemessage: any = {};
  public approveListData: any;
  public userType: string = null;
  public demoDoctorDashboard: boolean = false
  constructor(
    // public dialogRef: MatDialogRef<ApproveDialog>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<ApproveDialog>,
    public activateRoute: ActivatedRoute,
    private httpService: HttpServiceService,
    public cookieService: CookieService,
    public dialog: MatDialog,
    public router: Router,
  ) {
    console.log('DDD>>', data);
    if (data) {
      this.approveListData = data
    }
    if (data.usertype) {
      this.userType = data.usertype
    }
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
  }


  ngOnInit() {
    if (this.cookieData && (this.cookieData.user_type == 'sales_person')) {
      this.demoDoctorDashboard = false;
      if (window.location.pathname.includes('dashboard/doctor')) {
        this.demoDoctorDashboard = true;
      }
    }
  }

  modalClose(): void {
    this._bottomSheetRef.dismiss();
    // event.preventDefault();
  }


  accept() {

    this._bottomSheetRef.dismiss('accept');
    // return

    // this.approveProgressBar = true
    // let data: any = {
    //   data: {
    //     _id: this.approveListData._id,
    //     status: 4
    //   }
    // }
    // this.httpService.httpViaPost('intake/leadstatuschange', data).subscribe((response: any) => {
    //   console.log("finduniquecodebyuser", response)
    //   if (response.status == "success") {
    //     this.approveProgressBar = false
    //     this._bottomSheetRef.dismiss('accept');
    //   }

    // })

  }

  reject() {
    // const dialogRef = this.dialog.open(RejectNotes, {
    //   data: this.approveListData,
    //   panelClass: 'reject-notes-modal'
    // });

    this._bottomSheetRef.dismiss('reject');

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log("result test +++++", result);

    //   // this.userCount()
    //   // this.refreshSearchedList()
    // });


    // return

    //     this.approveProgressBar = true
    //     let data: any = {
    //       data:{
    //         _id: this.approveListData._id,
    //         status: 5
    //       }

    //     }
    //     this.httpService.httpViaPost('intake/leadstatuschange', data).subscribe((response: any) => {
    //       console.log("finduniquecodebyuser", response)

    //       if (response.status == "success") {
    //         this.approveProgressBar = false
    //         this._bottomSheetRef.dismiss('success');
    //       }

    //     })
  }


  // modalClose(): void {
  //   this.dialogRef.close();
  // }

  emailIntake() {
    this.modalClose();
    if (this.demoDoctorDashboard) {
      this.router.navigateByUrl(`${this.cookieData.user_type}/doctor/add-email-intake`);
    } else {
      this.router.navigateByUrl(`${this.cookieData.user_type}/email-intake-list`);
    }
  }
  patientIntake() {
    this.modalClose();
    if (this.demoDoctorDashboard) {
      this.router.navigateByUrl(`${this.cookieData.user_type}/doctor/patient-intake`);
    } else {
      this.router.navigateByUrl(`${this.cookieData.user_type}/patient-intake`);
    }
  }


}





@Component({
  selector: 'rejectnotes',
  templateUrl: 'rejectnotes.html',
})

export class RejectNotes {
  public addRejectForm: FormGroup;
  public cookieData: any = {};
  public approveProgressBar = false;
  // public error = false;

  constructor(
    public dialogRef: MatDialogRef<RejectNotes>,
    public apiservice: HttpServiceService,
    public activateRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public cookieService: CookieService
  ) {

    console.log("modal Data test", data);

  }



  ngOnInit() {
    this.addRejectForm = new FormGroup({
      addnotes: new FormControl(''),
      // user_id: new FormControl(this.cookieData._id),
    });

    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      console.log("this.cookieData>>>>", this.cookieData)


      // this.firstBlockData = this.cookieData.last_login_time;

    };
    console.log("cookieData+++++++++", this.cookieData);

  }

  addRejectNotes() {
    this.approveProgressBar = true
    console.log(">>>>>>>>", this.addRejectForm)
    if (this.addRejectForm.valid) {
      console.log(">>>>>>>>qq", this.addRejectForm)
      // return
      // this.addRejectForm.value.uniquecode = this.addRejectForm.value.uniquecode.replace(/\s/g, "")
      // this.progressBar = true;

      // let dataobj = {
      //   addnotes: this.addRejectForm.value.addnotes,

      // }

      let data: any = {
        data: {
          _id: this.data._id,
          status: 5,
          reject_notes: this.addRejectForm.value.addnotes,
          rejected_by: this.cookieData._id
        }

      }
      this.apiservice.httpViaPost('intake/leadstatuschange', data).subscribe((response: any) => {
        console.log("finduniquecodebyuser", response)

        if (response.status == "success") {
          this.approveProgressBar = false
          this.dialogRef.close(response);
        }

      })
    }
    else {
      this.approveProgressBar = false
      // this.error = true
    }
  }


  modalClose() {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'confirmmodal',
  templateUrl: 'confirmmodal.html',
})

export class ConfirmModal {
  public addRejectForm: FormGroup;
  public cookieData: any = {};
  public modalId: any = {};
  public approveProgressBar = false;
  // public error = false;
  public confirmType: string = null;
  public reUploadFlag: boolean = false;
  // public reportLink: any = null;
  public patientID: any = null;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef<HTMLElement>;
  @BlockUI() blockUI: NgBlockUI;
  public pdfDownloadLoader: boolean = true;
  public storageData: any;

  constructor(
    public dialogRef: MatDialogRef<ConfirmModal>,
    public httpService: HttpServiceService,
    public matSnackBar: MatSnackBar,
    public activateRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public cookieService: CookieService,
    public dialog: MatDialog,
    public resolveService: ResolveService
  ) {

    console.log("modal Data test", data);
    if (this.data.tbnid == 'edit_clinicalresults') {
      this.reUploadFlag = true;
    }

  }



  ngOnInit() {
    // this.modalFlagData = this.modalFlag
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (this.data) {
      this.modalId = this.data.tbnid
      if (this.data.type) {
        this.confirmType = this.data.type;
      }
    }
    console.log("this.modalFlag", this.modalId);
    if (this.data.patientID) {
      this.patientID = this.data.patientID;
      setTimeout(() => {
        this.pdfDownloadLoader = false;
      }, 5000)
    }
    // if (this.data.reportLink) {
    //   this.reportLink = this.data.reportLink;
    //   let el: HTMLElement = this.myDiv.nativeElement;
    //   setTimeout(() => {
    //     if (this.reportLink != '') {
    //       el.click();
    //     }
    //     this.dialogRef.close();
    //   }, 5000);
    // }

  }

  handleGeneratePdf() {
    this.blockUI.start('Downloading...');
    // console.log('Generating>>>', dataset);
    // this.pdfDownloadLoader = true;
    const input = document.getElementById("element-to-print");
    const input2 = document.getElementById("template2");
    var opt = {
      margin: 1,
      filename: this.patientID + '_order_sheet.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: 'css', after: '.pagebreak_before' }
    };

    // html2pdf().from(input).set(opt).toPdf().get('pdf').save();
    // this.storageData = html2pdf().from(input).set(opt).toPdf().get('pdf').save()
    let storageData = html2pdf().from(input).set(opt).toPdf().get('pdf').save()

    console.warn('PDF File', storageData);

    let reader = new FileReader();

    reader.onload = () => {
      console.log('this.storageData', reader);

    };
    // setTimeout(() => {
    this.stopUiBlock()
    // }, 7000);
    reader.readAsArrayBuffer(storageData.file);
    console.log(this.storageData, 'this.storageData11', reader);
    // this.pdfDownloadLoader = false;
    // html2canvas(input, {scrollY: -window.scrollY, scale: 0.7}).then((canvas) => {
    //   canvas.getContext('2d');
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;

    //   // var doc = new jsPDF('p', 'mm');
    //   var doc = new jsPDF('p', 'mm', [215.9, 279.4]);
    //   var position = 0;
    //   var imgData = canvas.toDataURL("image/jpeg", 1.0);
    //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //   heightLeft -= pageHeight;
    //   console.warn(heightLeft, 'position11>>>', imgData);

    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     console.warn(heightLeft, 'position12>>>', position);
    //     doc.addPage();
    //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //     heightLeft -= pageHeight;
    //   }
    //   doc.save(this.paramID + '_order_sheet.pdf');
    //   this.pdfDownloadLoader = false;
    // })

  }

  confirmdata() {
    if (this.modalId) {
      if (this.modalId == "approve_btn") {
        this.approveProgressBar = true
        let data: any = {
          data: {
            _id: this.data.dataset._id,
            status: 4
          }
        }
        this.httpService.httpViaPost('intake/leadstatuschange', data).subscribe((response: any) => {
          console.log("finduniquecodebyuser", response)
          if (response.status == "success") {
            this.approveProgressBar = false
            this.dialogRef.close(response);
          }

        })
      }

      else if (this.modalId == "markasdone_reversestatus") {
        this.approveProgressBar = true
        let dataset: any = {
          data: {
            _id: this.data.dataset._id,
            status: 3
          }
        }
        this.httpService.httpViaPost('intake/leadstatuschange', dataset).subscribe((response: any) => {
          // console.log("finduniquecodebyuser", response)
          if (response.status == "success") {
            this.approveProgressBar = false
            this.matSnackBar.open("Order Pending", "ok", {
              duration: 3000,
            });
            this.dialogRef.close(response);
          }

        })
      }
      else if (this.modalId == "markasdone_btn") {
        this.dialogRef.close();
        const dialogRef = this.dialog.open(FileUploadModal, {
          data: { dataset: this.data.dataset },
          panelClass: ['confirm-modal', 'fileUploadModal'],
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log("result test +++++", result);
          if (result) {
            this.resolveService.reportUpload(true);
          }
        });

        // this.approveProgressBar = true
        // let dataset: any = {
        //   data: {
        //     _id: this.data.dataset._id,
        //     status: 6
        //   }
        // }
        // this.httpService.httpViaPost('intake/leadstatuschange', dataset).subscribe((response: any) => {
        //   // console.log("finduniquecodebyuser", response)
        //   if (response.status == "success") {
        //     this.approveProgressBar = false
        //     this.matSnackBar.open("Diagnosis Completed", "ok", {
        //       duration: 3000,
        //     });
        //     this.dialogRef.close(response);
        //   }

        // })
      }

    }

  }

  stopUiBlock() {
    setTimeout(() => {
      this.blockUI.stop();
    }, 6000);
    this.dialogRef.close();
  }

  modalClose() {
    this.dialogRef.close();
  }

  reupload() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(FileUploadModal, {
      data: { dataset: this.data.dataset },
      panelClass: ['confirm-modal', 'fileUploadModal'],
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("result test +++++", result);
      if (result) {
        this.resolveService.reportUpload(true);
      }
    });
  }

  editReports() {
    this.approveProgressBar = true;
    let datasetval =
      { "searchcondition": { "_id": this.data.dataset._id } }
    this.httpService.httpViaPost('intake/getleaddata', datasetval).subscribe((response: any) => {
      if (response.status == "success") {
        this.dialogRef.close();
        this.approveProgressBar = false;
        const dialogRef = this.dialog.open(FileUploadModal, {
          data: { dataset: response.results.res[0], editreport: true },
          panelClass: ['confirm-modal', 'fileUploadModal'],
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log("result test +++++", result);
          if (result) {
            this.resolveService.reportUpload(true);
          }
        });

      }
    })

  }
}


@Component({
  selector: 'fileuploadmodal',
  templateUrl: 'file-uploaded-modal.html',
})

export class FileUploadModal {
  public uploadedReport: any;
  public uploadProgressBar: boolean = false
  public cookieData: any = null;
  public reportUploadFormData: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public errorFlag: boolean = false;
  public pdfFileName: any = [];
  public showFileFlag: boolean = false;
  public showLoaderFlag: boolean = false;
  public clinical_report: any;
  public resolveval: any = [];
  public isReuploadFlag: boolean = false;
  public editReport: boolean = false;
  public loadForm: boolean = false;
  public header_txt: any = 'Please upload the patient details clinical report!';
  objectKeys = Object.keys;
  constructor(
    public dialogRef: MatDialogRef<FileUploadModal>,
    public httpService: HttpServiceService,
    public matSnackBar: MatSnackBar,
    public activateRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public cookieService: CookieService,
    public apiservice: HttpServiceService,
    private sanitizer: DomSanitizer
  ) {

    console.log("modal Data FileUploadModal", data);
    if (typeof data.dataset != 'undefined') {
      if (data.dataset.status == 6) {
        this.isReuploadFlag = true;
      }
    }

    if (typeof data.editreport != 'undefined') {
      if (data.editreport) {
        this.editReport = true;
      }
    }

  }



  ngOnInit() {

    console.log("FileUploadModal");
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    // if(this.data.tbnid == "edit_clinicalresults"){
    //   this.reportInfo();
    // }else{
    //   this.initailForm();
    // }
    this.initailForm();
  }

  reportInfo() {
    let datasetval =
      { "searchcondition": { "_id": this.data.dataset._id } }
    this.httpService.httpViaPost('intake/getleaddata', datasetval).subscribe((response: any) => {
      if (response.status == "success") {
        this.resolveval = response.results.res[0];
        // if (response.results.res[0].clinical_report.length > 0) {
        //   for (const report of response.results.res[0].clinical_report) {
        //     this.resolveval.push({ fileservername: report.fileservername, name: report.name, size: report.size, type: report.type, path: report.path, bucket: report.bucket })
        //   }
        // }
        console.warn("CLINICAL REPORT >>>", this.resolveval);


        this.initailForm();

      }
    })
  }

  modalClose() {
    this.dialogRef.close();
  }
  initailForm() {
    this.reportUploadFormData =
    {
      successmessage: 'Added Successfully !!', // success message
      submittext: 'Save',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      // resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: true,
      jwttoken: '',
      // cancelroute: `${this.cookieData.user_type}/dashboard`, // use for redirect after cancel the form
      fields: [
        {
          // label: "Add Clinical Report",
          name: "clinical_report",
          type: "file",
          validations: [
            { rule: 'required', message: 'Clinical Report is Required' },

          ],
          multiple: true,
          prefix: Date.now(),
          // path: "patient-uploaded-reports/",
          // baseurl: "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/",
          // bucket: "practice2lab.com",
          // apiurl: "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL",
          // apideleteurl: "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket",
          path: 'patient-uploaded-reports/',
          bucket: 'practice2lab-all-reports',
          baseurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/',
          apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
          apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          value: this.data.dataset ? this.data.dataset.clinical_report : undefined
        },


      ]
    }
    this.loadForm = true;
  }
  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChange++++", val);
    if (val) {
      if (val.field && val.field == "fromsubmit") {
        this.errorFlag = false;
        if (val.fromval.clinical_report) {
          this.uploadProgressBar = true;
          this.clinical_report = val.fromval.clinical_report;
          if (val.fromval.clinical_report.length > 0) {
            for (const forval of val.fromval.clinical_report) {
              if (forval.type != "application/pdf") {
                this.matSnackBar.open("'" + forval.name + " ' not support, Only Pdf File Support", "ok", {
                  duration: 3000,
                });
                this.uploadProgressBar = false;
                return;
              }
            }
          }


          if (this.clinical_report.length > 0) {
            for (const setval of this.clinical_report) {
              this.pdfFileName.push(this.sanitizer.bypassSecurityTrustResourceUrl("https://practice2lab-all-reports.s3.us-west-1.amazonaws.com/" + setval.path + setval.fileservername));
            }
          }
          this.header_txt = 'Please Review and Finalize Test Report'
          this.showFileFlag = true;
          this.uploadProgressBar = false;
          this.showLoaderFlag = true;
          setTimeout(() => {
            this.showLoaderFlag = false;
            let fileUploadModalClass = document.querySelector(".fileUploadModal");
            fileUploadModalClass.classList.add("expandFileUploadModal");
          }, 6000);
          return;
          let dataset: any = {
            data: {
              _id: this.data.dataset._id,
              status: 6,
              clinical_report: this.clinical_report
            }
          }
          this.httpService.httpViaPost('intake/leadstatuschange', dataset).subscribe((response: any) => {
            // console.log("finduniquecodebyuser", response)
            if (response.status == "success") {
              this.uploadProgressBar = false;
              this.matSnackBar.open("Diagnosis Completed", "ok", {
                duration: 3000,
              });
              this.dialogRef.close(response);
            }

          })
        }
      }
      if (val.field && val.field == "formcancel") {
        this.dialogRef.close();
      }
      if (val.field && val.field == "fromsubmiterror") {
        if (val.fieldval && val.fieldval == "validationerror") {
          this.errorFlag = true;
        }
      }
    }
  }

  finalSubmit() {
    this.uploadProgressBar = true;
    let dataset: any = {
      data: {
        _id: this.data.dataset._id,
        status: 6,
        clinical_report: this.clinical_report,
        reupload: this.isReuploadFlag
      }
    }
    // if(this.isReuploadFlag){
    //   dataset.data['reupload'] = true;
    // }
    this.httpService.httpViaPost('intake/leadstatuschange', dataset).subscribe((response: any) => {
      // console.log("finduniquecodebyuser", response)
      if (response.status == "success") {
        let databody = {
          "pathParameters": {
            "path": "merge-pdf"
          },
          "queryStringParameters": {
            "_id": this.data.dataset._id
          }
        }
        this.httpService.httpViaPost('intake/dockerfunctioninvoke', databody).subscribe((response: any) => {
          console.log(response);
        })
        this.uploadProgressBar = false;
        this.matSnackBar.open("Diagnosis Completed", "ok", {
          duration: 3000,
        });
        this.dialogRef.close(response);
      }

    })
  }
  backFileUpload() {
    this.showFileFlag = false;
  }

  fileUploadClick() {
    this.uploadInputChanged(this.uploadedReport)
  }
  uploadInputChanged(event: any) {
    console.log("uploadedReport file event", event)
  }
  uploadReportFile() {
    console.log("uploadedReport", this.uploadedReport)
  }
}


@Component({
  selector: 'PracticeUpgradeAlert',
  templateUrl: 'practice-upgrade-alert.html'
})
export class PracticeUpgradeAlert {

  public cookieData: any = null;
  public addFormLoader: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PracticeUpgradeAlert>,
    public httpService: HttpServiceService,
    public matSnackBar: MatSnackBar,
    public activateRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public cookieService: CookieService,
    public dialog: MatDialog,
    public router: Router
  ) {

    console.log("modal Data test", data);
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(
        this.cookieService.getAll()['login_user_details']
      );
    }


  }


  ngOnInit() { }

  interestForUpgrade(type: string) {
    if (type) {
      this.addFormLoader = true;
      let payload: any = {
        data: {
          _id: this.cookieData._id,
          accept_ai: 0
        }
      }
      if (type == 'yes') {
        // this.addFormLoader = false;
        // this.router.navigateByUrl("practice/ai-upgradation")        
        // this.modalClose();
        payload.data.accept_ai = 1
      }

      this.httpService.httpViaPost("users/acceptai", payload).subscribe((response: any) => {
        console.log(response)
        if (response) {
          this.addFormLoader = false;
          this.matSnackBar.open(response.message, '', {
            duration: 3000,
          });
          if (response.status === "success") {
            this.cookieData.accept_ai = 0;
            this.cookieService.delete('login_user_details', '/');
            setTimeout(() => {
              this.cookieService.set('login_user_details', JSON.stringify(this.cookieData));
              this.dialogRef.close();
            }, 200);
          }
        }
      })

    }
  }
  learnDecisionAI() {
    window.open("https://ai.decisiondoc.com", "_blank")
  }

  modalClose() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'rapid-response-virtual-access-modal',
  templateUrl: 'rapid-response-virtual-access-modal.html'
})
export class RapidResponseVirtualAccessModal {

  public cookieData: any = null;
  public addFormLoader: boolean = false;
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', "diagnostic_priority"];
  public tabledatatalist: any = []
  public listprogressBar: any = false;
  public modalrogressBar: any = false;

  public approvelistdata: any[];
  public approvelist: any[];

  tablename = "diagnostic";
  editroute = "";
  // updateendpoint = "email/emailslugstatus";
  deleteendpoint = "api5/rapidresponsaccessuserlist";
  datacollection: any = "api5/rapidresponsaccessuserlist";

  searchendpoint = "api5/rapidresponsaccessuserlist";
  date_search_endpoint: any = "api5/rapidresponsaccessuserlist";
  date_search_source: any = "rapidresponsaccessuserlist";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {

    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];



  modify_header_array: any = {
    fullname: "Name",
    email: "Email",
    phone: "Phone",
    rapid_response_access: "Access",
    requested_datetime: "Requested On"
  };
  search_settings: any = {
    search: [

      {
        label: "Search By Name ", field: '_id',

        serversearchdata: { endpoint: 'api5/requestpracticeautocomplete' },

      },
    ],


    datesearch: [{ startdatelabel: "Requested On Start Date", enddatelabel: "Requested On End Date", submit: "Search", field: "requested_datetime" }],

  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["fullname", "requested_datetime", "email"],
  };
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Mark as unavailable',
      //   type: 'button',
      //   name: 'remove_test',
      //   tooltip: 'Mark as unavailable',
      //   classname: 'add_adminBTN',
      // },

      // {
      //   label: 'Add Diagnosis',
      //   type: 'button',
      //   name: 'add_diagnostic',
      //   tooltip: 'Add Diagnosis',
      //   classname: 'add_adminBTN',
      // },

      // {
      //   label: 'Add Diagnosis',
      //   type: 'button',
      //   name: 'add_diagnostic',
      //   tooltip: 'Add Diagnosis',
      //   classname: 'add_adminBTN',
      // }
    ],
  }
  libdata: any = {
    basecondition: {},
    detailview_override: [
    ],
    updateendpoint: "intake/diagnosticstatus",
    hidedeletebutton: true,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "intake/diagnosticstatus",

    deleteendpointmany: "intake/deletediagnostic",
    tableheaders: ["fullname", "email", "phone", "requested_datetime"],

    custombuttons: [
      // {
      //   label: "Approve",
      //   type: 'listner',
      //   id: 'approve_btn',
      //   tooltip: 'Approve',
      //   // name: 'edit',
      //   classname: 'approveButton'
      // },
      {
        label: "Approve",
        type: "listner",
        id: "approve_btn",
        classname: 'approveButton',
        tooltip: 'Approve',
      },

    ],

  }
  public api_url_rapid_response = this.httpService.rapidResponseBaseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  public adduserBTN: boolean = false;
  public daignosis_updatetable: boolean = false;
  public chooseDiagnosisLoader: boolean = false;
  public isPendingListLoader: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<RapidResponseVirtualAccessModal>,
    public httpService: HttpServiceService,
    public matSnackBar: MatSnackBar,
    public activateRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public cookieService: CookieService,
    public dialog: MatDialog,
    public router: Router
  ) {

    console.log("modal Data test", data);
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(
        this.cookieService.getAll()['login_user_details']
      );
    }
    if (data) {
      this.tabledatatalist = data.list;
      this.date_search_source_count = data.count;
    }


  }


  ngOnInit() { }


  onLiblistingButtonChange(val: any) {
    console.log("onLiblistingButtonChange val", val)
  }
  listenLiblistingChange(data: any) {
    console.log("listenLiblistingChange", data)
    if (data) {
      if (data.action && data.action == "custombuttonclick") {
        if (data.custombuttonclick && data.custombuttonclick.btninfo && data.custombuttonclick.btninfo.id == "approve_btn") {
          this.isPendingListLoader = true;
          let payload = {
            "_id": data.custombuttonclick.data._id,
            "has_rapid_response_access": 2,
            "email": data.custombuttonclick.data.email,
            "fullname": data.custombuttonclick.data.fullname,
          }
          this.httpService.httpViaPostRapidResponse('api5/rapidresponsaccept', payload).subscribe((response: any) => {
            console.log("rapid response count", response);
            if (response) {

              this.daignosis_updatetable = !this.daignosis_updatetable
              this.matSnackBar.open("Update Successfully!", null, {
                duration: 3000,
              });
              this.isPendingListLoader = false;
              this.tabledatatalist = this.tabledatatalist.filter((e) => e._id !== data.custombuttonclick.data._id);
              console.log("this.tabledatatalist", this.tabledatatalist)
              this.date_search_source_count = this.tabledatatalist.length;
              if (this.tabledatatalist.length == 0) {

                this.modalClose('close');
              }
            }
          })
        }
      }
    }

    if (data.action === "multipleselectionchange") {

      var destinationContainer = document.querySelector('.multipledeleteandupdatebuttan');

      console.log("approveBtn", document.querySelector(".approveBtn"))
      let newButton = document.createElement("button");
      newButton.innerText = "Approve All";
      newButton.classList.add("approveBtn")
      newButton.addEventListener("click", this.approveBtn.bind(this));


      if (destinationContainer && document.querySelector(".approveBtn") == null) {
        destinationContainer.append(newButton);
      }
      if (data.selecteddata && data.selecteddata.length > 0) {
        newButton.classList.add('approveBtnaction');

        this.approvelist = data.selecteddata;

      } else {
        if (newButton.classList.contains("approveBtnaction")) {
          newButton.classList.remove('approveBtnaction');
        }
      }


    }


  }
  approveBtn() {
    console.log(this.approvelist, 'this.approvelist++++++++')
    this.isPendingListLoader = true;
    this.approvelistdata = [];

    for (let i = 0; i < this.approvelist.length; i++) {
      let item = {
        "_id": this.approvelist[i]._id,
        "email": this.approvelist[i].email,
        "fullname": this.approvelist[i].fullname,

      };



      this.approvelistdata.push(item);

      console.log(item, '++++++++++++++');
    }

    console.log(this.approvelistdata, 'approvelistdata++++++++');

    let dataset: any = this.approvelistdata






    this.httpService.httpViaPostRapidResponse('api5/rapidresponsacceptmany', dataset).subscribe((response: any) => {
      console.log("response+++", response);



      if (response) {

        this.daignosis_updatetable = !this.daignosis_updatetable

        this.matSnackBar.open("Update Successfully!", null, {
          duration: 3000,
        });
        this.isPendingListLoader = false;
        // this.tabledatatalist = this.tabledatatalist.filter((e) => e._id !== this.approvelist[i]._id);

        this.approvelistdata.forEach((item) => {
          this.tabledatatalist = this.tabledatatalist.filter((e) => e._id !== item._id);
        });
        console.log(`Duplicate _id`, this.tabledatatalist);


        console.log("this.tabledatatalist++++", this.tabledatatalist)
        this.date_search_source_count = this.tabledatatalist.length;
        if (this.tabledatatalist.length == 0) {

          this.modalClose('close');
        }
      }

    });
  }

  listenFormFieldChange(data: any = null) { }
  modalClose(type?: string) {
    if (type) {
      this.dialogRef.close(type);
    } else {
      this.dialogRef.close();
    }
  }
}




