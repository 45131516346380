import { Component, Inject } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';
import * as html2pdf from "html2pdf.js";

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
// import { DialogData } from 'listing-angular15';
export interface DialogData {
  data: any;
  flag: any;
  cookiedata: any;
}

@Component({
  selector: 'app-lead-preview-intake',
  templateUrl: './lead-preview-intake.component.html',
  styleUrls: ['./lead-preview-intake.component.css']
})
export class LeadPreviewIntakeComponent {
  public patientdetailsFlug: boolean = false
  public ordersheetFlug: boolean = false
  public paramID: any
  public order_sheet_data: any = null;
  public tabviewFlag: boolean = false;
  public order_sheet_sign: boolean = true;
  public addFormLoader: boolean = true;
  public clinicalReportAvailable: boolean = false;
  public clinicalReportData: any = null;
  public isPDFFile: boolean = false;

  public statusCodeArray: any;
  public pdfDownloadLoader: boolean = false;
  public statusCodeArrayFlag: boolean = false;
  public cookiedata: any;
  public rejectedNoteShowFlag: boolean = false;
  public notesDataTxt: any;
  public fororderreject: boolean = false;
  public pdfFileName: any = [];
  public isEmailPreviewIntake: boolean = false;
  public patientCurrentStatus: number;
  public physician_examinationFlag: boolean = false;
  public slideIndex: number = 0;
  public needSignFlag: boolean = false;
  public downloadBtnFlag: boolean = false;
  public isLennyLab: boolean = false;
  public lennyLabId: string = "6486ffedea843c3af6adee38";

  public calculatDecisionDocFlag: boolean = false;

  constructor(
    private apiservice: HttpServiceService, public activatedRoute: ActivatedRoute, public cookieService: CookieService, private sanitizer: DomSanitizer, public dialog: MatDialog) {
    this.paramID = this.activatedRoute.snapshot.params['_id']
    console.log('activatedRoutelinktest', this.paramID);
    this.statusCodeView(this.paramID);
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookiedata = JSON.parse(this.cookieService.getAll()['login_user_details']);
      if (this.cookiedata.user_type == 'admin' || this.cookiedata.user_type == 'practice') {
        this.order_sheet_sign = false
      }
    }

    console.log('activatedRoute//////', (activatedRoute.snapshot.data as any)['data'].results.res[0].status);

    if (
      this.cookiedata?.user_type === 'doctor' &&
      (activatedRoute.snapshot.data as any)?.['data']?.results?.res[0]?.status > 1
    ) {
      this.calculatDecisionDocFlag = true;
    }

    console.log('paramID:', this.paramID);
    console.log('cookiedata:', this.cookiedata);



  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.routeConfig.path.includes('patient-folderview/:_id')) {
      this.order_sheet_sign = false
    }
    if (window.location.pathname.includes('email-preview-intake')) {
      this.isEmailPreviewIntake = true
    }
    console.log("window.location ", window.location)
    this.activatedRoute.data.subscribe((response: any) => {
      console.log("patient-folderview data", response)
      if (response) {
        if (response.data && response.data.results && response.data.results.res && response.data.results.res.length > 0) {
          this.patientCurrentStatus = response.data.results.res[0].status;
          if (response.data.results.res[0] && response.data.results.res[0].parent_laboratory && (response.data.results.res[0].parent_laboratory == this.lennyLabId)) {
            this.isLennyLab = true;
          }

          if (response.data.results.res[0].clinical_report && response.data.results.res[0].clinical_report.length > 0) {
            this.clinicalReportAvailable = true;
            this.clinicalReportData = response.data.results.res[0].clinical_report;
            console.warn(response.data.results.res[0].clinical_report, 'Clinical>>>>>>>', typeof response.data.results.res[0].clinical_report);
            if (typeof this.clinicalReportData.length != 'undefined') {
              if (this.clinicalReportData.length > 0) {
                for (const value of this.clinicalReportData) {
                  if (value.type == "application/pdf") {
                    this.isPDFFile = true;
                    // console.log("window.location ", window.location)
                    this.pdfFileName.push(this.sanitizer.bypassSecurityTrustResourceUrl("https://practice2lab-all-reports.s3.us-west-1.amazonaws.com/" + value.path + value.fileservername));
                  }
                }
              }
            }
            else {
              this.isPDFFile = true;
              this.pdfFileName.push(this.sanitizer.bypassSecurityTrustResourceUrl("https://practice2lab-all-reports.s3.us-west-1.amazonaws.com/" + this.clinicalReportData.path + this.clinicalReportData.fileservername));
            }
          }
        }
      }
    })

    setTimeout(() => {
      this.downloadBtnFlag = true;
    }, 8000);

  }
  handleGeneratePdf() {
    // console.log('Generating>>>', dataset);
    this.pdfDownloadLoader = true;
    const input = document.getElementById("element-to-print");
    const input2 = document.getElementById("template2");
    var opt = {
      margin: 3,
      filename: this.paramID + '_order_sheet.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: 'css', after: '.pagebreak_before' }
    };

    // html2pdf().from(input).set(opt).toPdf().get('pdf').save();
    let pdfff = html2pdf().from(input).set(opt).toPdf().get('pdf').save();
    console.warn("pdfff>>>>>>>>", pdfff);

    this.pdfDownloadLoader = false;

    // html2canvas(input, {scrollY: -window.scrollY, scale: 0.7}).then((canvas) => {
    //   canvas.getContext('2d');
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;

    //   // var doc = new jsPDF('p', 'mm');
    //   var doc = new jsPDF('p', 'mm', [215.9, 279.4]);
    //   var position = 0;
    //   var imgData = canvas.toDataURL("image/jpeg", 1.0);
    //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //   heightLeft -= pageHeight;
    //   console.warn(heightLeft, 'position11>>>', imgData);

    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     console.warn(heightLeft, 'position12>>>', position);
    //     doc.addPage();
    //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //     heightLeft -= pageHeight;
    //   }
    //   doc.save(this.paramID + '_order_sheet.pdf');
    //   this.pdfDownloadLoader = false;
    // })

  }

  public tapDeviceOrder: boolean = false;


  tabview(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    console.log(tab, 'ordersheetpage');
    this.tapDeviceOrder = false;
    if (tab == 'Patient Details') {
      this.patientdetailsFlug = true
      this.ordersheetFlug = false
      this.tabviewFlag = false
      this.physician_examinationFlag = false;
    }
    else if (tab == 'Order Sheet') {
      this.patientdetailsFlug = false
      this.ordersheetFlug = true
      this.ordersheetpage();
      this.tabviewFlag = true
      this.physician_examinationFlag = false;

    }
    else if (tab == 'Patient History') {
      this.patientdetailsFlug = false
      this.ordersheetFlug = false
      this.tabviewFlag = true
      this.physician_examinationFlag = true;

    }
    else if (tab == 'Device Order') {
      let payload: any = {
        patient_id: this.paramID,
      }
      this.apiservice.httpViaPost('intake/deviceordersheet', payload).subscribe((response: any) => {
        console.log('response0000', response)
        if (response && response.results && response.results.res) {
          this.tapDeviceOrder = response.results.res;
        }
      })
    }
    else {
      this.ordersheetFlug = false;
      this.patientdetailsFlug = true;
      if (this.clinicalReportData && this.clinicalReportData.length > 0) {
        setTimeout(() => {
          this.showSlides(this.slideIndex)
        }, 1000);
      }
    }
  }


  ordersheetpage() {
    let endpoint = 'intake/ordersheet';
    let data: any = {
      searchcondition: {
        patient_id: this.paramID,
        is_trash: { $exists: false }

      }
    }
    if (this.activatedRoute.snapshot.routeConfig.path.includes('patient-folderview/:_id')) {
      if (this.cookiedata.user_type != 'sales_person') {
        data.searchcondition.status = 1;
      }
    }
    if (this.statusCodeArray && this.statusCodeArray.curentstatus == 5 && this.cookiedata.user_type == 'doctor') {
      data.searchcondition.status = { $in: [1, 0] };
      this.fororderreject = true;
    }
    if (this.cookiedata.user_type == 'laboratory') {
      endpoint = 'intake/ordersheetforlab'
    }
    this.apiservice.httpViaPost(endpoint, data).subscribe((response: any) => {
      if (response && response.status == 'success') {
        // console.log('response0000', response.results.res)
        this.order_sheet_data = response.results.res;
        this.addFormLoader = false;
      }
    })
  }

  statusCodeView(id) {
    let statusdata: any = {
      _id: id
    }

    this.apiservice.httpViaPost('intake/viewpatientstatus', statusdata).subscribe((response: any) => {
      if (response && response.status == 'success') {
        this.statusCodeArray = response.results;
        this.statusCodeArrayFlag = true;
        if (this.statusCodeArray.curentstatus == 5 && this.cookiedata.user_type == 'doctor') {
          let noteData = {
            _id: id
          }
          this.apiservice.httpViaPost('intake/previewrejectdata', noteData).subscribe((res: any) => {
            if (res.status == 'success') {
              this.notesDataTxt = res.res.notes;
              this.rejectedNoteShowFlag = true;
            }
          })
        }
        if (this.statusCodeArray.curentstatus == 2 && this.cookiedata.user_type == 'doctor') {
          this.needSignFlag = true;
        }
      }
    })
  }
  slideClicked(type: string) {
    console.log(type)
    if (type == 'pre') {
      this.slideIndex--;;
    } else {
      this.slideIndex++;
    }
    this.showSlides(this.slideIndex);
  };

  showSlides(n) {
    let i;
    let slides = document.querySelectorAll(".isPDFFile");
    console.log("slides", n)
    // let dots = document.getElementsByClassName("dot");
    // if (n > slides.length) { this.slideIndex = 1 }
    // if (n < 1) { this.slideIndex = slides.length }
    for (i = 0; i < slides.length; i++) {

      if (n === i) {
        slides[i].classList.remove("displayNone");
        slides[i].classList.add("displayBlock");
      } else {
        slides[i].classList.remove("displayBlock");
        slides[i].classList.add("displayNone");
      }
    }
    // for (i = 0; i < dots.length; i++) {
    //   dots[i].className = dots[i].className.replace(" active", "");
    // }
    // let temp = slides[this.slideIndex - 1];
    // if (temp) {
    //   temp.classList.add("displayBlock")
    // }
    // if (dots) {
    //   dots[this.slideIndex - 1].className += " active";
    // }
  }


  DecisionDoccal() {

    var calculatDDblockContainer = document.querySelector('.calculatDDblock');


    var existingButton = document.querySelector('.OpenDecisiondocBtn');
    if (!existingButton) {

      var newButton = document.createElement("button");
      newButton.innerText = "Open Decisiondoc";
      newButton.classList.add("OpenDecisiondocBtn");


      calculatDDblockContainer.appendChild(newButton);


      var existingOldButton = document.querySelector('.calculatDDbtn');

      newButton.addEventListener("click", this.OpenDecisiondoc.bind(this));


      if (existingOldButton) {
        existingOldButton.parentNode.removeChild(existingOldButton);
      }
    }


    // console.log(calculatDDblockContainer, 'calculatDDblockContainer');

  }



  OpenDecisiondoc() {

    const dialogRef = this.dialog.open(CalculateDecisionDoc, {

      panelClass: ['decisionDoccal'],

      data: {
        data: this.paramID,
        cookiedata: this.cookiedata
      },

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}



@Component({
  selector: 'CalculateDecisionDoc',
  templateUrl: 'CalculateDecisionDoc.html',

})
export class CalculateDecisionDoc {

  public getleadData: any;
  public patienthistoryData: any;

  public possibleDiagnosisData: any;
  public treatmentProtocalData: any;
  public medicationData: any;
  public lifestylechangesData: any;
  public sampleConversationData: any;
  public taxonomychangesData: any;

  public progressBarLoader = true;
  public rapidresponseID: any;

  public patientID: any;
  public cookiedataDetail: any;

  public bodyMetricsDatablock: any;
  public eandcodeData:any

  constructor(private httpService: HttpServiceService,
    public dialogRef: MatDialogRef<CalculateDecisionDoc>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

    // console.log(data, 'data+++++++++++++++')


    this.cookiedataDetail = this.data.cookiedata;

    let datasetval =
      { "searchcondition": { "_id": data.data } }


    this.httpService.httpViaPost('intake/getleaddata', datasetval).subscribe((response: any) => {
      if (response.status == "success") {



        this.getleadData = response.results.res[0];

        if (this.getleadData) {
          this.eandcodeData = this.getleadData.evaluation_info
        }

        console.log("this.getleadData=========>>>>>>", this.eandcodeData);
        




        this.bodyMetricsDatablock = response.results.res[0].body_metrics[0];


        console.log(this.bodyMetricsDatablock, 'getleadData+++++++++++++++')



        this.httpService.httpViaPostRapidResponse('api5/rapidresponse-ai-info', { "_id": data.data }).subscribe((response: any) => {
          if (response.status == "success") {
            this.progressBarLoader = false

            this.patientID = this.data;

            this.possibleDiagnosisData = response.res[0].possible_diagnoses_html;
            this.treatmentProtocalData = response.res[0].treatment_protocall_html;
            this.medicationData = response.res[0].medication_html;
            this.lifestylechangesData = response.res[0].lifestyle_changes_html;

            this.sampleConversationData = response.res[0].sample_conversation_html;
            this.taxonomychangesData = response.res[0].taxonomy_changes_html;

            this.rapidresponseID = response.res[0].google_events_id



            console.log(this.patientID, 'patienthistoryData+++++++++++++++')

          }
        })




      }
    })

  }



  onNoClick(): void {
    this.dialogRef.close();
  }
}