import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-taxonomies-add',
  templateUrl: './taxonomies-add.component.html',
  styleUrls: ['./taxonomies-add.component.css']
})
export class TaxonomiesAddComponent {
  data: any;
  flag: any;
  externaldatavalue: any;
  name: any;
  value: any;

  public addFormLoader: boolean = false;
  public cookieData: any = {};
  public practiceformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];

  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {
    if (!window.location.pathname.includes('admin/taxonomies')) {
      this.router.navigateByUrl('404')
    }
  }





  ngOnInit() {
    // if (this.cookieService.getAll()['login_user_details']) {
    //   this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    // }
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
          }
        }
      });
      // this.editForm();
    } else {
    }
    console.log("editFormData", this.editFormData);

    this.initailForm();
  }



  initailForm() {
    this.practiceformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      cancelroute: `admin/taxonomies/list`, // use for redirect after cancel the form
      fields: [

        {
          label: 'Taxonomy',
          name: 'taxonomy',
          value: this.editFormData && this.editFormData.taxonomy ? this.editFormData.taxonomy : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Taxonomy is Required' },

          ],
        },
        {
          label: 'Priority',
          name: 'priority',
          value: this.editFormData && this.editFormData.priority ? this.editFormData.priority : '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Priority is Required' },

          ],
        },
        {
          label: "Description",
          name: "description",
          type: "textarea",
          // group: "grp1",
          rows: 1,
          cols: 2,
          value: this.editFormData && this.editFormData.description ? this.editFormData.description : '',
          // hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Description field Needs to be required" },
          ]
        },

        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          val: this.statusarr,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
          validations: [
            // { rule: 'required', message: 'Required Type' },
          ]
        },


      ]
    }
   

  }


  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChangeval", val)
    if (val) {
      if (val.field && val.field == "fromsubmit") {
        this.addFormLoader = true;
        // val.fromval.prioriy = Number(val.fromval.prioriy) 
        console.log("val.fromval", val.fromval);

        val.fromval.location = this.externaldatavalue;
        let dataobj = {

          data: val.fromval
        }
        if (this.editFormData) {
          dataobj.data._id = this.editFormData._id
        }

        this.apiservice.httpViaPost('users/add-update-taxonomy', dataobj).subscribe((response) => {
          console.log("dataobj", response)
          if (response && response.status == 'success') {
            this.matSnackBar.open(response.message, "Ok", {
              duration: 1000
            });
            this.router.navigateByUrl(`admin/taxonomies/list`);
            this.addFormLoader = false;
          }

          if (response && response.status == 'error') {
            this.matSnackBar.open(response.message, "", {
              duration: 1000
            });
            this.addFormLoader = false;
          }

        })

      }
      if (val.field && val.field == "formreset") {
        this.formfieldrefreshdata = {
          field: "description",
          value: '',
        };
      }
    }
  }

}
