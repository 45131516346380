import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpServiceService } from './http-service.service';

@Injectable({
  providedIn: 'root'
})

export class ResolveService {


  public cookiedata: any = '';
  public reportUploading = new BehaviorSubject<boolean>(false);
  reportUploader = this.reportUploading.asObservable();



  constructor(public httpService: HttpServiceService, public cookieService: CookieService,) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      console.log("routehhhhh", route);
      let endpoint = route.data['endpoint'];
      let requestObj = route.data['requestcondition'];


      if (route.data && this.cookieService.getAll()['login_user_details']) {

        this.cookiedata = JSON.parse(this.cookieService.getAll()['login_user_details']);
        console.log('cookiedata', this.cookiedata)
        // if(endpoint=="users/fetchUsers"){
        //   requestObj.searchcondition.parent_laboratory = this.cookiedata._id
        // }
        console.log('routtestparams', route.params)
        console.log('routtestnew', route)

        if (route.routeConfig.path.includes("partial-patient-list")) {
          if (this.cookiedata.user_type == "doctor") {
            requestObj.searchcondition['doctor_id'] = this.cookiedata._id
          }
          if (this.cookiedata.user_type == "practice") {
            requestObj.searchcondition['practice_id'] = this.cookiedata._id
          }
        }

        if (route.routeConfig.path.includes("edit-diagnostic/:_id")) {
          requestObj.searchcondition._id = route.params['_id']
        }

        if (route.routeConfig.path.includes("edit-cpt/:_id")) {
          requestObj.searchcondition._id = route.params['_id']
        }

        if (route.routeConfig.path.includes("edit-icd/:_id")) {
          requestObj.searchcondition._id = route.params['_id']
        }

        if (route.routeConfig.path.includes("addEdit-diagnostic-category/:_id")) {
          requestObj.searchcondition._id = route.params['_id']
        }
        if (route.routeConfig.path.includes("edit-assay/:_id")) {
          requestObj.searchcondition._id = route.params['_id']
        }


        if (route.routeConfig.path.includes("edit-evaluation/:_id")) {
          requestObj.searchcondition._id = route.params['_id']
        }



        if (route.routeConfig.path.includes("assay-list")) {

          if (this.cookiedata.user_type == "laboratory") {

            requestObj.searchcondition.addedby = this.cookiedata._id
          }

        }


        if (route.routeConfig.path.includes("diagnostic-list")) {

          if (this.cookiedata.user_type == "laboratory") {

            requestObj.searchcondition.selected_lab = this.cookiedata._id
          }

        }

        if (route.routeConfig.path.includes("choose-diagnostic")) {
          requestObj.searchcondition.selected_lab = { $ne: this.cookiedata._id }
        }

        if (route.routeConfig.path.includes("email-intake-list")) {

          requestObj.searchcondition.added_by = this.cookiedata._id

        }
        if (route.routeConfig.path.includes("lead-list")) {
          requestObj.searchcondition = {
            status: JSON.parse(route.params["param"]) == 3 ? { $in: [JSON.parse(route.params["param"]), 7] } : JSON.parse(route.params["param"])
          };
          switch (this.cookiedata.user_type) {
            case 'doctor':
              requestObj.searchcondition = {
                $or: [{ doctor_id: this.cookiedata._id }], //{ practice_id: this.cookieData.practice_id }
                status: JSON.parse(route.params["param"]) == 3 ? { $in: [JSON.parse(route.params["param"]), 7] } : JSON.parse(route.params["param"])
              };
              // requestObj.searchcondition = { status: 1 };
              break;
            case 'practice':
              requestObj.searchcondition = {
                practice_id: this.cookiedata._id,
                status: JSON.parse(route.params["param"]) == 3 ? { $in: [JSON.parse(route.params["param"]), 7] } : JSON.parse(route.params["param"])
              };

              break;
            case 'laboratory':
              requestObj.searchcondition = {
                parent_laboratory: this.cookiedata._id,
                is_demo: false,
                status: JSON.parse(route.params["param"]) == 3 ? { $in: [JSON.parse(route.params["param"]), 7] } : JSON.parse(route.params["param"])
              };
              break;
            case 'sales_person':
              requestObj.searchcondition = {
                parent_laboratory: this.cookiedata.parent_laboratory,
                status: JSON.parse(route.params["param"]) == 3 ? { $in: [JSON.parse(route.params["param"]), 7] } : JSON.parse(route.params["param"])
              };
              break;
          }
        }
        if (route.routeConfig.path.includes("doctor/lead-list")) {
          switch (this.cookiedata.user_type) {
            case 'sales_person':
              requestObj.searchcondition = {
                $or: [{ doctor_id: this.cookiedata._id }],
                status: JSON.parse(route.params["param"]) == 3 ? { $in: [JSON.parse(route.params["param"]), 7] } : JSON.parse(route.params["param"]),
                is_demo: true
              }
              break;
          }
        }


        if (route.routeConfig.path.includes("diagnostic-category-list")) {

          if (this.cookiedata.user_type == "laboratory") {

            requestObj.searchcondition.addedby = this.cookiedata._id
          }

        }

        if (route.routeConfig.path.includes("edit-symptoms/:_id")) {
          requestObj.searchcondition._id = route.params['_id']
        }
        if (route.routeConfig.path.includes("equipment-edit")) {
          console.log(route.params)
          requestObj.searchcondition._id = route.params['_id']
        }




        if (route.routeConfig.path.includes("edit/:_id") || route.routeConfig.path.includes("training-category-edit/:_id")) {
          requestObj.searchcondition._id = route.params['_id']
        }

        if (route.routeConfig.path.includes("user-management/folder-view/:_id")) {
          requestObj.searchcondition._id = route.params['_id']
        }



        if (route.routeConfig.path.includes(":usertype/account-settings")) {
          requestObj.searchcondition._id = this.cookiedata._id

        }
        if (route.routeConfig.path.includes(":usertype/update-logo")) {
          requestObj.searchcondition._id = this.cookiedata._id

        }
        if (route.routeConfig.path.includes("dashboard")) {
          //  console.warn(this.cookiedata.user_type,"doctor/dashboard >>>>>>>>>>>>>>>",requestObj,endpoint);
          if (this.cookiedata.user_type == 'doctor') {
            endpoint = "landingpage/finduniquecodebyuser"
            requestObj.searchcondition.user_id = this.cookiedata._id
          }

        }


        if (route.routeConfig.path.includes("user-management/users")) {
          console.log('this.cookiedata', this.cookiedata)
          switch (this.cookiedata.user_type) {
            case 'laboratory':
              requestObj.searchcondition.parent_laboratory = this.cookiedata._id;
              break;
            case 'practice':
              requestObj.searchcondition.practice_id = this.cookiedata._id;
              break;
          }
        }



        if (route.routeConfig.path.includes("user-management/users/:usertype")) {
          console.log('this.cookiedata-------', this.cookiedata)
          console.log("route.params", route.params);

          // if (this.cookiedata.user_type) {           
          //   requestObj.searchcondition = { user_type: [route.params['usertype']]}; 
          //   requestObj.searchcondition.parent_laboratory = this.cookiedata._id; 
          //   console.log('this.cookiedata-------+++', this.cookiedata)

          // }


          switch (this.cookiedata.user_type) {

            case 'admin':
              requestObj.searchcondition = { user_type: [route.params['usertype']] };
              break;
            case 'laboratory':
              requestObj.searchcondition = { user_type: [route.params['usertype']], parent_laboratory: this.cookiedata._id };
              // requestObj.searchcondition.parent_laboratory = this.cookiedata._id;
              break;
            case 'practice':
              requestObj.searchcondition = { user_type: [route.params['usertype']] };
              requestObj.searchcondition.practice_id = this.cookiedata._id;
              break;
            case 'sales_person':
              requestObj.searchcondition = { user_type: [route.params['usertype']] };
              requestObj.searchcondition.refferd_sealsperson = this.cookiedata._id;
          }



        }

        if (route.routeConfig.path.includes(":usertype/manage-location")) {
          requestObj.user_id = this.cookiedata._id;


        }

        if (route.routeConfig.path == '') {
          requestObj.searchcondition = {};

          switch (this.cookiedata.user_type) {
            case 'admin':
              requestObj.searchcondition.user_type = ['admin']
              break;
            case 'laboratory':
              requestObj.searchcondition.user_type = ['practice'];
              requestObj.searchcondition.parent_laboratory = this.cookiedata._id;
              break;
            case 'practice':
              requestObj.searchcondition.user_type = ['doctor'];
              requestObj.searchcondition.practice_id = this.cookiedata._id;
              //  requestObj.searchcondition.parent_laboratory = this.cookiedata._id;
              break;
            case 'sales_person':
              requestObj.searchcondition.user_type = ['practice'];
              requestObj.searchcondition.refferd_sealsperson = this.cookiedata._id;
              // endpoint = 'users/fetchUserDetails'
              break;

          }
          if (this.cookieService.get('currentUser')) {
            let currentUser = this.cookieService.get('currentUser');
            let currentUserStatus: any;
            let demoView: any;
            if (this.cookieService.get('currentUserStatus')) {
              currentUserStatus = JSON.parse(this.cookieService.get('currentUserStatus'));
            }
            if (this.cookieService.get('demoView')) {
              demoView = JSON.parse(this.cookieService.get('demoView'));
            }
            if (currentUser == 'leads') {
              if (currentUserStatus == 3) {
                currentUserStatus = { $in: [3, 7] };
              }
              endpoint = 'intake/leadlist';
              requestObj = { "condition": { "limit": 10, "skip": 0 }, "sort": { "type": "desc", "field": "_id" }, "searchcondition": { "status": currentUserStatus, "is_demo": demoView, is_partial_done: false } }
              if (this.cookiedata && this.cookiedata.user_type == 'laboratory') {
                requestObj.searchcondition.parent_laboratory = this.cookiedata._id
              }
              console.log("currentUser endpoint", endpoint, "requestObj ", requestObj)
            } else {
              requestObj.searchcondition.user_type = [currentUser];
            }
          }
          console.log("routehhhhh currentUser this.cookiedata.user_type", requestObj.searchcondition)
        }
        if (route.routeConfig.path.includes('practice-signup')) {
          // console.log('LLLLLLLL',route.params);
          requestObj.searchcondition = route.params;

        }
        // if (route.routeConfig.path.includes("change-password")) {
        //   requestObj.id._id = this.cookiedata._id

        // }
        if (route.routeConfig.path.includes("training-center")) {
          if (this.cookiedata.user_type !== 'admin') {
            requestObj.searchcondition.user_roll = this.cookiedata.user_type
          }
        }



        this.httpService.httpViaPost(endpoint, requestObj)
          .subscribe(response => {
            console.log(response, '<<<<<<< resolve response');
            return resolve(response);
          });
      } else {
        if (route.routeConfig.path.includes('clinical-report-view')) {
          console.log('LLLLLLLL', requestObj, "requestObj", route.params);
          // alert("ffg")
          requestObj.searchcondition._id = route.params['_id'];

          this.httpService.httpViaPost(endpoint, requestObj)
            .subscribe(response => {
              console.log(response, '<<<<<<< resolve response');
              return resolve(response);
            });
        } else {
          return resolve('');
        }
      }



    });
  }

  reportUpload(uploaded: any) {
    this.reportUploading.next(uploaded);
  }
}
