import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';

export interface DialogData {
  h3Text: any;
  patient_id: any;
}

@Component({
  selector: 'app-sample-conversation',
  templateUrl: './sample-conversation.component.html',
  styleUrls: ['./sample-conversation.component.css']
})

export class SampleConversationComponent {

  public sampleConversationDataset: any;
  public patientID: any;

  public sampleconversationHTML: any;

  public progressBarLoader = false

  @Input() set sampleconversation(value: any) {

    if (value) {


      this.sampleConversationDataset = value.taxonomychangesData;

      console.log(value, 'sampleConversationDataset+++++++++++++++')

      this.patientID = value.patientID;

    }


  }

  constructor(
    public dialog: MatDialog, private httpService: HttpServiceService, public activeRoute: ActivatedRoute) {





  }

  handleClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (target.tagName === 'H3') {
      const h3Text = target.textContent?.trim();

      const allH3Elements = document.querySelectorAll('.suggestion');
      allH3Elements.forEach((element: HTMLElement) => {
        element.classList.remove('active');
      });

      target.classList.add('active');

      this.openFunction(h3Text);
      console.log(target.outerHTML, 'outerHTML');
    }
  }

  openFunction(h3Text: any) {
    console.log('Clicked with parameter:', h3Text);
  
    this.progressBarLoader = true;
  
    let payload: any = {
      taxonomy: h3Text,
      chat_open_ais_id: this.patientID
    };
  
    this.httpService.httpViaPostRapidResponse('api5/rapidresponseconversationdata', payload).subscribe((response: any) => {
  
      if (response.status == "success") {
  
        const nextReasonElement = document.querySelector(`.suggestion.active + p.reason`);
        console.log(nextReasonElement, 'Clicked suggestion element');
  
        this.progressBarLoader = false;

        const existingNewElement = document.querySelector('.newElement');
        if (existingNewElement) {
          existingNewElement.remove();
        }
  
        if (response && response.res) {
          if (nextReasonElement) {
            const newDiv = document.createElement('div');
            newDiv.classList.add('newElement');
            newDiv.innerHTML = response.res;
            nextReasonElement.insertAdjacentElement('afterend', newDiv);
          }
        } else {
          // Create the newDiv and set its innerHTML
          const newDiv = document.createElement('div');
          newDiv.classList.add('newElement');
          newDiv.innerHTML = `
            <div class="fld_nodata">
              <h3> No Record Found !</h3>
            </div>`;
          
          // Append the newDiv after the nextReasonElement
          if (nextReasonElement) {
            nextReasonElement.insertAdjacentElement('afterend', newDiv);
          }
        }
  
      }
    });
  }



}


@Component({
  selector: 'sampleconversationModal',
  templateUrl: 'sampleconversationModal.html',

})
export class sampleconversationModal {

  public sampleconversationHTML: any;

  public progressBarLoader = true

  constructor(
    public dialogRef: MatDialogRef<sampleconversationModal>, private httpService: HttpServiceService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {


    console.log('dataModal>>>>>>>>>>>>>>>>>', data)



    // let payload: any = {

    //   taxonomy: data.h3Text,
    //   chat_open_ais_id: data.patient_id,

    // };


    // this.httpService.httpViaPostRapidResponse('api5/rapidresponseconversationdata', payload).subscribe((response: any) => {




    //   if (response) {
    //     if (response.res) {



    //        this.sampleconversationHTML=response.res

    //       //  console.log('response>>>>>>>>>>>>>>>>>',  response.res ,this.sampleconversationHTML)

    //       this.progressBarLoader =false
    //     }

    //   }


    // })



  }



  onNoClick(): void {
    this.dialogRef.close();
  }
}
