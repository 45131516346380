<div class="ModalTabSubConPage">
    <h1>BODY METRICS</h1>

    <h3>Patient Details
    </h3>
    <div class="bodyMetricsContainerFluid">
        <div class="bodyMetricsContainerInnerContentDiv">
            <p><sup>Name</sup><span>{{BodyMetricsDataset?.patient_name}}</span></p>
            <p><sup>Gender</sup><span>{{BodyMetricsDataset?.gender}}</span></p>
            <p><sup>Date Of Birth</sup><span>{{BodyMetricsDataset?.dob}}</span></p>
            <p><sup>Address</sup><span>{{BodyMetricsDataset?.address}}</span></p>
            <p><sup>State</sup><span>{{BodyMetricsDataset?.state}}</span></p>
            <p><sup>City</sup><span>{{BodyMetricsDataset?.city}}</span></p>
            <p><sup>Zip</sup><span>{{BodyMetricsDataset?.zip}}</span></p>
        </div>
    </div>
    <h3>Anthropometrics
    </h3>
    <div class="bodyMetricsContainerFluid">
        <div class="bodyMetricsContainerInnerContentDiv">
            <div class="matricsDiv ng-star-inserted"><span class="spanimgdiv"><img
                        src="https://all-frontend-assest.s3.amazonaws.com/bmi.jpg"></span>
                <div class="matricTitle"><span class="label">Body Mass Index :</span><strong>{{BodyMetricsDataset?.bmi}}</strong></div>
            </div>
            <div class="matricsDiv ng-star-inserted"><span class="spanimgdiv"><img
                        src="https://all-frontend-assest.s3.amazonaws.com/bmr.png"></span>
                <div class="matricTitle"><span class="label">Basal Metabolic Rate :</span><strong>{{BodyMetricsDataset?.bmr}} <span
                            class="unit">Calories/day</span></strong></div>
            </div>
            <div class="matricsDiv ng-star-inserted"><span class="spanimgdiv"><img
                        src="https://all-frontend-assest.s3.amazonaws.com/body_fat.jpg"></span>
                <div class="matricTitle"><span class="label">Body Fat Percentage :</span><strong>{{BodyMetricsDataset?.body_fat}} <span
                            class="unit">%</span></strong></div>
            </div>
            <div class="matricsDiv ng-star-inserted"><span class="spanimgdiv"><img
                        src="https://all-frontend-assest.s3.amazonaws.com/hydration.jpg"></span>
                <div class="matricTitle"><span class="label">Daily Water Needs :</span><strong>{{BodyMetricsDataset?.hydration}} <span
                            class="unit">Litre/day</span></strong></div>
            </div>
            <div class="matricsDiv ng-star-inserted"><span class="spanimgdiv"><img
                        src="https://all-frontend-assest.s3.amazonaws.com/ibw.jpg"></span>
                <div class="matricTitle"><span class="label">Ideal Body Weight :</span><strong>{{BodyMetricsDataset?.ibw}} <span
                            class="unit">Lbs</span></strong></div>
            </div>
            <div class="matricsDiv ng-star-inserted"><span class="spanimgdiv"><img
                        src="https://all-frontend-assest.s3.amazonaws.com/lbm.jpg"></span>
                <div class="matricTitle"><span class="label">Lean Body Mass :</span><strong>{{BodyMetricsDataset?.lbm}} <span
                            class="unit">Lbs</span></strong></div>
            </div>
        </div>
    </div>
</div>