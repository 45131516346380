<div class="ModalTabSubConPage">
    <h1>PATIENT DETAILS</h1>

    <h3>PATIENT GENERAL INFORMATION </h3>

    <div class="ModalTabSubConPageFrom" *ngIf="patientdetailsData">

        <div class="PageFromcon">
            <span>Practice Name</span>
            <label>{{folderviewdataData && folderviewdataData.practice_name ? folderviewdataData.practice_name : 'NA' }}</label>



        </div>

        <div class="PageFromcon">
            <span>Address</span>

            <label>{{ patientdetailsData.address ? patientdetailsData.address : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>State</span>

            <label>{{ patientdetailsData.state ? patientdetailsData.state : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>City</span>
            <label>{{ patientdetailsData.city ? patientdetailsData.city : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>ZIP</span>
            <label>{{ patientdetailsData.zip ? patientdetailsData.zip : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>Patient Name</span>
            <label>{{ patientdetailsData.patient_name ? patientdetailsData.patient_name : 'NA' }}</label>

        </div>
        <div class="PageFromcon">
            <span>Patient Phone</span>
            <label>{{ patientdetailsData.patient_phone ? patientdetailsData.patient_phone : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>Gender</span>
            <label>{{ patientdetailsData.patient_gender ? patientdetailsData.patient_gender : 'NA' }}</label>

        </div>


        <div class="PageFromcon">
            <span>Patient Email</span>
            <label>{{ patientdetailsData.patient_email ? patientdetailsData.patient_email : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>Height</span>
            <label>{{ patientdetailsData.height ? patientdetailsData.height : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>weight</span>
            <label>{{ patientdetailsData.weight ? patientdetailsData.weight : 'NA' }}</label>

        </div>

        <div class="PageFromcon">
            <span>Date Of Birth</span>
            <label>{{ patientdetailsData.patient_dob ? patientdetailsData.patient_dob : 'NA' }}</label>

        </div>


    </div>

    <h3>INSURANCE INFORMATION </h3>

    <div class="ModalTabSubConPageFrom">

        <div class="PageFromcon">
            <span>Insurance Name</span>
            <label>Insurance Name</label>

        </div>

        <div class="PageFromcon">
            <span>Insurance Type</span>
            <label>Insurance Type</label>

        </div>

    </div>

    <h3>OTHER INFORMATION </h3>
    <div class="ModalTabSubConPageFrom2">
        <label>Patient has Diabetes <mat-checkbox class="example-margin" checked></mat-checkbox></label>

        <label>Patient has PVD (Peripheral Vascular Disease)
            <mat-checkbox class="example-margin" checked></mat-checkbox></label>
        <label>Patient has been diagnosed and treated for Covid 19 within the last year
            <mat-checkbox class="example-margin"></mat-checkbox></label>
        <label>Patient has approved the use of their unidentifiable data
            <mat-checkbox class="example-margin"></mat-checkbox></label>

    </div>

    <div class="ModalTabSubConPageFrom3">

        <div class="ModalTabSubConPageFrom3SUB">
            <h4>AUTONOMIC NERVOUS SYSTEM DYSFUNCTION (ANSD)</h4>

            <table>
                <tr>
                    <td>Blurred Vision</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>






                <tr>
                    <td>Elevated Blood Sugar</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Extreme Thirst</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Frequent Urination</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Fatigue (Tiredness)</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Heartburn</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Increased Hunger</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Nausea</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Numbness and/or Tingling in Hands or Feet</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Vomiting</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Burning Sensations</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Difficulty Digesting Food</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Dizziness</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Fainting</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Exercise Intolerance</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Sexual Difficulties</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>


                <tr>
                    <td>Sweat Abnormalities</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>



                <tr>
                    <td>Urinary Problems</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


            </table>



        </div>

        <div class="ModalTabSubConPageFrom3SUB">
            <h4>ENDOTHELIAL DYSFUNCTION (ENDOD)
            </h4>

            <table>

                <tr>
                    <td>Angina (severe chest pain, often spreading to shoulder, arm, back, neck, or jaw)</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Chest Pain that goes away with rest</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Heartburn</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Pain In Calves</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Shortness of Breath</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        Stroke</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>
                    <td>
                        TIA (mini stroke)</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


            </table>

        </div>

        <div class="ModalTabSubConPageFrom3SUB">
            <h4>CARDIOMETABOLIC RISK (CMR) </h4>

            <table>

                <tr>

                    <td> Headaches</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>
                <tr>
                    <td>
                        Dizziness</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>
                <tr>
                    <td>
                        Swelling of Ankles</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>




            </table>
        </div>

        <div class="ModalTabSubConPageFrom3SUB">
            <h4>INSULIN RESISTANCE (IR)
            </h4>

            <table>



                <tr>

                    <td> Blurred Vision</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Elevated Blood Sugar</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Extreme Thirst</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Fatigue (Tiredness)</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Increased Hunger</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>






            </table>
        </div>

        <div class="ModalTabSubConPageFrom3SUB">
            <h4>SMALL FIBER SENSORY NEUROPATHY (SFN) </h4>

            <table>


                <tr>

                    <td> Burning Sensations</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Painful Contact With Socks or Bed Sheets</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Pebble or Sandlike Sensation In Shoes</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Stabbing or Electrical Shock Sensation</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Pins And Needles Sensation In Feet</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>






            </table>
        </div>

        <div class="ModalTabSubConPageFrom3SUB">
            <h4>CARDIOMETABOLIC AUTONOMIC NEUROPATHY (CAN)
            </h4>

            <table>


                <tr>

                    <td>
                        Blurred Vision</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Cold, Clammy, Pale Skin</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Depression</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Lightheadedness</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Thirst</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Fainting</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Fatigue (Tiredness)</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Lack of Concentration</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Lack of Energy</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>

                <tr>

                    <td>
                        Nausea
                    </td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>
                <tr>

                    <td>
                        Rapid, Shallow Breathing</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>



            </table>
        </div>


        <div class="ModalTabSubConPageFrom3SUB">
            <h4>PLETHYSMOGRAPHY CARDIOVASCULAR DISEASE (PTG CVD)</h4>

            <table>



                <tr>

                    <td>
                        Blood clot in a vein (Venous Thrombosis)</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                    </td>
                </tr>


                <tr>

                    <td>
                        Heart Attack</td>
                    <td><span>6 Months<mat-checkbox class="example-margin" checked></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>

                    <td>
                        Irregular heartbeat, too fast/slow (Atrial Fibrillation)</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>


                <tr>

                    <td>
                        Stroke</td>
                    <td><span>6 Months<mat-checkbox class="example-margin"></mat-checkbox></span>
                        <span>Today<mat-checkbox class="example-margin"></mat-checkbox></span>
                    </td>
                </tr>








            </table>
        </div>







    </div>

    <button class="modalsubbtn">Submit</button>

</div>