import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-add-edit-ddiagnostic',
  templateUrl: './add-edit-ddiagnostic.component.html',
  styleUrls: ['./add-edit-ddiagnostic.component.css']
})
export class AddEditDdiagnosticComponent {
  data: any;
  flag: any;
  externaldatavalue: any;
  name: any;
  value: any;

  public addFormLoader: boolean = false;
  public cookieData: any = {};
  public practiceformdata: any = {};
  public diagnosticcatautocompleteData: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  public diagnosticcatautocompleteDataNew: any = {};


  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];

  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute, private metaService: Meta, private titleService: Title) {

    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous') && !window.location.pathname.includes('laboratory/add-diagnostic') && !window.location.pathname.includes('laboratory/edit-diagnostic')) {
      this.router.navigateByUrl('404')
    }
    console.log('cookieDataType', this.cookieData._id)

  }
  ngOnInit() {
    this.titleService.setTitle(`${this.cookieData.user_type.charAt(0).toUpperCase() + this.cookieData.user_type.slice(1)}  Available Test Add`);
    this.metaService.addTags([
      { name: 'keywords', content: `${this.cookieData.user_type.charAt(0).toUpperCase() + this.cookieData.user_type.slice(1)}  Available Test Add` },
      { name: 'description', content: `${this.cookieData.user_type}  Available Test Add` },
      { name: 'robots', content: 'index, follow' }
    ]);
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.titleService.setTitle(`${this.cookieData.user_type.charAt(0).toUpperCase() + this.cookieData.user_type.slice(1)}  Available Test Edit`);
            this.metaService.addTags([
              { name: 'keywords', content: `${this.cookieData.user_type.charAt(0).toUpperCase() + this.cookieData.user_type.slice(1)}  Available Test Edit` },
              { name: 'description', content: `${this.cookieData.user_type}  Available Test Edit` },
              { name: 'robots', content: 'index, follow' }
            ]);
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
            console.log("Edit data", this.editFormData);

            // let data = {
            //   searchcondition: {
            //     _id: this.editFormData.diagnostic_category_id
            //   }
            // }
            // this.apiservice.httpViaPost('intake/getdiagnosticcategory', data).subscribe((response) => {
            //   console.log("dataobj", response)
            //   this.diagnosticcatautocompleteData = response.results.res[0]
            //   this.diagnosticcatautocompleteDataNew = {
            //     key: this.diagnosticcatautocompleteData._id,
            //     val: this.diagnosticcatautocompleteData.diagnostic_category_name,
            //   }
            //   // console.log("diagnosticcatautocompleteData", this.diagnosticcatautocompleteData);
            //   // this.formfieldrefreshdata = {
            //   //   field: "diagnostic_category_id",
            //   //   // val: this.diagnosticcatautocompleteDataNew,
            //   //   value: this.diagnosticcatautocompleteData.diagnostic_category_name
            //   // };
            //   // console.log("diagnosticcatautocompleteDataNew", this.diagnosticcatautocompleteDataNew)
            //   console.log("practiceformdata>>>", this.practiceformdata.fields[1]);


            // })

          }
        }
      });
      // this.editForm();
    } else {
    }
    console.log("editFormData", this.editFormData);
    this.initailForm();







  }



  initailForm() {
    // console.log("diagnosticcatautocompleteDataNew>>>", this.diagnosticcatautocompleteDataNew);

    this.practiceformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      cancelroute: this.cookieData.user_type == 'laboratory' ? `${this.cookieData.user_type}/diagnostic-list` : `${this.cookieData.user_type}/miscellaneous/diagnostic-list`, // use for redirect after cancel the form
      fields: [

        {
          label: 'Diagnostic Name',
          name: 'diagnostic_name',
          value: this.editFormData && this.editFormData.diagnostic_name ? this.editFormData.diagnostic_name : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Diagnostic Name is Required' },

          ],
        },
        {
          label: "Select Equipment",
          name: "equipment_id",
          type: 'autocomplete',
          endpoint: "intake/equipmentautocomplete",
          search_field: "name_search",
          multiple: true,
          val: this.editFormData && Object.keys(this.editFormData).length > 0 && this.editFormData.equipment_val ? this.editFormData.equipment_val : [],
          value: this.editFormData && this.editFormData.equipment_id ? this.editFormData.equipment_id : '',
          validations: [
            { rule: 'required', message: 'Equipment is Required' }
          ]

        },
        {
          label: "Diagnostic Category",
          name: "diagnostic_category_id",
          // hint: 'In months',
          type: 'autocomplete',
          // multiple: false,
          endpoint: "intake/diagnosticcatautocomplete",
          search_field: "name_search",

          val: this.editFormData && Object.keys(this.editFormData).length > 0 ? [{ key: this.editFormData.diagnostic_category_id[0].key, val: this.editFormData.diagnostic_category_id[0].val }] : [],
          value: this.editFormData && this.editFormData.diagnostic_category_id ? this.editFormData.diagnostic_category_id[0] : '',
          validations: [
            { rule: 'required', message: 'Diagnostic Category is Required' }
          ]

        },

        {
          label: "Assay",
          name: "assay_id",
          // hint: 'In months',
          type: 'autocomplete',
          multiple: true,
          endpoint: "intake/assayautocomplete",
          search_field: "name_search",

          val: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.assay_val : [],
          value: this.editFormData && Object.keys(this.editFormData).length > 0 ? this.editFormData.assay_id : [],
          validations: [
            { rule: 'required', message: 'Assay is Required' }
          ]

        },
        {
          label: 'Priority',
          name: 'diagnostic_priority',
          value: this.editFormData && this.editFormData.diagnostic_priority ? this.editFormData.diagnostic_priority : '',
          type: 'number',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Priority is Required' },

          ],
        },
        {
          label: 'Reference Link',
          name: 'diago_reference_link',
          value: this.editFormData && this.editFormData.diago_reference_link ? this.editFormData.diago_reference_link : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            // { rule: 'required', message: 'Reference Link is Required' },

          ],
        },
        {
          label: "Diagnostic Reason",
          name: "diagnostic_reason",
          type: "textarea",
          // group: "grp1",
          rows: 1,
          cols: 2,
          value: this.editFormData && this.editFormData.diagnostic_reason ? this.editFormData.diagnostic_reason : '',
          // hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Diagnostic Reason field Needs to be required" },
          ]
        },
        {
          label: "Diagnostic Description",
          name: "diagnostic_des",
          type: "textarea",
          // group: "grp1",
          rows: 1,
          cols: 2,
          value: this.editFormData && this.editFormData.diagnostic_des ? this.editFormData.diagnostic_des : '',
          // hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Diagnostic Description field Needs to be required" },
          ]
        },




        {
          label: 'Active',
          name: 'status',
          type: this.cookieData.user_type == "laboratory" ? 'hidden' : 'checkbox',
          val: this.statusarr,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
          validations: [
            // { rule: 'required', message: 'Required Type' },
          ]
        },


      ]
    }
    if (this.editFormData) {
      console.log("sdfsd")
      this.formfieldrefreshdata = {
        field: "removefromcontrol",
        value: ["password", "confirmpassword"],
      };
      // this.locationFieldManage(this.editFormData.location)
    }

  }

  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChangeval", val)
    if (val.field && val.field == "fromsubmit") {
      this.addFormLoader = true;
      val.fromval.location = this.externaldatavalue;
      let dataobj = {
        data: val.fromval,
      }
      dataobj.data.addedby = this.cookieData._id
      dataobj.data.is_private = false;
      if (this.editFormData) {
        dataobj.data._id = this.editFormData._id
      }

      if (this.cookieData.user_type == "laboratory") {
        dataobj.data.selected_lab = this.cookieData._id;
        dataobj.data.is_private = true;
        if (typeof dataobj.data._id == 'undefined' || dataobj.data._id == null) {
          dataobj.data.isNew = true;
        }
      }

      console.log('dataobjTest', dataobj)

      // return;

      this.apiservice.httpViaPost('intake/adddiagnostic', dataobj).subscribe((response) => {
        console.log("dataobj", response)
        if (response && response.status == 'success') {

          this.pathParametersFunction()

          // this.router.navigateByUrl(`admin/miscellaneous/diagnostic-list`);
          if (this.cookieData.user_type == "laboratory") {
            this.matSnackBar.open('New test submitted for review. You will receive an email once the new test has been properly set up, and the status of the test will be changed to active.', "Ok", {
              duration: 7000
            });
            setTimeout(() => {
              this.router.navigateByUrl(`${this.cookieData.user_type}/diagnostic-list`)
            }, 2000);
          } else {
            this.matSnackBar.open(response.message, "Ok", {
              duration: 5000
            });
            setTimeout(() => {
              this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/diagnostic-list`)
            }, 2000);
          }
          this.addFormLoader = false;
        }

        if (response && response.status == 'error') {
          this.matSnackBar.open(response.message, "", {
            duration: 1000
          });
          this.addFormLoader = false;
        }

      })

    };





    if (val.field && val.field == "formreset") {
      this.formfieldrefreshdata = {
        field: "diagnostic_des",
        value: '',
      };
    }
  }


  pathParametersFunction(){
    let body = {
      pathParameters: {
        path: "totalcost-calculation"
      },
      queryStringParameters: {        
      }
    }
    if (this.editFormData && this.editFormData._id) {
      body.queryStringParameters['_id'] = this.editFormData._id
    }

    this.apiservice.httpViaPost('intake/dockerfunctioninvoke', body).subscribe((response) => {
      console.log("dataobj", response)    

    })


  }
}
