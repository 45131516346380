import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public count: number = 0;

  constructor(public cookieService: CookieService, private router: Router, public activateRoute: ActivatedRoute) { }
  isLoggedIn() {
    if (this.cookieService.get('loggedin_user')) {
      let userData: any = JSON.parse(this.cookieService.get('login_user_details'))
      console.log("window.location.pathname", window.location.pathname, "userData", userData)
      if (!window.location.pathname.includes(userData.user_type) && this.count == 0 && userData.first_login > 1 && !window.location.pathname.includes('404')) {
        if (userData.first_login === 2 && userData.user_type == 'laboratory') {
          this.router.navigateByUrl(`${userData.user_type}/miscellaneous/choose-diagnostic`);
        } else {
          if (userData.landing_page && userData.info_update !== null && userData.info_update !== undefined && userData.info_update == false) {
            this.router.navigateByUrl(`${userData.user_type}/account-settings`);
          } else {
            this.router.navigateByUrl(`${userData.user_type}/dashboard`);
          }
        }
        this.count++
      }
      return true;
    }
    return false;
  }
}
