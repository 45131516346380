import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-add-edit-diagnostic-category',
  templateUrl: './add-edit-diagnostic-category.component.html',
  styleUrls: ['./add-edit-diagnostic-category.component.css']
})
export class AddEditDiagnosticCategoryComponent {
  public isedit: boolean = false;
  public addFormLoader: boolean = false;
  public diagnosticCategoryformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public cookieData: any
  public editFormData: any
  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];
  public ckeConfig: any = {}

  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {

    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous')) {
      this.router.navigateByUrl('404')
    }
  }

  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data.results.res) {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
          }
        }
      });
      // this.editForm();
    }
    this.initailForm();
  }
  initailForm() {
    this.diagnosticCategoryformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'email/addtemplate',
      redirectpath: `${this.cookieData.user_type}/diagnostic-category-list`,
      resettext: 'Reset',
      canceltext: 'Back',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      cancelroute: `${this.cookieData.user_type}/miscellaneous/diagnostic-category-list`, // use for redirect after cancel the form
      fields: [
        {
          label: 'Diagnostic Category Name',
          name: 'diagnostic_category_name',
          value: this.editFormData && this.editFormData.diagnostic_category_name ? this.editFormData.diagnostic_category_name : '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Diagnostic Category Name is Required' },

          ],
        },


        {
          label: 'Priority',
          name: 'diagnostic_category_priority',
          value: this.editFormData && this.editFormData.diagnostic_category_name ? this.editFormData.diagnostic_category_priority : '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Priority is Required' },

          ],
        },





        {
          label: "Diagnostic Category Description",
          name: "diagnostic_category_des",
          type: "textarea",

          // rows: 1,
          // cols: 2,
          value: this.editFormData && this.editFormData.diagnostic_category_des ? this.editFormData.diagnostic_category_des : '',

          validations: [
            { rule: 'required', message: 'Diagnostic Category Description Name is Required' },
          ]
        },

        {

          label: 'Active',
          name: 'status',
          type: 'checkbox',
          hint: '',
          val: this.statusarr,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : '',

        }

      ]

    }
  }

  listenFormFieldChange(val: any) {
    console.log("dataobj", val)
    if (val.field && val.field == "fromsubmit") {
      let dataobj = {
        data: val.fromval
      }
      if (this.editFormData) {
        dataobj.data._id = this.editFormData._id
      }


      if (this.cookieData.user_type == "laboratory") {

        dataobj.data.addedby = this.cookieData._id

      }


      this.addFormLoader = true;
      console.log("dataobj------------", dataobj);

      this.apiservice.httpViaPost('intake/adddiagnosticcategory', dataobj).subscribe((response) => {
        console.log("response", response);

        if (response) {
          if (response.status == 'success') {

            this.addFormLoader = false;
            this.matSnackBar.open(this.editFormData ? "Updated Successfully" : response.message, "Ok", {
              duration: 2000
            });
            this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/diagnostic-category-list`);
          }
          else {
            this.matSnackBar.open(response.message, "", {
              duration: 2000
            });
            this.addFormLoader = false;
          }
        }

      })

    }
  }
}
