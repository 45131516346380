<div class="labReportViewModalBody">
    <button class="close_button">
        <mat-icon style="cursor: pointer;" (click)="closeModal()"> cancel</mat-icon>
    </button>
    <h2>Diagnosis Reports</h2>
    <div class="reportList">
        <ul>
            <li *ngFor="let diagnosis of modalData">
                <strong>{{diagnosis.diagnostic_name}}</strong>
            </li>
        </ul>
    </div>
</div>