<mat-card class="admin_search_modal">
    <button class="close_button">
        <mat-icon style="cursor: pointer;" (click)="modalClose()"> cancel</mat-icon>
    </button>
    <h1 class="listingsearchtitle">Search</h1>
    <!-- input search -->
    <div class="search_form">

        <!-- <mat-form-field class="example-full-width patient_searchmodal_location_label">
            <input type="text" placeholder="Search By Name" matInput [(ngModel)]="modalData.fullname" (ngModelChange)="userNameModalChange.next($event)" [matAutocomplete]="nameAuto">
            <mat-autocomplete #nameAuto="matAutocomplete">
                <mat-option *ngFor="let nameObj of nameArr" [value]="nameObj.name">
                    {{nameObj.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field> -->
        <ng-container>
            <mat-card-content class="new_usermanagement_right_mainpart_searchpart cw1">
                <div class="navlistwrapper">
                    <div class="navlist" style="display: inline;" *ngFor="let item of nameselectedData;let i=index;">
                        <mat-chip class="example-box">{{item.name}}
                            <mat-icon style="cursor: pointer;" matChipRemove (click)="remove(item,i,'name')">
                                cancel</mat-icon>
                        </mat-chip>
                    </div>
                </div>
                <mat-progress-bar mode="indeterminate" *ngIf="nameprogressFlag"></mat-progress-bar>
                <mat-form-field class="example-full-width patient_searchmodal_location_label">
                    <input type="text" placeholder="{{isLead?'Search By Patient Name':'Search By Name'}}" matInput [(ngModel)]="modalData.fullname" (ngModelChange)="userNameModalChange.next($event)" [matAutocomplete]="nameAuto">
                    <!-- <input type="text" placeholder="Search By Patient Name" matInput [(ngModel)]="modalData.fullname" (ngModelChange)="userNameModalChange.next($event)" [matAutocomplete]="nameAuto" *ngIf="isLead"> -->
                </mat-form-field>
                <mat-autocomplete #nameAuto="matAutocomplete">
                    <mat-option *ngFor="let nameObj of nameArr;let i = index" [value]="nameObj.name" (click)="namechooseChipItem(nameObj,i)">
                        {{nameObj.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-card-content>
        </ng-container>


        <ng-container *ngIf="isLead">
            <mat-card-content class="new_usermanagement_right_mainpart_searchpart">
                <div class="navlistwrapper">
                    <div class="navlist" style="display: inline;" *ngFor="let item of patientemailSelectedData;let i=index;">
                        <mat-chip class="example-box">{{item.name}}
                            <mat-icon style="cursor: pointer;" matChipRemove (click)="remove(item,i,'email')">
                                cancel</mat-icon>
                        </mat-chip>
                    </div>
                </div>
                <mat-progress-bar mode="indeterminate" *ngIf="emailprogressFlag"></mat-progress-bar>
                <mat-form-field class="example-full-width patient_searchmodal_location_label">
                    <input type="text" placeholder="Search By Email" matInput [(ngModel)]="modalData.patient_email" (ngModelChange)="patientEmailModalChange.next($event)" [matAutocomplete]="emailAuto" *ngIf="!isLead">

                    <input type="text" placeholder="Search By Patient Email" matInput [(ngModel)]="modalData.patient_email" (ngModelChange)="patientEmailModalChange.next($event)" [matAutocomplete]="emailAuto" *ngIf="isLead">
                </mat-form-field>
                <mat-autocomplete #emailAuto="matAutocomplete">
                    <mat-option *ngFor="let nameObj of patientemailArr;let i = index" [value]="nameObj.name" (click)="patientemailChooseChipItem(nameObj,i)">
                        {{nameObj.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-card-content>
            <!-- </ng-container>
        <ng-container *ngIf="isLead"> -->
            <mat-card-content class="new_usermanagement_right_mainpart_searchpart cw1">
                <div class="navlistwrapper">
                    <div class="navlist" style="display: inline;" *ngFor="let item of praticenameselectedData;let i=index;">
                        <mat-chip class="example-box">{{item.name}}
                            <mat-icon style="cursor: pointer;" matChipRemove (click)="remove(item,i,'name')">
                                cancel</mat-icon>
                        </mat-chip>
                    </div>
                </div>
                <mat-progress-bar mode="indeterminate" *ngIf="practicenameprogressFlag"></mat-progress-bar>
                <mat-form-field class="example-full-width patient_searchmodal_location_label">
                    <input type="text" placeholder="Search By Practice Name" matInput [(ngModel)]="modalData.practice_name" (ngModelChange)="practiceNameModalChange.next($event)" [matAutocomplete]="practiceNameAuto">
                </mat-form-field>
                <mat-autocomplete #practiceNameAuto="matAutocomplete">
                    <mat-option *ngFor="let nameObj of practicenameArr;let i = index" [value]="nameObj.name" (click)="praticenamechooseChipItem(nameObj,i)">
                        {{nameObj.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-card-content>
            <!-- </ng-container>

        <ng-container *ngIf="isLead"> -->
            <mat-card-content class="new_usermanagement_right_mainpart_searchpart cw1">
                <div class="navlistwrapper">
                    <div class="navlist" style="display: inline;" *ngFor="let item of doctornameselectedData;let i=index;">
                        <mat-chip class="example-box">{{item.name}}
                            <mat-icon style="cursor: pointer;" matChipRemove (click)="remove(item,i,'name')">
                                cancel</mat-icon>
                        </mat-chip>
                    </div>
                </div>
                <mat-progress-bar mode="indeterminate" *ngIf="doctornameprogressFlag"></mat-progress-bar>
                <mat-form-field class="example-full-width patient_searchmodal_location_label">
                    <input type="text" placeholder="Search By Doctor Name" matInput [(ngModel)]="modalData.doctor_name" (ngModelChange)="doctorNameModalChange.next($event)" [matAutocomplete]="doctorNameAuto">
                </mat-form-field>
                <mat-autocomplete #doctorNameAuto="matAutocomplete">
                    <mat-option *ngFor="let nameObj of doctornameArr;let i = index" [value]="nameObj.name" (click)="doctornamechooseChipItem(nameObj,i)">
                        {{nameObj.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-card-content>
        </ng-container>

        <ng-container *ngIf="!isLead">
            <mat-card-content class="new_usermanagement_right_mainpart_searchpart">
                <div class="navlistwrapper">
                    <div class="navlist" style="display: inline;" *ngFor="let item of emailSelectedData;let i=index;">
                        <mat-chip class="example-box">{{item.name}}
                            <mat-icon style="cursor: pointer;" matChipRemove (click)="remove(item,i,'email')">
                                cancel</mat-icon>
                        </mat-chip>
                    </div>
                </div>
                <mat-progress-bar mode="indeterminate" *ngIf="emailprogressFlag"></mat-progress-bar>
                <mat-form-field class="example-full-width patient_searchmodal_location_label">
                    <input type="text" placeholder="Search By Email" matInput [(ngModel)]="modalData.email" (ngModelChange)="userEmailModalChange.next($event)" [matAutocomplete]="emailAuto">
                </mat-form-field>
                <mat-autocomplete #emailAuto="matAutocomplete">
                    <mat-option *ngFor="let nameObj of emailArr;let i = index" [value]="nameObj.name" (click)="emailChooseChipItem(nameObj,i)">
                        {{nameObj.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-card-content>
        </ng-container>
        <mat-form-field appearance="fill">
            <mat-label>Update on Start Date</mat-label>
            <input matInput [matDatepicker]="pickerStart" [(ngModel)]="modalData.startdate">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Update on End Date</mat-label>
            <input matInput [matDatepicker]="pickerEnd" [(ngModel)]="modalData.enddate">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="!isLead">
            <mat-label>Search By Status</mat-label>
            <mat-select [(ngModel)]="modalData.status">
                <mat-option value="1">Active</mat-option>
                <mat-option value="0">Inactive</mat-option>
            </mat-select>
        </mat-form-field>



    </div>

    <div class="search_modal_buttonwrapper">
        <button mat-button (click)="onNoClick()">Submit</button>
        <button mat-button [mat-dialog-close]="modalData" (click)="modalClose('cancel')">Cancel</button>
        <button mat-button (click)="resetForm()">Reset</button>
    </div>



    <!-- <lib-showform class="addEditForm" [formdata]="searchformdata" [formfieldrefreshdata]="formfieldrefreshdata"
    (onFormFieldChange)="listenFormFieldChange($event)">
</lib-showform> -->


    <!-- </div> -->




</mat-card>