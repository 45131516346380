import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpServiceService } from 'src/app/service/http-service.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CookieService } from 'ngx-cookie-service';
import { AddUserBottomSheet } from '../../../usermanagement/user-list/user-list.component';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AssayMapWithIcdCodeComponent } from '../assay-map-with-icd-code/assay-map-with-icd-code.component';

@Component({
  selector: 'app-assay-list',
  templateUrl: './assay-list.component.html',
  styleUrls: ['./assay-list.component.css']
})
export class AssayListComponent {
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'usertype'];
  public tabledatatalist: []
  tablename = "users";
  editroute = "";
  updateendpoint = "intake/assaystatus";
  deleteendpoint = "intake/deleteassay";
  datacollection: any = "intake/assaylist";
  public listprogressBar: any = false;

  searchendpoint = "";
  date_search_endpoint: any = "intake/assaylist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  modify_header_array: any = {
    assay_name: "Assay Name",
    assay_des: "Assay Description",
    createdon_datetime: "Created On",
    updatedon_datetime: "Updated On",
    status: "Status"
  };
  search_settings: any = {


    search: [{
      label: "Search By Assay Name", field: '_id',
      serversearchdata: { endpoint: 'intake/assayautocomplete' },
    },
    ],

    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],

    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["assay_name", "createdon_datetime", "updatedon_datetime", "status", "usertype"],
  };
  public cookieData: any = {};
  public isAdminUser: boolean = false;
  public isDoctorUser: boolean = false;

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: 'Add Assay',
      //   type: 'button',
      //   name: 'add_taxonomies',
      //   tooltip: 'Add Taxonomies',
      //   classname: 'add_adminBTN',
      // },
    ],
  }
  libdata: any = {
    basecondition: {},

    detailview_override: [
      { key: "assay_name", val: "Assay Name" },
      { key: "assay_des", val: "Assay Description" },
      { key: "createdon_datetime", val: "Created On" },
      { key: "updatedon_datetime", val: "Updated On" },
      { key: "status", val: 'Status' }



    ],
    updateendpoint: "intake/assaystatus",

    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "intake/assaystatus",

    deleteendpointmany: "intake/deleteassay",
    tableheaders: ["assay_name", "assay_des", "status", "updatedon_datetime"],


    custombuttons: [
      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },
      {
        label: "Preview",
        type: 'action',
        datatype: 'api',
        endpoint: 'intake/previewassay',
        otherparam: [],
        // cond:'status',
        // condval:0,
        param: '_id',
        datafields: ['assay_name', 'assay_des', 'reference', 'createdon_datetime', 'updatedon_datetime', 'status'],
        //  refreshdata: true,
        headermessage: 'Preview',
        tooltip: 'Preview',
        classname: 'previewButton'
      },
      {
        label: "Map CPT Code",
        type: 'listner',
        id: 'map_cpt_code',
        tooltip: 'Map CPT Code',
        name: 'map_cpt_code',
        classname: 'map_cpt_code'
      },
      {
        label: "Map ICD Code",
        type: 'listner',
        id: 'map_icd_code',
        tooltip: 'Map ICD Code',
        name: 'map_icd_code',
        classname: 'map_icd_code'
      },

      {
        label: "Assay Range",
        type: 'listner',
        id: 'assay_range',
        tooltip: 'Assay Range',
        name: 'assay_range',
        classname: 'assay_range'
      },


      //     {
      //       label: "Preview",
      //     type: 'action',
      //     datatype: 'local',
      //       datafields: ['assay_name', 'assay_des', 'status', 'createdon_datetime',  'updatedon_datetime'],
      //     headermessage: 'Local Info',
      //     // cond:'status',
      //     // condval:0
      //       classname: 'previewButton'
      // },
    ],

  }
  public taxonomy_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;
  constructor(private httpService: HttpServiceService, public router: Router, public activatedRoute: ActivatedRoute, private _bottomSheet: MatBottomSheet, public cookieService: CookieService, public dialog: MatDialog) {
    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous')) {
      this.router.navigateByUrl('404')
    }
  }
  openBottomSheet(): void {
    this._bottomSheet.open(AddUserBottomSheet);
  }

  ngOnInit() {



    this.progressLoader = true;
    this.userListFetching();
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
      switch (this.cookieData.user_type) {
        case 'admin':
          this.isAdminUser = true;

          break;
        case 'doctor':
          this.isDoctorUser = true;
          break;

        case 'laboratory':
          this.libdata.basecondition = { addedby: this.cookieData._id }
          break;

      }
    }
    console.log("cookieService", this.cookieData)


  }
  addBtn() {
    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/add-assay`);
  }
  userListFetching() {
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {}


    }

    if (this.cookieData.user_type == "laboratory") {
      data.searchcondition = {

        addedby: this.cookieData._id,
      }

    }



    this.activatedRoute.data.subscribe((response: any) => {
      this.listprogressBar = true
      console.log("activatedRoute", response)
      if (response) {
        this.tabledatatalist = response.data.results.res;
        setTimeout(() => {
          this.listprogressBar = false
        }, 150);
      }
    })

    this.httpService.httpViaPost('intake/assaylist-count', data).subscribe((response: any) => {
      // console.log(response)
      if (response) {
        this.date_search_source_count = response.count;
      }

      // this.progressLoader = false;
    })

    // httpService: ;


    console.log("this.tabledatatalist", this.tabledatatalist)

  }



  onLiblistingButtonChange(val: any) {
    // throw new Error('Function not implemented.');
    console.log("test>>>>>>>", val);
    this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/add-assay`);



  }
  listenLiblistingChange(data: any = null) {
    console.log("test", data);
    if (data.action == "custombuttonclick") {
      if (data.custombuttonclick.btninfo.id == 'edit_btn') {
        this.editListItem(data.custombuttonclick.data);
      }
      if (data.custombuttonclick.btninfo.id == "map_cpt_code") {
        const dialogRef = this.dialog.open(SelectCPTCodeDialog, {
          panelClass: "symptoms-modal-class",
          data: { modalData: data.custombuttonclick.data._id }
        });
      }
      if (data.custombuttonclick.btninfo.id == "map_icd_code") {
        const dialogRef = this.dialog.open(AssayMapWithIcdCodeComponent, {
          panelClass: "mapICDCode-modal-class",
          data: { modalData: data.custombuttonclick.data._id }
        });
      }
      if (data.custombuttonclick.btninfo.id == "assay_range") {


        const dialogRef = this.dialog.open(AssayRangeDialog, {
          panelClass: "assayrange-modal-class",
          data: { modalData: data.custombuttonclick.data._id }
        });


      }
    }
    if (data.action == "multipleselectionchange") {
      const actionbuttonwrapperDiv = document.querySelector('.tablewrapper');
      if (data.selecteddata.length > 0) {
        actionbuttonwrapperDiv.classList.add("remove");
      } else {
        actionbuttonwrapperDiv.classList.remove("remove");
      }
    }
    if (data.action == "statusupdate") {
      if (data.totalresults.status == 'success') {
        console.log('statusupdate>>>>', data);
        this.taxonomy_updatetable = !this.taxonomy_updatetable;

      }

    }
    if (data.action == "multiplestatusupdate") {
      console.log('statusupdate>>>>', data);
      this.taxonomy_updatetable = !this.taxonomy_updatetable;
    }
  }
  editListItem(item: any) {
    if (item) {
      console.log("Op=====>1", item);
      this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/edit-assay/${item._id}`);


    }
  }
}

@Component({
  selector: 'select-cpt-code-dialog',
  templateUrl: './select-cpt-code-modal.html',
})


export class SelectCPTCodeDialog {
  public modalSymptomsData: any;
  userNameModalChange = new Subject<string>();
  public nameprogressFlag: boolean = false;
  nameselectedData: any = null;
  nameArr: any = [];
  public cookieData: any = {};
  public userId: any;
  public modalrogressBar: any = false;

  public Url: any;
  // public signupLinkpac: any;
  public env = environment;
  public diagnosticId: any = null;
  public symptomsName: string = null;
  constructor(
    public dialogRef: MatDialogRef<SelectCPTCodeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matSnackBar: MatSnackBar,
    public router: Router,
    public cookieService: CookieService,
    public apiservice: HttpServiceService,
  ) {
    console.log('DDD>>', data);
    this.diagnosticId = data.modalData

    this.modalSymptomsData = data.searchItem;
    console.log("env++++", this.modalSymptomsData);

  }


  ngOnInit() {
    if (this.cookieService.getAll()['login_user_details']) {
      this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    }
    if (this.modalSymptomsData) {
      if (this.modalSymptomsData.nameArr && this.modalSymptomsData.nameArr.length > 0) {
        this.modalSymptomsData.nameArr.forEach((e: any, i: number) => {
          this.namechooseChipItem(e, i, 'patch');
        })
      }
    }


    this.userNameModalChange.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        this.nameprogressFlag = true;
        if (value && value !== '') {
          let data: any = {
            "searchcondition": { "name_search": value }
            // "search_str": value,
            // "sort": { "field": "_id", "type": "desc" },
            // "allSearchCond": [],
            // "basecondition": {
            //   // user_type: { $in: [this.userType] }
            // },
            // "datasearch": {},
            // "textsearch": {},
            // "buttonSearch": {},
            // "selectsearch": {},
            // "secret": "na",
            // "token": ""
          }
          this.apiservice.httpViaPost('intake/cptcodeautocomplete', data).subscribe((response) => {
            // console.log("The dialog userNameAutocomplete response", response)
            this.nameprogressFlag = false;
            if (response) {
              this.nameArr = response.res
            }
          })
        }
      });


    let data: any = {

      "searchcondition": {
        _id: this.diagnosticId
      },

    }
    this.modalrogressBar = true
    this.apiservice.httpViaPost('intake/getmapcpt', data).subscribe((response) => {
      console.log("The dialog userNameAutocomplete response", response.results.res[0].cpt_code_id_val)
      // this.nameprogressFlag = false;          
      if (response) {
        if (response.status == "success") {
          this.modalrogressBar = false
          console.log("modalrogressBar", this.modalrogressBar);

          if (response.results.res[0].cpt_code_id_val) {
            this.nameselectedData = response.results.res[0].cpt_code_id_val
          }

          console.log(this.nameselectedData, "this.nameselectedData");
        }

      }


    })





    console.log("DialogData", this.cookieData);
    console.log("emailintakeData>>>", this.diagnosticId);


  }

  namechooseChipItem(item: any, i: number, type?: string) {
    console.log("nameselectedData", item);

    // if (this.nameselectedData && Object.keys(this.nameselectedData).length > 0) {
    //   this.nameselectedData = this.nameselectedData.filter((e) => {
    //     return e._id !== item._id
    //   });
    // }
    this.modalrogressBar = false
    this.nameselectedData = null;
    this.nameselectedData = item;
    // this.nameselectedData.name = this.nameselectedData.val;
    console.log("nameselectedData", this.nameselectedData);


    // if (!type) {
    //   this.modalSymptomsData.fullname = "";
    // }
    this.symptomsName = ''
    // console.log("symptomsName", this.symptomsName);

  }

  remove(value: any, flag: any) {
    this.nameselectedData = null;
  }

  closeModal(): void {
    this.dialogRef.close();
    // this.router.navigateByUrl(this.cookieData.user_type + `/email-intake-list`);
  }
  onNoClick() {
    console.log("listenFormFieldChangeval,", this.nameselectedData, this.diagnosticId)
    let data = {
      cpt_code_id_val: this.nameselectedData,
      _id: this.diagnosticId,
      status: 1
    };

    this.apiservice.httpViaPost('intake/addassay', data).subscribe((response) => {
      // console.log("The dialog userNameAutocomplete response", response)
      this.nameprogressFlag = false;
      if (response) {
        this.nameArr = response.res
      }
      if (response.status == "success") {
        this.matSnackBar.open("Symptoms Maping Successfully", "Ok", {
          duration: 1000
        });
        this.dialogRef.close();
      }
    })


    // this.dialogRef.close();
  }
}



@Component({
  selector: 'AssayRangeDialog',
  templateUrl: './assay-range-modal.html',
})


export class AssayRangeDialog {

  constructor(
    public dialogRef: MatDialogRef<AssayRangeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matSnackBar: MatSnackBar,
    public router: Router, private httpService: HttpServiceService,
    public cookieService: CookieService,
    public apiservice: HttpServiceService,
  ) {
    console.log('data>>', data);


    this.progressLoader = true;


  }

  public getassayrangeData: any;
  public addFormLoader: boolean = false;

  public practiceformdata: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];
  public progressLoader: boolean = false;
  public datasource: any;
  tabledata_header_skip: any = ["_id"];
  tabledata_detail_skip: any = ['_id', 'usertype'];
  public tabledatatalist: []
  tablename = "users";
  editroute = "";
  updateendpoint = "intake/assayrangestatus";
  deleteendpoint = "intake/deleteassayrange";
  datacollection: any = "intake/assayrangelist";
  public listprogressBar: any = false;

  searchendpoint = "";
  date_search_endpoint: any = "intake/assayrangelist";
  date_search_source: any = "users";
  date_search_source_count: any = 0;
  Tabledata_header_skip: any = ["_id"];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  modify_header_array: any = {
    range_label: "Range Label",
    range_max_value: "Range Max Value",
    range_max_value_notes: "Range Max Value Note",
    range_min_value: "Range Min Value",
    range_min_value_notes: "Range Min Value Notes",
    range_unit: "Range Unit",
    createdon_datetime: "Created On",
    status: "Status"
  };
  search_settings: any = {


    // search: [  
    //   {
    //   label: "Search By Range Label", field: '_id',
    //   serversearchdata: { endpoint: 'intake/assayautocomplete' },
    // },
    // ],

    textsearch: [
      { label: "Search By Range Label", field: 'range_label' },

    ],


    datesearch: [
      {
        startdatelabel: 'Update On Start Date',
        enddatelabel: 'Update On End Date',
        submit: 'Search',
        field: 'updatedon_datetime',
        // value: {$gte: createdon_datetime, $lte: 1622962799000}
      },
    ],

    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },

    ],
  }
  sortdata: any = {
    type: "desc",
    field: "_id",
    options: ["createdon_datetime", "status", "range_label"],
  };
  public cookieData: any = {};
  public isdatahas: boolean = false;


  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [

    ],
  }
  libdata: any = {
    basecondition: {},

    detailview_override: [
      { key: "range_label", val: "Range Label" },
      { key: "range_max_value", val: "Range Max Value" },
      { key: "range_max_value_notes", val: "Range Max Value Note" },
      { key: "range_min_value", val: "Range Min Value" },
      { key: "range_min_value_notes", val: "Range Min Value Notes" },
      { key: "range_unit", val: "range_unit" },

      { key: "createdon_datetime", val: "Created On" },

      { key: "status", val: 'Status' }



    ],
    updateendpoint: "intake/assayrangestatus",

    hidedeletebutton: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: true,
    hideaction: false,
    updateendpointmany: "intake/assayrangestatus",

    deleteendpointmany: "intake/deletecptcode",
    tableheaders: ["range_label", "range_max_value", "range_max_value_notes", "range_min_value", "range_min_value_notes", "range_unit", "status", "createdon_datetime"],


    custombuttons: [

      {
        label: "Edit",
        type: 'listner',
        id: 'edit_btn',
        tooltip: 'Edit',
        name: 'edit',
        classname: 'edit_btn'
      },




    ],

  }
  public modal_updatetable: boolean = false;
  public api_url_for_managebanner = this.httpService.baseUrl;
  public jwttokenformanagebanner = this.httpService.jwtToken;




  onLiblistingButtonChange(val: any) {



  }
  listenLiblistingChangemodal(data: any = null) {

    console.log("test>>>>>>>", data);

    if (data.custombuttonclick.btninfo.id == 'edit_btn') {

      this.progressLoader = true;

      let dataobj = {
        searchcondition: {
          _id: data.custombuttonclick.data._id
        }
      };

      this.apiservice.httpViaPost('intake/getassayrange', dataobj).subscribe((response) => {
        console.log("dataobj", response)
        if (response && response.status == 'success') {


          // this.initailForm();
          this.editFormData = response.results.res[0]


          this.editListItem(this.editFormData);

          console.log("editFormData>>>>>>>", this.editFormData);

          this.progressLoader = false;
          // var addBlock = document.querySelector('.addblock');
          // addBlock.classList.add('open_addblock');


          // var Assaylistblock = document.querySelector('.Assaylistblock');
          // Assaylistblock.classList.add('closelistblock');

          // const addblockElement = document.querySelector('.addblock');
          // if (addblockElement) {
          //   addblockElement.scrollIntoView({ behavior: 'smooth' });

          // }
          // var closelistblock = document.querySelector('.closelistblock');


          // if (closelistblock) {
          //   closelistblock.classList.remove('closelistblock');
          // }


        }



      })


    }

  }
  editListItem(item: any) {
    if (item) {
      console.log("Op=====>1", item);
      this.progressLoader = false;
      this.isedit = true;
      this.initailFormmodal();
      setTimeout(() => {
        this.showForm = true;
      }, 1000)


    }
  }




  initailFormmodal() {

    console.log("this.editFormData++++", this.editFormData)
    this.practiceformdata =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',


      fields: [


        {
          label: 'Range Label',
          name: 'range_label',

          value: this.editFormData && this.editFormData.range_label ? this.editFormData.range_label : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Range Label is Required' }
          ]
        },
        {
          label: 'Range Min Value',
          name: 'range_min_value',
          type: 'number',
          classname: 'aaa',
          value: this.editFormData && this.editFormData.range_min_value ? this.editFormData.range_min_value : '',
          validations: [
            { rule: 'required', message: 'Range Min Value Value is Required' },
            { rule: 'min', value: 0, message: 'Min Value must be greated than 0' },
          ]
        },





        {
          label: 'Range Max Value',
          name: 'range_max_value',
          type: 'number',
          classname: 'aaa',
          value: this.editFormData && this.editFormData.range_max_value ? this.editFormData.range_max_value : '',
          validations: [
            { rule: 'required', message: 'Range Max Value is Required' },
            { rule: 'min', value: 0, message: 'Min Value must be greated than 0' },
          ]
        },

        {
          label: 'Range Unit',
          name: 'range_unit',
          type: 'text',
          classname: 'aaa',
          value: this.editFormData && this.editFormData.range_unit ? this.editFormData.range_unit : '',
          validations: [
            { rule: 'required', message: 'Range Max Value Suggested Notes is Required' }
          ]
        },


        {
          label: 'Range Max Value Suggested Notes',
          name: 'range_max_value_notes',
          type: "textarea",

          value: this.editFormData && this.editFormData.range_max_value_notes ? this.editFormData.range_max_value_notes : '',
          validations: [
            { rule: 'required', message: 'Range Max Value Suggested Notes is Required' }
          ]
        },

        {
          label: 'Range Min Value Suggested Notes',
          name: 'range_min_value_notes',
          type: "textarea",

          value: this.editFormData && this.editFormData.range_min_value_notes ? this.editFormData.range_min_value_notes : '',
          validations: [
            { rule: 'required', message: "Range Min Value Suggested Notesto be required" },
          ]
        },








        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          val: this.statusarr,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
          validations: [
            // { rule: 'required', message: 'Required Type' },
          ]
        },


      ]
    }


  }

  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChangevalnew", val)



    // if (val.field && val.field == "fromsubmit") {


    //   this.addFormLoader = true;
    //   // let dataobj = {

    //   //   data: val.fromval,
    //   //   assay_id: this.data.modalData
    //   // }

    //   let dataobj = {
    //     data: val.fromval
    //   };
    //   dataobj.data.assay_id = this.data.modalData;

    //   if (this.editFormData) {
    //     dataobj.data._id = this.editFormData._id;
    //   }

    //   this.apiservice.httpViaPost('intake/addassayrange', dataobj).subscribe((response) => {
    //     console.log("dataobj", response)
    //     if (response && response.status == 'success') {
    //       this.matSnackBar.open(response.message, "Ok", {
    //         duration: 1000
    //       });

    //       this.addFormLoader = false;

    //       this.getassayrangefunction();

    //        this.tabledatatalist = []

    //       var addBlock = document.querySelector('.open_addblock');


    //       if (addBlock) {


    //         addBlock.classList.remove('open_addblock');

    //         // Check if the addBlock element exists
    //         addBlock.classList.remove('open_addblock');

    //         // Select the element with class 'closelistblock'
    //         var closelistblock = document.querySelector('.closelistblock');

    //         // If the closelistblock element exists, remove its class 'closelistblock'
    //         if (closelistblock) {
    //           closelistblock.classList.remove('closelistblock');
    //         }
    //       }
    //     }

    //     if (response && response.status == 'error') {
    //       this.matSnackBar.open(response.message, "", {
    //         duration: 1000
    //       });
    //       this.addFormLoader = false;
    //     }

    //   })




    // }



    if (val.field && val.field == "fromsubmit") {
      if (val.fromval && val.fromval.range_min_value) {
        if (JSON.parse(val.fromval.range_min_value) > JSON.parse(val.fromval.range_max_value)) {
          this.matSnackBar.open("Max Value must be greated than Min value", "Ok", {
            duration: 1000
          });
        } else {
          this.progressLoader = true;
          let dataobj = {
            data: val.fromval
          }
          dataobj.data.assay_id = this.data.modalData;

          if (this.editFormData) {
            dataobj.data._id = this.editFormData._id
          }

          console.log('dataobjTest', dataobj)
          this.httpService.httpViaPost('intake/addassayrange', dataobj).subscribe((response) => {
            console.log("dataobj", response)
            if (response && response.status == 'success') {
              this.matSnackBar.open(response.message, "Ok", {
                duration: 1000
              });
              this.tabledatatalist = []
              this.getassayrangefunction();
              this.showForm = false;

              // var addBlock = document.querySelector('.open_addblock');


              // if (addBlock) {


              //   addBlock.classList.remove('open_addblock');


              //   addBlock.classList.remove('open_addblock');


              //   var closelistblock = document.querySelector('.closelistblock');


              //   if (closelistblock) {
              //     closelistblock.classList.remove('closelistblock');
              //   }
              // }

              this.modal_updatetable = !this.modal_updatetable;
              setTimeout(() => {

                this.progressLoader = false;
              }, 1000)
            }

            if (response && response.status == 'error') {
              this.matSnackBar.open(response.message, "", {
                duration: 1000
              });
              this.progressLoader = false;
            }

          })
        }
      }


    }


    if (val.field && val.field == "formreset") {

    }

    if (val.field && val.field == "formcancel") {
      this.showForm = false;
      // var addBlock = document.querySelector('.open_addblock');
      // if (addBlock) {
      // Check if the addBlock element exists
      // addBlock.classList.remove('open_addblock');

      // // Select the element with class 'closelistblock'
      // var closelistblock = document.querySelector('.closelistblock');

      // // If the closelistblock element exists, remove its class 'closelistblock'
      // if (closelistblock) {
      //   closelistblock.classList.remove('closelistblock');
      // }
      // }



    }
  }

  ngOnInit() {



    this.getassayrangefunction();


    // 



  }


  getassayrangefunction() {




    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id'
      },
      searchcondition: {

        assay_id: this.data.modalData
      }
    }

    this.apiservice.httpViaPost('intake/assayrangelist', data).subscribe((response) => {


      if (response) {
        console.log("addassayrange", response)
        this.progressLoader = false;
        this.tabledatatalist = response.results.res


        let dataCount = {
          condition: {
            limit: 10,
            skip: 0
          },
          sort: {
            type: "desc",
            field: "_id"
          },
          searchcondition: {
            assay_id: this.data.modalData
          }
        };




        this.httpService.httpViaPost('intake/assayrangelist-count', dataCount).subscribe((response: any) => {

          if (response) {
            this.date_search_source_count = response.count;
          }


        })



        // if (response.results.res.length > 0) {
        //   this.isdatahas=true;
        // }

      }


    })

  }

  public showForm: boolean = false;
  addBtn() {
    // alert('ff')

    // var addBlock = document.querySelector('.addblock');
    // addBlock.classList.add('open_addblock');

    // const addblockElement = document.querySelector('.addblock');
    // if (addblockElement) {
    //   addblockElement.scrollIntoView({ behavior: 'smooth' });
    // }

    // var Assaylistblock = document.querySelector('.Assaylistblock');
    // Assaylistblock.classList.add('closelistblock');
    this.showForm = true;
    this.editFormData = null;
    this.initailFormmodal();

  }






  closeModal(): void {
    this.dialogRef.close();

  }

}