<div class="ModalTabSubConPage">
    <mat-progress-bar mode="indeterminate" *ngIf="progressBarLoader"></mat-progress-bar>
    <h1>Recommended Next Steps by Potential Diagnosis
    </h1>

    <div class="recomendedContainerFluid">
        <div class="recomendedContainer">
            <!-- <h3>Recommended Next Steps by Potential Diagnosis</h3> -->
            <div class="recomendedContainerMain">
                <div class="recomendedContainerInnerDiv" *ngFor="let step of recommendedData">
                    <div class="recomendedContainerInnerImageDiv">
                        <img src="{{step.diago_image_link}}" alt="{{step.diago_image_link}}">
                    </div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP">
                            <span class="label">Validated Diagnosis :</span> <span class="content">{{step.additional_diagnostic_name}}</span>
                        </p>
                        <p><span class="label">Validated ICD Code :</span> <span class="content" innerHtml="{{step.additional_diagnostic_des}}"></span></p>
                        <p><span class="label">Matched Symptoms :</span>

                            <ng-container>
                                <span class="content">
                                    <ul class="matchedSymptomList">
                                        <li *ngFor="let symptom of step.matched_symptoms">
                                            {{symptom}}
                                        </li>
                                    </ul>
                                    <!-- {{step.matched_symptoms}} -->
                                </span>
                            </ng-container>
                        </p>
                    </div>

                    <!-- <div class="recomendedContainerInnerContentDiv">
                    <p>Matched Symptoms <span>{{step.matched_symptoms}}</span></p>
                </div> -->
                </div>
            </div>
            <div class="blankDataClass" *ngIf="recommendedData && recommendedData.length == 0">No Additional Next Steps to this Patient !</div>
        </div>
    </div>

    <!-- <div class="recomendedContainerFluid ">
        <div class="recomendedContainer">
             
            <div class="recomendedContainerMain">
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/a1c.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/a1c.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">A1C 5.7%–6.4%</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>R73.3 - Pre-Diabetes</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Increased Hunger </li>
                                    <li class=""> Fatigue (Tiredness) </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/impaired_fasting_glucose.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/impaired_fasting_glucose.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Impaired Fasting Glucose</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>R73.3 - Pre-Diabetes</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Increased Hunger </li>
                                    <li class=""> Fatigue (Tiredness) </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/impaired_glucose_tolerance.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/impaired_glucose_tolerance.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Impaired Glucose Tolerance</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>R73.3 - Pre-Diabetes</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Increased Hunger </li>
                                    <li class=""> Fatigue (Tiredness) </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/ncv.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/ncv.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Non-Invasive Nerve Conduction Study</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>R73.3 - Pre-Diabetes</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Fatigue (Tiredness) </li>
                                    <li class=""> Increased Hunger </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/ct.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/ct.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Computed Tomography</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>G60.3 - Perpheral &amp; Distal Perpheral Neuropathy</li>
                                    <li>I70.209 - Atherosclerosis</li>
                                    <li>I25.10 - Atherosclerotic Heart Disease Of Native Coronary Artery With Unstable
                                        Angina Pectoris</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Shortness of Breath </li>
                                    <li class=""> Pain In Calves </li>
                                    <li class=""> Rapid, Shallow Breathing </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/usg.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/usg.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Ultrasound</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>G60.3 - Perpheral &amp; Distal Perpheral Neuropathy</li>
                                    <li>I73.9 - Peripheral Artery Disease (PAD Or PVD)</li>
                                    <li>I25.10 - Atherosclerotic Heart Disease Of Native Coronary Artery With Unstable
                                        Angina Pectoris</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Pain In Calves </li>
                                    <li class=""> Shortness of Breath </li>
                                    <li class=""> Rapid, Shallow Breathing </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/angiogram.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/angiogram.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Angiogram (Arteriogram)</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>I70.209 - Atherosclerosis</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Shortness of Breath </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/xray.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/xray.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Chest X-ray</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>I70.209 - Atherosclerosis</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Shortness of Breath </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/xray.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/xray.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Electrocardiogram</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>I70.209 - Atherosclerosis</li>
                                    <li>R00.0 - Abnormal Heart Rate Variabilty</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Shortness of Breath </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/angiography.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/angiography.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Angiography</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>I73.9 - Peripheral Artery Disease (PAD Or PVD)</li>
                                    <li>I25.10 - Atherosclerotic Heart Disease Of Native Coronary Artery With Unstable
                                        Angina Pectoris</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Pain In Calves </li>
                                    <li class=""> Shortness of Breath </li>
                                    <li class=""> Rapid, Shallow Breathing </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/stress_test.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/stress_test.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Stress Test</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>I25.10 - Atherosclerotic Heart Disease Of Native Coronary Artery With Unstable
                                        Angina Pectoris</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Pain In Calves </li>
                                    <li class=""> Shortness of Breath </li>
                                    <li class=""> Rapid, Shallow Breathing </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/photoplethysmography.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/photoplethysmography.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Photoplethysmography</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>R00.0 - Abnormal Heart Rate Variabilty</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Dizziness or Lightheadedness </li>
                                    <li class=""> Shortness of Breath </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.peceportal.com/assets/images/holter_monitor.jpg"
                            alt="https://dev.peceportal.com/assets/images/holter_monitor.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Holter Monitor</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>R00.0 - Abnormal Heart Rhythm Stability</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Dizziness or Lightheadedness </li>
                                    <li class=""> Shortness of Breath </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.peceportal.com/assets/images/transtelephonic_monitor.jpg"
                            alt="https://dev.peceportal.com/assets/images/transtelephonic_monitor.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Transtelephonic Monitor</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>R00.0 - Abnormal Heart Rhythm Stability</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Dizziness or Lightheadedness </li>
                                    <li class=""> Shortness of Breath </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.peceportal.com/assets/images/treadmill_testing.jpg"
                            alt="https://dev.peceportal.com/assets/images/treadmill_testing.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Treadmill Testing</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>R00.0 - Abnormal Heart Rhythm Stability</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Dizziness or Lightheadedness </li>
                                    <li class=""> Shortness of Breath </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/tilt_table.webp"
                            alt="https://dev.decisiondoc.com/assets/images/tilt_table.webp"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Tilt-Table Test</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>R00.0 - Abnormal Heart Rhythm Stability</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Dizziness or Lightheadedness </li>
                                    <li class=""> Shortness of Breath </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.peceportal.com/assets/images/ep_study.jpg"
                            alt="https://dev.peceportal.com/assets/images/ep_study.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Electrophysiology (EP) Study</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>R00.0 - Abnormal Heart Rhythm Stability</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Dizziness or Lightheadedness </li>
                                    <li class=""> Shortness of Breath </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.decisiondoc.com/assets/images/ep_study.jpg"
                            alt="https://dev.decisiondoc.com/assets/images/ep_study.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Esophageal Electrophysiologic Procedure</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>R00.0 - Abnormal Heart Rhythm Stability</li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Dizziness or Lightheadedness </li>
                                    <li class=""> Shortness of Breath </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.peceportal.com/assets/images/a1c.jpg"
                            alt="https://dev.peceportal.com/assets/images/a1c.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">A1C 5.7%–6.4%</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>E11.42 - Type 2 Diabetes Mellitus With Other Circulatory Complications</li>
                                    <li>E11.49 - Type 2 Diabetes Mellitus With Other Diabetic Neurological Complication
                                    </li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Dizziness or Lightheadedness </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.peceportal.com/assets/images/impaired_fasting_glucose.jpg"
                            alt="https://dev.peceportal.com/assets/images/impaired_fasting_glucose.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Impaired Fasting Glucose</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>E11.42 - Type 2 Diabetes Mellitus With Other Circulatory Complications</li>
                                    <li>E11.49 - Type 2 Diabetes Mellitus With Other Diabetic Neurological Complication
                                    </li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Dizziness or Lightheadedness </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.peceportal.com/assets/images/impaired_glucose_tolerance.jpg"
                            alt="https://dev.peceportal.com/assets/images/impaired_glucose_tolerance.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Impaired Glucose Tolerance</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>E11.42 - Type 2 Diabetes Mellitus With Other Circulatory Complications</li>
                                    <li>E11.49 - Type 2 Diabetes Mellitus With Other Diabetic Neurological Complication
                                    </li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Dizziness or Lightheadedness </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
                <div class="recomendedContainerInnerDiv ">
                    <div class="recomendedContainerInnerImageDiv"><img
                            src="https://dev.peceportal.com/assets/images/ncv.jpg"
                            alt="https://dev.peceportal.com/assets/images/ncv.jpg"></div>
                    <div class="recomendedContainerInnerContentDiv">
                        <p class="validateDiagnosisP"><span class="label">Validated Diagnosis :</span><span
                                class="content">Non-Invasive Nerve Conduction Study</span></p>
                        <p><span class="label">Validated ICD Code :</span><span class="content">
                                <ul>
                                    <li>E11.42 - Type 2 Diabetes Mellitus With Other Circulatory Complications</li>
                                    <li>E11.49 - Type 2 Diabetes Mellitus With Other Diabetic Neurological Complication
                                    </li>
                                </ul>
                            </span></p>
                        <p><span class="label">Matched Symptoms :</span><span class="content">
                                <ul class="matchedSymptomList">
                                    <li class=""> Dizziness or Lightheadedness </li>
                                </ul>
                            </span></p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->


</div>