<div class="signPageDialogBody symptomReasonModal adminAvailableTestModal">
    <button mat-button (click)="onNoClick()" class="closealert"> <mat-icon>close</mat-icon></button>
    <mat-card class="adminBody diagnostic_listwrapper">
        <mat-card-content class="Emailtemplatelist listingpage_wrapper list_style1">

            <h2 class="listingpageh2">Add Available Tests</h2>
            <div class="admin-list_v1 labDiagnosis">
                <div *ngIf="chooseDiagnosisLoader" class="progressbar_wrapper">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div>
                    <lib-listing class="adminlisttable_v1 DiagnosticCategory" *ngIf="tabledatatalist != null " [datasource]="tabledatatalist" [skip]="tabledata_header_skip" [modify_header_array]="modify_header_array" [apiurl]="api_url_for_managebanner"
                        [deleteendpoint]="deleteendpoint" [date_search_source]="date_search_source" [date_search_endpoint]="date_search_endpoint" [sourcedata]="tablename" [editroute]="editroute" [statusarr]="statusarray" [detail_skip_array]="tabledata_detail_skip"
                        [jwttoken]="jwttokenformanagebanner" [search_settings]="search_settings" [searchendpoint]="searchendpoint" [sortdata]="sortdata" [datacollection]="datacollection" [date_search_source_count]="date_search_source_count" [libdata]="libdata"
                        [updatetable]="daignosis_updatetable" [limitcond]="limitcond" [customlistenbutton]="customlistenbutton" (onLiblistingButtonChange)="onLiblistingButtonChange($event)" (onLiblistingChange)="listenLiblistingChange($event)" (onFormFieldChange)="listenFormFieldChange($event)">
                    </lib-listing>
                </div>
                <!-- <div *ngIf="tabledatatalist.length == 0">
                    <p>opps</p>
                   <button (click)="reload()">Back to List</button> test
                </div> -->
                <!-- <div class="signmodalgroup">
                <button type="submit" mat-raised-button color="primary" (click)="submitDiagnosis()">Submit</button>
                <button type="button" mat-raised-button color="accent" (click)="onNoClick()">Cancel</button>
            </div> -->
                <div *ngIf="chooseDiagnosisLoader" class="progressbar_wrapper">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>