import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpServiceService } from 'src/app/service/http-service.service';

@Component({
  selector: 'app-equipment-add-edit',
  templateUrl: './equipment-add-edit.component.html',
  styleUrls: ['./equipment-add-edit.component.css']
})
export class EquipmentAddEditComponent {
  data: any;
  flag: any;
  externaldatavalue: any;
  name: any;
  value: any;

  public addFormLoader: boolean = false;
  public cookieData: any = {};
  public equipmentFormData: any = {};
  public diagnosticcatautocompleteData: any = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public isedit: boolean = false;
  public editFormData: any = null;
  public diagnosticcatautocompleteDataNew: any = {};


  statusarr: any = [{ val: 1, name: 'Active' }, { val: 0, name: 'Inactive' }];
  constructor(public cookieService: CookieService, public router: Router, public apiservice: HttpServiceService, public dialog: MatDialog, public matSnackBar: MatSnackBar, public activateRoute: ActivatedRoute) {

    this.cookieData = JSON.parse(this.cookieService.getAll()['login_user_details']);
    if (!window.location.pathname.includes('admin/miscellaneous') && !window.location.pathname.includes('laboratory/add-diagnostic') && !window.location.pathname.includes('laboratory/edit-diagnostic')) {
      this.router.navigateByUrl('404')
    }
    console.log('cookieDataType', this.cookieData._id)

  }
  ngOnInit() {
    if (this.activateRoute.snapshot.params && this.activateRoute.snapshot.params["_id"]) {
      this.activateRoute.data.subscribe((response: any) => {
        console.log("edit data", response)
        if (response) {
          if (response.data && response.data.status == "success") {
            this.isedit = true;
            this.editFormData = response.data.results.res[0];
            console.log("Edit data", this.editFormData);

          }
        }
      })
    }

    this.initailForm();
  }

  initailForm() {
    this.equipmentFormData =
    {
      // from start
      successmessage: 'Added Successfully !!', // success message
      submittext: this.editFormData ? 'Update' : 'Submit',
      apiUrl: this.apiservice.baseUrl,
      // endpoint: 'users/addUpdateData',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: this.editFormData ? true : false,
      jwttoken: '',
      cancelroute: this.cookieData.user_type + `/miscellaneous/equipment-list`, // use for redirect after cancel the form
      fields: [

        {
          label: 'Equipment Name',
          name: 'equipment_name',
          value: this.editFormData && this.editFormData.equipment_name ? this.editFormData.equipment_name : '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Equipment Name is Required' },

          ],
        },
        {
          label: "Description",
          name: "description",
          type: "textarea",
          // group: "grp1",
          rows: 1,
          cols: 2,
          value: this.editFormData && this.editFormData.description ? this.editFormData.description : '',
          // hint: "Desc .... ",
          validations: [
            { rule: 'required', message: "Description field Needs to be required" },
          ]
        },

        {
          label: 'Active',
          name: 'status',
          type: 'checkbox',
          val: this.statusarr,
          value: this.editFormData && this.editFormData.status ? this.editFormData.status : 0,
          validations: [
            // { rule: 'required', message: 'Required Type' },
          ]
        },
        {
          label: this.editFormData ? 'Edit Images' : 'Upload Images',
          name: 'equipment_images',
          type: 'file',
          // validations: [{ rule: 'required', message: 'Image is Required' }],
          // multiple: true,
          prefix: Date.now(),
          path: 'practice2lab/',
          bucket: 'all-frontend-assets',
          // baseurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/',
          // apiurl:'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL/',
          // apideleteurl: 'https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          baseurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/',
          apiurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
          apideleteurl: 'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
          value:
            this.editFormData &&
              this.editFormData.equipment_images && Object.keys(this.editFormData.equipment_images).length > 0 &&
              this.editFormData.equipment_images.name
              ? {
                url: this.editFormData.equipment_images.baseurl,
                type: 'image',
                name: this.editFormData.equipment_images.name,
              }
              : undefined,
        },

      ]
    }
    if (this.editFormData) {
      console.log("sdfsd")
      this.formfieldrefreshdata = {
        field: "removefromcontrol",
        value: ["password", "confirmpassword"],
      };
      // this.locationFieldManage(this.editFormData.location)
    }

  }


  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChangeval", val)
    if (val) {
      if (val.field == "fromsubmit") {
        let dataObj: any = val.fromval
        if (this.isedit) {
          dataObj._id = this.editFormData._id
        }
        console.log('dataObj', dataObj)
        this.apiservice.httpViaPost('intake/addeditequipment', dataObj).subscribe((response) => {
          console.log("dataobj", response)
          if (response) {
            this.matSnackBar.open(response.message, "Ok", {
              duration: 1000
            });
            if (response.status == 'success') {
              this.router.navigateByUrl(`${this.cookieData.user_type}/miscellaneous/equipment-list`)
            }
          }
        })
      }
    }
  }
}
