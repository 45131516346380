<div class="lab_report_body">

    <div class="lab_report_body_top">

        <div class="lab_report_body_topCon1">

            <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/RepidResposeLogonew.png"
                alt="" class="repid_respose_virtual_paymentlogo" />

            <h1>Patient Details:</h1>
            <div class="nameblock nameblockfirst">
                <strong><b>Name</b>:</strong>
                <span>Robert Ken</span>
            </div>
            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Gender</b>:</strong>
                    <span>Male</span> 
                </div>

                <div class="nameblock">
                    <strong><b>Date of Birth</b>:</strong>
                    <span>1/1/1973</span>
                </div>
            </div>

            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Height</b>:</strong>
                    <span> 5’ 10’’</span>
                </div>

                <div class="nameblock">
                    <strong><b>Weight</b>:</strong>
                    <span>210 Lbs</span>
                </div>
            </div>






        </div>

        <div class="lab_report_body_topCon2">

            <h2>Referring Physician:</h2>

            <div class="nameblockBody">


                <div class="nameblockBodycondevider"></div>
                <div class="nameblockBodycon">
                    <div class="nameblock">
                        <strong><b>Name:</b></strong>
                        <span>Anne Tuggle</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Clinic:</b></strong>
                        <span>Mesa Family Medicine</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Address:</b></strong>
                        <span>120, David’s Square</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>State:</b></strong>
                        <span>Arizona</span>
                    </div>

                    <div class="nameblock">
                        <strong><b>City:</b></strong>
                        <span>Mesa</span>
                        <div class="zipblock">
                            <strong><b>ZIP:</b></strong>
                            <span>54210</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>


    <div class="lab_report_bodyConDIV">

        <h1>CBC 3 Part Differential</h1>
        <h2>Investigation</h2>

        <div class="lab_report_Sec">

            <h3>WBC count</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <!-- <div class="report_pointer"></div>
                    <div class="report_note">7,500 cells/mcL</div> -->
                    <div class="report_notetext2">
                        <p>Normal Range (4,500 - 10,000 cells/mc)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">
                    <div class="report_pointer"></div>
                    <div class="report_note">12,500 cells/mcL</div>
                    <span></span>
                </div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>
        <div class="lab_report_Sec">

            <h3>WBC differential</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">102 IU/L</div>
                    <div class="report_notetext2">
                        <p>Normal Range (44 - 147 IU/L)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>RBC count</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">

                    <div class="report_notetext2">
                        <p>Normal Range (4.1 - 5.9 million cells/mcL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">
                    <div class="report_pointer"></div>
                    <div class="report_note">6.5 U/L</div>
                    <span></span>
                </div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>HGB</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">

                    <div class="report_notetext2">
                        <p>Normal Range (12.0 - 17.5 grams/dL)</p>
                    </div>
                    <span></span>
                </div>
                <div class="report_DIV divcolor4 hgbpointermove">
                    <div class="report_pointer"></div>
                    <div class="report_note">18.2 U/L</div>
                    <span></span>
                </div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>HCT</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">

                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    

                    <div class="report_notetext2">
                        <p>Normal Range (34.9 - 50.0 %)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4 hctpointermove">
                    <div class="report_pointer"></div>
                    <div class="report_note">61.5 %</div>
                    <span></span>
                </div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>MCV</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">

                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">94 fL</div>

                    <div class="report_notetext2">
                        <p>Normal Range (80 - 100 fL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">

                    <span></span>
                </div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>MCH</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">

                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">28.0 pg</div>

                    <div class="report_notetext2">
                        <p>Normal Range (27 - 33 pg)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">

                    <span></span>
                </div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>MCHC</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">

                    <span></span>
                </div>
                <div class="report_DIV divcolor2">
                    <div class="report_pointer"></div>
                    <div class="report_note">30 grams/dL</div>
                     <span></span></div>
                <div class="report_DIV divcolor3">
                    

                    <div class="report_notetext2">
                        <p>Normal Range (32 - 36 grams/dL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">

                    <span></span>
                </div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>







    </div>






</div>
<div class="lab_report_body">

    <div class="lab_report_body_top">

        <div class="lab_report_body_topCon1">

            <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/RepidResposeLogonew.png"
                alt="" class="repid_respose_virtual_paymentlogo" />

            <h1>Patient Details:</h1>
            <div class="nameblock nameblockfirst">
                <strong><b>Name</b>:</strong>
                <span>Robert Ken</span>
            </div>
            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Gender</b>:</strong>
                    <span>Male</span>
                </div>

                <div class="nameblock">
                    <strong><b>Date of Birth</b>:</strong>
                    <span>1/1/1973</span>
                </div>
            </div>

            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Height</b>:</strong>
                    <span> 5’ 10’’</span>
                </div>

                <div class="nameblock">
                    <strong><b>Weight</b>:</strong>
                    <span>210 Lbs</span>
                </div>
            </div>






        </div>

        <div class="lab_report_body_topCon2">

            <h2>Referring Physician:</h2>

            <div class="nameblockBody">


                <div class="nameblockBodycondevider"></div>
                <div class="nameblockBodycon">
                    <div class="nameblock">
                        <strong><b>Name:</b></strong>
                        <span>Anne Tuggle</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Clinic:</b></strong>
                        <span>Mesa Family Medicine</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Address:</b></strong>
                        <span>120, David’s Square</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>State:</b></strong>
                        <span>Arizona</span>
                    </div>

                    <div class="nameblock">
                        <strong><b>City:</b></strong>
                        <span>Mesa</span>
                        <div class="zipblock">
                            <strong><b>ZIP:</b></strong>
                            <span>54210</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>


    <div class="lab_report_bodyConDIV">

        <h1>CBC 3 Part Differential</h1>
        <h2>Investigation</h2>

        <div class="lab_report_Sec">

            <h3>RDW</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">
                        <p>Normal Range (11.5 - 14.5 %)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5">
                    <div class="report_pointer"></div>
                    <div class="report_note">13.5 %</div><span></span>
                </div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>PLT count</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">300,000 cells/mcL</div><span></span>
                    <div class="report_notetext2">                        
                        <p>Normal Range (150,000 - 450,000 cells/mcL)</p>
                    </div>
                    <span></span>
                </div>
                <div class="report_DIV divcolor4">
                    
                </div>
                <div class="report_DIV divcolor5"> <span></span></div>

            </div>

        </div>



        <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/NEWcbc_3_girl_bg.webp"
            alt="#" class="reportconIMG">

    </div>

    <div class="lab_report_bodyConnotes">

        <h2>Notes:</h2>
        <ol>
            <li>HGB and MCHC levels are elevated, indicating a potential need for further evaluation of polycythemia and its underlying causes.</li>
        </ol>
        <h3>Interpretation/ Comments:</h3>

        <p>The results of the CBC - 3 Part Differential assays reveal abnormalities in HGB and MCHC levels, which are critical for assessing potential polycythemia. Additionally, the elevated WBC count and neutrophils suggest possible infection or inflammation. Further investigation may be warranted to explore these findings.</p>

    </div>




</div>


<div class="lab_report_body">

    <div class="lab_report_body_top">

        <div class="lab_report_body_topCon1">

            <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/RepidResposeLogonew.png"
                alt="" class="repid_respose_virtual_paymentlogo" />

            <h1>Patient Details:</h1>
            <div class="nameblock nameblockfirst">
                <strong><b>Name</b>:</strong>
                <span>Robert Ken</span>
            </div>
            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Gender</b>:</strong>
                    <span>Male</span>
                </div>

                <div class="nameblock">
                    <strong><b>Date of Birth</b>:</strong>
                    <span>1/1/1973</span>
                </div>
            </div>

            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Height</b>:</strong>
                    <span> 5’ 10’’</span>
                </div>

                <div class="nameblock">
                    <strong><b>Weight</b>:</strong>
                    <span>210 Lbs</span>
                </div>
            </div>






        </div>

        <div class="lab_report_body_topCon2">

            <h2>Referring Physician:</h2>

            <div class="nameblockBody">


                <div class="nameblockBodycondevider"></div>
                <div class="nameblockBodycon">
                    <div class="nameblock">
                        <strong><b>Name:</b></strong>
                        <span>Anne Tuggle</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Clinic:</b></strong>
                        <span>Mesa Family Medicine</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Address:</b></strong>
                        <span>120, David’s Square</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>State:</b></strong>
                        <span>Arizona</span>
                    </div>

                    <div class="nameblock">
                        <strong><b>City:</b></strong>
                        <span>Mesa</span>
                        <div class="zipblock">
                            <strong><b>ZIP:</b></strong>
                            <span>54210</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>


    <div class="lab_report_bodyConDIV">

        <h1>Liver Panel Plus</h1>
        <h2>Investigation</h2>

        <div class="lab_report_Sec">

            <h3>Albumin</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">

                    <span></span>
                </div>
                <div class="report_DIV divcolor2">
                    <div class="report_pointer"></div>
                    <div class="report_note">2.5 g/dL</div> <span></span>
                </div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">
                        <p>Normal Range (3.4 - 5.4 g/dL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5">
                    <span></span>
                </div>

            </div>


        </div>


        <div class="lab_report_Sec">

            <h3>Alkaline phosphatase</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">

                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">
                        <p>Normal Range (44 - 147 IU/L)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5">

                    <div class="report_pointer"></div>
                    <div class="report_note">197 IU/L</div>
                    <span></span>
                </div>

            </div>


        </div>

        <div class="lab_report_Sec">

            <h3>Alanine transaminase</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">

                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">
                        <p>Normal Range (7 - 56 mg/dL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">
                    <div class="report_pointer"></div>
                    <div class="report_note">63 U/L</div>
                    <span></span>
                </div>
                <div class="report_DIV divcolor5">


                    <span></span>
                </div>

            </div>


        </div>


        <div class="lab_report_Sec">

            <h3>Amylase</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">

                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">79 U/L</div>
                    <div class="report_notetext2">
                        <p>Normal Range (40 - 140 U/L)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">

                    <span></span>
                </div>
                <div class="report_DIV divcolor5">


                    <span></span>
                </div>

            </div>


        </div>


        <div class="lab_report_Sec">

            <h3>Aspartate aminotransferase</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">

                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">56 U/L</div>
                    <div class="report_notetext2">
                        <p>Normal Range (8 - 33 U/L)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">

                    <span></span>
                </div>
                <div class="report_DIV divcolor5">


                    <span></span>
                </div>

            </div>


        </div>







        <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/NEWliver_panel_girls_bg.webp"
            alt="#" class="reportconIMG">

    </div>






</div>
<div class="lab_report_body">

    <div class="lab_report_body_top">

        <div class="lab_report_body_topCon1">

            <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/RepidResposeLogonew.png"
                alt="" class="repid_respose_virtual_paymentlogo" />

            <h1>Patient Details:</h1>
            <div class="nameblock nameblockfirst">
                <strong><b>Name</b>:</strong>
                <span>Robert Ken</span>
            </div>
            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Gender</b>:</strong>
                    <span>Male</span>
                </div>

                <div class="nameblock">
                    <strong><b>Date of Birth</b>:</strong>
                    <span>1/1/1973</span>
                </div>
            </div>

            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Height</b>:</strong>
                    <span> 5’ 10’’</span>
                </div>

                <div class="nameblock">
                    <strong><b>Weight</b>:</strong>
                    <span>210 Lbs</span>
                </div>
            </div>






        </div>

        <div class="lab_report_body_topCon2">

            <h2>Referring Physician:</h2>

            <div class="nameblockBody">


                <div class="nameblockBodycondevider"></div>
                <div class="nameblockBodycon">
                    <div class="nameblock">
                        <strong><b>Name:</b></strong>
                        <span>Anne Tuggle</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Clinic:</b></strong>
                        <span>Mesa Family Medicine</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Address:</b></strong>
                        <span>120, David’s Square</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>State:</b></strong>
                        <span>Arizona</span>
                    </div>

                    <div class="nameblock">
                        <strong><b>City:</b></strong>
                        <span>Mesa</span>
                        <div class="zipblock">
                            <strong><b>ZIP:</b></strong>
                            <span>54210</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>


    <div class="lab_report_bodyConDIV">

        <h1>Liver Panel Plus</h1>
        <h2>Investigation</h2>

        <div class="lab_report_Sec">

            <h3>Gamma-Glutamyl Transpeptidase</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">
                        <p>Normal Range (7 - 48 IU/L)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5">
                    <div class="report_pointer"></div>
                    <div class="report_note">73 IU/L</div><span></span>
                </div>

            </div>



        </div>

        <div class="lab_report_Sec">

            <h3>Bilirubin (total)</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">
                        <p>Normal Range (0.2 - 1.3 mg/dL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">
                    <div class="report_pointer"></div>
                    <div class="report_note">3.4 mg/dL</div>
                    <span></span>
                </div>
                <div class="report_DIV divcolor5">
                    <span></span>
                </div>

            </div>



        </div>

        <div class="lab_report_Sec">

            <h3>Protein (total)</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">6.1 g/dL</div>
                    <div class="report_notetext2">
                        <p>Normal Range (6.0 - 8.3 g/dL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">

                    <span></span>
                </div>
                <div class="report_DIV divcolor5">
                    <span></span>
                </div>

            </div>



        </div>





        <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/Onsite-device-order/NEWliver_panel_plus_bg2.webp"
            alt="#" class="reportconIMG">

    </div>

    <div class="lab_report_bodyConnotes">

        <h2>Notes:</h2>
        <ol>
            <li>Non alcoholic fatty liver disease(NAFLD) is the most common cause of increased AST, ALT levels.
                NAFLD is considered as hepatic manifestation of metabolic syndrome.</li>
            <li>In most types of liver disease, ALT activity is higher than that of AST.</li>
        </ol>
        <h3>Interpretation/ Comments:</h3>

        <p>The results of the General Chemistry 13 assays indicate abnormalities in several liver function
            markers. These markers are essential for assessing liver health and function. Elevated levels may
            suggest potential liver damage or dysfunction, although further clinical evaluation is recommended for a
            comprehensive diagnosis.</p>

    </div>




</div>





<div class="lab_report_body">

    <div class="lab_report_body_top">

        <div class="lab_report_body_topCon1">

            <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/RepidResposeLogonew.png"
                alt="" class="repid_respose_virtual_paymentlogo" />

            <h1>Patient Details:</h1>
            <div class="nameblock nameblockfirst">
                <strong><b>Name</b>:</strong>
                <span>Robert Ken</span>
            </div>
            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Gender</b>:</strong>
                    <span>Male</span>
                </div>

                <div class="nameblock">
                    <strong><b>Date of Birth</b>:</strong>
                    <span>1/1/1973</span>
                </div>
            </div>

            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Height</b>:</strong>
                    <span> 5’ 10’’</span>
                </div>

                <div class="nameblock">
                    <strong><b>Weight</b>:</strong>
                    <span>210 Lbs</span>
                </div>
            </div>






        </div>

        <div class="lab_report_body_topCon2">

            <h2>Referring Physician:</h2>

            <div class="nameblockBody">


                <div class="nameblockBodycondevider"></div>
                <div class="nameblockBodycon">
                    <div class="nameblock">
                        <strong><b>Name:</b></strong>
                        <span>Anne Tuggle</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Clinic:</b></strong>
                        <span>Mesa Family Medicine</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Address:</b></strong>
                        <span>120, David’s Square</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>State:</b></strong>
                        <span>Arizona</span>
                    </div>

                    <div class="nameblock">
                        <strong><b>City:</b></strong>
                        <span>Mesa</span>
                        <div class="zipblock">
                            <strong><b>ZIP:</b></strong>
                            <span>54210</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>


    <div class="lab_report_bodyConDIV">

        <h1>GENERAL CHEMISTRY 13</h1>
        <h2>Investigation</h2>

        <div class="lab_report_Sec">

            <h3>Albumin</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <div class="report_pointer"></div>
                    <div class="report_note">2.5 g/dL</div> <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">
                        <p>Normal Range (3.4 - 5.4 g/dL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>Alkaline phosphatase</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">
                        <p>Normal Range (44 - 147IU/L)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5">
                    <div class="report_pointer"></div>
                    <div class="report_note">197 IU/L</div><span></span>
                </div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>Alanine transaminase</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">
                        <p>Normal Range (7 - 56 U/L)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">
                    <div class="report_pointer"></div>
                    <div class="report_note">63 U/L</div><span></span>
                </div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>Amylase</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">79 U/L</div>
                    <div class="report_notetext2">


                        <p>Normal Range (40 - 140 U/L)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>Aspartate aminotransferase</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">


                        <p>Normal Range (8 - 33 U/L)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">
                    <div class="report_pointer"></div>
                    <div class="report_note">56 U/L</div><span></span>
                </div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>Blood Urea Nitrogen</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">13 mg/dL</div>
                    <div class="report_notetext2">


                        <p>Normal Range (7 - 20 mg/dL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>Total Calcium</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">9.1 mg/dl</div>
                    <div class="report_notetext2">
                        <p>Normal Range (8.5 - 10.5 mg/dl)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>Creatinine</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">0.94 mg/dL</div>
                    <div class="report_notetext2">
                        <p>Normal Range (0.74 - 1.35 mg/dL)</p>
                    </div>
                    <span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>




    </div>






</div>

<div class="lab_report_body">

    <div class="lab_report_body_top">

        <div class="lab_report_body_topCon1">

            <img src="https://all-frontend-assets.s3.amazonaws.com/practice2lab/RepidResposeLogonew.png"
                alt="" class="repid_respose_virtual_paymentlogo" />

            <h1>Patient Details:</h1>
            <div class="nameblock nameblockfirst">
                <strong><b>Name</b>:</strong>
                <span>Robert Ken</span>
            </div>
            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Gender</b>:</strong>
                    <span>Male</span>
                </div>

                <div class="nameblock">
                    <strong><b>Date of Birth</b>:</strong>
                    <span>1/1/1973</span>
                </div>
            </div>

            <div class="nameblockcon">
                <div class="nameblock">
                    <strong><b>Height</b>:</strong>
                    <span> 5’ 10’’</span>
                </div>

                <div class="nameblock">
                    <strong><b>Weight</b>:</strong>
                    <span>210 Lbs</span>
                </div>
            </div>






        </div>

        <div class="lab_report_body_topCon2">

            <h2>Referring Physician:</h2>

            <div class="nameblockBody">


                <div class="nameblockBodycondevider"></div>
                <div class="nameblockBodycon">
                    <div class="nameblock">
                        <strong><b>Name:</b></strong>
                        <span>Anne Tuggle</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Clinic:</b></strong>
                        <span>Mesa Family Medicine</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>Address:</b></strong>
                        <span>120, David’s Square</span>
                    </div>
                    <div class="nameblock">
                        <strong><b>State:</b></strong>
                        <span>Arizona</span>
                    </div>

                    <div class="nameblock">
                        <strong><b>City:</b></strong>
                        <span>Mesa</span>
                        <div class="zipblock">
                            <strong><b>ZIP:</b></strong>
                            <span>54210</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>


    <div class="lab_report_bodyConDIV">

        <h1>GENERAL CHEMISTRY 13</h1>
        <h2>Investigation</h2>

        <div class="lab_report_Sec">

            <h3>Gamma-Glutamyl Transpeptidase</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1">
                    <div class="report_pointer"></div>
                    <div class="report_note">73 IU/L</div> <span></span>
                </div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">
                        <p>Normal Range (7 - 48 IU/L)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>Glucose</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1"> <span></span></div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">90 mg/dL</div>
                    <div class="report_notetext2">
                        <p>Normal Range (70 - 100 mg/dL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>Bilirubin (total)</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1"> <span></span></div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_notetext2">
                        <p>Normal Range (0.2 - 1.3 mg/dL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4">
                    <div class="report_pointer"></div>
                    <div class="report_note">3.4 mg/dL</div><span></span>
                </div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>

        <div class="lab_report_Sec">

            <h3>Protein (total)</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1"> <span></span></div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">6.1 g/dL</div>
                    <div class="report_notetext2">
                        <p>Normal Range (6.0 - 8.3 g/dL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>




        <div class="lab_report_Sec">

            <h3>Albumin</h3>

            <div class="lab_report_Sec_SUB">

                <div class="report_DIV divcolor1"> <span></span></div>
                <div class="report_DIV divcolor2"> <span></span></div>
                <div class="report_DIV divcolor3">
                    <div class="report_pointer"></div>
                    <div class="report_note">5.4 mg/dL</div>
                    <div class="report_notetext2">
                        <p>Normal Range (3.5 - 7.2 mg/dL)</p>
                    </div><span></span>
                </div>
                <div class="report_DIV divcolor4"><span></span></div>
                <div class="report_DIV divcolor5"><span></span></div>

            </div>

        </div>



    </div>

    <div class="lab_report_bodyConnotes">

        <h2>Notes:</h2>
        <ol>
            <li>Non alcoholic fatty liver disease(NAFLD) is the most common cause of increased AST, ALT levels.
                NAFLD is considered as hepatic manifestation of metabolic syndrome.</li>
            <li>In most types of liver disease, ALT activity is higher than that of AST.</li>
        </ol>
        <h3>Interpretation/ Comments:</h3>

        <p>The results of the General Chemistry 13 assays indicate abnormalities in several liver function markers.
            These markers are essential for assessing liver health and function. Elevated levels may suggest potential
            liver damage or dysfunction, although further clinical evaluation is
            recommended for a comprehensive diagnosis.</p>

    </div>




</div>